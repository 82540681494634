import { PayloadAction } from "@reduxjs/toolkit"
import { all, call, takeLatest } from "redux-saga/effects"
import { organizationApi } from "~/api"
import {
    organizationActions,
    SaveOrganizationAccountingContactPayload,
    SaveOrganizationAddressPayload,
    SaveOrganizationBankDetailsPayload,
    SaveOrganizationRegistrationPayload,
} from "./organizationSlice"

function* saveOrganizationRegistration(action: PayloadAction<SaveOrganizationRegistrationPayload>) {
    yield call(organizationApi.provideRegistration, action.payload.organizationId, action.payload.registration)
}

function* saveOrganizationAddress(action: PayloadAction<SaveOrganizationAddressPayload>) {
    yield call(organizationApi.provideAddress, action.payload.organizationId, action.payload.address)
}

function* saveOrganizationBankDetails(action: PayloadAction<SaveOrganizationBankDetailsPayload>) {
    yield call(organizationApi.provideBankDetails, action.payload.organizationId, action.payload.bankDetails)
}

function* saveOrganizationAccountingContact(action: PayloadAction<SaveOrganizationAccountingContactPayload>) {
    yield call(
        organizationApi.provideAccountingContact,
        action.payload.organizationId,
        action.payload.accountingContact
    )
}

export default function* organizationSaga() {
    yield all([
        takeLatest(organizationActions.saveOrganizationRegistration.type, saveOrganizationRegistration),
        takeLatest(organizationActions.saveOrganizationAddress.type, saveOrganizationAddress),
        takeLatest(organizationActions.saveOrganizationBankDetails.type, saveOrganizationBankDetails),
        takeLatest(organizationActions.saveOrganizationAccountingContact.type, saveOrganizationAccountingContact),
    ])
}
