import { useCallback } from "react"
import { useCustomFieldsApi } from "../customFieldsApi"
import { CustomFieldI } from "../types/CustomField"
import { useAppDispatch } from "~/store/hooks"
import { customFieldsActions } from "../store"

export const useUpdateCustomFieldValue = (objectId: string, customField: CustomFieldI) => {
    const customFieldsApi = useCustomFieldsApi()
    const dispatch = useAppDispatch()

    return useCallback(
        async (customFieldId: string, value: string) => {
            await customFieldsApi.updateCustomFieldValue(customFieldId, value)
            dispatch(customFieldsActions.updateCustomFieldValue({ objectId, customField, value }))
        },
        [dispatch, customFieldsApi]
    )
}
