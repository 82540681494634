import { useAppDispatch } from "~/store/hooks"
import { authActions } from "../authSlice"

export const useAuth = () => {
    const dispatch = useAppDispatch()

    const addFocusListener = () => {
        window.addEventListener("focus", handleFocus)
    }
    const removeFocusListener = () => {
        window.removeEventListener("focus", handleFocus)
    }
    const handleFocus = () => {
        window.removeEventListener("focus", handleFocus)
        dispatch(authActions.setClosePopup())
    }

    return { addFocusListener, removeFocusListener }
}
