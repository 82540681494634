import { DocumentI, OrganizationId } from "~/types"
import { useDocumentRelationsApi } from "../documentRelationsApi"
import { useCallback } from "react"
import { useAppDispatch } from "~/store/hooks"
import { documentRelationsActions } from "../store/documentRelationsSlice"
import { isResultSuccess } from "~/core/Result"

export const useAddRelationToPurchaseOrder = (organizationId: OrganizationId, purchaseOrderId: string) => {
    const documentRelations = useDocumentRelationsApi()
    const dispatch = useAppDispatch()

    return useCallback(
        async (document: DocumentI) => {
            const result = await documentRelations.addRelationToPurchaseOrder(organizationId, purchaseOrderId, {
                invoiceId: document.invoiceId,
            })
            if (isResultSuccess(result)) {
                dispatch(
                    documentRelationsActions.addPurchaseOrderRelation({
                        relationId: result.result.relationId,
                        invoiceId: document.invoiceId,
                    })
                )
            }
            return result
        },
        [organizationId, purchaseOrderId, dispatch]
    )
}
