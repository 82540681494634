function useTitle(title?: string) {
    const defaultTitle = "Flowie"
    let currentTitle = ""
    const fullTitle = (title ? title + " - " : "") + defaultTitle
    if (title) {
        currentTitle = fullTitle
    }
    document.title = fullTitle

    return {
        currentTitle,
    }
}

export default useTitle
