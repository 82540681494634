import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useCallback } from "react"
import { SelectedTagI } from "~/domains/tags/types"
import { selectPendingTags, tagsActions } from "~/domains/tags/store/tagsSlice"

type TagsforLine = [
    SelectedTagI[],
    (tagsOrFunction: SelectedTagI[] | ((state: SelectedTagI[]) => SelectedTagI[])) => void
]
export const useTagsForCreatingPurchaseRequest = (): TagsforLine => {
    const dispatch = useAppDispatch()
    const pendingTags = useAppSelector(selectPendingTags)
    const setPendingTags = useCallback(
        (tagsOrFunction: SelectedTagI[] | ((state: SelectedTagI[]) => SelectedTagI[])) => {
            dispatch(tagsActions.setPendingTags(tagsOrFunction))
        },
        [dispatch]
    )
    return [pendingTags, setPendingTags]
}
