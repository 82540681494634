/*
    Used for outputting a shorten name in this format: "file - docu[...].ext"
    Example: type = "file - ", name = "document.ext", maxChar = 27
*/

export function useShortenName(type: string, name: string, maxChar: number) {
    const customEllipsis = "[...]"
    const typeLen = type.length
    const nameLen = name.length
    const splitName = name.split(".")
    const extension = splitName.length > 1 ? splitName[splitName.length - 1] : ""
    const extensionLen = extension.length + 1 /* The + 1 stands for the dot before the extension */
    const totalExtraLen = type.length + extensionLen + customEllipsis.length
    const nameLength = totalExtraLen > maxChar ? 1 : maxChar - totalExtraLen

    if (typeLen + nameLen > maxChar) {
        return `${name.slice(0, nameLength)}${customEllipsis}${extension ? "." + extension : ""}`
    }
    return name
}
