import { useCallback } from "react"
import { ocrApi } from "~/api"
import { useAppDispatch } from "~/store/hooks"
import { ocrActions } from "~/store/ocr/ocrSlice"
import { InvoiceUserType, OrganizationId } from "~/types"
import { OcrDocumentId } from "../types"

export const useUploadOCRDocument = (initiator: InvoiceUserType, organizationId?: OrganizationId) => {
    const dispatch = useAppDispatch()
    return useCallback(
        async (file: File): Promise<OcrDocumentId | undefined> => {
            try {
                dispatch(ocrActions.newUpload())
                const response = await ocrApi.newUpload(file, initiator, organizationId)
                dispatch(ocrActions.newUploadSuccess(response.id))
                return response.id
            } catch (error) {
                dispatch(ocrActions.newUploadFailed())
            }
        },
        [dispatch, initiator, organizationId]
    )
}
