import { useCallback } from "react"
import { useCustomFieldsApi } from "../customFieldsApi"
import { CreateCustomFieldPayload } from "../types/CreateCustomFieldPayload"

export const useCreateCustomField = () => {
    const customFieldsApi = useCustomFieldsApi()

    return useCallback(
        (payload: CreateCustomFieldPayload, allowCustom?: boolean) => {
            return customFieldsApi.createCustomField(payload, allowCustom)
        },
        [customFieldsApi]
    )
}
