import { OrganizationId } from "~/types"
import { useCatalogApi } from "../catalogApi"
import { useCallback, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { catalogActions, selectProductsVersionsByContext } from "../store/catalogSlice"
import { store } from "~/store"
import { pipeResult } from "~/core/Result"
import { ProductVersionsState } from "../store/catalogStore"
import { useProductVersionsQueryFilter } from "./useProductVersionsQueryFilter"
import { PaginationQuery } from "~/utils"

const emptyState: ProductVersionsState = {
    organizationId: "",
    products: [],
    loading: true,
    pagination: null,
    fetchError: undefined,
    query: "",
}

export const useProductVersions = (organizationId: OrganizationId, context: string) => {
    const catalogApi = useCatalogApi()
    const productsVersionsByContext = useAppSelector(selectProductsVersionsByContext)
    const productVersionsState = productsVersionsByContext[context]
    const dispatch = useAppDispatch()

    const state = { ...(productVersionsState ?? emptyState) }

    const [, setQuery] = useProductVersionsQueryFilter(context)

    const fetch = useCallback(
        async (paginationQuery?: PaginationQuery) => {
            dispatch(catalogActions.fetchCatalog({ context, organizationId }))
            const results = await catalogApi.fetchProductVersions(organizationId, paginationQuery)
            pipeResult(
                results,
                (result) => dispatch(catalogActions.fetchCatalogSuccess({ context, result })),
                (error) => dispatch(catalogActions.fetchCatalogFailure({ context, error }))
            )
        },
        [organizationId, dispatch]
    )

    const setQueryWraper = useCallback(
        (query: string) => {
            setQuery(query)
            fetch({ ...state.pagination, query })
        },
        [setQuery, fetch, state.pagination]
    )

    useEffect(() => {
        // we use synchronous fetch of state to make sure we don't load more than once
        const productVersionsState = selectProductsVersionsByContext(store.getState())[context]
        if (
            !productVersionsState ||
            (!productVersionsState.pagination && !productVersionsState.loading) ||
            productVersionsState.organizationId !== organizationId
        ) {
            fetch()
        }
    }, [fetch, context])

    return { ...state, fetch, setQuery: setQueryWraper }
}
