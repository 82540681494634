import React from "react"
import { PurchaseRequestDetails } from "../../types/PurchaseRequests"
import { ProgressBar } from "~/domains/common/components/ProgressBar"
import { defineMessages, useIntl } from "react-intl"
import { usePurchaseRequestValidationActions } from "../../store/hooks"
import { Button, Loader } from "~/components"
import { Tooltip } from "@mui/material"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"

const messages = defineMessages({
    validate: {
        id: "purchase.requests.request.validate",
        defaultMessage: "Validate",
    },
    refuse: {
        id: "purchase.requests.request.reject",
        defaultMessage: "Refuse",
    },
    checkDone: {
        id: "purchase.requests.checkDone",
        defaultMessage: "Check done {checkDone}/{totalChecks}",
    },
    waitingForApproval: {
        id: "purchase.requests.waitingForOtherApproval",
        defaultMessage: "Waiting for {peopleNames} approval",
    },
    title: {
        id: "purchase.requests.request.actions.title",
        defaultMessage: "Actions",
    },
})

interface Props {
    purchaseRequest: PurchaseRequestDetails
}

export const SubmittedPRActions: React.FC<Props> = ({ purchaseRequest }) => {
    const { formatMessage } = useIntl()
    const {
        loading,
        withinWorkflow,
        checkDone,
        totalChecks,
        currentUserCanValidate,
        currentUserApproval,
        reviewers,
        approve,
        refuse,
    } = usePurchaseRequestValidationActions(purchaseRequest)
    const approvePermission = purchaseRequest.permissions?.approve
    const canApprove = (approvePermission && typeof approvePermission !== "undefined") ?? false
    const permissionLabel = !canApprove ? formatMessage(permissionMessages.errorNoAccessAdministrator) : ""

    if (loading) {
        return (
            <div className="actions">
                <Loader small />
            </div>
        )
    }
    return (
        <>
            <h4 className="header-actions">{formatMessage(messages.title)}</h4>
            {withinWorkflow && (
                <ProgressBar progress={checkDone / totalChecks}>
                    {formatMessage(messages.checkDone, {
                        checkDone,
                        totalChecks,
                    })}
                </ProgressBar>
            )}
            {currentUserCanValidate ? (
                <div className="actions">
                    <Tooltip arrow title={permissionLabel}>
                        <span>
                            <Button
                                type="error"
                                buttonType="button"
                                className="reject"
                                disabled={!canApprove || currentUserApproval === false}
                                {...(canApprove ? { onClick: refuse } : {})}
                            >
                                {formatMessage(messages.refuse)}
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip arrow title={permissionLabel}>
                        <span className="flex-1">
                            <Button
                                type="primary"
                                buttonType="button"
                                className="validate"
                                disabled={!canApprove || currentUserApproval === true}
                                {...(canApprove ? { onClick: approve } : {})}
                            >
                                {formatMessage(messages.validate)}
                            </Button>
                        </span>
                    </Tooltip>
                </div>
            ) : (
                withinWorkflow && (
                    <div>
                        {formatMessage(messages.waitingForApproval, {
                            peopleNames: reviewers.map((reviewer) => reviewer.fullName).join(", "),
                        })}
                    </div>
                )
            )}
        </>
    )
}
