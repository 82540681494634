import { genericParser } from "~/utils"
import { RoomI, RoomIO, RoomInvolvedOrganizationI } from "./Room"
import { getResultSuccessValue, isResultSuccess, ResultSuccess } from "~/core/Result"
import { isDefined } from "~/utils/isDefined"
import { parseMessage } from "./parseMessage"

export const parseRoom = (data: unknown) => {
    const result = genericParser(data, RoomIO)
    if (isResultSuccess(result)) {
        return ResultSuccess<RoomI>({
            ...result.result,
            lastMessages: result.result.lastMessages ?? [],
            pinnedMessages:
                result.result.pinnedMessages?.map(parseMessage).filter(isResultSuccess).map(getResultSuccessValue) ??
                [],
            involvedOrganization: result.result.involvedOrganization
                ? (result.result.involvedOrganization.filter((orga) =>
                      isDefined(orga.organizationId)
                  ) as RoomInvolvedOrganizationI[])
                : [],
        })
    }
    return result
}
