import { DefaultElement, RenderElementProps } from "slate-react"
import React from "react"
import { CustomElement } from "../../types/Slate"
import { MentionElement, isElementMention } from "./Mentions"

export const renderElement = (props: RenderElementProps) => {
    const element: CustomElement = props.element
    if (isElementMention(element)) {
        return <MentionElement attributes={props.attributes} element={element} />
    }
    return <DefaultElement {...props} />
}
