import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FetchError } from "~/domains/common/apiClient/errors"
import { CatalogState } from "./catalogStore"
import { PaginatedProductVersionI, ProductVersionI, ProductId } from "../types"
import { RootState } from "~/store"
import { OrganizationId } from "~/types"

const initialState: CatalogState = {
    productVersionsByContext: {},
}

const defaultProductVersionState = {
    loading: false,
    pagination: null,
    products: [],
    query: "",
}

const catalogSlice = createSlice({
    name: "catalog",
    initialState,
    reducers: {
        fetchCatalog(
            state,
            { payload: { context, organizationId } }: PayloadAction<{ context: string; organizationId: OrganizationId }>
        ) {
            state.productVersionsByContext[context] = {
                ...(state.productVersionsByContext[context] ?? defaultProductVersionState),
                organizationId: organizationId,
                loading: true,
                fetchError: null,
            }
        },
        fetchCatalogSuccess(
            state,
            { payload: { context, result } }: PayloadAction<{ context: string; result: PaginatedProductVersionI }>
        ) {
            state.productVersionsByContext[context] = {
                ...(state.productVersionsByContext[context] ?? defaultProductVersionState),
                loading: false,
                pagination: result.pagination,
                products: result.results,
            }
        },
        fetchCatalogFailure(
            state,
            {
                payload: { context, error },
            }: PayloadAction<{ context: string; error: FetchError<PaginatedProductVersionI> }>
        ) {
            if (state.productVersionsByContext[context]) {
                state.productVersionsByContext[context] = {
                    ...state.productVersionsByContext[context],
                    loading: false,
                    fetchError: error,
                }
            }
        },
        setCatalogQueryFilter(
            state,
            { payload: { context, query } }: PayloadAction<{ context: string; query: string }>
        ) {
            state.productVersionsByContext[context] = {
                ...(state.productVersionsByContext[context] ?? defaultProductVersionState),
                query,
            }
        },
        addProduct(
            state,
            { payload: { context, product } }: PayloadAction<{ context: string; product: ProductVersionI }>
        ) {
            const currentState = state.productVersionsByContext[context] ?? defaultProductVersionState
            state.productVersionsByContext[context] = {
                ...currentState,
                products: [product, ...currentState.products],
            }
        },
        addProducts(
            state,
            { payload: { context, products } }: PayloadAction<{ context: string; products: ProductVersionI[] }>
        ) {
            const currentState = state.productVersionsByContext[context] ?? defaultProductVersionState
            state.productVersionsByContext[context] = {
                ...currentState,
                products: [...products, ...currentState.products],
            }
        },
        updateProduct(
            state,
            { payload: { context, product } }: PayloadAction<{ context: string; product: ProductVersionI }>
        ) {
            const currentState = state.productVersionsByContext[context] ?? defaultProductVersionState
            state.productVersionsByContext[context] = {
                ...currentState,
                products: currentState.products.map((currentProduct) =>
                    currentProduct.id === product.id ? product : currentProduct
                ),
            }
        },
        deleteProduct(
            state,
            { payload: { context, productId } }: PayloadAction<{ context: string; productId: ProductId }>
        ) {
            const currentState = state.productVersionsByContext[context]
            if (currentState) {
                state.productVersionsByContext[context] = {
                    ...currentState,
                    products: currentState.products.filter((currentProduct) => currentProduct.productId !== productId),
                }
            }
        },
    },
})

export const catalogActions = catalogSlice.actions
export const catalogReducer = catalogSlice.reducer

export const selectProductsVersionsByContext = (state: RootState) => state.catalog.productVersionsByContext
