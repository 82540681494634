import { OrganizationId } from "~/types"
import { useTagsApi } from "../tagsApi"
import { useCallback } from "react"
import { TagId } from "../types"

export const useDeleteTagObject = (organizationId?: OrganizationId, objectId?: string | string[]) => {
    const tagsApi = useTagsApi()
    return useCallback(
        async (tagId: TagId, objId?: string | string[]) => {
            if (!organizationId || (!objectId && !objId)) return
            const realObjectId = objectId ?? objId ?? []
            if (Array.isArray(realObjectId)) {
                return Promise.allSettled(
                    realObjectId.map((oId) => {
                        return tagsApi.deleteTagObject(organizationId, oId, tagId)
                    })
                )
            } else if (realObjectId) {
                return await tagsApi.deleteTagObject(organizationId, realObjectId, tagId)
            }
        },
        [objectId, organizationId, tagsApi]
    )
}
