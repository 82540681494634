import * as t from "io-ts"
import { optional } from "~/types/utils"
import { PaginationInfoIO, RealOpaque } from "~/utils"

export type ProductId = RealOpaque<string, { readonly T: unique symbol }>
export type ProductVersionId = RealOpaque<string, { readonly T: unique symbol }>

export const ProductVersionIO = t.intersection([
    t.type({
        id: t.string,
        productId: t.string,
        name: t.string,
        type: t.string,
    }),
    t.partial({
        description: optional(t.string),
        sku: optional(t.string),
        externalId: optional(t.string),
        packagingSize: optional(t.number),
        packagingName: optional(t.string),
        countryCode: optional(t.string),
        taxName: optional(t.string),
        unitTaxAmount: optional(t.number),
        taxRate: optional(t.number),
        currency: optional(t.string),
        unitPriceWithoutTax: optional(t.number),
        incoterm: optional(t.string),
        forSpecificBuyer: optional(t.string),
    }),
])

export const PaginatedProductVersionIO = t.type({
    pagination: PaginationInfoIO,
    results: t.array(ProductVersionIO),
})

export type ProductVersionI = t.TypeOf<typeof ProductVersionIO> & {
    id: ProductVersionId
    productId: ProductId
}
export type PaginatedProductVersionI = t.TypeOf<typeof PaginatedProductVersionIO> & {
    results: ProductVersionI[]
}

export const BatchImportProductVersionResultIO = t.union([
    t.type({
        data: t.array(ProductVersionIO),
        errors: t.array(t.any),
    }),
    t.intersection([
        t.type({
            objectsToImport: t.number,
            expectedProcessingTimeInSeconds: t.number,
        }),
        t.partial({ data: t.null }),
    ]),
])
export type BatchImportImmediateResult = {
    data: ProductVersionI[]
    objectsToImport?: never
    expectedProcessingTimeInSeconds?: never
}

export type BatchImportDefferResult = {
    data?: never
    objectsToImport: number
    expectedProcessingTimeInSeconds: number
}

export type BatchImportProductVersionResultI = BatchImportImmediateResult | BatchImportDefferResult
