import { ThemeProvider } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Provider } from "react-redux"
import { Loader, Theme } from "~/core"
import { LocalesProvider } from "~/i18n/LocalesProvider"
import { IntercomProvider } from "react-use-intercom"
import "../../assets/css/App.scss"
import { store } from "~/store"
import { FormattedMessage } from "react-intl"
import { ErrorMessage } from "~/components/ErrorMessage"
import messages_en from "~/lang/en.json"
import messages_fr from "~/lang/fr.json"
import messages_ar from "~/lang/ar.json"
import messages_ch from "~/lang/ch.json"
import messages_de from "~/lang/de.json"

import "./AppCrashFallback.scss"

const messages = {
    fr: messages_fr,
    en: messages_en,
    ar: messages_ar,
    ch: messages_ch,
    de: messages_de,
}

const ENV = import.meta.env.VITE_ENV
const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID

export interface IFallbackProps {
    eventId: string
    error?: Error
}

export const AppCrashFallback: React.FC<IFallbackProps> = ({ error }) => {
    const [remainingTime, setRemainingTime] = useState<number>(10)

    useEffect(() => {
        if (remainingTime === 0 && ENV === "production") {
            window.location.href = "/"
        } else if (remainingTime > 0) {
            window.setTimeout(() => setRemainingTime(remainingTime - 1), 1000)
        }
    }, [remainingTime, setRemainingTime])

    return (
        <ThemeProvider theme={Theme}>
            <Provider store={store}>
                <LocalesProvider messages={messages} locale={"en"}>
                    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={ENV !== "development"}>
                        <div className="App AppCrash">
                            <h1>
                                <FormattedMessage id="crashpage.title" defaultMessage="An unexpected error occured" />
                            </h1>
                            <ErrorMessage>{error?.message}</ErrorMessage>
                            <Loader />
                            <p>
                                <FormattedMessage
                                    id="crashpage.redirectionTime"
                                    defaultMessage="You'll get redirected to Flowie home page in {remainingTime} seconds..."
                                    values={{ remainingTime }}
                                />
                            </p>
                            {ENV === "production" ? null : <div id="environment">{ENV}</div>}
                        </div>
                    </IntercomProvider>
                </LocalesProvider>
            </Provider>
        </ThemeProvider>
    )
}
