import {
    FilterFieldProperty,
    WorkflowFilterType,
    WorkflowFilterTypes,
    WorkflowTriggerType,
} from "~/features/workflow/types"

export const AvailableInvoiceFilters: WorkflowFilterTypes[] = [
    {
        field: FilterFieldProperty.SUPPLIER,
        fieldType: WorkflowFilterType.REFERENCE_CONDITION,
    },
    {
        field: FilterFieldProperty.AMOUNT,
        fieldType: WorkflowFilterType.NUMERICAL_CONDITION,
    },
    {
        field: FilterFieldProperty.TAGS_V2,
        fieldType: WorkflowFilterType.REFERENCE_CONDITION,
    },
]

export const AvailablePurchaseRequestFilters: WorkflowFilterTypes[] = [
    {
        field: FilterFieldProperty.SUPPLIER,
        fieldType: WorkflowFilterType.REFERENCE_CONDITION,
    },
    {
        field: FilterFieldProperty.AMOUNT,
        fieldType: WorkflowFilterType.NUMERICAL_CONDITION,
    },
    {
        field: FilterFieldProperty.AUTHOR,
        fieldType: WorkflowFilterType.REFERENCE_CONDITION,
    },
    {
        field: FilterFieldProperty.TAGS_V2,
        fieldType: WorkflowFilterType.REFERENCE_CONDITION,
    },
]

export const AvailablePurchaseRequestLineFilters: WorkflowFilterTypes[] = [
    {
        field: FilterFieldProperty.LINE_AMOUNT,
        fieldType: WorkflowFilterType.NUMERICAL_CONDITION,
    },
    {
        field: FilterFieldProperty.SUPPLIER,
        fieldType: WorkflowFilterType.REFERENCE_CONDITION,
    },
    {
        field: FilterFieldProperty.AUTHOR,
        fieldType: WorkflowFilterType.REFERENCE_CONDITION,
    },
]
export const AvailableFilter: Record<WorkflowTriggerType, WorkflowFilterTypes[]> = {
    [WorkflowTriggerType.InvoiceReceived]: AvailableInvoiceFilters,
    [WorkflowTriggerType.PurchaseRequestReceived]: AvailableInvoiceFilters,
}
