import { usePurchaseRequestLineActions, usePurchaseRequestWorkflowReviewers } from "~/store/workflow/hooks"
import { LineStatus } from "../../types/PurchaseRequests"
import { useAppSelector } from "~/store/hooks"
import { selectUser } from "~/store/account/accountSlice"
import {
    PurchaseRequestApiWorkflowLineCheckStatusWithUsersI,
    PurchaseRequestCheckWorkflowWithUsers,
} from "~/features/workflow/types/ApiWorkflow"
import { useCallback } from "react"
import { OrganizationId, UserI } from "~/types"
import { useUpdatePurchaseRequestLineStatus } from "./useUpdatePurchaseRequestLineStatus"
import { useFetchPurchaseRequest } from "./useFetchPurchaseRequest"

type PurchaseRequestLineLoadingState = {
    loading: true
    withWorkflow?: never
    workflowLineCheckStatus?: never

    currentUserCanValidate?: never
    currentUserApproval?: never
    approveLine?: never
    refuseLine?: never
    statusLoading?: never
    checkDone?: never
    totalChecks?: never
    reviewers?: never
}

type PurchaseRequestLineActionsWithWorkflow = {
    loading: false
    withWorkflow: true
    workflowLineCheckStatus: PurchaseRequestApiWorkflowLineCheckStatusWithUsersI | undefined
    checkDone: number
    totalChecks: number
    reviewers: UserI[]
}

type PurchaseRequestLineActionsWithoutWorkflow = {
    loading: false
    withWorkflow: false
    workflowLineCheckStatus?: never
    checkDone?: never
    totalChecks?: never
    reviewers?: never
}

type PurchaseRequestLineUserCanApprove = {
    currentUserCanValidate: true
    currentUserApproval: boolean | undefined
    approveLine: () => void
    refuseLine: () => void
    statusLoading: boolean
}

type PurchaseRequestLineUserCannotApprove = {
    currentUserCanValidate: false
    currentUserApproval: boolean | undefined
    approveLine?: never
    refuseLine?: never
    statusLoading?: never
}

type PurchaseRequestLineValidationState =
    | PurchaseRequestLineLoadingState
    | (PurchaseRequestLineActionsWithWorkflow &
          (PurchaseRequestLineUserCanApprove | PurchaseRequestLineUserCannotApprove))
    | (PurchaseRequestLineActionsWithoutWorkflow & PurchaseRequestLineUserCanApprove)

export const usePurchaseRequestLineValidationActions = (
    organizationId: OrganizationId,
    purchaseRequestId: string,
    lineId: string | null
): PurchaseRequestLineValidationState => {
    const currentUser = useAppSelector(selectUser)
    const { workflowCheck, loading } = usePurchaseRequestWorkflowReviewers(organizationId, purchaseRequestId)

    const {
        approvePurchaseRequestLine,
        refusePurchaseRequestLine,
        loading: workflowValidationLoading,
    } = usePurchaseRequestLineActions(organizationId, purchaseRequestId, lineId)

    const { updateLineStatus, loading: updating } = useUpdatePurchaseRequestLineStatus(
        organizationId,
        purchaseRequestId
    )

    const { fetchPurchaseRequest } = useFetchPurchaseRequest(organizationId)
    const refreshPurchaseRequest = useCallback(() => {
        window.setTimeout(() => {
            fetchPurchaseRequest(purchaseRequestId)
        }, 1000)
    }, [fetchPurchaseRequest, purchaseRequestId])

    const approveLine = useCallback(async () => {
        if (lineId) {
            await updateLineStatus(lineId, LineStatus.APPROVED)
            refreshPurchaseRequest()
        }
    }, [updateLineStatus, lineId])
    const refuseLine = useCallback(async () => {
        if (lineId) {
            await updateLineStatus(lineId, LineStatus.REJECTED)
            refreshPurchaseRequest()
        }
    }, [updateLineStatus, lineId])
    const approveLineFromWorkflow = useCallback(async () => {
        if (await approvePurchaseRequestLine()) {
            refreshPurchaseRequest()
        }
    }, [approvePurchaseRequestLine])
    const refuseLineFromWorkflow = useCallback(async () => {
        if (await refusePurchaseRequestLine()) {
            refreshPurchaseRequest()
        }
    }, [refusePurchaseRequestLine])

    if (loading || updating) {
        return { loading: true }
    }
    if (workflowCheck) {
        // we select the workflow check for the current line
        const workflowCheckLine = workflowCheck?.lineCheckStatuses.find((lineCheck) => lineCheck.lineId === lineId)
        // we filter the workflows where the current user is a reviewer
        const workflowChecksForUser =
            workflowCheckLine?.checks.filter((check) =>
                check.reviewers.some((reviewer) => reviewer.id === currentUser.id)
            ) ?? []

        // We select the workflow check with the least number of missing reviewers
        const currentWorkflowCheck = workflowChecksForUser.reduce(
            (acc: PurchaseRequestCheckWorkflowWithUsers | null, check: PurchaseRequestCheckWorkflowWithUsers) => {
                if (!acc) return check
                if (check.checkThreshold - check.approvers.length < acc.checkThreshold - acc.approvers.length)
                    return check
                return acc
            },
            null
        )
        if (workflowCheckLine && currentWorkflowCheck) {
            const hasApproved = workflowCheckLine.approvers.some((userId) => userId === currentUser.id)
            const hasRefused = workflowCheckLine.refusers.some((userId) => userId === currentUser.id)
            return {
                loading: false,
                withWorkflow: true,
                workflowLineCheckStatus: workflowCheckLine,
                currentUserCanValidate: true,
                currentUserApproval: hasApproved ? true : hasRefused ? false : undefined,
                checkDone: workflowCheckLine.approvers.length,
                totalChecks: currentWorkflowCheck.checkThreshold,
                reviewers: currentWorkflowCheck.reviewers,
                statusLoading: workflowValidationLoading,
                approveLine: approveLineFromWorkflow,
                refuseLine: refuseLineFromWorkflow,
            }
        } else {
            // We select the workflow check with the least number of missing reviewers
            const currentWorkflowCheck = workflowCheckLine?.checks.reduce(
                (acc: PurchaseRequestCheckWorkflowWithUsers | null, check: PurchaseRequestCheckWorkflowWithUsers) => {
                    if (!acc) return check
                    if (check.checkThreshold - check.approvers.length < acc.checkThreshold - acc.approvers.length)
                        return check
                    return acc
                },
                null
            )
            if (currentWorkflowCheck) {
                return {
                    loading: false,
                    withWorkflow: true,
                    workflowLineCheckStatus: workflowCheckLine,
                    currentUserCanValidate: false,
                    currentUserApproval: undefined,
                    checkDone: currentWorkflowCheck?.approvers.length ?? 0,
                    totalChecks: currentWorkflowCheck?.checkThreshold ?? 1,
                    reviewers: currentWorkflowCheck?.approvers ?? [],
                }
            }
        }
    }
    return {
        loading: false,
        withWorkflow: false,
        currentUserApproval: undefined,
        currentUserCanValidate: true,
        approveLine,
        refuseLine,
        statusLoading: updating,
    }
}
