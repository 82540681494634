import React, { SyntheticEvent } from "react"
import { Autocomplete, Box, TextField } from "@mui/material"
import classnames from "classnames"

interface Props<T> {
    options: T[]
    selectedEntities: T[]
    setSelectedEntities: (selected: T[]) => void
    numberOfTagsToShow?: number
    width?: string
    getOptionLabel: (option: T) => string
    isOptionEqualToValue: (option: T, value: T) => boolean
    placeholder?: string
    classname?: string
    disabled?: boolean
}

export const AutocompleteWithTags = <T,>({
    options,
    selectedEntities,
    setSelectedEntities,
    numberOfTagsToShow = 2,
    width = "100%",
    getOptionLabel,
    isOptionEqualToValue,
    placeholder,
    classname,
    disabled,
}: Props<T>) => {
    const classes = classnames("mb-12", classname)

    const handleChange = (event: SyntheticEvent<Element, Event>, value: T[]) => {
        setSelectedEntities(value)
    }

    return (
        <Autocomplete
            className={classes}
            multiple
            autoComplete
            options={options}
            getOptionLabel={getOptionLabel}
            value={selectedEntities}
            onChange={handleChange}
            fullWidth={false}
            renderTags={(values) => {
                const additionalTagsCount = values.length - numberOfTagsToShow
                let displayedValue = values.slice(0, numberOfTagsToShow).map(getOptionLabel).join(", ")
                if (additionalTagsCount > 0) {
                    displayedValue += ` +${additionalTagsCount}`
                }
                return displayedValue
            }}
            style={{ width }}
            renderInput={(params) => (
                <TextField
                    className="search-input"
                    {...params}
                    placeholder={!placeholder || selectedEntities.length ? undefined : placeholder}
                    variant="outlined"
                />
            )}
            renderOption={(props, option, state, ownerState) => {
                const optionId =
                    option && typeof option === "object" && "id" in option
                        ? option.id
                        : ownerState.getOptionLabel(option)
                return (
                    <Box component="li" {...props} key={`${optionId}`}>
                        {ownerState.getOptionLabel(option)}
                    </Box>
                )
            }}
            isOptionEqualToValue={isOptionEqualToValue}
            disabled={disabled}
        />
    )
}
