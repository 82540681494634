import React from "react"
import { styled } from "@mui/material"

interface iProps {
    color?: string
    size?: number
}

const StyledI = styled("i")<iProps>(({ color = "black", size = 3 }) => ({
    width: size,
    height: size,
    margin: size / 2,
    display: "inline-block",
    backgroundColor: color,
    borderRadius: size,
}))

interface Props extends React.HTMLProps<HTMLDivElement> {
    color?: string
    size?: number
}

export const ActionIcon: React.FC<Props> = ({ color = "black", size = 24, ...props }) => {
    const dotSize = size / 8
    return (
        <div {...props}>
            <StyledI size={dotSize} color={color} />
            <StyledI size={dotSize} color={color} />
            <StyledI size={dotSize} color={color} />
        </div>
    )
}
