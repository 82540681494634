import React, { Dispatch, FormEvent, SetStateAction, useRef, useState } from "react"
import "./UploadDocument.scss"
import { defineMessages, useIntl } from "react-intl"
import { Box, TextField } from "@mui/material"
import { Button, Loader, Modal } from "~/components"
import { UploadDocumentBox } from "./UploadDocumentBox"
import { useUploadDocument } from "~/components/UploadDocument/hooks"
import { DocumentType, DraftDocumentI, ObjectId, DocumentObjectType, UploadDocumentDTO } from "../Documents"
import { OrganizationId } from "~/types"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/browser"
import { SelectDocumentType } from "../SelectDocumentType"

const messages = defineMessages({
    title: { id: "documents.modal.uploadFile.title", defaultMessage: "Upload a document" },
    cancel: { id: "documents.modal.uploadFile.cancel", defaultMessage: "Cancel" },
    confirm: { id: "documents.modal.uploadFile.confirm", defaultMessage: "Confirm" },
    documentName: { id: "documents.modal.uploadFile.documentName", defaultMessage: "Name of the document" },
    errorUpload: { id: "documents.modal.uploadFile.errorUpload", defaultMessage: "Failed to upload" },
    errorPercent: { id: "documents.modal.uploadFile.errorPercent", defaultMessage: " 0%" },
    successfulUpload: {
        id: "documents.modal.uploadFile.successfulUpload",
        defaultMessage: "Ready for upload",
    },
    successfulPercent: {
        id: "documents.modal.uploadFile.successfulPercent",
        defaultMessage: " 100%",
    },
    missingData: {
        id: "documents.modal.uploadFile.missingData",
        defaultMessage: "Some required information is missing or incorrect.",
    },
})

interface UploadFileModalProps {
    organizationId: OrganizationId
    objectId?: ObjectId
    objectType: DocumentObjectType
    open: boolean
    close: () => void
    setDraftDocuments?: Dispatch<SetStateAction<DraftDocumentI[]>>
}

export const UploadDocumentModal: React.FC<UploadFileModalProps> = ({
    organizationId,
    objectId,
    objectType,
    open,
    close,
    setDraftDocuments,
}) => {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null)
    const [fileError, setFileError] = useState<boolean>(false)
    const [name, setName] = useState<string>("")
    const [type, setType] = useState<DocumentType>(DocumentType.CONTRACT)
    const formRef = useRef<HTMLFormElement>(null)
    const { formatMessage } = useIntl()
    const { uploadDocument, loadingUpload } = useUploadDocument(organizationId)

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault()
        if ((formRef.current && !formRef.current.checkValidity()) || !uploadedFile) {
            toast.error(formatMessage(messages.missingData))
            return
        }

        const payload: UploadDocumentDTO = {
            file: uploadedFile,
            name: name,
            documentType: type,
            objectId: objectId ?? "",
            objectType,
            organizationIds: [organizationId],
        }
        try {
            if (objectId) {
                await uploadDocument(payload)
            } else if (setDraftDocuments) {
                setDraftDocuments((draftDocuments) => [...draftDocuments, payload])
            } else {
                throw new Error("Missing an ObectId or a draftDocuments state in props of Document component")
            }
            handleClose()
        } catch (error) {
            setFileError(true)
            Sentry.captureException("Upload document error", {
                extra: {
                    errors: error,
                },
            })
        }
    }

    const handleUploadedFile = (uploadedFile: File) => {
        setUploadedFile(uploadedFile)
        setFileError(false)
        setName(uploadedFile.name)
    }

    const handleClose = () => {
        close()
        setFileError(false)
        setUploadedFile(null)
        setName("")
        setType(DocumentType.CONTRACT)
    }

    return (
        <Modal open={open} onClose={() => handleClose()} className="size-sm">
            <Modal.Header className="upload-document-modal-header">
                <h4>{formatMessage(messages.title)}</h4>
            </Modal.Header>
            <form onSubmit={handleSubmit} ref={formRef}>
                <Modal.Content className="upload-document-modal">
                    {fileError ? (
                        <div className="upload-document-feedback">
                            <hr className="fail-hr" />
                            {formatMessage(messages.errorUpload)}
                            <span className="fail-percent">{formatMessage(messages.errorPercent)}</span>
                        </div>
                    ) : null}
                    {loadingUpload ? (
                        <Loader />
                    ) : uploadedFile && !fileError ? (
                        <>
                            <span className="upload-document-feedback">
                                <hr className="success-hr" />
                                {formatMessage(messages.successfulUpload)}
                                <span className="success-percent">{formatMessage(messages.successfulPercent)}</span>
                            </span>
                            <div className="upload-document-formfields">
                                <TextField
                                    required
                                    name="uploadDocument.name"
                                    label={formatMessage(messages.documentName)}
                                    placeholder={formatMessage(messages.documentName)}
                                    value={name}
                                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                                        setName(e.currentTarget.value)
                                    }
                                    fullWidth
                                    variant="filled"
                                    className="documents-fields"
                                    inputProps={{
                                        maxLength: 255,
                                    }}
                                />
                                <SelectDocumentType value={type} onChange={setType} />
                            </div>
                        </>
                    ) : (
                        <Box textAlign="center">
                            <UploadDocumentBox handleFile={handleUploadedFile} />
                        </Box>
                    )}
                </Modal.Content>
                <Modal.Footer>
                    <Button type="neutral" buttonType="button" onClick={() => handleClose()}>
                        {formatMessage(messages.cancel)}
                    </Button>
                    <Button type="primary" buttonType="submit" disabled={!uploadedFile || fileError ? true : false}>
                        {formatMessage(messages.confirm)}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
