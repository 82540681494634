import { OrganizationId } from "~/types"
import { useCommunicationApi } from "../communicationApi"
import { useCallback, useEffect, useMemo } from "react"
import { CreateRoomPayload, RoomI } from "../types"
import { isResultSuccess } from "~/core/Result"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectRoomsState } from "../store/selectors"
import { communicationActions } from "../store/CommunicationSlice"

const isSharedRoom = (room: RoomI) => room.isShared
const isPrivateRoom = (room: RoomI) => !room.isShared

export const useRoomForObjectId = (organizationId: OrganizationId, createPayload: CreateRoomPayload) => {
    const { roomsByObjectId, loadingRoomsByObjectId, errorsLoadingRoomsByObjectId } = useAppSelector(selectRoomsState)
    const rooms = roomsByObjectId[createPayload.objectId]
    const loading = loadingRoomsByObjectId[createPayload.objectId] === true
    const error = errorsLoadingRoomsByObjectId[createPayload.objectId]

    const communicationApi = useCommunicationApi()
    const dispatch = useAppDispatch()

    const objectId = createPayload.objectId

    const fetchRooms = useCallback(async () => {
        dispatch(communicationActions.fetchRooms({ objectId }))
        const result = await communicationApi.findRoomsForObjectIds(organizationId, objectId)
        if (isResultSuccess(result)) {
            const rooms = result.result
            if (rooms.length === 0) {
                const resultCreate = await communicationApi.createRoom(
                    organizationId,
                    createPayload,
                    !createPayload.organizations || createPayload.organizations.length === 0
                        ? { onlyPrivateRoom: true }
                        : undefined
                )
                if (isResultSuccess(resultCreate)) {
                    dispatch(communicationActions.fetchRoomsSuccess({ objectId, rooms: resultCreate.result }))
                } else {
                    dispatch(communicationActions.fetchRoomsFailure({ objectId, error: resultCreate.error }))
                }
            } else {
                dispatch(communicationActions.fetchRoomsSuccess({ objectId, rooms }))
            }
        } else {
            dispatch(communicationActions.fetchRoomsFailure({ objectId, error: result.error }))
        }
    }, [organizationId, objectId, communicationApi, createPayload.organizations, dispatch])

    useEffect(() => {
        if (rooms && rooms.length > 0) {
            const otherOrganizations = createPayload.organizations
            if (otherOrganizations && otherOrganizations.length > 0) {
                const organizationWithoutRoom = otherOrganizations.filter((otherOrganization) => {
                    const organizationHasRoom = rooms.some((room) =>
                        room.involvedOrganization?.some(
                            (involvedOrganization) => otherOrganization === involvedOrganization.organizationId
                        )
                    )
                    return !organizationHasRoom
                })
                if (organizationWithoutRoom.length > 0) {
                    communicationApi
                        .createRoom(organizationId, createPayload, { onlySharedRoom: true })
                        .then((result) => {
                            if (isResultSuccess(result)) {
                                dispatch(communicationActions.addRooms({ objectId, rooms: result.result }))
                            }
                        })
                }
            }
            if (!rooms.some(isPrivateRoom)) {
                // If the private room does not exist, create it
                communicationApi.createRoom(organizationId, createPayload, { onlyPrivateRoom: true }).then((result) => {
                    if (isResultSuccess(result)) {
                        dispatch(communicationActions.addRooms({ objectId, rooms: result.result }))
                    }
                })
            }
        }
    }, [organizationId, createPayload.organizations, rooms])

    useEffect(() => {
        if (!rooms && !loading && !error) {
            fetchRooms()
        }
    }, [fetchRooms, loading, error, rooms])

    return useMemo(() => {
        if (rooms) {
            const filteredRooms: RoomI[] = []
            const firstPrivateRoom = rooms.find(isPrivateRoom)
            const sharedRoom = rooms
                .filter(isSharedRoom)
                .find(
                    (room) =>
                        createPayload.organizations &&
                        createPayload.organizations.length > 0 &&
                        createPayload.organizations.every((otherOrganizationId) =>
                            room.involvedOrganization?.some(
                                (involvedOrganization) => involvedOrganization.organizationId === otherOrganizationId
                            )
                        )
                )
            if (firstPrivateRoom) {
                filteredRooms.push(firstPrivateRoom)
            }
            if (sharedRoom) {
                filteredRooms.push(sharedRoom)
            }
            return {
                rooms: filteredRooms,
                loading,
                error,
            }
        }
        return { rooms, loading, error }
    }, [rooms, loading, error, createPayload.organizations])
}
