import React, { useCallback, useEffect } from "react"
import { HeaderH1 } from "~/components/Header/HeaderH1"
import { Command, Send } from "react-feather"
import { Box, TextField } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import { Button, Loader } from "~/components"
import "../assets/Prompt.scss"
import { useAppSelector } from "~/store/hooks"
import { selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { selectUser } from "~/store/account/accountSlice"
import { copilotApi } from "~/domains/copilot/prompt/api"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { Features, isFeatureEnabled } from "~/core/featureFlag"
import { useNavigate } from "react-router-dom"

enum From {
    USER = "user",
    COPILOT = "copilot",
}
interface Conversation {
    from: From
    message: string
}

function IconName({ name }: { name: string | null | undefined }) {
    return (
        <span className={`icon-name ${!name ? "copilot" : "user"}`}>
            {name?.charAt(0).toUpperCase() ?? <Command />}
        </span>
    )
}

export function Prompt() {

    const displayMenu = useAppSelector(selectKeepSidebarOpened)
    const [message, setMessage] = React.useState<string>("")
    const [conversation, setConversation] = React.useState<Conversation[]>([])
    const [threadId, setThreadId] = React.useState<string>("")
    const [threadLoading, setThreadLoading] = React.useState<boolean>(true)
    const [messageLoading, setMessageLoading] = React.useState<boolean>(false)

    const user = useAppSelector(selectUser)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const isEnabled = isFeatureEnabled(Features.ChatBot, currentOrganizationId)
    const navigate = useNavigate()

    useEffect(() => {
        if (!isEnabled) {
            navigate("/")
        }
    }, [isEnabled])

    const handleChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value)
    }
    const handleSendMessage = () => {
        if (message) {
            setConversation((prev) => [...prev, { from: From.USER, message }])
            setMessage("")
            setMessageLoading(true)
            copilotApi.createMessage(threadId, user.id, message).then((result) => {
                setConversation((prev) => [...prev, { from: From.COPILOT, message: result.answer.message }])
                setMessageLoading(false)
            })
        }
    }

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight)
    }, [conversation])

    const handlePressEnter = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.code === "Enter") {
                event.stopPropagation()
                event.preventDefault()
                handleSendMessage()
                return false
            }
        },
        [handleSendMessage]
    )
    useEffect(() => {
        if (currentOrganizationId && user?.id && threadId === "") {
            copilotApi.createThread(currentOrganizationId, user.id).then((result) => {
                setThreadLoading(false)
                setThreadId(result.thread.id)
            })
        }
    }, [currentOrganizationId, user, threadId])

    if (!isEnabled) {
        return null
    }

    return (
        <div className="copilot-page">
            <HeaderH1 title={"Flowie Copilot"} icon={<Command />} />
            <Box className="main-box" component={"div"}>
                {threadLoading ? (
                    <Loader />
                ) : (
                    <Grid container spacing={2}>
                        <Grid xs={12} md={12}>
                            <Box
                                className={`prompt-result ${conversation.length ? "conversation-on" : ""}`}
                                component={"div"}
                            >
                                <Box className={`message copilot`} component={"div"}>
                                    <h4>
                                        <IconName name={null} /> Flowie Copilot
                                    </h4>
                                    <p>
                                        Ask me :{" "}
                                        <span>
                                            What was my terms of contract with the XXX ? What is our current impact in
                                            eqCO2 ? Which invoice are late ? ...
                                        </span>
                                    </p>
                                </Box>
                                {conversation.map((message, key) => {
                                    return (
                                        <Box key={key} className={`message ${message.from}`} component={"div"}>
                                            <h4>
                                                <IconName name={message.from === From.USER ? user?.fullName : null} />{" "}
                                                {message.from === From.USER ? user?.fullName : "Flowie Copilot"}
                                            </h4>
                                            {message.from === From.USER ? (
                                                <p>{message.message}</p>
                                            ) : (
                                                <>
                                                    {message.message.split("\n").map((message, key) => (
                                                        <p key={key}>{message}</p>
                                                    ))}
                                                </>
                                            )}
                                        </Box>
                                    )
                                })}
                                {messageLoading ? <Loader small /> : null}
                            </Box>
                            <Box className={`prompt-container ${displayMenu ? "opened" : ""}`} component={"div"}>
                                <TextField
                                    required
                                    id="message"
                                    name="message"
                                    label={"Ask a question or get some help"}
                                    placeholder={"Ask a question or get some help"}
                                    onChange={handleChangeMessage}
                                    value={message}
                                    fullWidth
                                    onKeyDown={handlePressEnter}
                                />
                                <Button type="primary" onClick={handleSendMessage}>
                                    <Send />
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </div>
    )
}
