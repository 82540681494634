import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { isDefined } from "~/utils/isDefined"
import { purchaseRequestApi } from "../../api"
import { purchaseRequestsActions } from "../purchaseRequestsSlice"
import { getPurchaseRequestId } from "./useDeletePurchaseRequests"

const messages = defineMessages({
    purchaseRequestApproved: {
        id: "purchase.requests.purchaseRequestApproved",
        defaultMessage: "Your purchase request has been successfully approved.",
    },
    purchaseRequestsApproved: {
        id: "purchase.requests.purchaseRequestsApproved",
        defaultMessage: "Your purchase requests has been successfully approved.",
    },
    unableToApprovePurchaseRequest: {
        id: "purchase.requests.unableToApprovePurchaseRequest",
        defaultMessage: "An error occured: your purchase request could not be approved.",
    },
    unableToApprovePurchaseRequests: {
        id: "purchase.requests.unableToApprovePurchaseRequests",
        defaultMessage: "An error occured: your purchase requests could not be approved.",
    },
    unableToApproveSomePurchaseRequests: {
        id: "purchase.requests.unableToApproveSomePurchaseRequests",
        defaultMessage: "An error occured: {count} purchase request{s} could not be approved.",
    },
})

export const useApprovePurchaseRequests = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    return async (purchaseRequestIds: string[], organizationId: OrganizationId) => {
        const results = await Promise.allSettled(
            purchaseRequestIds.map((purchaseRequestId) => purchaseRequestApi.approve(organizationId, purchaseRequestId))
        )
        const successApproved = results
            .map((r, index) => getPurchaseRequestId(r, index, purchaseRequestIds))
            .filter(isDefined)
        const failApprovedLength = results.length - successApproved.length
        dispatch(purchaseRequestsActions.approvePurchaseRequests(successApproved))
        if (failApprovedLength > 0) {
            toast.error(
                formatMessage(
                    failApprovedLength === results.length
                        ? messages.unableToApprovePurchaseRequests
                        : messages.unableToApproveSomePurchaseRequests,
                    { count: failApprovedLength, s: failApprovedLength > 1 ? "s" : "" }
                )
            )
        } else {
            toast.success(
                formatMessage(
                    purchaseRequestIds.length === 1
                        ? messages.purchaseRequestApproved
                        : messages.purchaseRequestsApproved
                )
            )
        }
        return true
    }
}
