import { usePurchaseRequestActions, usePurchaseRequestWorkflowReviewers } from "~/store/workflow/hooks"
import { PurchaseRequestDetails, PurchaseRequestStatus } from "../../types/PurchaseRequests"
import { useAppSelector } from "~/store/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { PurchaseRequestCheckWorkflowWithUsers } from "~/features/workflow/types/ApiWorkflow"
import { useUpdatePurchaseRequestStatus } from "./useUpdatePurchaseRequestStatus"
import { useCallback } from "react"
import { useFetchPurchaseRequest } from "./useFetchPurchaseRequest"
import { UserI } from "~/types"

type PurchaseRequestStateWithWorkflow = {
    withinWorkflow: true
    checkDone: number
    totalChecks: number
    reviewers: UserI[]
}
type PurchaseRequestStateWithoutWorkflow = {
    withinWorkflow: false
    checkDone?: never
    totalChecks?: never
    reviewers?: never
}

type PurchaseRequestUserCanApprove = {
    loading: false
    currentUserCanValidate: true
    currentUserApproval: boolean | undefined
    approve: () => void
    refuse: () => void
    statusLoading: boolean
}

type PurchaseRequestUserCannotApprove = {
    loading: false
    currentUserCanValidate: false
    approve?: never
    refuse?: never
    statusLoading?: never
    currentUserApproval?: never
}

type PurchaseRequestLoadingState = {
    loading: true
    withinWorkflow?: never
    checkDone?: never
    totalChecks?: never
    currentUserCanValidate?: never
    approve?: never
    refuse?: never
    statusLoading?: never
    currentUserApproval?: never
    reviewers?: never
}

type PurchaseRequestValidationState =
    | PurchaseRequestLoadingState
    | (PurchaseRequestStateWithWorkflow & PurchaseRequestUserCanApprove)
    | (PurchaseRequestStateWithWorkflow & PurchaseRequestUserCannotApprove)
    | (PurchaseRequestStateWithoutWorkflow & PurchaseRequestUserCanApprove)
    | (PurchaseRequestStateWithoutWorkflow & PurchaseRequestUserCannotApprove)

export const usePurchaseRequestValidationActions = (
    purchaseRequest: PurchaseRequestDetails
): PurchaseRequestValidationState => {
    const currentUser = useAppSelector(selectUser)
    const { workflowCheck, loading } = usePurchaseRequestWorkflowReviewers(
        purchaseRequest.organizationId,
        purchaseRequest.id
    )
    const { updatePRStatus, statusLoading } = useUpdatePurchaseRequestStatus(
        purchaseRequest.organizationId,
        purchaseRequest.id
    )
    const {
        approvePurchaseRequest,
        refusePurchaseRequest,
        loading: validationLoading,
    } = usePurchaseRequestActions(purchaseRequest)

    const { fetchPurchaseRequest } = useFetchPurchaseRequest(purchaseRequest.organizationId)

    const refreshPurchaseRequest = useCallback(() => {
        window.setTimeout(() => {
            fetchPurchaseRequest(purchaseRequest.id)
        }, 1000)
    }, [fetchPurchaseRequest, purchaseRequest.id])

    const approveFromWorkflow = useCallback(async () => {
        await approvePurchaseRequest()
        refreshPurchaseRequest()
    }, [approvePurchaseRequest, refreshPurchaseRequest])

    const refuseFromWorkflow = useCallback(async () => {
        await refusePurchaseRequest()
        refreshPurchaseRequest()
    }, [approvePurchaseRequest, refreshPurchaseRequest])

    const rejectPurchaseRequest = useCallback(async () => {
        await updatePRStatus(PurchaseRequestStatus.REJECTED)
        refreshPurchaseRequest()
    }, [updatePRStatus, refreshPurchaseRequest])

    const validatePurchaseRequest = useCallback(async () => {
        await updatePRStatus(PurchaseRequestStatus.APPROVED)
        refreshPurchaseRequest()
    }, [updatePRStatus, refreshPurchaseRequest])

    if (loading) {
        return { loading: true }
    }

    if (workflowCheck) {
        // we filter the workflows where the current user is a reviewer
        const workflowChecksForUser =
            workflowCheck.purchaseRequestCheckStatus.checks.filter((check) =>
                check.reviewers.some((reviewer) => reviewer.id === currentUser.id)
            ) ?? []

        // We select the workflow check with the least number of missing reviewers
        const currentWorkflowCheck = workflowChecksForUser.reduce(
            (acc: PurchaseRequestCheckWorkflowWithUsers | null, check: PurchaseRequestCheckWorkflowWithUsers) => {
                if (!acc) return check
                if (check.checkThreshold - check.approvers.length < acc.checkThreshold - acc.approvers.length)
                    return check
                return acc
            },
            null
        )
        if (currentWorkflowCheck) {
            const hasApproved = workflowCheck.purchaseRequestCheckStatus.approvers.some(
                (userId) => userId === currentUser.id
            )
            const hasRefused = workflowCheck.purchaseRequestCheckStatus.refusers.some(
                (userId) => userId === currentUser.id
            )
            return {
                loading: false,
                withinWorkflow: true,
                currentUserCanValidate: true,
                checkDone: currentWorkflowCheck.approvers.length,
                totalChecks: currentWorkflowCheck.checkThreshold,
                reviewers: currentWorkflowCheck.reviewers,
                statusLoading: validationLoading,
                approve: approveFromWorkflow,
                refuse: refuseFromWorkflow,
                currentUserApproval: hasApproved ? true : hasRefused ? false : undefined,
            }
        } else {
            // We select the workflow check with the least number of missing reviewers
            const currentWorkflowCheck = workflowCheck.purchaseRequestCheckStatus.checks.reduce(
                (acc: PurchaseRequestCheckWorkflowWithUsers | null, check: PurchaseRequestCheckWorkflowWithUsers) => {
                    if (!acc) return check
                    if (check.checkThreshold - check.approvers.length < acc.checkThreshold - acc.approvers.length)
                        return check
                    return acc
                },
                null
            )
            if (currentWorkflowCheck) {
                return {
                    loading: false,
                    withinWorkflow: true,
                    reviewers: currentWorkflowCheck.reviewers,
                    currentUserCanValidate: false,
                    checkDone: currentWorkflowCheck.approvers.length,
                    totalChecks: currentWorkflowCheck.checkThreshold,
                }
            }
        }
    }
    return {
        loading: false,
        withinWorkflow: false,
        currentUserCanValidate: true,
        approve: validatePurchaseRequest,
        refuse: rejectPurchaseRequest,
        currentUserApproval: undefined,
        statusLoading,
    }
}
