import { useCallback, useEffect } from "react"
import { useCustomFieldsApi } from "../customFieldsApi"
import { CustomFieldI } from "../types/CustomField"
import { store } from "~/store"
import { customFieldsActions, selectCustomFieldValues, selectFetchingCustomFieldValues } from "../store"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { CustomFieldValueI } from "../types/CustomFieldValue"

export const useCustomFieldValue = (customField: CustomFieldI, objectId: string): CustomFieldValueI | null => {
    const dispatch = useAppDispatch()
    const customFieldsApi = useCustomFieldsApi()
    const customFieldsValues = useAppSelector(selectCustomFieldValues)[objectId]
    const customFieldValue = customFieldsValues ? customFieldsValues[customField.name] : null

    const fetch = useCallback(async () => {
        const fetching = selectFetchingCustomFieldValues(store.getState())[objectId] === true
        if (!fetching) {
            dispatch(customFieldsActions.fetchCustomFieldValues(objectId))
            const values = await customFieldsApi.getValues(objectId)
            dispatch(customFieldsActions.fetchCustomFieldValuesSuccess({ objectId, values }))
        }
    }, [customFieldsApi, objectId, dispatch])

    useEffect(() => {
        fetch()
    }, [fetch])

    return customFieldValue
}
