import * as t from "io-ts"
import { optional } from "~/types/utils"

export const DataCoverageMetricsIO = t.partial({
    description: optional(t.string),
    dnbCode: optional(t.number),
})

const PaymentBehaviorResultIO = t.type({
    description: t.string,
    dnbCode: t.number,
})

export const BusinessTradingSummaryIO = t.intersection([
    t.type({
        paymentBehaviorDays: t.number,
        paydexScore: t.number,
        totalExperiencesCount: t.number,
        paymentBehaviorResult: PaymentBehaviorResultIO,
    }),
    t.partial({
        dataCoverage: optional(DataCoverageMetricsIO),
    }),
])
export const BusinessTradingMetricsIO = t.type({
    summary: t.array(BusinessTradingSummaryIO),
    summaryDate: t.string,
})

export type BusinessTradingMetricsI = t.TypeOf<typeof BusinessTradingMetricsIO>

export const insteance: BusinessTradingMetricsI = {
    summary: [
        {
            dataCoverage: {
                description: "12 Months",
                dnbCode: 19167,
            },
            paydexScore: 68,
            paymentBehaviorDays: 17,
            paymentBehaviorResult: {
                description: "Beyond terms",
                dnbCode: 1239,
            },
            totalExperiencesCount: 109,
        },
    ],
    summaryDate: "2024-06-08",
}
