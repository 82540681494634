import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
} from "react"
import "./LangSelector.scss"
import {
    globalActions,
    Languages,
    LANGUAGES,
    selectLang,
} from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { FlagComponent, FR, GB, AE, CN, DE } from "country-flag-icons/react/3x2"

const LanguagesFlags: Record<Languages, FlagComponent> = {
    [Languages.FR]: FR,
    [Languages.EN]: GB,
    [Languages.AR]: AE,
    [Languages.CH]: CN,
    [Languages.DE]: DE,
}
const LanguagesLabels: Record<Languages, string> = {
    [Languages.FR]: "Français",
    [Languages.EN]: "English",
    [Languages.AR]: "Arabic",
    [Languages.CH]: "Chinese",
    [Languages.DE]: "German",
}

interface Props {
    displayLang: boolean
    setDisplayLang: Dispatch<SetStateAction<boolean>>
}

export function LangSelector({displayLang, setDisplayLang}: Props) {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (displayLang) {
            const onClick = () => setDisplayLang(false)
            window.addEventListener("click", onClick)
            return () => {
                window.removeEventListener("click", onClick)
            }
        }
    }, [displayLang, setDisplayLang])

    const handleSelectLang = useCallback(
        (e: React.MouseEvent<HTMLLIElement>, lang: Languages) => {
            if (displayLang) {
                e.stopPropagation()
            }
            setDisplayLang(false)
            dispatch(globalActions.setLang(lang))
        },
        [setDisplayLang, displayLang, dispatch]
    )

    const handleDisplayLang = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation()
            setDisplayLang(true)
        },
        [setDisplayLang]
    )

    const lang = useAppSelector(selectLang)
    const otherLanguages = useMemo(() => LANGUAGES.filter((l) => l !== lang), [lang])
    const LangFlag = LanguagesFlags[lang]

    return <div className={"lang-selector " + (displayLang ? "open" : "")} onClick={handleDisplayLang}>
        <ul>
            <li className="lang-selected" onClick={(e) => handleSelectLang(e, lang)}>
                <LangFlag /> <span>{LanguagesLabels[lang]}</span>
            </li>
            {otherLanguages.map((language) => {
                const Flag = LanguagesFlags[language]
                return (
                    <li key={language} onClick={(e) => handleSelectLang(e, language)}>
                        <Flag />
                        <span>{LanguagesLabels[language]}</span>
                    </li>
                )
            })}
        </ul>
    </div>
}
