import { TextField, styled } from "@mui/material"

export const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root.MuiFilledInput-root": {
        backgroundColor: "var(--background-color)",
    },
    "& .MuiInputBase-input.MuiFilledInput-input": {
        backgroundColor: "var(--background-color)",
        color: theme.palette.grey[500],
    },
}))
