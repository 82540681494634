import axiosClient from "~/api/axiosClient"
import {
    DocumentDataI,
    DocumentErrorResponseI,
    DocumentId,
    DocumentSuccessResponseI,
    DuplicateDocumentPayload,
    UploadDocumentDTO,
    UploadDocumentResponseV2I,
} from "../Documents"

const DOCUMENT_BASE_URL = import.meta.env.VITE_API_DOCUMENT_URL + "documents"

export const documentApi = {
    findObjectDocuments(objectId: string): Promise<DocumentDataI[]> {
        const url = `${DOCUMENT_BASE_URL}/objects/${objectId}`
        return axiosClient.get(url)
    },
    uploadDocument(payload: UploadDocumentDTO): Promise<UploadDocumentResponseV2I> {
        const formData = new FormData()
        formData.append("file", payload.file)
        formData.append("organizationIds", JSON.stringify(payload.organizationIds))
        formData.append("name", payload.name)
        formData.append("documentType", payload.documentType)

        if (payload.objectId) {
            formData.append("objectId", payload.objectId)
        }
        if (payload.objectType) {
            formData.append("objectType", payload.objectType)
        }

        const url = `${DOCUMENT_BASE_URL}`

        return axiosClient.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
    deleteDocument(documentId: DocumentId): Promise<DocumentSuccessResponseI | DocumentErrorResponseI> {
        const forceOption = "?force=True"
        const url = `${DOCUMENT_BASE_URL}/${documentId}${forceOption}`
        return axiosClient.delete(url)
    },
    duplicateDocumentLink(
        payload: DuplicateDocumentPayload
    ): Promise<DocumentSuccessResponseI | DocumentErrorResponseI> {
        const url = `${DOCUMENT_BASE_URL}/objects/duplicate`
        return axiosClient.post(url, payload)
    },
    // not used at the moment
    // downloadDocument(documentUrl: string, documentName: string) {
    //     axiosClient
    //         .get(documentUrl, {
    //             headers: {
    //                 "Content-Type": "multipart/form-data",
    //             },
    //             responseType: "blob",
    //         })
    //         .then((response) => {
    //             // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //             // @ts-ignore
    //             fileDownload(response, documentName)
    //         })
    //         .catch((error) => {
    //             console.error(error)
    //         })
    // },
    // findOneObjectDocument(documentId: DocumentId): Promise<DocumentAPIResponseI> {
    //     const url = `${DOCUMENT_BASE_URL}/${documentId}`
    //     return axiosClient.get(url)
    // },
}
