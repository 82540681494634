import React, { useEffect, useState } from "react"
import { MessageI, RoomId } from "../../types"
import { useMessagesWithUsers } from "../../hooks/useMessagesWithUsers"
import { Box, IconButton, styled } from "@mui/material"
import { TransitionGroup } from "react-transition-group"
import Collapse from "@mui/material/Collapse"
import { MessageContent } from "../Message"
import { UserI } from "~/types"
import { ArrowLeft, ArrowRight } from "react-feather"

interface Props {
    messages: MessageI[]
    roomId: RoomId
    currentUser: UserI
}

const PinnedMessagesContainer = styled(Box)(({ theme }) => ({
    position: "sticky",
    top: 0,
    width: "100%",
    left: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    backgroundColor: theme.palette.primary.light,
    zIndex: 5,
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    boxSizing: "border-box",
    padding: "0 8px",
}))

const Footer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
})

export const PinnedMessages: React.FC<Props> = ({ messages, roomId, currentUser }) => {
    const [messageDisplayedIndex, setMessageDisplayedIndex] = useState<number>(messages.length - 1)
    const messagesWithUser = useMessagesWithUsers(messages)
    const currentMessage =
        messageDisplayedIndex < messagesWithUser.length && messageDisplayedIndex >= 0
            ? messagesWithUser[messageDisplayedIndex]
            : null

    useEffect(() => {
        if (messages.length > 0 && messageDisplayedIndex < 0) {
            setMessageDisplayedIndex(0)
        }
        if (messageDisplayedIndex > messages.length - 1) setMessageDisplayedIndex(messages.length - 1)
    }, [messages, messageDisplayedIndex])
    if (!currentMessage) return null
    return (
        <PinnedMessagesContainer>
            <TransitionGroup>
                <Collapse key={currentMessage.id}>
                    <MessageContent message={currentMessage} roomId={roomId} currentUser={currentUser} withoutFooter />
                </Collapse>
            </TransitionGroup>
            {messages.length > 1 && (
                <Footer>
                    <IconButton
                        size="small"
                        onClick={() =>
                            setMessageDisplayedIndex(
                                messageDisplayedIndex > 0 ? messageDisplayedIndex - 1 : messages.length - 1
                            )
                        }
                    >
                        <ArrowLeft size={14} />
                    </IconButton>
                    {messageDisplayedIndex + 1} / {messages.length}
                    <IconButton
                        size="small"
                        onClick={() =>
                            setMessageDisplayedIndex(
                                messageDisplayedIndex < messages.length - 1 ? messageDisplayedIndex + 1 : 0
                            )
                        }
                    >
                        <ArrowRight size={14} />
                    </IconButton>
                </Footer>
            )}
        </PinnedMessagesContainer>
    )
}
