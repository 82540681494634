import { Result } from "~/core/Result"
import axiosClient from "./axiosClient"
import {
    AxonautCredentialsI,
    CountryCode,
    CreateOrganizationResponseI,
    CreateOrganizationTeamI,
    OrganizationAccountingContactI,
    OrganizationAddressI,
    OrganizationBankDetailI,
    OrganizationI,
    OrganizationId,
    OrganizationIdentifier,
    OrganizationRegistrationI,
    OrganizationTeamI,
    PennylaneCredentialsI,
    TeamId,
    UserId,
} from "~/types"
import { ImportBatchResponseI } from "~/types/ImportBatch"

const STAKEHOLDERS_BASE_URL = import.meta.env.VITE_API_STAKEHOLDERS_URL
const BASE_URL = `${STAKEHOLDERS_BASE_URL}v1/organization`
const TEAMS_URL = `${import.meta.env.VITE_API_TEAMS_URL}`
const IMPORT_BASE_URL = import.meta.env.VITE_API_IMPORT_FROM_FILES_URL

export interface CreateTag {
    tagName: string
    tagValue?: number
    tagExternalId?: string
}

export const organizationApi = {
    getOrganizationById(organizationId: OrganizationId): Promise<OrganizationI> {
        const url = `${BASE_URL}/${organizationId}`
        return axiosClient.get(url)
    },
    fetchOrganizationsByIds(organizationIds: OrganizationId[]): Promise<OrganizationI[]> {
        const url = `${STAKEHOLDERS_BASE_URL}v1/organizations?ids=${organizationIds.join(",")}`
        return axiosClient.get(url)
    },
    getOrganizationByIdentifier(identifier: OrganizationIdentifier): Promise<OrganizationI> {
        const url = `${STAKEHOLDERS_BASE_URL}v1/organization-by-identifier/${identifier}`
        return axiosClient.get(url)
    },
    /* This route creates a new Organization with the userId in the token as the Founder */
    foundOrganization(
        name: string,
        countryCode: CountryCode,
        identifier: string
    ): Promise<CreateOrganizationResponseI> {
        const url = `${BASE_URL}/found`
        return axiosClient.post(url, {
            name,
            countryCode,
            identifier,
        })
    },
    /* This route creates a new Organization with no Founder */
    createOrganization(
        name: string,
        countryCode: CountryCode,
        identifier: string
    ): Promise<CreateOrganizationResponseI> {
        const url = `${BASE_URL}/create`
        return axiosClient.post(url, {
            name,
            countryCode,
            identifier,
        })
    },
    renameOrganization(organizationId: OrganizationId, name: string): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/rename`
        return axiosClient.post(url, { name })
    },
    deleteOrganization(organizationId: OrganizationId): Promise<void> {
        const url = `${BASE_URL}/${organizationId}`
        return axiosClient.delete(url)
    },
    inviteMember(organizationId: OrganizationId, userId: UserId): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/invite`
        return axiosClient.post(url, { invitedUser: userId })
    },
    requestMembership(organizationId: OrganizationId): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/request-membership`
        return axiosClient.post(url)
    },
    acceptInvitation(organizationId: OrganizationId): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/accept`
        return axiosClient.post(url)
    },
    refuseInvitation(organizationId: OrganizationId): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/refuse`
        return axiosClient.post(url)
    },
    acceptMembership(organizationId: OrganizationId, userId: UserId): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/accept-membership`
        return axiosClient.post(url, { requestUserId: userId })
    },
    refuseMembership(organizationId: OrganizationId, userId: UserId): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/refuse-membership`
        return axiosClient.post(url, { requestUserId: userId })
    },
    deleteMember(organizationId: OrganizationId, userId: UserId): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/remove-member`
        return axiosClient.post(url, { removedUserId: userId })
    },
    provideBankDetails(organizationId: OrganizationId, bankDetails: OrganizationBankDetailI): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/provide-bank-details`
        return axiosClient.post(url, bankDetails)
    },
    provideAddress(organizationId: OrganizationId, address: OrganizationAddressI): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/provide-address`
        return axiosClient.post(url, address)
    },
    provideRegistration(organizationId: OrganizationId, registration: OrganizationRegistrationI): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/provide-registration`
        return axiosClient.post(url, registration)
    },
    provideAccountingContact(
        organizationId: OrganizationId,
        accountingContact: OrganizationAccountingContactI
    ): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/provide-accounting-contact`
        return axiosClient.post(url, accountingContact)
    },
    provideAxonautCredentials(organizationId: OrganizationId, axonautCredentials: AxonautCredentialsI): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/provide-axonaut-credentials`
        return axiosClient.post(url, axonautCredentials)
    },
    providePennylaneCredentials(
        organizationId: OrganizationId,
        pennylaneCredentials: PennylaneCredentialsI
    ): Promise<void> {
        const url = `${BASE_URL}/${organizationId}/provide-pennylane-credentials`
        return axiosClient.post(url, pennylaneCredentials)
    },
    importBatch(organizationId: string, attachedFile: File): Promise<Result<ImportBatchResponseI>> {
        const formData = new FormData()
        formData.append("file", attachedFile)
        formData.append("organizationId", organizationId)

        return axiosClient.post(`${IMPORT_BASE_URL}user/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
    // Teams management
    fetchOrganizationTeams(organizationId: OrganizationId): Promise<OrganizationTeamI[]> {
        const url = `${TEAMS_URL}organization/${organizationId}/teams`
        return axiosClient.get(url)
    },
    fetchOrganizationTeamsPeople(organizationId: OrganizationId): Promise<OrganizationTeamI[]> {
        const url = `${TEAMS_URL}organization/${organizationId}/users`
        return axiosClient.get(url)
    },
    createTeam(organizationId: string, newTeam: CreateOrganizationTeamI): Promise<{ teamId: string }> {
        const url = `${TEAMS_URL}organization/${organizationId}/teams`
        return axiosClient.post(url, newTeam)
    },
    updateTeam(organizationId: string, team: Partial<OrganizationTeamI>): Promise<void> {
        const url = `${TEAMS_URL}organization/${organizationId}/teams/${team.teamId}`
        return axiosClient.put(url, team)
    },
    deleteTeam(organizationId: string, teamId: string): Promise<void> {
        const url = `${TEAMS_URL}organization/${organizationId}/teams/${teamId}`
        return axiosClient.delete(url)
    },
    addUserToTeam(organizationId: string, teamId: TeamId, userId: UserId, creatorId: UserId): Promise<void> {
        const url = `${TEAMS_URL}organization/${organizationId}/teams/${teamId}/users`
        return axiosClient.post(url, { userId: userId, addedByUserId: creatorId })
    },
    removeUserFromTeam(organizationId: string, teamId: TeamId, userId: UserId): Promise<void> {
        const url = `${TEAMS_URL}organization/${organizationId}/teams/${teamId}/users/${userId}`
        return axiosClient.delete(url)
    },
    importBatchTeams(organizationId: string, attachedFile: File): Promise<Result<ImportBatchResponseI>> {
        const formData = new FormData()
        formData.append("file", attachedFile)
        formData.append("organizationId", organizationId)

        return axiosClient.post(`${IMPORT_BASE_URL}team/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
}
