import { InvoiceCustomFieldsI, InvoiceLineCustomFieldsI } from "~/features/invoice/core/invoiceCustomFields"
import { WorkflowReviewsI } from "~/features/workflow/types"
import {
    AlreadyPaidPaymentI,
    CompanyI,
    CreateInvoiceResponseI,
    DocumentsSortByI,
    EventI,
    InvoiceI,
    InvoiceRequestI,
    InvoiceUserType,
    MessageI,
    OrderDirection,
    UserI,
    UserId,
    ComplianceStatusI,
    InvoiceExportI,
    OrganizationId,
    InvoiceId,
} from "~/types"

export interface WorkflowReviewsState {
    data: WorkflowReviewsI
    loading: boolean
    error: string | null
}

export interface WorkflowReviewUsersState {
    data: UserI[]
    loading: boolean
    error: string | null
}

export interface ComplianceStatusState {
    data: ComplianceStatusI[] | null
    loading: boolean
    error: string | null
}

export interface InvoiceExportsState {
    organizationId: OrganizationId | null
    data: InvoiceExportI[]
    loading: boolean
    error: string | null
}

export interface InvoiceState extends BuyerStateI {
    userIds: UserId[]
    loading: boolean
    loadingInvoice: boolean
    error: null | string | number
    communications: MessageI[]
    communicationsLoaded: boolean
    events: EventI[]
    eventsLoaded: boolean
    invoice: InvoiceI | null
    request: InvoiceRequestI
    userType: InvoiceUserType | null
    involvedUsersLoaded?: boolean
    involvedUsers?: UserI[]
    workflowReviews: WorkflowReviewsState
    reviewUsers: WorkflowReviewUsersState
    suggestedBuyerCompanies?: CompanyI[]
    suggestedSupplierCompanies?: CompanyI[]
    alreadyPaidPayment: AlreadyPaidPaymentI
    documentsSortBy: DocumentsSortByI
    creatingInvoice: CreateInvoiceResponseI | null
    ocrCreated: boolean
    complianceStatus: ComplianceStatusState
    invoiceExports: InvoiceExportsState
    invoicesById: Record<InvoiceId, { invoice: InvoiceI | null; loading: boolean; error: string | null }>
    invoiceCustomFields: InvoiceCustomFieldsI | null
    invoiceLineCustomFields: Record<string, InvoiceLineCustomFieldsI>
}

interface BuyerStateI {
    sendingValidation: boolean
    validationSuccess: boolean
    sendingApproval: boolean
    approvalSuccess: boolean
    sendingRejection: boolean
    rejectionSuccess: boolean
}

const buyerState: BuyerStateI = {
    sendingValidation: false,
    validationSuccess: false,
    sendingApproval: false,
    approvalSuccess: false,
    sendingRejection: false,
    rejectionSuccess: false,
}

export const invoiceState: InvoiceState = {
    userIds: [],
    ...buyerState,
    loading: false,
    loadingInvoice: false,
    error: null,
    userType: null,
    communications: [],
    events: [],
    communicationsLoaded: false,
    eventsLoaded: false,
    request: {
        subject: "",
        body: "",
        invoiceId: "",
        userId: "",
    },
    alreadyPaidPayment: {
        alreadyPaid: false,
        executionDate: null,
        requestIBAN: false,
    },
    documentsSortBy: {
        sortBy: "updateDate",
        direction: OrderDirection.DESC,
    },
    creatingInvoice: null,
    ocrCreated: false,
    invoice: null,
    reviewUsers: { data: [], loading: false, error: null },
    workflowReviews: {
        data: {
            workflowIds: [],
            reviewers: [],
            approvals: [],
            refusals: [],
        },
        loading: false,
        error: null,
    },
    complianceStatus: {
        data: [],
        loading: false,
        error: null,
    },
    invoiceExports: {
        organizationId: null,
        data: [],
        loading: false,
        error: null,
    },
    invoicesById: {},
    invoiceCustomFields: null,
    invoiceLineCustomFields: {},
}
