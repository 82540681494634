import React, { lazy } from "react"
import { Route } from "react-router-dom"

import { ORGANIZATION_ROLES_PERMISSIONS_ROUTE } from "./routes"
import { ProtectedRoutes } from "~/components"
const RolesPermissions = lazy(() => import("./pages/RolesPermissions").then(({ RolesPermissions }) => ({ default: RolesPermissions })))

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="roles_permissions_route">
            <Route path={ORGANIZATION_ROLES_PERMISSIONS_ROUTE} element={<RolesPermissions />} />
        </Route>,
    ],
}
