import React from "react"
import { MessageViewI } from "../../types"
import { useSelectUserById } from "~/store/users/hooks"
import { Loader } from "~/components"
import { MessageAvatarContainer } from "../Message/MessageContent"
import UserAvatar from "~/components/Avatar/UserAvatar"
import { Box, Typography, styled } from "@mui/material"
import { useIntl } from "react-intl"
import { DateComponent, toISOStringWithFixedOffset } from "~/components/Date"

interface Props {
    messageView: MessageViewI
}

const MessageViewContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
})

const UserInfo = styled("div")({
    flex: 1,
    marginTop: "4px",
    marginBottom: "8px",
})

const UserName = styled(Typography)({
    margin: 0,
    padding: 0,
})

const MessageDate = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
}))

export const MessageView: React.FC<Props> = ({ messageView }) => {
    const user = useSelectUserById(messageView.userId)
    const { locale } = useIntl()

    return (
        <MessageViewContainer>
            <MessageAvatarContainer>
                <UserAvatar email={user?.email} />
            </MessageAvatarContainer>
            <UserInfo>
                <UserName>{user ? user.fullName : <Loader small />}</UserName>
                <MessageDate>
                    <DateComponent value={toISOStringWithFixedOffset(messageView.viewedAt)} timeLocale={locale} />
                </MessageDate>
            </UserInfo>
        </MessageViewContainer>
    )
}
