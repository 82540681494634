import * as t from "io-ts"
import { CountryCode, OrganizationAddressIO, UserId } from "~/types"
import { optional } from "~/types/utils"

export enum SourceType {
    ERP = "ERP",
    MANUAL = "MANUAL",
    INVOICE = "INVOICE",
    PURCHASE_ORDER = "PURCHASE_ORDER",
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
    NONE = "NONE",
}

const SourceTypeIO = t.union([
    t.literal(SourceType.ERP),
    t.literal(SourceType.INVOICE),
    t.literal(SourceType.MANUAL),
    t.literal(SourceType.PURCHASE_ORDER),
    t.literal(SourceType.PURCHASE_REQUEST),
    t.literal(SourceType.NONE),
])

export const OneSideDataIO = t.partial({
    pendingAmount: optional(t.string),
    pendingAmountExcludedTaxes: optional(t.string),
    pendingNumber: optional(t.number),
    overdueAmount: optional(t.string),
    overdueAmountExcludedTaxes: optional(t.string),
    overdueNumber: optional(t.number),
    paidAmount: optional(t.string),
    paidAmountExcludedTaxes: optional(t.string),
    paidNumber: optional(t.number),
    aggregatedTimeToPayInDays: optional(t.string),
    averagePaymentDelayInDays: optional(t.string),
    source: optional(SourceTypeIO),
    preferredStatus: optional(t.boolean),
    verifiedStatus: optional(t.boolean),
})

const preferredRegistrationNumberIO = t.type({
    registrationType: t.string,
    registrationNumber: t.string,
})

const PartnerDataRegistrationIO = t.intersection([
    t.type({
        legalName: t.string,
        preferredRegistrationNumber: preferredRegistrationNumberIO,
    }),
    t.partial({
        countryCode: optional(t.string),
        dunsNumber: optional(t.string),
        vatNumber: optional(t.string),
    }),
])

export type PartnerDataRegistrationI = t.TypeOf<typeof PartnerDataRegistrationIO> & {
    countryCode: CountryCode
}

const PartnerAddressIO = t.type({
    city: t.string,
})
export type PartnerAddressI = t.TypeOf<typeof PartnerAddressIO>

const PartnersDataIO = t.intersection([
    t.type({
        organizationId: t.string,
        organizationName: t.string,
        registration: PartnerDataRegistrationIO,
    }),
    t.partial({
        brandName: optional(t.string),
        lastAction: optional(t.string),
        supplier: optional(OneSideDataIO),
        buyer: optional(OneSideDataIO),
        address: optional(PartnerAddressIO),
    }),
])

const PartnerOrganizationIO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        registration: PartnerDataRegistrationIO,
    }),
    t.partial({
        address: optional(OrganizationAddressIO),
    }),
])

export interface PartnerIdAndName {
    partnerId?: string
    partnerName?: string
}

export const PartnersDataIOArray = t.array(PartnersDataIO)

export type OneSideDataI = t.TypeOf<typeof OneSideDataIO>
export type PartnersDataI = t.TypeOf<typeof PartnersDataIO> & {
    registration: PartnerDataRegistrationI
}
export type PartnerOrganizationI = t.TypeOf<typeof PartnerOrganizationIO> & {
    registration: PartnerDataRegistrationI
}

export enum PartnershipType {
    SUPPLIER = "SUPPLIER",
    BUYER = "BUYER",
}

export enum PartnershipTypeOption {
    SUPPLIER = "SUPPLIER",
    BUYER = "BUYER",
    BOTH = "BOTH",
}

const PartnershipTypeIO = t.union([t.literal(PartnershipType.BUYER), t.literal(PartnershipType.SUPPLIER)])

const PartnershipIO = t.type({
    initiatorId: t.string,
    partnerId: t.string,
    partnershipType: PartnershipTypeIO,
    source: SourceTypeIO,
    preferredStatus: t.boolean,
    verifiedStatus: t.boolean,
})

export type PartnershipI = t.TypeOf<typeof PartnershipIO>

export interface CreatePartnershipPayload {
    partnerId: string
    partnershipType: PartnershipTypeOption
    preferredStatus?: boolean
    verifiedStatus?: boolean
}

export interface UpdatePartnershipPayload {
    partnershipType: PartnershipType
    preferredStatus?: boolean
    verifiedStatus?: boolean
}

export enum PartnerAttributes {
    PREFERRED = "preferredStatus",
    VERIFIED = "verifiedStatus",
}

export interface PartnerInvoicesData {
    invoiceId: string
    overdueTime: string
    paidTime: string
}

export enum PartnerNavItem {
    INVOICES = "invoices",
    PURCHASE_ORDERS = "purchaseOrders",
    PURCHASE_REQUESTS = "purchaseRequests",
    KYB = "kyb",
    ADDRESSES = "addresses",
    CONTACTS = "contacts",
    COMPANY_DETAILS = "companyDetails",
    PAYMENT_DETAILS = "paymentDetails",
}

export type PartialPartnerProfileContact = {
    contactName: string
    email: string
}

export const CreatePartnerProfileContactIO = t.partial({
    lastName: optional(t.string),
    firstName: optional(t.string),
    email: optional(t.string),
    phoneNumber: optional(t.string),
    position: optional(t.string),
    userId: optional(t.string),
})
export type CreatePartnerProfileContactI = t.TypeOf<typeof CreatePartnerProfileContactIO>

const PartnerProfileContactIO = t.intersection([
    t.type({
        id: t.string,
    }),
    CreatePartnerProfileContactIO,
])

export const PartnerProfileContactArrayIO = t.array(PartnerProfileContactIO)

export type PartnerProfileContactI = t.TypeOf<typeof PartnerProfileContactIO>

export const CreatePartnerProfileAddressIO = t.intersection([
    t.type({
        addressLine1: t.string,
        zipCode: t.string,
        city: t.string,
        country: t.string,
    }),
    t.partial({
        label: optional(t.string),
        contactLine: optional(t.string),
        addressLine2: optional(t.string),
        region: optional(t.string),
        extra: optional(t.string),
    }),
])
export type CreatePartnerProfileAddressI = t.TypeOf<typeof CreatePartnerProfileAddressIO>

const PartnerProfileAddressIO = t.intersection([
    t.type({
        id: t.string,
    }),
    CreatePartnerProfileAddressIO,
])

export const PartnerProfileAddressArrayIO = t.array(PartnerProfileAddressIO)

export type PartnerProfileAddressI = t.TypeOf<typeof PartnerProfileAddressIO>

export const CreatePartnerProfilePaymentDetailIO = t.partial({
    label: optional(t.string),
    iban: optional(t.string),
    bic: optional(t.string),
    bankName: optional(t.string),
    paymentMethod: optional(t.string),
    verifiedStatus: optional(t.boolean),
})

export type CreatePartnerProfilePaymentDetailI = t.TypeOf<typeof CreatePartnerProfilePaymentDetailIO>

const PartnerProfilePaymentDetailIO = t.intersection([
    t.type({
        id: t.string,
        source: SourceTypeIO,
        createdAt: t.string,
    }),
    CreatePartnerProfilePaymentDetailIO,
])

export const PartnerProfilePaymentDetailArrayIO = t.array(PartnerProfilePaymentDetailIO)

export type PartnerProfilePaymentDetailI = t.TypeOf<typeof PartnerProfilePaymentDetailIO> & {
    source: SourceType
}

const commonPartnerProfileIO = t.type({
    initiatorId: t.string,
    partnerId: t.string,
    brandName: optional(t.string),
    description: optional(t.string),
    paymentTerms: optional(t.string),
    responsibleUserId: optional(t.string),
})

export const CreatePartnerProfileIO = t.intersection([
    commonPartnerProfileIO,
    t.type({
        paymentDetails: t.array(CreatePartnerProfilePaymentDetailIO),
        contacts: t.array(CreatePartnerProfileContactIO),
        addresses: t.array(CreatePartnerProfileAddressIO),
    }),
])

export type CreatePartnerProfileI = t.TypeOf<typeof CreatePartnerProfileIO>

export const PartnerProfileIO = t.intersection([
    commonPartnerProfileIO,
    t.type({
        paymentDetails: t.array(PartnerProfilePaymentDetailIO),
        contacts: t.array(PartnerProfileContactIO),
        addresses: t.array(PartnerProfileAddressIO),
    }),
])

export type PartnerProfileI = t.TypeOf<typeof PartnerProfileIO> & {
    responsibleUserId?: UserId
}

export type PartialPartnerProfile = Partial<PartnerProfileI> & {
    initiatorId: string
    partnerId: string
}

export type ProfileDetailsType = "contacts" | "paymentDetails" | "addresses"

export const OCR_ID = "contactOCRData"
export const CREATION_ID = "contactCreationData"

export enum PartnerContactGroup {
    "CREATION" = "CREATION",
    "MYBOOK" = "MYBOOK",
}
