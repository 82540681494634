import React, { useCallback, useMemo } from "react"
import { Editor, BaseRange, Transforms } from "slate"
import { ReactEditor } from "slate-react"
import { Box, Fade, Popper, alpha, styled } from "@mui/material"
import { insertMention } from "./insertMention"
import { MentionI, MentionTypes } from "~/domains/communication/types"
import { UserOrTeamWithOrganizationContext, UserWithOrganizationsContext } from "./types"
import { isDefined } from "~/utils/isDefined"
import { useAppSelector } from "~/store/hooks"
import { selectPartnersBrandNames } from "~/domains/transactions/book-of-relations/store/bookOfRelationsSlice"
import { isTeamWithOrganizationContext, isUserWithOrganizationsContext } from "../InputMessage"
import { OrganizationId, OrganizationTeamI } from "~/types"

interface Props {
    editor: Editor
    options?: UserOrTeamWithOrganizationContext[]
    highlightedOption: UserOrTeamWithOrganizationContext | null
    target: BaseRange | null
    setTarget: React.Dispatch<React.SetStateAction<BaseRange | null>>
    setTaggedCompanyId: React.Dispatch<React.SetStateAction<string | null>>
}
const Container = styled(Box)({
    backgroundColor: "var(--color-white)",
    marginBottom: "8px",
    display: "flex",
    flexDirection: "column-reverse",
})
const Option = styled("div")<{ highlighted?: boolean }>(({ highlighted, theme }) => ({
    padding: "3px 8px",
    ...(highlighted && {
        backgroundColor: alpha(theme.palette.primary.main, 0.25),
    }),
}))

const isOptionHighlighted = (
    option: UserOrTeamWithOrganizationContext,
    highlightedOption: UserOrTeamWithOrganizationContext | null
): boolean => {
    if (!highlightedOption) {
        return false
    }
    if (isUserWithOrganizationsContext(option)) {
        return isUserWithOrganizationsContext(highlightedOption) && option.id === highlightedOption.id
    } else {
        return isTeamWithOrganizationContext(highlightedOption) && option.teamId === highlightedOption.teamId
    }
}

const renderOption = (
    option: UserOrTeamWithOrganizationContext,
    highlightedOption: UserOrTeamWithOrganizationContext | null,
    selectOption: (option: UserOrTeamWithOrganizationContext) => void,
    brandNames: Record<OrganizationId, string>
) => {
    if (isUserWithOrganizationsContext(option)) {
        return <div key={option.id}>{renderUserOption(option, highlightedOption, selectOption, brandNames)}</div>
    } else {
        return <div key={option.teamId}>{renderTeamOption(option, highlightedOption, selectOption)}</div>
    }
}

const renderUserOption = (
    option: UserWithOrganizationsContext,
    highlightedOption: UserOrTeamWithOrganizationContext | null,
    selectOption: (option: UserOrTeamWithOrganizationContext) => void,
    brandNames: Record<OrganizationId, string>
) => {
    return (
        <Option
            key={option.id}
            highlighted={isOptionHighlighted(option, highlightedOption)}
            onClick={() => selectOption(option)}
        >
            <div>{option.fullName}</div>
            <div>
                {option.memberOfOrganizations.map((organization) => (
                    <span key={organization.id}>{brandNames[organization.id] ?? organization.name}</span>
                ))}
            </div>
        </Option>
    )
}

const renderTeamOption = (
    option: OrganizationTeamI,
    highlightedOption: UserOrTeamWithOrganizationContext | null,
    selectOption: (option: UserOrTeamWithOrganizationContext) => void
) => {
    return (
        <Option
            key={option.teamId}
            highlighted={isOptionHighlighted(option, highlightedOption)}
            onClick={() => selectOption(option)}
        >
            <div>{option.name}</div>
        </Option>
    )
}

const getInsertMention = (option: UserOrTeamWithOrganizationContext): MentionI | null => {
    if (isUserWithOrganizationsContext(option)) {
        const { id, fullName, memberOfOrganizations } = option
        return {
            type: MentionTypes.USER,
            value: id,
            label: fullName ?? "",
            organizationId: memberOfOrganizations[0]?.id ?? "",
        }
    } else {
        const { teamId, name, organizationId } = option
        return {
            type: MentionTypes.TEAM,
            value: teamId,
            label: name,
            organizationId: organizationId,
        }
    }
}

export const HoveringMentions: React.FC<Props> = ({
    editor,
    options,
    highlightedOption,
    target,
    setTarget,
    setTaggedCompanyId,
}) => {
    const selectOption = useCallback(
        (option: UserOrTeamWithOrganizationContext) => {
            if (target) {
                Transforms.select(editor, target)
            }
            const mention = getInsertMention(option)
            if (mention) {
                insertMention(editor, mention)
                setTaggedCompanyId(mention.organizationId)
            }
            setTarget(null)
        },
        [target, editor, setTarget, setTaggedCompanyId]
    )

    const brandNames = useAppSelector(selectPartnersBrandNames)

    const open = isDefined(target) && isDefined(options) && options.length > 0

    const domRange = useMemo(() => {
        if (!target) return null
        try {
            return ReactEditor.toDOMRange(editor, target)
        } catch {
            return null
        }
    }, [editor, target])

    const startContainer = domRange?.startContainer
    const anchorElement = startContainer?.parentElement

    const marginLeft = useMemo(() => {
        if (!domRange || !anchorElement) return 0
        const rect = domRange.getBoundingClientRect()
        const startContainerRect = anchorElement.getBoundingClientRect()
        return rect.x - startContainerRect.x
    }, [domRange, anchorElement])

    return (
        <Popper id="communication-mentions" open={open} anchorEl={anchorElement} placement="top-start" transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Container sx={{ marginLeft: `${marginLeft}px` }}>
                        {target &&
                            options
                                ?.slice(0, 10)
                                .map((option) => renderOption(option, highlightedOption, selectOption, brandNames))}
                    </Container>
                </Fade>
            )}
        </Popper>
    )
}
