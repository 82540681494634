import { ParsingErrorType, genericParser } from "~/utils"
import {
    InvoiceWorkflowCheckI,
    InvoiceWorkflowCheckIO,
    ApiWorkflowCheckConditionNodeI,
    ApiWorkflowCheckLineConditionNodeI,
    ApiWorkflowConditionNodeI,
    ApiWorkflowI,
    ApiWorkflowIO,
    ApiWorkflowNodeI,
    ApiWorkflowNodeIO,
    ApiWorkflowStartNodeI,
    ApiWorkflowValidationNodeI,
    ApiWorkflowValidationNodeIO,
    ApiWorkflowValidationType,
    PurchaseRequestWorkflowCheckI,
    PurchaseRequestWorkflowCheckIO,
    WorkflowNodeType,
    WorkflowType,
    ApiWorkflowBranchNodeI,
    ApiWorkflowBudgetNodeI,
    ApiWorkflowBudgetNodeIO,
} from "./ApiWorkflow"
import { TypeOf } from "io-ts"
import { Result, ResultSuccess } from "~/core/Result"
import { BudgetId } from "~/features/budget/types"

const AllBlockType: Record<WorkflowNodeType, true> = Object.values(WorkflowNodeType).reduce((acc, key) => {
    acc[key] = true
    return acc
}, {}) as Record<WorkflowNodeType, true>

const parseNodeType = (nodeTypeStr: string): WorkflowNodeType => {
    const nodeType = nodeTypeStr as WorkflowNodeType
    if (AllBlockType[nodeType]) return nodeType
    return WorkflowNodeType.UNKNOWN
}

const parseWorkflowType = (workflowTypeStr: string): WorkflowType => {
    const workflowType = workflowTypeStr as WorkflowType
    if (Object.values(WorkflowType).includes(workflowType)) return workflowType
    return WorkflowType.UNKNOWN
}

const validValidationType: Record<ApiWorkflowValidationType, boolean> = {
    [ApiWorkflowValidationType.APPROVAL]: true,
    [ApiWorkflowValidationType.REFUSAL]: true,
}
const parseValidationType = (validationTypeStr: string): ApiWorkflowValidationType =>
    validValidationType[validationTypeStr]
        ? (validationTypeStr as ApiWorkflowValidationType)
        : ApiWorkflowValidationType.REFUSAL

const parseValidationNode = (apiNode: TypeOf<typeof ApiWorkflowValidationNodeIO>): ApiWorkflowValidationNodeI => ({
    ...apiNode,
    type: WorkflowNodeType.VALIDATION_NODE,
    validationType: parseValidationType(apiNode.validationType),
})

const parseBudgetNode = (apiNode: TypeOf<typeof ApiWorkflowBudgetNodeIO>): ApiWorkflowBudgetNodeI => ({
    ...apiNode,
    type: WorkflowNodeType.BUDGET_NODE,
    budgetId: apiNode.budgetId as BudgetId,
})

const parseApiNode = (apiNode: TypeOf<typeof ApiWorkflowNodeIO>): ApiWorkflowNodeI => {
    const nodeType = parseNodeType(apiNode.type)
    if (nodeType === WorkflowNodeType.START_NODE) {
        return apiNode as ApiWorkflowStartNodeI
    } else if (nodeType === WorkflowNodeType.VALIDATION_NODE || nodeType === WorkflowNodeType.EXECUTION_NODE) {
        return parseValidationNode(apiNode as ApiWorkflowValidationNodeI)
    } else if (nodeType === WorkflowNodeType.CHECK_CONDITION_NODE) {
        return apiNode as ApiWorkflowCheckConditionNodeI
    } else if (nodeType === WorkflowNodeType.BRANCH_CONDITION_NODE) {
        return apiNode as ApiWorkflowConditionNodeI
    } else if (nodeType === WorkflowNodeType.BRANCH_NODE) {
        return apiNode as ApiWorkflowBranchNodeI
    } else if (nodeType === WorkflowNodeType.LINE_CHECK_CONDITION_NODE) {
        return apiNode as ApiWorkflowCheckLineConditionNodeI
    } else if (nodeType === WorkflowNodeType.BUDGET_NODE) {
        return parseBudgetNode(apiNode as ApiWorkflowBudgetNodeI)
    }
    return { type: WorkflowNodeType.UNKNOWN, nodeId: apiNode.nodeId }
}

export const parsePurchaseRequestApiWorkflow = (workflowData: unknown): Result<ApiWorkflowI, ParsingErrorType> => {
    const result = genericParser(workflowData, ApiWorkflowIO)
    if (result.success) {
        return ResultSuccess({
            ...result.result,
            type: parseWorkflowType(result.result.type),
            nodes: result.result.nodes.map(parseApiNode),
            enabled: result.result.enabled === true,
        })
    } else {
        return result
    }
}

export const parsePurchaseRequestWorkflowCheck = (workflowCheckData: unknown) => {
    return genericParser<PurchaseRequestWorkflowCheckI>(workflowCheckData, PurchaseRequestWorkflowCheckIO)
}

export const parseInvoiceWorkflowCheck = (workflowCheckData: unknown) => {
    return genericParser<InvoiceWorkflowCheckI>(workflowCheckData, InvoiceWorkflowCheckIO)
}
