import * as t from "io-ts"
import { TagId } from "./Tag"
import { ParsingErrorType, genericParser } from "~/utils"
import { Result, ResultSuccess, isResultSuccess } from "~/core/Result"

export const TagRecommandationIO = t.type({
    numUniqueObjects: t.number,
    score: t.number,
    tagId: t.string,
})

export const TagRecommandationsResultIO = t.type({
    directApplication: t.array(TagRecommandationIO),
    recommended: t.array(TagRecommandationIO),
})
export type TagRecommandationI = {
    numUniqueObjects: number
    score: number
    tagId: TagId
}
export type TagRecommandationsResultI = {
    directApplication: TagRecommandationI[]
    recommended: TagRecommandationI[]
}

const tagRecommandationFromData = (data: t.TypeOf<typeof TagRecommandationIO>) => ({
    ...data,
    tagId: data.tagId as TagId,
})

export const parseTagRecommandationsResult = (data: unknown): Result<TagRecommandationsResultI, ParsingErrorType> => {
    const result = genericParser(data, TagRecommandationsResultIO)
    if (isResultSuccess(result)) {
        return ResultSuccess({
            ...result.result,
            directApplication: result.result.directApplication.map(tagRecommandationFromData),
            recommended: result.result.recommended.map(tagRecommandationFromData),
        })
    }
    return result
}
