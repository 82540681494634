import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react"
import { SelectedTagI, TagAssignmentRule, TagGroupI, TagI, TagId } from "../../types"
import { MultipleOptionsProps, OptionsList, SingleOptionProps } from "~/components/OptionsList"
import { styled } from "@mui/material"

interface Props {
    tagGroup: TagGroupI
    unfilteredTags: TagI[]
    selectedTags: SelectedTagI[]
    setSelectedTags: Dispatch<SetStateAction<SelectedTagI[]>>
    highlightTag?: TagI | null
}

const TagGroupLabel = styled("div")(({ theme }) => ({
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: theme.palette.grey[900],
    minHeight: "34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "20px",
}))

export const TagGroup: React.FC<Props> = ({
    tagGroup,
    unfilteredTags,
    selectedTags,
    setSelectedTags,
    highlightTag,
}) => {
    const multiple = tagGroup.assignmentRule === TagAssignmentRule.MULTIPLE

    const selectedId = useMemo(
        () =>
            selectedTags.reduce((acc: Set<TagId>, tag: TagI) => {
                acc.add(tag.tagId)
                return acc
            }, new Set<TagId>()),
        [selectedTags]
    )
    const findSelectedTag = useCallback((tag: TagI) => selectedId.has(tag.tagId), [selectedId])

    const onSingleChange = useCallback(
        (tag: TagI | null) => {
            setSelectedTags((currentSelection) => [
                ...currentSelection.filter((stateTag) => stateTag.tagGroupId !== tagGroup.tagGroupId),
                ...(tag ? [tag] : []),
            ])
        },
        [setSelectedTags]
    )

    const onMultipleChange = useCallback(
        (tags: SelectedTagI[]) => {
            setSelectedTags((currentSelection) => {
                return [...currentSelection.filter((stateTag) => stateTag.tagGroupId !== tagGroup.tagGroupId), ...tags]
            })
        },
        [setSelectedTags]
    )

    const props = multiple
        ? ({
              multiple: true,
              withRatio: true,
              ratioKey: "ratio",
              onChange: onMultipleChange,
              value: selectedTags.filter((selectedTag) => selectedTag.tagGroupId === tagGroup.tagGroupId),
          } as MultipleOptionsProps<SelectedTagI>)
        : ({
              multiple: false,
              onChange: onSingleChange,
              value: unfilteredTags.find(findSelectedTag) || null,
          } as SingleOptionProps<SelectedTagI>)

    return (
        <div>
            <TagGroupLabel>{tagGroup.name}</TagGroupLabel>
            <OptionsList
                sx={{ paddingX: "20px" }}
                items={tagGroup.tags}
                labelKeys={["name", "value"]}
                idKey="tagId"
                highlightItem={highlightTag}
                {...props}
            />
        </div>
    )
}
