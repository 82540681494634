import React, { ChangeEvent, useCallback } from "react"
import { InputLineConfigI, lineCustomFieldsLabelMessages } from "~/features/invoice/core"
import { TextField } from "@mui/material"
import { useIntl } from "react-intl"
import { useInvoiceLineCustomFields } from "~/features/invoice/hooks/useInvoiceLineCustomFields"

interface Props {
    lineIndex: number
    lineConfig: InputLineConfigI
    contextId?: string
}

export const CustomFieldCell: React.FC<Props> = ({ lineIndex, lineConfig, contextId }) => {
    const { formatMessage } = useIntl()
    const [fields, setFieldValue] = useInvoiceLineCustomFields(`${contextId}-${lineIndex}`)

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value
            const customField = fields?.[lineConfig.name] || null
            if (customField) {
                setFieldValue(lineConfig.name, { ...customField, value })
            }
        },
        [setFieldValue, fields]
    )

    return (
        <TextField
            required={lineConfig.required}
            value={fields[lineConfig.name]?.value ?? ""}
            placeholder={formatMessage(lineCustomFieldsLabelMessages[lineConfig.name])}
            className="custom-field-value"
            onChange={onChange}
            variant="standard"
            type="text"
        />
    )
}
