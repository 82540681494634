import { OrganizationId } from "~/types"
import { DocumentRelationId } from "../types/InvoiceRelation"
import { useCallback } from "react"
import { useDocumentRelationsApi } from "../documentRelationsApi"
import { useAppDispatch } from "~/store/hooks"

export const useDeleteRelation = (
    organizationId: OrganizationId,
    relationId: DocumentRelationId,
    actionPayload: { payload: DocumentRelationId; type: string }
) => {
    const documentRelationsApi = useDocumentRelationsApi()
    const dispatch = useAppDispatch()
    return useCallback(async () => {
        const result = await documentRelationsApi.removeRelation(organizationId, relationId)
        if (result) {
            dispatch(actionPayload)
        }
    }, [documentRelationsApi, organizationId, relationId, dispatch, actionPayload])
}
