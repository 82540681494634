import React, { ReactNode, Suspense, useEffect, useState } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { Container } from "@mui/material"
import { HOME_ROUTE } from "~/core/routes"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectDisplayModalFinalizeAccount, selectUser, selectUserId } from "~/store/account/accountSlice"
import { ModalFinalizeAccount } from "~/components/"
import { useIntercom } from "react-use-intercom"
import { selectIsConnected } from "~/store/auth/authSlice"
import { useSegment } from "~/hooks/useSegment"
import Sidebar from "~/components/Sidebar"
import { globalActions, selectDisplayMenu, selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { UserI } from "~/types"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { useFetchPartnersData } from "~/domains/transactions/book-of-relations/store/hooks"

interface LayoutProps {
    modulesRoutes: ReactNode[]
    modulesNavItems: []
}

const isDevelopment = import.meta.env.VITE_ENV === "development"

function Layout({ modulesRoutes }: LayoutProps) {
    const displayModalFinalizeAccount = useAppSelector(selectDisplayModalFinalizeAccount)
    const sideBarOpened = useAppSelector(selectKeepSidebarOpened)
    const [prevPath, setPrevPath] = useState<null | string>(null)
    const { update } = useIntercom()
    const { segmentIdentify, segmentPage, segmentTrack } = useSegment()
    const isConnected = useAppSelector(selectIsConnected)
    const user = useAppSelector(selectUser)
    const userId = useAppSelector(selectUserId)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const displayMenu = useAppSelector(selectDisplayMenu)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const pathname = location.pathname
    const [page, setPage] = useState<string>(pathname)

    useEffect(() => {
        const newPage = pathname.split("/")[2]
        if (newPage !== page) {
            setPage(newPage)
        }
    }, [pathname, page])

    useEffect(() => {
        localStorage?.removeItem("invoice.store.current")
    }, [])

    useEffect(() => {
        if (isConnected && userId && !isDevelopment) {
            initTrackingUser(userId, user)
        }
    }, [isConnected, userId])

    useEffect(() => {
        if (!isDevelopment) {
            updateIntercomLocation()
            if (prevPath !== location.pathname) {
                segmentPage(location, prevPath)
                setPrevPath(location.pathname)
            }
        }
    }, [location])

    useEffect(() => {
        if (displayModalFinalizeAccount && !isDevelopment) {
            segmentTrack("User Signed Up", {
                user_id: userId,
                origin_page: location.pathname,
            })
        }
    }, [displayModalFinalizeAccount])

    useFetchPartnersData(currentOrganizationId)

    const initTrackingUser = (id: string, user: UserI) => {
        update({
            name: user.fullName,
            userId: id,
            email: user.email,
            company: {
                companyId: id, // TODO: change when companies will be detached from user entity
                name: "",
                //name: user.companyDetails.name ?? "",
            },
        })
        segmentIdentify(id, user)
    }

    const updateIntercomLocation = () => {
        update()
    }

    const handleHideMenu = () => {
        dispatch(globalActions.setDisplayMenu())
    }

    return (
        <div className={isConnected ? "logged-layout" : ""}>
            <Sidebar />
            <div className={`overlay-menu ${displayMenu ? "" : "hidden"}`} onClick={handleHideMenu} />
            <main className={`${sideBarOpened ? "sidebar-opened" : ""} page-${page}`}>
                <>
                    <Container maxWidth={false}>
                        <Suspense fallback={<></>}>
                            <Routes>
                                {modulesRoutes}
                                <Route path="*" element={<Navigate to={HOME_ROUTE} replace />} />
                            </Routes>
                        </Suspense>
                    </Container>
                    {!displayModalFinalizeAccount ? null : (
                        <ModalFinalizeAccount displayModal={displayModalFinalizeAccount} />
                    )}
                </>
            </main>
        </div>
    )
}

export default Layout
