import * as React from "react"
import { SetStateAction, useEffect } from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box, { BoxProps } from "@mui/material/Box"
import "./Tabs.scss"
import classnames from "classnames"

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number | string
    currentValue: number | string
}

function TabPanel(props: TabPanelProps) {
    const { children, value, currentValue, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={(typeof value !== "string" && value !== index) || value !== currentValue}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {((typeof value !== "string" && value === index) || value === currentValue) && (
                <Box sx={{ p: 3 }}>{children}</Box>
            )}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    }
}

export interface BasicTabsTabProps {
    label: React.ReactNode
    component: React.ReactNode
    value?: number | string
}

interface Props extends React.PropsWithChildren, BoxProps {
    tabs: BasicTabsTabProps[]
    className?: string
    defaultValue?: number | string
    toTab?: number | string | null
    setToTab?: React.Dispatch<SetStateAction<number | null>>
    setChange?: (number: string | number) => void
}

export default function BasicTabs({
    tabs,
    className,
    defaultValue,
    toTab,
    setToTab,
    setChange,
    sx,
    children,
    ...boxProps
}: Props) {
    const [value, setValue] = React.useState<number | string>(defaultValue ?? 0)
    const classes = classnames("tabs", className)

    useEffect(() => {
        if (defaultValue || defaultValue === 0) {
            setValue(defaultValue)
        }
    }, [])
    useEffect(() => {
        if (toTab === 0 || toTab) {
            setValue(toTab)
            if (setToTab) {
                setToTab(null)
            }
        }
    }, [toTab])

    const handleChange = (event: React.SyntheticEvent, newValue: string | number) => {
        setValue(newValue)
        if (setChange) {
            setChange(newValue)
        }
    }

    return (
        <Box sx={{ width: "100%", ...sx }} {...boxProps} className={classes}>
            <Box
                className="tabs-box"
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs"
                    scrollButtons
                    variant="scrollable"
                    allowScrollButtonsMobile
                >
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} value={tab.value ?? index} {...a11yProps(index)} />
                    ))}
                </Tabs>
                {children && <div className="tabs-side">{children}</div>}
            </Box>
            {tabs.map((tab, index) => (
                <TabPanel value={tab.value ?? value} currentValue={value} key={index} index={index}>
                    {tab.component}
                </TabPanel>
            ))}
        </Box>
    )
}
