import React, { useCallback, useEffect } from "react"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { Button, Modal, Size } from "~/components"
import "./ModalAddtems.scss"
import {
    CreatePOLineDTO,
    PurchaseRequestLine,
    LineStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { taxRateToBack } from "~/domains/transactions/purchase-requests/utils/lines"
import { useAppDispatch } from "~/store/hooks"
import { purchaseRequestsActions } from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { LineItem } from "~/domains/transactions/components/Items/ModalAddItems/LineItem"
import {
    PurchaseOrderApprovalStatus,
    PurchaseOrderLine,
    PurchaseOrderLineStatus,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { useItemsInModal } from "~/domains/transactions/components/Items/hooks/useItemsInModal"
import { purchaseOrdersActions } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { PurchaseType } from "~/domains/transactions/types/Purchases"
import { OrganizationId, ViewTypeI, CurrencyCodes } from "~/types"

const messages = defineMessages({
    title: {
        id: "purchase.requests.request.modalAddItems.title",
        defaultMessage: "Edit items",
    },
    addItem: {
        id: "purchase.requests.request.modalAddItems.addItem",
        defaultMessage: "Add item",
    },

    save: {
        id: "purchase.requests.request.modalAddItems.save",
        defaultMessage: "Save",
    },
    cancel: {
        id: "purchase.requests.request.modalAddItems.cancel",
        defaultMessage: "Cancel",
    },
    totalHT: {
        id: "purchase.requests.request.modalAddItems.line.totalHT",
        defaultMessage: "Total (tax excl)",
    },
    totalTTC: {
        id: "purchase.requests.request.modalAddItems.line.totalTTC",
        defaultMessage: "Total (tax incl)",
    },
})

interface Props {
    organizationId: OrganizationId
    lines: PurchaseRequestLine[] | PurchaseOrderLine[] | undefined
    currency: CurrencyCodes
    addNewItem: boolean
    open: boolean
    close: () => void
    purchaseType: PurchaseType
    viewType?: ViewTypeI
} // TODO add from purchase order or purchase request

export const ModalAddItems: React.FC<Props> = ({
    organizationId,
    lines,
    currency,
    open,
    close,
    purchaseType,
    addNewItem,
    viewType,
}) => {
    const { formatMessage, formatNumber } = useIntl()

    const { items, total, totalExcludingTaxes, resetItems, addEmptyItem, handleChangeItem, handleDeleteItem } =
        useItemsInModal(open, lines, addNewItem, purchaseType)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!items.length) {
            addEmptyItem()
        }
    }, [items])

    const convertItemsToLines = (items: CreatePOLineDTO[]) => {
        if (purchaseType === PurchaseType.REQUEST) {
            const lines = items.map((item: CreatePOLineDTO): PurchaseRequestLine => {
                return {
                    id: item.id ?? null,
                    approvalStatus: LineStatus.PENDING,
                    description: item.description,
                    quantity: item.quantity,
                    unitPrice: item.unitPrice,
                    unitPriceExcludingTax: item.unitPriceExcludingTax,
                    taxRate: taxRateToBack(item.taxRate),
                    totalAmount: item.totalAmount,
                    totalAmountExcludingTax: item.totalAmountExcludingTax,
                    temporaryId: item.temporaryId,
                    itemId: item.itemId,
                }
            })
            dispatch(purchaseRequestsActions.setLines(lines))
        } else {
            const lines = items.map((item: CreatePOLineDTO): PurchaseOrderLine => {
                return {
                    id: item.id ?? null,
                    status: item.hasChanged || !item.status ? PurchaseOrderLineStatus.PENDING_APPROVAL : item.status,
                    description: item.description,
                    quantity: item.quantity,
                    unitPrice: item.unitPrice,
                    unitPriceExcludingTax: item.unitPriceExcludingTax,
                    taxRate: taxRateToBack(item.taxRate),
                    totalAmount: item.totalAmount,
                    totalAmountExcludingTax: item.totalAmountExcludingTax,
                    totalTax: item.totalAmount - item.totalAmountExcludingTax,
                    buyerApprovalStatus:
                        item.hasChanged || !item.buyerApprovalStatus
                            ? PurchaseOrderApprovalStatus.PENDING
                            : item.buyerApprovalStatus,
                    supplierApprovalStatus:
                        item.hasChanged || !item.supplierApprovalStatus
                            ? PurchaseOrderApprovalStatus.PENDING
                            : item.supplierApprovalStatus,
                    temporaryId: item.temporaryId,
                    hasChanged: item.hasChanged,
                    ...(viewType === ViewTypeI.supplier
                        ? { supplierItemId: item.supplierItemId? item.supplierItemId : item.itemId }
                        : { buyerItemId: item.buyerItemId? item.buyerItemId : item.itemId }),
                }
            })
            dispatch(purchaseOrdersActions.setLines(lines))
        }
    }

    const handleCancel = () => {
        resetItems()
        close()
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        convertItemsToLines(items)
        close()
    }

    const handleAddItem = (e: React.MouseEvent) => {
        e.preventDefault()
        addEmptyItem()
    }

    const onDeleteItem = (key) => {
        handleDeleteItem(key)
    }

    const getLineItem = useCallback(
        (item: CreatePOLineDTO, index: number) => {
            const lineStatus = item.status
            return (
                <LineItem
                    item={item}
                    key={index}
                    organizationId={organizationId}
                    index={index}
                    currency={currency}
                    onChangeItem={handleChangeItem}
                    onDeleteItem={() => onDeleteItem(index)}
                    disabled={
                        purchaseType === PurchaseType.ORDER
                            ? lineStatus !== PurchaseOrderLineStatus.PENDING_APPROVAL
                            : false
                    }
                />
            )
        },
        [currency, handleChangeItem, onDeleteItem, purchaseType, organizationId]
    )

    if (!lines || !currency) {
        return null
    }
    return (
        <Modal open={open} className={"modal-add-items"} size={Size.XL}>
            <Modal.Header>
                <h4>
                    {formatMessage(messages.title)}
                    <a
                        href="~/domains/transactions/purchase-requests/components/ModalAddItems#"
                        onClick={handleAddItem}
                    >
                        <FormattedMessage {...messages.addItem} /> +
                    </a>
                </h4>
            </Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Content>
                    <table>
                        <tbody>{items.length ? items.map(getLineItem) : null}</tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={4}>Total</td>
                                <td className={"item-total-price"}>
                                    <label>{formatMessage(messages.totalTTC)}</label>
                                    <span>
                                        {formatNumber(totalExcludingTaxes, {
                                            style: "currency",
                                            currency,
                                        })}
                                    </span>
                                </td>
                                <td className={"item-total-price"}>
                                    <label>{formatMessage(messages.totalTTC)}</label>
                                    <span>
                                        {formatNumber(total, {
                                            style: "currency",
                                            currency,
                                        })}
                                    </span>
                                </td>
                                <td className={"delete-item"}></td>
                            </tr>
                        </tfoot>
                    </table>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="neutral" buttonType="button" onClick={() => handleCancel()}>
                        {formatMessage(messages.cancel)}
                    </Button>
                    <Button type="primary" buttonType="submit">
                        {formatMessage(messages.save)}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
