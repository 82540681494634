export enum CountryCode {
    AF = "AF",
    AL = "AL",
    DZ = "DZ",
    AS = "AS",
    AD = "AD",
    AO = "AO",
    AI = "AI",
    AQ = "AQ",
    AG = "AG",
    AR = "AR",
    AM = "AM",
    AW = "AW",
    AU = "AU",
    AT = "AT",
    AZ = "AZ",
    BS = "BS",
    BH = "BH",
    BD = "BD",
    BB = "BB",
    BY = "BY",
    BE = "BE",
    BZ = "BZ",
    BJ = "BJ",
    BM = "BM",
    BT = "BT",
    BO = "BO",
    BQ = "BQ",
    BA = "BA",
    BW = "BW",
    BV = "BV",
    BR = "BR",
    IO = "IO",
    BN = "BN",
    BG = "BG",
    BF = "BF",
    BI = "BI",
    CV = "CV",
    KH = "KH",
    CM = "CM",
    CA = "CA",
    KY = "KY",
    CF = "CF",
    TD = "TD",
    CL = "CL",
    CN = "CN",
    CX = "CX",
    CC = "CC",
    CO = "CO",
    KM = "KM",
    CD = "CD",
    CG = "CG",
    CK = "CK",
    CR = "CR",
    HR = "HR",
    CU = "CU",
    CW = "CW",
    CY = "CY",
    CZ = "CZ",
    CI = "CI",
    DK = "DK",
    DJ = "DJ",
    DM = "DM",
    DO = "DO",
    EC = "EC",
    EG = "EG",
    SV = "SV",
    GQ = "GQ",
    ER = "ER",
    EE = "EE",
    SZ = "SZ",
    ET = "ET",
    FK = "FK",
    FO = "FO",
    FJ = "FJ",
    FI = "FI",
    FR = "FR",
    GF = "GF",
    PF = "PF",
    TF = "TF",
    GA = "GA",
    GM = "GM",
    GE = "GE",
    DE = "DE",
    GH = "GH",
    GI = "GI",
    GR = "GR",
    GL = "GL",
    GD = "GD",
    GP = "GP",
    GU = "GU",
    GT = "GT",
    GG = "GG",
    GN = "GN",
    GW = "GW",
    GY = "GY",
    HT = "HT",
    HM = "HM",
    VA = "VA",
    HN = "HN",
    HK = "HK",
    HU = "HU",
    IS = "IS",
    IN = "IN",
    ID = "ID",
    IR = "IR",
    IQ = "IQ",
    IE = "IE",
    IM = "IM",
    IL = "IL",
    IT = "IT",
    JM = "JM",
    JP = "JP",
    JE = "JE",
    JO = "JO",
    KZ = "KZ",
    KE = "KE",
    KI = "KI",
    KP = "KP",
    KR = "KR",
    KW = "KW",
    KG = "KG",
    LA = "LA",
    LV = "LV",
    LB = "LB",
    LS = "LS",
    LR = "LR",
    LY = "LY",
    LI = "LI",
    LT = "LT",
    LU = "LU",
    MO = "MO",
    MG = "MG",
    MW = "MW",
    MY = "MY",
    MV = "MV",
    ML = "ML",
    MT = "MT",
    MH = "MH",
    MQ = "MQ",
    MR = "MR",
    MU = "MU",
    YT = "YT",
    MX = "MX",
    FM = "FM",
    MD = "MD",
    MC = "MC",
    MN = "MN",
    ME = "ME",
    MS = "MS",
    MA = "MA",
    MZ = "MZ",
    MM = "MM",
    NA = "NA",
    NR = "NR",
    NP = "NP",
    NL = "NL",
    NC = "NC",
    NZ = "NZ",
    NI = "NI",
    NE = "NE",
    NG = "NG",
    NU = "NU",
    NF = "NF",
    MP = "MP",
    NO = "NO",
    OM = "OM",
    PK = "PK",
    PW = "PW",
    PS = "PS",
    PA = "PA",
    PG = "PG",
    PY = "PY",
    PE = "PE",
    PH = "PH",
    PN = "PN",
    PL = "PL",
    PT = "PT",
    PR = "PR",
    QA = "QA",
    MK = "MK",
    RO = "RO",
    RU = "RU",
    RW = "RW",
    RE = "RE",
    BL = "BL",
    SH = "SH",
    KN = "KN",
    LC = "LC",
    MF = "MF",
    PM = "PM",
    VC = "VC",
    WS = "WS",
    SM = "SM",
    ST = "ST",
    SA = "SA",
    SN = "SN",
    RS = "RS",
    SC = "SC",
    SL = "SL",
    SG = "SG",
    SX = "SX",
    SK = "SK",
    SI = "SI",
    SB = "SB",
    SO = "SO",
    ZA = "ZA",
    GS = "GS",
    SS = "SS",
    ES = "ES",
    LK = "LK",
    SD = "SD",
    SR = "SR",
    SJ = "SJ",
    SE = "SE",
    CH = "CH",
    SY = "SY",
    TW = "TW",
    TJ = "TJ",
    TZ = "TZ",
    TH = "TH",
    TL = "TL",
    TG = "TG",
    TK = "TK",
    TO = "TO",
    TT = "TT",
    TN = "TN",
    TR = "TR",
    TM = "TM",
    TC = "TC",
    TV = "TV",
    UG = "UG",
    UA = "UA",
    AE = "AE",
    GB = "GB",
    UM = "UM",
    US = "US",
    UY = "UY",
    UZ = "UZ",
    VU = "VU",
    VE = "VE",
    VN = "VN",
    VG = "VG",
    VI = "VI",
    WF = "WF",
    EH = "EH",
    YE = "YE",
    ZM = "ZM",
    ZW = "ZW",
    AX = "AX",
    UNKNOWN = "UNKNOWN",
}

export const countryCodeIsNotUnknown = (countryCode: CountryCode) => countryCode !== CountryCode.UNKNOWN

export const AllCountries = Object.values(CountryCode).filter(countryCodeIsNotUnknown)

export const ValidCountryCodes = Object.values(CountryCode).reduce((acc, countryCode) => {
    acc[countryCode] = true
    return acc
}, {} as Partial<Record<CountryCode, boolean>>) as Record<CountryCode, boolean>

export const isCountryCode = (countryCodeStr: string): countryCodeStr is CountryCode =>
    ValidCountryCodes[countryCodeStr as CountryCode] === true

export const validateCountryCodeOrSetUnknown = (countryCodeStr: string): CountryCode =>
    ValidCountryCodes[countryCodeStr as CountryCode] ? (countryCodeStr as CountryCode) : CountryCode.UNKNOWN

export const Countries: Record<CountryCode, string> = {
    [CountryCode.AF]: "Afghanistan",
    [CountryCode.AL]: "Albania",
    [CountryCode.DZ]: "Algeria",
    [CountryCode.AS]: "American Samoa",
    [CountryCode.AD]: "Andorra",
    [CountryCode.AO]: "Angola",
    [CountryCode.AI]: "Anguilla",
    [CountryCode.AQ]: "Antarctica",
    [CountryCode.AG]: "Antigua and Barbuda",
    [CountryCode.AR]: "Argentina",
    [CountryCode.AM]: "Armenia",
    [CountryCode.AW]: "Aruba",
    [CountryCode.AU]: "Australia",
    [CountryCode.AT]: "Austria",
    [CountryCode.AZ]: "Azerbaijan",
    [CountryCode.BS]: "Bahamas",
    [CountryCode.BH]: "Bahrain",
    [CountryCode.BD]: "Bangladesh",
    [CountryCode.BB]: "Barbados",
    [CountryCode.BY]: "Belarus",
    [CountryCode.BE]: "Belgium",
    [CountryCode.BZ]: "Belize",
    [CountryCode.BJ]: "Benin",
    [CountryCode.BM]: "Bermuda",
    [CountryCode.BT]: "Bhutan",
    [CountryCode.BO]: "Bolivia",
    [CountryCode.BQ]: "Bonaire, Sint Eustatius and Saba",
    [CountryCode.BA]: "Bosnia and Herzegovina",
    [CountryCode.BW]: "Botswana",
    [CountryCode.BV]: "Bouvet Island",
    [CountryCode.BR]: "Brazil",
    [CountryCode.IO]: "British Indian Ocean Territory",
    [CountryCode.BN]: "Brunei Darussalam",
    [CountryCode.BG]: "Bulgaria",
    [CountryCode.BF]: "Burkina Faso",
    [CountryCode.BI]: "Burundi",
    [CountryCode.CV]: "Cabo Verde",
    [CountryCode.KH]: "Cambodia",
    [CountryCode.CM]: "Cameroon",
    [CountryCode.CA]: "Canada",
    [CountryCode.KY]: "Cayman Islands",
    [CountryCode.CF]: "Central African Republic",
    [CountryCode.TD]: "Chad",
    [CountryCode.CL]: "Chile",
    [CountryCode.CN]: "China",
    [CountryCode.CX]: "Christmas Island",
    [CountryCode.CC]: "Cocos (Keeling) Islands",
    [CountryCode.CO]: "Colombia",
    [CountryCode.KM]: "Comoros",
    [CountryCode.CD]: "Democratic Republic of Congo",
    [CountryCode.CG]: "Congo",
    [CountryCode.CK]: "Cook Islands",
    [CountryCode.CR]: "Costa Rica",
    [CountryCode.HR]: "Croatia",
    [CountryCode.CU]: "Cuba",
    [CountryCode.CW]: "Curaçao",
    [CountryCode.CY]: "Cyprus",
    [CountryCode.CZ]: "Czechia",
    [CountryCode.CI]: "Côte d'Ivoire",
    [CountryCode.DK]: "Denmark",
    [CountryCode.DJ]: "Djibouti",
    [CountryCode.DM]: "Dominica",
    [CountryCode.DO]: "Dominican Republic",
    [CountryCode.EC]: "Ecuador",
    [CountryCode.EG]: "Egypt",
    [CountryCode.SV]: "El Salvador",
    [CountryCode.GQ]: "Equatorial Guinea",
    [CountryCode.ER]: "Eritrea",
    [CountryCode.EE]: "Estonia",
    [CountryCode.SZ]: "Eswatini",
    [CountryCode.ET]: "Ethiopia",
    [CountryCode.FK]: "Falkland Islands",
    [CountryCode.FO]: "Faroe Islands",
    [CountryCode.FJ]: "Fiji",
    [CountryCode.FI]: "Finland",
    [CountryCode.FR]: "France",
    [CountryCode.GF]: "French Guiana",
    [CountryCode.PF]: "French Polynesia",
    [CountryCode.TF]: "French Southern Territories",
    [CountryCode.GA]: "Gabon",
    [CountryCode.GM]: "Gambia",
    [CountryCode.GE]: "Georgia",
    [CountryCode.DE]: "Germany",
    [CountryCode.GH]: "Ghana",
    [CountryCode.GI]: "Gibraltar",
    [CountryCode.GR]: "Greece",
    [CountryCode.GL]: "Greenland",
    [CountryCode.GD]: "Grenada",
    [CountryCode.GP]: "Guadeloupe",
    [CountryCode.GU]: "Guam",
    [CountryCode.GT]: "Guatemala",
    [CountryCode.GG]: "Guernsey",
    [CountryCode.GN]: "Guinea",
    [CountryCode.GW]: "Guinea-Bissau",
    [CountryCode.GY]: "Guyana",
    [CountryCode.HT]: "Haiti",
    [CountryCode.HM]: "Heard Island and McDonald Islands",
    [CountryCode.VA]: "Holy See",
    [CountryCode.HN]: "Honduras",
    [CountryCode.HK]: "Hong Kong",
    [CountryCode.HU]: "Hungary",
    [CountryCode.IS]: "Iceland",
    [CountryCode.IN]: "India",
    [CountryCode.ID]: "Indonesia",
    [CountryCode.IR]: "Iran",
    [CountryCode.IQ]: "Iraq",
    [CountryCode.IE]: "Ireland",
    [CountryCode.IM]: "Isle of Man",
    [CountryCode.IL]: "Israel",
    [CountryCode.IT]: "Italy",
    [CountryCode.JM]: "Jamaica",
    [CountryCode.JP]: "Japan",
    [CountryCode.JE]: "Jersey",
    [CountryCode.JO]: "Jordan",
    [CountryCode.KZ]: "Kazakhstan",
    [CountryCode.KE]: "Kenya",
    [CountryCode.KI]: "Kiribati",
    [CountryCode.KP]: "North Korea",
    [CountryCode.KR]: "South Korea",
    [CountryCode.KW]: "Kuwait",
    [CountryCode.KG]: "Kyrgyzstan",
    [CountryCode.LA]: "Lao People's Democratic Republic",
    [CountryCode.LV]: "Latvia",
    [CountryCode.LB]: "Lebanon",
    [CountryCode.LS]: "Lesotho",
    [CountryCode.LR]: "Liberia",
    [CountryCode.LY]: "Libya",
    [CountryCode.LI]: "Liechtenstein",
    [CountryCode.LT]: "Lithuania",
    [CountryCode.LU]: "Luxembourg",
    [CountryCode.MO]: "Macao",
    [CountryCode.MG]: "Madagascar",
    [CountryCode.MW]: "Malawi",
    [CountryCode.MY]: "Malaysia",
    [CountryCode.MV]: "Maldives",
    [CountryCode.ML]: "Mali",
    [CountryCode.MT]: "Malta",
    [CountryCode.MH]: "Marshall Islands",
    [CountryCode.MQ]: "Martinique",
    [CountryCode.MR]: "Mauritania",
    [CountryCode.MU]: "Mauritius",
    [CountryCode.YT]: "Mayotte",
    [CountryCode.MX]: "Mexico",
    [CountryCode.FM]: "Micronesia",
    [CountryCode.MD]: "Moldova",
    [CountryCode.MC]: "Monaco",
    [CountryCode.MN]: "Mongolia",
    [CountryCode.ME]: "Montenegro",
    [CountryCode.MS]: "Montserrat",
    [CountryCode.MA]: "Morocco",
    [CountryCode.MZ]: "Mozambique",
    [CountryCode.MM]: "Myanmar",
    [CountryCode.NA]: "Namibia",
    [CountryCode.NR]: "Nauru",
    [CountryCode.NP]: "Nepal",
    [CountryCode.NL]: "Netherlands",
    [CountryCode.NC]: "New Caledonia",
    [CountryCode.NZ]: "New Zealand",
    [CountryCode.NI]: "Nicaragua",
    [CountryCode.NE]: "Niger (the)",
    [CountryCode.NG]: "Nigeria",
    [CountryCode.NU]: "Niue",
    [CountryCode.NF]: "Norfolk Island",
    [CountryCode.MP]: "Northern Mariana Islands",
    [CountryCode.NO]: "Norway",
    [CountryCode.OM]: "Oman",
    [CountryCode.PK]: "Pakistan",
    [CountryCode.PW]: "Palau",
    [CountryCode.PS]: "Palestine",
    [CountryCode.PA]: "Panama",
    [CountryCode.PG]: "Papua New Guinea",
    [CountryCode.PY]: "Paraguay",
    [CountryCode.PE]: "Peru",
    [CountryCode.PH]: "Philippines",
    [CountryCode.PN]: "Pitcairn",
    [CountryCode.PL]: "Poland",
    [CountryCode.PT]: "Portugal",
    [CountryCode.PR]: "Puerto Rico",
    [CountryCode.QA]: "Qatar",
    [CountryCode.MK]: "Republic of North Macedonia",
    [CountryCode.RO]: "Romania",
    [CountryCode.RU]: "Russian Federation",
    [CountryCode.RW]: "Rwanda",
    [CountryCode.RE]: "Réunion",
    [CountryCode.BL]: "Saint Barthélemy",
    [CountryCode.SH]: "Saint Helena, Ascension and Tristan da Cunha",
    [CountryCode.KN]: "Saint Kitts and Nevis",
    [CountryCode.LC]: "Saint Lucia",
    [CountryCode.MF]: "Saint Martin",
    [CountryCode.PM]: "Saint Pierre and Miquelon",
    [CountryCode.VC]: "Saint Vincent and the Grenadines",
    [CountryCode.WS]: "Samoa",
    [CountryCode.SM]: "San Marino",
    [CountryCode.ST]: "Sao Tome and Principe",
    [CountryCode.SA]: "Saudi Arabia",
    [CountryCode.SN]: "Senegal",
    [CountryCode.RS]: "Serbia",
    [CountryCode.SC]: "Seychelles",
    [CountryCode.SL]: "Sierra Leone",
    [CountryCode.SG]: "Singapore",
    [CountryCode.SX]: "Sint Maarten",
    [CountryCode.SK]: "Slovakia",
    [CountryCode.SI]: "Slovenia",
    [CountryCode.SB]: "Solomon Islands",
    [CountryCode.SO]: "Somalia",
    [CountryCode.ZA]: "South Africa",
    [CountryCode.GS]: "South Georgia and the South Sandwich Islands",
    [CountryCode.SS]: "South Sudan",
    [CountryCode.ES]: "Spain",
    [CountryCode.LK]: "Sri Lanka",
    [CountryCode.SD]: "Sudan",
    [CountryCode.SR]: "Suriname",
    [CountryCode.SJ]: "Svalbard and Jan Mayen",
    [CountryCode.SE]: "Sweden",
    [CountryCode.CH]: "Switzerland",
    [CountryCode.SY]: "Syrian Arab Republic",
    [CountryCode.TW]: "Taiwan",
    [CountryCode.TJ]: "Tajikistan",
    [CountryCode.TZ]: "Tanzania",
    [CountryCode.TH]: "Thailand",
    [CountryCode.TL]: "Timor-Leste",
    [CountryCode.TG]: "Togo",
    [CountryCode.TK]: "Tokelau",
    [CountryCode.TO]: "Tonga",
    [CountryCode.TT]: "Trinidad and Tobago",
    [CountryCode.TN]: "Tunisia",
    [CountryCode.TR]: "Turkey",
    [CountryCode.TM]: "Turkmenistan",
    [CountryCode.TC]: "Turks and Caicos Islands",
    [CountryCode.TV]: "Tuvalu",
    [CountryCode.UG]: "Uganda",
    [CountryCode.UA]: "Ukraine",
    [CountryCode.AE]: "United Arab Emirates",
    [CountryCode.GB]: "United Kingdom of Great Britain and Northern Ireland",
    [CountryCode.UM]: "United States Minor Outlying Islands",
    [CountryCode.US]: "United States of America",
    [CountryCode.UY]: "Uruguay",
    [CountryCode.UZ]: "Uzbekistan",
    [CountryCode.VU]: "Vanuatu",
    [CountryCode.VE]: "Venezuela",
    [CountryCode.VN]: "Viet Nam",
    [CountryCode.VG]: "Virgin Islands (British)",
    [CountryCode.VI]: "Virgin Islands (U.S.)",
    [CountryCode.WF]: "Wallis and Futuna",
    [CountryCode.EH]: "Western Sahara",
    [CountryCode.YE]: "Yemen",
    [CountryCode.ZM]: "Zambia",
    [CountryCode.ZW]: "Zimbabwe",
    [CountryCode.AX]: "Åland Islands",
    [CountryCode.UNKNOWN]: "Unknown",
}

export const getCountryName = (countryCode: CountryCode): string => Countries[countryCode] ?? "Unknown"
