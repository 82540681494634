import React, { useCallback, useEffect } from "react"
import { OrganizationId } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"
import { useRoomForObjectId } from "../../hooks"
import { Loader } from "~/components"
import { ErrorMessage } from "~/components/ErrorMessage"
import { useFetchErrorMessage } from "~/domains/common/apiClient/errors"
import { ChatRoom } from "../ChatRoom"
import { Tab, Tabs, styled } from "@mui/material"
import { RoomI, RoomId } from "../../types"
import { defineMessages, useIntl } from "react-intl"

const messages = defineMessages({
    title: {
        id: "communication.communicationRoom.title",
        defaultMessage: "Communication Room",
    },
    private: {
        id: "communication.communicationRoom.private",
        defaultMessage: "Private",
    },
    shared: {
        id: "communication.communicationRoom.shared",
        defaultMessage: "Shared",
    },
})

interface Props extends JSX.IntrinsicAttributes {
    organizationId: OrganizationId
    objectId: string
    objectType: SharedObjectType
    otherOrganizations: OrganizationId[]
}

const CommunicationContainer = styled("div")({
    maxHeight: "calc(100vh - 160px)",
    overflow: "visible",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "flex-start",
})

const getRoomId = (room: RoomI) => room.id

const CommunicationRoomView = ({ organizationId, objectId, objectType, otherOrganizations }: Props) => {
    const { formatMessage } = useIntl()
    const [currentTab, setCurrentTab] = React.useState<RoomId | null>(null)

    const onTabChange = useCallback((_event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue)
    }, [])
    const { rooms, loading, error } = useRoomForObjectId(organizationId, {
        objectId,
        objectType,
        organizations: otherOrganizations,
    })
    const errorMessage = useFetchErrorMessage(error)

    useEffect(() => {
        setCurrentTab((tab) => {
            if (rooms && rooms.length > 0 && (!tab || !rooms.map(getRoomId).includes(tab))) {
                return rooms[0].id
            }
            return tab
        })
    }, [rooms])

    const currentRoom = rooms?.find((room) => room.id === currentTab)

    return (
        <CommunicationContainer className="tabs">
            <h4>{formatMessage(messages.title)}</h4>
            {loading && <Loader small />}
            <ErrorMessage>{errorMessage}</ErrorMessage>
            {rooms && currentTab && (
                <Tabs
                    value={currentTab}
                    onChange={onTabChange}
                    className="tabs-box"
                    aria-label="communication rooms"
                    scrollButtons
                    variant="scrollable"
                    allowScrollButtonsMobile
                    style={{ minHeight: "50px" }}
                >
                    {rooms.map((room) => (
                        <Tab
                            key={room.id}
                            label={room.isShared ? formatMessage(messages.shared) : formatMessage(messages.private)}
                            value={room.id}
                        />
                    ))}
                </Tabs>
            )}
            {rooms?.map((room) => (
                <ChatRoom
                    key={room.id}
                    organizationId={organizationId}
                    room={room}
                    visible={currentRoom?.id === room.id}
                />
            ))}
        </CommunicationContainer>
    )
}

export const CommunicationRoom = ({ organizationId, objectId, objectType, otherOrganizations }: Props) => {
    return (
        <CommunicationRoomView
            organizationId={organizationId}
            objectId={objectId}
            objectType={objectType}
            otherOrganizations={otherOrganizations}
        />
    )
}
