import { WorkflowFilterNumberOperation, WorkflowFilterOperation } from "~/features/workflow/types"
import { defineMessages, MessageDescriptor } from "react-intl"

const numberOperationsAdjectives: Partial<Record<WorkflowFilterNumberOperation, MessageDescriptor>> = defineMessages({
    [WorkflowFilterNumberOperation.GREATER_THAN_OR_EQUAL_TO]: {
        id: "workflow.operations.adjectives.minimum",
        defaultMessage: "minimum",
    },
    [WorkflowFilterNumberOperation.GREATER_THAN]: {
        id: "workflow.operations.adjectives.minimum",
        defaultMessage: "minimum",
    },
    [WorkflowFilterNumberOperation.LESS_THAN_OR_EQUAL_TO]: {
        id: "workflow.operations.adjectives.maximum",
        defaultMessage: "maximum",
    },
    [WorkflowFilterNumberOperation.LESS_THAN]: {
        id: "workflow.operations.adjectives.maximum",
        defaultMessage: "maximum",
    },
})

const operationsAdjectives: Partial<Record<WorkflowFilterOperation, MessageDescriptor>> = {
    ...numberOperationsAdjectives,
}
export const getOperationAdjective = (operation: WorkflowFilterOperation): MessageDescriptor | undefined =>
    operationsAdjectives[operation]
