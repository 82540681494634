import { useContext } from "react"
import { SocketIOContext } from "./SocketIOContextProvider"

export const useSocketIOContext = () => {
    const { ioSocket, isConnected } = useContext(SocketIOContext)
    if (!ioSocket) {
        throw new Error(`Cannot use useSocketIOContext outside SocketIOContext: ioSocket is null`)
    }
    return { ioSocket, isConnected }
}
