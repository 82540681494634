import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"
import { DocumentI, OrganizationId } from "~/types"
import { CreateTagObjectI, TagI, TagObjectI, TagObjectType } from "~/domains/tags/types"
import { useTagsApi } from "~/domains/tags/tagsApi"
import { isDefined } from "~/utils/isDefined"
import { PurchaseOrders } from "../types/PurchaseOrders"
import { selectUser } from "~/store/account/accountSlice"
import { useState } from "react"
import { purchaseOrdersActions } from "../store/purchaseOrdersSlice"
import { commonMessages } from "~/common-messages"

const messages = defineMessages({
    nothingToAdd: {
        id: "purchase.orders.addTags.nothingToAdd",
        defaultMessage: "No tag to add",
    },
    addTagsSuccess: {
        id: "purchase.orders.addTags.success",
        defaultMessage: "{countTags} tag{sCount} added in {countSelected} purchase order{sSelected}",
    },
})

export const useAddTagsToPurchaseOrders = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const tagsApi = useTagsApi()
    const user = useAppSelector(selectUser)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    const addTagsToPurchaseOrders = async (
        organizationId: OrganizationId,
        objectIds: string[],
        objectType: TagObjectType,
        objects: (DocumentI | PurchaseOrders)[],
        tagsToAdd: TagI[],
        tagsToRemove: TagI[]
    ): Promise<void> => {
        if (!user || !objects || !objects.length) {
            return
        }
        setLoading(true)
        try {
            await Promise.allSettled(
                tagsToRemove.flatMap((tagToRemove) =>
                    objectIds.map((objectId) => tagsApi.deleteTagObject(organizationId, objectId, tagToRemove.tagId))
                )
            )
            const createBulkObjectTags = tagsToAdd.flatMap((tag) =>
                objectIds.map(
                    (objectId): CreateTagObjectI => ({
                        objectId,
                        tagId: tag.tagId,
                        objectType,
                    })
                )
            )
            await tagsApi.createObjectTags(organizationId, createBulkObjectTags)

            const objectTagsToRemove = objectIds.flatMap((objectId) =>
                tagsToRemove.map(
                    (tagToRemove): TagObjectI => ({
                        organizationId,
                        objectId,
                        tagId: tagToRemove.tagId,
                    })
                )
            )

            const count = tagsToAdd.length + tagsToRemove.length
            if (!count) {
                toast.error(formatMessage(messages.nothingToAdd))
                return
            }

            dispatch(
                purchaseOrdersActions.addTagsToPurchaseOrders({
                    selected: objectIds,
                    tagsToAdd: createBulkObjectTags
                        .map((tagObject): TagObjectI | null => {
                            const tag = tagsToAdd.find((tag) => tag.tagId === tagObject.tagId)
                            if (tag) {
                                return {
                                    objectId: tagObject.objectId,
                                    tagId: tagObject.tagId,
                                    tagGroupName: tag.tagGroupId,
                                    tagName: tag.name,
                                    organizationId,
                                }
                            }
                            return null
                        })
                        .filter(isDefined),
                    tagsToRemove: objectTagsToRemove,
                })
            )

            const countSelected = objectIds.length
            const countTags = tagsToAdd.length
            toast.success(
                formatMessage(messages.addTagsSuccess, {
                    countTags,
                    sCount: countTags > 1 ? "s" : "",
                    countSelected,
                    sSelected: countSelected > 1 ? "s" : "",
                })
            )
        } catch (err) {
            console.error(err)
            setError(JSON.stringify(err))
            toast.error(formatMessage(commonMessages.errorContact))
        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        error,
        addTagsToPurchaseOrders,
    }
}
