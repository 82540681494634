import { all, call, put, takeLatest } from "redux-saga/effects"
import { InvoiceUserType, OcrResultI } from "~/types"
import { ocrActions } from "./ocrSlice"
import { ocrApi } from "~/api"
import { PayloadAction } from "@reduxjs/toolkit"

function* uploadOCRDocument(action: PayloadAction<{ file: File; userType: InvoiceUserType }>) {
    try {
        const response: OcrResultI = yield call(ocrApi.upload, action.payload.file)
        yield put(ocrActions.uploadDocumentSuccess(response))
    } catch (error) {
        yield put(ocrActions.uploadDocumentFailed(`${error}`))
    }
}

function* getOcrResult(action: PayloadAction<string>) {
    try {
        const response: OcrResultI = yield call(ocrApi.getOcrResult, action.payload)
        yield put(ocrActions.ocrResultSuccess(response))
    } catch (error) {
        yield put(ocrActions.ocrResultFailed(`${error}`))
    }
}

export default function* ocrSaga() {
    yield all([
        takeLatest(ocrActions.uploadDocument.type, uploadOCRDocument),
        takeLatest(ocrActions.getOcrResult.type, getOcrResult),
    ])
}
