import React, { ChangeEvent, useCallback, useEffect, useState } from "react"
import { CreatePOLineDTO } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { defineMessages, useIntl } from "react-intl"
import { TextField } from "@mui/material"
import { Trash } from "react-feather"
import { ProductVersionAutocomplete } from "~/domains/identity/features/catalog/components/ProductVersionAutocomplete"
import { OrganizationId, CurrencyCodes } from "~/types"
import { ProductVersionI } from "~/domains/identity/features/catalog/types"
import { isDefined } from "~/utils/isDefined"

const messages = defineMessages({
    description: {
        id: "purchase.requests.request.modalAddItems.line.description",
        defaultMessage: "Description",
    },
    quantity: {
        id: "purchase.requests.request.modalAddItems.line.quantity",
        defaultMessage: "Qty",
    },
    taxRate: {
        id: "purchase.requests.request.modalAddItems.line.taxRate",
        defaultMessage: "Tax",
    },
    priceHT: {
        id: "purchase.requests.request.modalAddItems.line.priceHT",
        defaultMessage: "Unit price (tax excl)",
    },
    totalHT: {
        id: "purchase.requests.request.modalAddItems.line.totalHT",
        defaultMessage: "Total (tax excl)",
    },
    totalTTC: {
        id: "purchase.requests.request.modalAddItems.line.totalTTC",
        defaultMessage: "Total (tax incl)",
    },
})

interface Props {
    organizationId: OrganizationId
    item: CreatePOLineDTO
    index: number
    onChangeItem: (payload: Partial<CreatePOLineDTO>, index: number, id?: string | null) => void
    onDeleteItem: (e: any) => void
    currency: CurrencyCodes
    disabled?: boolean
}

const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select()
}

export const LineItem = ({ organizationId, item, index, currency, onChangeItem, onDeleteItem, disabled }: Props) => {
    const { formatNumber, formatMessage } = useIntl()

    const onAutocompleteChange = useCallback(
        (productVersion: ProductVersionI | null) => {
            if (!productVersion) return
            const payload: Partial<CreatePOLineDTO> = { description: productVersion.name }
            if (productVersion.unitPriceWithoutTax) {
                payload.unitPriceExcludingTax = productVersion.unitPriceWithoutTax
            }
            if (isDefined(productVersion.taxRate)) {
                payload.taxRate = productVersion.taxRate / 1000
            }
            if (productVersion.id) {
                payload.itemId = productVersion.id
            }
            onChangeItem(payload, index, item.id)
        },
        [onChangeItem, index]
    )

    const setDescription = useCallback(
        (value: string) => onChangeItem({ description: value }, index, item.id),
        [onChangeItem, index]
    )
    const [quantityString, setQuantityString] = useState<string>(item.quantity.toString())
    useEffect(() => {
        setQuantityString(item.quantity.toString())
    }, [item.quantity])
    const setQuantity = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setQuantityString(event.currentTarget.value)
            const value = parseFloat(event.currentTarget.value)
            if (!isNaN(value)) {
                onChangeItem({ quantity: value }, index, item.id)
            }
        },
        [onChangeItem, index]
    )

    const [taxRateString, setTaxRateString] = useState<string>(item.taxRate.toString())
    useEffect(() => {
        setTaxRateString(item.taxRate.toString())
    }, [item.taxRate])
    const setTaxRate = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setTaxRateString(event.currentTarget.value)
            const value = parseFloat(event.currentTarget.value)
            if (!isNaN(value)) {
                onChangeItem({ taxRate: value }, index, item.id)
            }
        },
        [onChangeItem, index]
    )

    const [unitPriceExcludingTaxString, setUnitPriceExcludingTaxString] = useState<string>(
        item.unitPriceExcludingTax.toString()
    )
    useEffect(() => {
        setUnitPriceExcludingTaxString(item.unitPriceExcludingTax.toString())
    }, [item.unitPriceExcludingTax])
    const setUnitPriceExcludingTax = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setUnitPriceExcludingTaxString(event.currentTarget.value)
            const value = parseFloat(event.currentTarget.value)
            if (!isNaN(value)) {
                onChangeItem({ unitPriceExcludingTax: value }, index, item.id)
            }
        },
        [onChangeItem, index]
    )

    return (
        <tr>
            <td className={"item-description"}>
                <ProductVersionAutocomplete
                    lineId={item.id}
                    initialValue={item.description}
                    setInputValue={setDescription}
                    organizationId={organizationId}
                    context={`line-item-${index}-${item.id}`}
                    label={formatMessage(messages.description)}
                    disabled={disabled}
                    onChange={onAutocompleteChange}
                    size="small"
                />
            </td>
            <td className={"item-quantity"}>
                <TextField
                    type="number"
                    label={formatMessage(messages.quantity)}
                    onChange={setQuantity}
                    onFocus={handleFocus}
                    value={quantityString}
                    inputProps={{ step: 1 }}
                    required={true}
                    size="small"
                    disabled={disabled}
                />
            </td>
            <td className={"item-tax"}>
                <TextField
                    type={"number"}
                    label={formatMessage(messages.taxRate) + " (%)"}
                    onChange={setTaxRate}
                    onFocus={handleFocus}
                    value={taxRateString}
                    inputProps={{ min: 0, step: 0.01 }}
                    required={true}
                    size="small"
                    disabled={disabled}
                />
            </td>
            <td className={"item-unit-price"}>
                <TextField
                    type={"number"}
                    label={formatMessage(messages.priceHT)}
                    onChange={setUnitPriceExcludingTax}
                    onFocus={handleFocus}
                    value={unitPriceExcludingTaxString}
                    required={true}
                    inputProps={{ step: 0.01 }}
                    size="small"
                    disabled={disabled}
                />
            </td>
            <td className={"item-total-price"}>
                <TextField
                    type={"text"}
                    label={formatMessage(messages.totalHT)}
                    value={formatNumber(item["totalAmountExcludingTax"], {
                        style: "currency",
                        currency,
                    })}
                    inputProps={{
                        readOnly: true,
                    }}
                    size="small"
                    disabled={disabled}
                />
            </td>
            <td className={"item-total-price"}>
                <TextField
                    type={"text"}
                    label={formatMessage(messages.totalTTC)}
                    value={formatNumber(item["totalAmount"], {
                        style: "currency",
                        currency,
                    })}
                    inputProps={{
                        readOnly: true,
                    }}
                    size="small"
                    disabled={disabled}
                />
            </td>
            <td className={"delete-item"}>{disabled ? null : <Trash onClick={onDeleteItem} size={20} />}</td>
        </tr>
    )
}
