import * as t from "io-ts"
import { optional } from "~/types/utils"

export const DunsOperatingStatusIO = t.partial({
    description: optional(t.string),
    dnbCode: optional(t.number),
    startDate: optional(t.string),
})

export const DunsControlStatusIO = t.partial({
    operatingStatus: optional(DunsOperatingStatusIO),
})

export type DunsOperatingStatusI = t.TypeOf<typeof DunsOperatingStatusIO>
export type DunsControlStatusI = t.TypeOf<typeof DunsControlStatusIO>
