import React, { useCallback } from "react"
import { defineMessages, FormattedMessage } from "react-intl"
import { Button } from "~/components"

import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { authActions } from "~/store/auth/authSlice"
import { globalActions, selectDisplayMenu } from "~/store/global/globalSlice"
import { Menu as HamburgerMenu, X as CloseMenu } from "react-feather"

const messages = defineMessages({
    buttonLogin: {
        id: "header.buttonLogin",
        defaultMessage: "Sign in/up",
    },
})

interface Props {
    noMarginUser?: boolean
    isConnected: boolean
}

export default function UserActions({ noMarginUser, isConnected }: Props) {
    const dispatch = useAppDispatch()
    const displayMenu = useAppSelector(selectDisplayMenu)

    const handleDisplayHamburgerMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        dispatch(globalActions.setDisplayMenu())
    }
    const handleLogin = useCallback(() => {
        dispatch(authActions.openPopupSignIn("signup"))
    }, [dispatch])

    return (
        <div id="user-actions" className={"show " + (noMarginUser ? "no-margin" : "")}>
            {isConnected ? null : (
                <Button className="button-login" type="primary" onClick={handleLogin}>
                    <FormattedMessage {...messages.buttonLogin} />
                </Button>
            )}
            <a
                href="~/components/Header/UserActions#"
                title=""
                className="hamburger-menu"
                onClick={handleDisplayHamburgerMenu}
            >
                {!displayMenu ? <HamburgerMenu size={30} /> : <CloseMenu size={30} />}
            </a>
        </div>
    )
}
