import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useCallback } from "react"
import { SelectedTagI } from "~/domains/tags/types"
import { store } from "~/store"
import { selectPendingLineTags, tagsActions } from "~/domains/tags/store/tagsSlice"

type TagsforLine = [
    SelectedTagI[],
    (tagsOrFunction: SelectedTagI[] | ((state: SelectedTagI[]) => SelectedTagI[])) => void
]

export const useTagsForLine = (lineId: string): TagsforLine => {
    const dispatch = useAppDispatch()
    const pendingTagsForLines = useAppSelector(selectPendingLineTags)
    const pendingTags = pendingTagsForLines[lineId] ?? []
    const setPendingTags = useCallback(
        (tagsOrFunction: SelectedTagI[] | ((state: SelectedTagI[]) => SelectedTagI[])) => {
            const tags = Array.isArray(tagsOrFunction)
                ? tagsOrFunction
                : tagsOrFunction(selectPendingLineTags(store.getState())[lineId] ?? [])
            dispatch(tagsActions.setPendingLineTags({ lineId, tags }))
        },
        [dispatch]
    )
    return [pendingTags, setPendingTags]
}
