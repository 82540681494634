/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { all, put, select, takeLatest } from "redux-saga/effects"
import { errorsActions, selectErrorAuth } from "./errorsSlice"
import { authActions } from "~/store/auth/authSlice"
import { selectDisplayModalReconnect } from "../auth/authSlice"

function* setErrorAuth() {
    const displayModalReconnect = yield select(selectDisplayModalReconnect)
    const errorAuth = yield select(selectErrorAuth)
    if (!displayModalReconnect && errorAuth) {
        yield put(authActions.displayModalReconnect(true))
    }
}

export default function* errorsSaga() {
    yield all([takeLatest(errorsActions.setErrorAuth.type, setErrorAuth)])
}
