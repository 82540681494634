import React from "react"
import { CustomFieldI, CustomFieldType } from "../../types/CustomField"
import { CustomFieldObjectType } from "../../types/CustomFieldObjectType"
import { OrganizationId } from "~/types"
import { ESGCustomFieldNumberCell } from "./ESGCustomFieldNumberCell"
import { ESGCustomFieldStringCell } from "./ESGCustomFieldStringCell"

interface Props {
    objectId: string
    isTemporaryId: boolean
    quantity: number
    customField: CustomFieldI
    contextType: CustomFieldObjectType
    contextId?: string
    organizationId?: OrganizationId
}

export const ESGCustomFieldCell: React.FC<Props> = (props) => {
    if (props.customField.type === CustomFieldType.NUMBER) {
        return <ESGCustomFieldNumberCell {...props} />
    }
    return <ESGCustomFieldStringCell {...props} />
}
