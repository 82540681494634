import { all } from "redux-saga/effects"
import accountSaga from "~/store/account/accountSaga"
import authSaga from "~/store/auth/authSaga"
import errorsSaga from "~/store/errors/errorsSaga"
import invoiceSaga from "~/store/invoice/invoiceSaga"
import ocrSaga from "~/store/ocr/ocrSaga"
import organizationSaga from "~/store/organization/organizationSaga"
import paymentSaga from "~/store/payment/paymentSaga"
import usersSaga from "./users/usersSaga"

export default function* rootSaga() {
    yield all([
        accountSaga(),
        authSaga(),
        errorsSaga(),
        invoiceSaga(),
        ocrSaga(),
        organizationSaga(),
        paymentSaga(),
        usersSaga(),
    ])
}
