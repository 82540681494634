import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "~/store"
import { LoginPageType } from "~/utils"

export interface AuthState {
    isLoading: boolean
    isConnected: boolean | null
    openPopupSignIn: boolean
    displayModalReconnect: boolean
}

const initialState: AuthState = {
    isLoading: false,
    isConnected: null,
    openPopupSignIn: false,
    displayModalReconnect: false,
}

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setIsConnected: (state, action: PayloadAction<boolean>) => {
            state.isConnected = action.payload
            state.displayModalReconnect = false
            state.isLoading = false
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayModalReconnect: (state, _action: PayloadAction<boolean>) => {
            state.displayModalReconnect = true
        },
        isAuthenticated: (state) => {
            state.isLoading = true
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        openPopupSignIn: (state, _action: PayloadAction<LoginPageType | undefined>) => {
            state.isLoading = true
            state.openPopupSignIn = true
        },
        setClosePopup: (state) => {
            state.isLoading = false
            state.openPopupSignIn = false
        },
        logout: (state) => {
            state.isConnected = false
            state.displayModalReconnect = false
        },
    },
})

// Actions
export const authActions = authSlice.actions

// // Selectors
export const selectAuthLoading = (state: RootState) => state.auth.isLoading
export const selectIsConnected = (state: RootState) => !!state.auth.isConnected
export const selectOpenPopupSignIn = (state: RootState) => state.auth.openPopupSignIn
export const selectDisplayModalReconnect = (state: RootState) => state.auth.displayModalReconnect
export const getIsConnected = (state: RootState) => state.auth.isConnected

// // Reducer
const authReducer = authSlice.reducer
export default authReducer
