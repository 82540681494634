import React from "react"
import { Box } from "@mui/material"
import { generatePath, Link } from "react-router-dom"
import { BUDGET_ROUTE } from "~/features/budget/routes"
import { BudgetDataWithMetricsI } from "~/features/budget/types"
import { Edit3 } from "react-feather"

import "./BudgetLink.scss"

interface Props {
    budgets: BudgetDataWithMetricsI[]
    label: string
    handleEdit: () => void
}

export function BudgetLink({ budgets, label, handleEdit }: Props) {
    return (
        <Box component="div" className="purchase-order-link budget-link">
            <label>{label} :</label>
            <div>
                <div>
                    {budgets.map((budget, index) => (
                        <Link
                            key={index}
                            to={generatePath(BUDGET_ROUTE, { budgetId: budget.budget.id })}
                            target="_blank"
                        >
                            {budget.budget.name}
                            {index !== budgets.length - 1 ? ", " : ""}
                        </Link>
                    ))}
                </div>
                <Edit3 size={16} onClick={handleEdit} />
            </div>
        </Box>
    )
}
