import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { isDefined } from "~/utils/isDefined"
import { purchaseRequestApi } from "../../api"
import { purchaseRequestsActions } from "../purchaseRequestsSlice"
import { getPurchaseRequestId } from "./useDeletePurchaseRequests"

const messages = defineMessages({
    purchaseRequestRejected: {
        id: "purchase.requests.purchaseRequestRejected",
        defaultMessage: "Your purchase request has been successfully rejected.",
    },
    purchaseRequestsRejected: {
        id: "purchase.requests.purchaseRequestsRejected",
        defaultMessage: "Your purchase requests has been successfully rejected.",
    },
    unableToRejectPurchaseRequest: {
        id: "purchase.requests.unableToRejectPurchaseRequest",
        defaultMessage: "An error occured: your purchase request could not be rejected.",
    },
    unableToRejectPurchaseRequests: {
        id: "purchase.requests.unableToRejectPurchaseRequests",
        defaultMessage: "An error occured: your purchase requests could not be rejected.",
    },
    unableToRejectSomePurchaseRequests: {
        id: "purchase.requests.unableToRejectSomePurchaseRequests",
        defaultMessage: "An error occured: {count} purchase request{s} could not be rejected.",
    },
})

export const useRejectPurchaseRequests = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    return async (purchaseRequestIds: string[], organizationId: OrganizationId) => {
        const results = await Promise.allSettled(
            purchaseRequestIds.map((purchaseRequestId) => purchaseRequestApi.reject(organizationId, purchaseRequestId))
        )
        const successRejected = results
            .map((r, index) => getPurchaseRequestId(r, index, purchaseRequestIds))
            .filter(isDefined)
        const failRejectedLength = results.length - successRejected.length
        dispatch(purchaseRequestsActions.rejectPurchaseRequests(successRejected))
        if (failRejectedLength > 0) {
            toast.error(
                formatMessage(
                    failRejectedLength === results.length
                        ? messages.unableToRejectPurchaseRequests
                        : messages.unableToRejectSomePurchaseRequests,
                    { count: failRejectedLength, s: failRejectedLength > 1 ? "s" : "" }
                )
            )
        } else {
            toast.success(
                formatMessage(
                    purchaseRequestIds.length === 1
                        ? messages.purchaseRequestRejected
                        : messages.purchaseRequestsRejected
                )
            )
        }
        return true
    }
}
