import * as t from "io-ts"
import { TeamId, UserId, AuthorizationName, AuthorizationNameExtra } from "~/types"
import { optional } from "~/types/utils"

export enum AuthorizationFirstLetter {
    C = AuthorizationName.CREATE,
    R = AuthorizationName.READ,
    U = AuthorizationName.UPDATE,
    D = AuthorizationName.DELETE,
    A = AuthorizationNameExtra.APPROVE,
    V = AuthorizationNameExtra.CONVERT,
    K = AuthorizationNameExtra.CHECK,
    S = AuthorizationNameExtra.SUBMIT,
}

export enum DomainName {
    IDENTITY = "identity",
    TRANSACTION = "transaction",
    ORCHESTRATION = "orchestration",
    COMMUNICATION = "communication",
    BANKING = "banking",
    ANALYTICS = "analytics",
}

export const DomainShorthand: Record<string, DomainName> = {
    id: DomainName.IDENTITY,
    tx: DomainName.TRANSACTION,
    or: DomainName.ORCHESTRATION,
    co: DomainName.COMMUNICATION,
    bk: DomainName.BANKING,
    an: DomainName.ANALYTICS,
}

export enum ScopeName {
    ORGANIZATIONS = "organizations",
    USERS = "users",
    TEAMS = "teams",
    TAGS = "tags",
    ROLES_PERMISSIONS = "roles-permissions",
    BOOK_OF_RELATIONS = "book-of-relations",
    PURCHASE_REQUESTS = "purchase-requests",
    PURCHASE_ORDERS = "purchase-orders",
    BUDGETS = "budgets",
    INVOICES = "invoices",
    WORKFLOWS = "workflows",
    PAYMENTS = "payments",
}

/* For now TAGS, BUDGETS and TEAMS have no shorthand scope name */
export const ScopeShorthand: Record<string, ScopeName> = {
    org: ScopeName.ORGANIZATIONS,
    usr: ScopeName.USERS,
    wf: ScopeName.WORKFLOWS,
    po: ScopeName.PURCHASE_ORDERS,
    pr: ScopeName.PURCHASE_REQUESTS,
    inv: ScopeName.INVOICES,
    bor: ScopeName.BOOK_OF_RELATIONS,
    rp: ScopeName.ROLES_PERMISSIONS,
    pay: ScopeName.PAYMENTS,
}

export const ScopeExtraAuthorization: Partial<Record<ScopeName, AuthorizationNameExtra[]>> = {
    [ScopeName.PURCHASE_REQUESTS]: [AuthorizationNameExtra.APPROVE, AuthorizationNameExtra.CONVERT],
    [ScopeName.INVOICES]: [AuthorizationNameExtra.APPROVE],
}

const CreatePermissionIO = t.type({
    id: t.string,
    organizationId: t.string,
    displayName: t.string,
    isPredefined: t.boolean,
})

const PermissionIO = t.intersection([
    CreatePermissionIO,
    t.type({
        creator: t.string,
    }),
])

const PermissionIdIO = t.string

export type PermissionI = t.TypeOf<typeof PermissionIO>
export type PermissionId = t.TypeOf<typeof PermissionIdIO>
export type CreatePermissionI = t.TypeOf<typeof CreatePermissionIO>

const CreateRoleIO = t.type({
    name: t.string,
    description: t.string,
    isPredefined: t.boolean,
    permissions: optional(t.array(PermissionIdIO)),
})

const UpdateRoleIO = t.partial({
    name: t.string,
    description: optional(t.string),
    permissions: optional(t.array(PermissionIdIO)),
})

const RoleIO = t.intersection([
    CreateRoleIO,
    t.type({
        organizationId: t.string,
        id: t.string,
        creator: t.string,
        permissions: t.array(PermissionIdIO),
        createdAt: t.string,
    }),
])

export type RoleI = t.TypeOf<typeof RoleIO>
export type CreateRoleI = t.TypeOf<typeof CreateRoleIO>
export type UpdateRoleI = t.TypeOf<typeof UpdateRoleIO>
export type RoleWithEntityIds = t.TypeOf<typeof RoleIO> & {
    userIds?: UserId[]
    teamIds?: TeamId[]
}

const OrganizationPermissionIO = t.type({
    organizationId: t.string,
    permissions: t.array(PermissionIdIO),
})
export type OrganizationPermission = t.TypeOf<typeof OrganizationPermissionIO>

export enum permissionErrorType {
    "PERMISSION_ID_ALREADY_USED" = "PERMISSION_ID_ALREADY_USED",
    "PERMISSION_ENTITY_NOT_VALID" = "PERMISSION_ENTITY_NOT_VALID",
    "ORGANIZATION_ID_NOT_VALID" = "ORGANIZATION_ID_NOT_VALID",
    "PERMISSION_ID_NOT_WELL_FORMATED" = "PERMISSION_ID_NOT_WELL_FORMATED",
    "AUTHORIZATION_NAME_UNKNOWN" = "AUTHORIZATION_NAME_UNKNOWN",
    "DOMAIN_NAME_UNKNOWN" = "DOMAIN_NAME_UNKNOWN",
    "CANT_UPDATE_PREDEFINED_ROLE" = "CANT_UPDATE_PREDEFINED_ROLE",
    "CANT_DELETE_PREDEFINED_ROLE" = "CANT_DELETE_PREDEFINED_ROLE",
    "UPDATE_ROLE_MAX_RETRY" = "UPDATE_ROLE_MAX_RETRY",
    "CREATE_ROLE_MAX_RETRY" = "CREATE_ROLE_MAX_RETRY",
    "ROLE_ENTITY_NOT_VALID" = "ROLE_ENTITY_NOT_VALID",
    "USER_ROLE_ASSOCIATION_NOT_FOUND" = "USER_ROLE_ASSOCIATION_NOT_FOUND",
    "TEAM_ROLE_ASSOCIATION_NOT_FOUND" = "TEAM_ROLE_ASSOCIATION_NOT_FOUND",
    "ROLE_NOT_FOUND" = "ROLE_NOT_FOUND",
    "ADD_ROLE_PERMISSION_MAX_RETRY" = "ADD_ROLE_PERMISSION_MAX_RETRY",
    "PERMISSION_DATA_PROVIDED_NOT_VALID" = "PERMISSION_DATA_PROVIDED_NOT_VALID",
    "ROLE_DATA_PROVIDED_NOT_VALID" = "ROLE_DATA_PROVIDED_NOT_VALID",
}
