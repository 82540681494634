import { useCallback, useState } from "react"
import { OrganizationId } from "~/types"
import { toast } from "react-toastify"
import { defineMessages, useIntl } from "react-intl"
import {
    LineStatus,
} from "../../types/PurchaseRequests"
import {
    purchaseRequestApi,
} from "~/domains/transactions/purchase-requests/api/purchaseRequestsApi"
import {
    useFetchPurchaseRequest
} from "~/domains/transactions/purchase-requests/store/hooks/useFetchPurchaseRequest"

const messages = defineMessages({
    successValidate: {
        id: "purchase.requests.request.line.accept.success",
        defaultMessage: "Line accepted",
    },
    successReject: {
        id: "purchase.requests.request.line.reject.success",
        defaultMessage: "Line rejected",
    },
    error: {
        id: "purchase.requests.request.update.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const useUpdatePurchaseRequestLineStatus = (organizationId: OrganizationId, purchaseRequestId: string) => {
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const { fetchPurchaseRequest} = useFetchPurchaseRequest(organizationId)

    const updateLineStatus = useCallback(
        async (lineId: string, status: LineStatus) => {
            try {
                setLoading(true)
                let message = ""
                switch (status) {
                    case LineStatus.APPROVED:
                        await purchaseRequestApi.lineApprove(organizationId, purchaseRequestId, lineId)
                        message = "successValidate"
                        break
                    case LineStatus.REJECTED:
                        await purchaseRequestApi.lineReject(organizationId, purchaseRequestId, lineId)
                        message = "successReject"
                        break
                }
                await fetchPurchaseRequest(purchaseRequestId)
                setLoading(false)
                // TODO: dispatch update line status
                return toast.success(formatMessage(messages[message]))
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                toast.error(formatMessage(messages.error))
                throw e
            }
        },
        [organizationId, purchaseRequestId]
    )

    return {
        updateLineStatus,
        error,
        loading,
    }
}
