import React, { Dispatch, SetStateAction, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { TagsSelectorContainer } from "./TagsSelectorContainer"
import { FromRecommandationValues, SelectedTagI, TagGroupI, TagGroupId } from "../../types"
import { Tag } from "./Tag"
import { TagInput } from "./TagInput"
import { Loader } from "~/components"
import { ModalSetTagsRatio } from "./ModalSetTagsRatio"
import { TagRecommandationsResultI } from "../../types/TagRecommandation"
import { useRecommandationsAsTagsToSelect } from "../../hooks"

export interface TagsSelectorStateless {
    tagGroups: TagGroupI[]
    selectedTags: SelectedTagI[]
    setSelectedTags: Dispatch<SetStateAction<SelectedTagI[]>>
    tagsRecommandations: TagRecommandationsResultI | null
    readonly?: boolean
    showTagGroup?: boolean
}

const getTagsInTagGroup = (tags: SelectedTagI[], tagGroupId: TagGroupId) =>
    tags.filter((tag) => tag.tagGroupId === tagGroupId)

export const TagsSelectorStateless = forwardRef<HTMLDivElement, TagsSelectorStateless>(
    ({ tagGroups, selectedTags, setSelectedTags, readonly, tagsRecommandations, showTagGroup }, containerRef) => {
        const inputRef = useRef<HTMLInputElement>(null)
        const [tagToEdit, setTagToEdit] = useState<SelectedTagI | null>(null)
        const editedTagGroup = tagToEdit
            ? tagGroups.find((tagGroup) => tagGroup.tagGroupId === tagToEdit.tagGroupId)
            : undefined

        const selectedTagWithTagGroup = useMemo<SelectedTagI[]>(
            () =>
                selectedTags.map((tag) => ({
                    ...tag,
                    tagGroup: tagGroups.find((tagGroup) => tagGroup.tagGroupId === tag.tagGroupId),
                })),
            [selectedTags, tagGroups]
        )

        const tagsToAddFromRecommandations = useRecommandationsAsTagsToSelect(
            tagsRecommandations?.directApplication,
            tagGroups,
            selectedTags,
            FromRecommandationValues.AUTO
        )

        const suggestedTags = useRecommandationsAsTagsToSelect(
            tagsRecommandations?.recommended,
            tagGroups,
            selectedTags,
            FromRecommandationValues.MANUAL
        )
        const setAutoTagsOnlyOnce = useRef(false)
        useEffect(() => {
            if (
                !setAutoTagsOnlyOnce.current &&
                tagsToAddFromRecommandations &&
                tagsToAddFromRecommandations.length > 0
            ) {
                setAutoTagsOnlyOnce.current = true
                setSelectedTags((selectedTags) => [...selectedTags, ...tagsToAddFromRecommandations])
            }
        }, [tagsToAddFromRecommandations])

        const clearTagToEdit = useCallback(() => setTagToEdit(null), [])

        const onUpdateTagRatio = useCallback(
            (updatedTags: SelectedTagI[]) => {
                setSelectedTags((currentTags) =>
                    currentTags.map((tag) => updatedTags.find((upTag) => upTag.tagId === tag.tagId) ?? tag)
                )
                clearTagToEdit()
            },
            [setSelectedTags]
        )

        const focusInput = useCallback((event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }, [])

        const removeTag = useCallback(
            (tag: SelectedTagI) => {
                setSelectedTags((currentSelection) => {
                    const newSelection = currentSelection.filter((selectedTag) => selectedTag.tagId !== tag.tagId)
                    if (typeof tag.ratio === "number") {
                        const isWithinTagGroup = (t: SelectedTagI) => t.tagGroupId === tag.tagGroupId
                        const totalForOtherTagsInGroup = 1
                        const newTagsInGroup = newSelection.filter(isWithinTagGroup)
                        const currentTotalForRemainingTags = newTagsInGroup.reduce(
                            (acc, tag) => acc + (tag.ratio ?? 0),
                            0
                        )
                        return newSelection.map((item) =>
                            item.tagGroupId === tag.tagGroupId
                                ? {
                                      ...item,
                                      ratio:
                                          (!item.ratio || item.ratio <= 0) && totalForOtherTagsInGroup > 0
                                              ? totalForOtherTagsInGroup / newTagsInGroup.length
                                              : ((item.ratio ?? 0) * totalForOtherTagsInGroup) /
                                                currentTotalForRemainingTags,
                                  }
                                : item
                        )
                    }
                    return newSelection
                })
            },
            [setSelectedTags]
        )

        const renderTag = useCallback(
            (tag: SelectedTagI) => {
                return (
                    <Tag
                        key={tag.tagId}
                        tag={tag}
                        removeTag={readonly ? undefined : removeTag}
                        setTagToEdit={readonly ? undefined : setTagToEdit}
                        showTagGroup={showTagGroup}
                    />
                )
            },
            [removeTag, setTagToEdit, showTagGroup]
        )

        if (!selectedTags) {
            return (
                <TagsSelectorContainer>
                    <Loader small />
                </TagsSelectorContainer>
            )
        }

        return (
            <TagsSelectorContainer onClick={focusInput} ref={containerRef}>
                {selectedTagWithTagGroup.map(renderTag)}
                {tagGroups ? (
                    readonly ? null : (
                        <TagInput
                            tagGroups={tagGroups}
                            ref={inputRef}
                            selectedTags={selectedTags}
                            setSelectedTags={setSelectedTags}
                            suggestions={suggestedTags}
                        />
                    )
                ) : (
                    <Loader small />
                )}
                <ModalSetTagsRatio
                    tagGroup={editedTagGroup}
                    tags={editedTagGroup ? getTagsInTagGroup(selectedTags, editedTagGroup.tagGroupId) : null}
                    close={clearTagToEdit}
                    onSave={onUpdateTagRatio}
                />
            </TagsSelectorContainer>
        )
    }
)

TagsSelectorStateless.displayName = "TagsSelectorStateless"
