import { defineMessages } from "react-intl"

export const commonMessages = defineMessages({
    people: {
        id: "common.peopleCount",
        defaultMessage: "{peopleCount, plural, =0 {no one} one {# person} other {# people}}",
    },
    inDevelopment: {
        id: "common.inDevelopment",
        defaultMessage: "in development",
    },
    rowsPerPage: {
        id: "common.pagination.rowsPerPage",
        defaultMessage: "Rows per page:",
    },
    labelDisplayedRowsNoCount: {
        id: "common.pagination.labelDisplayedRowsNoCount",
        defaultMessage: "{from}-{to}",
    },
    labelDisplayedRows: {
        id: "common.pagination.labelDisplayedRows",
        defaultMessage: "{from}-{to} of {count}",
    },
    cancel: {
        id: "common.cancel",
        defaultMessage: "Cancel",
    },
    confirm: {
        id: "common.confirm",
        defaultMessage: "Confirm",
    },
    save: {
        id: "common.save",
        defaultMessage: "Save",
    },
    create: {
        id: "common.create",
        defaultMessage: "Create",
    },
    close: {
        id: "common.close",
        defaultMessage: "Close",
    },
    supplier: {
        id: "common.supplier",
        defaultMessage: "Vendor",
    },
    buyer: {
        id: "common.buyer",
        defaultMessage: "Client",
    },
    error: {
        id: "common.error",
        defaultMessage: "We encountered an error. Please contact your administrator",
    },
    errorContact: {
        id: "common.errorContact",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
    errorNoOrganization: {
        id: "common.errorNoOrganization",
        defaultMessage: "This feature is only available for organizations, please create one in your settings.",
    },
})
