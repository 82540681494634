import { auth0 } from "~/utils/auth0"
import { getJWTCookie } from "~/utils/cookies"

type JWTType = string | null | undefined
export const getJWT = async (): Promise<JWTType> => {
    const cookie = getJWTCookie()
    if (cookie) {
        return cookie
    }

    const auth0Client = auth0()
    const isAuthenticated = await auth0Client.isAuthenticated()
    if (isAuthenticated) {
        const { token: JWT } = await auth0Client.getToken()
        if (JWT) {
            return JWT
        }
    }

    return null
}
