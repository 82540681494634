import { useCallback, useEffect } from "react"
import { CurrencyCodes, OrganizationI, ViewTypeI } from "~/types"

import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { PurchaseOrderStatus, PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import {
    purchaseOrdersActions,
    selectPurchaseOrder,
} from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { organizationAddressToAddress } from "~/domains/transactions/purchase-orders/pages/PurchaseOrder"

const createEmptyPurchaseOrder = (organization: OrganizationI, view: ViewTypeI): PurchaseOrders => {
    const buyerAddress =
        view === ViewTypeI.buyer && organization.address
            ? organizationAddressToAddress(organization.address)
            : {
                  city: "",
                  country: "",
                  street: "",
                  street2: "",
                  zipCode: "",
              }
    return {
        id: "",
        shortId: "",
        buyerId: view === ViewTypeI.buyer ? organization.id : "",
        supplierId: view === ViewTypeI.supplier ? organization.id : "",
        requesterUserId: "",
        supplierName: "",
        buyerName: organization.name ?? "",
        requesterName: "",
        totalAmount: 0,
        totalAmountExcludingTax: 0,
        totalTax: 0,
        status: view === ViewTypeI.buyer ? PurchaseOrderStatus.DRAFT_BUYER : PurchaseOrderStatus.DRAFT_SUPPLIER,
        description: "",
        currency: CurrencyCodes.EUR,
        lines: [],
        expectedDeliveryDate: "",
        creationDate: "",
        purchaseRequest: {
            id: "",
            number: "",
        },
        billingAddress: buyerAddress,
        shippingAddress: buyerAddress,
    }
}

const createEmptyBuyerPurchaseOrder = (organization: OrganizationI): PurchaseOrders => {
    return createEmptyPurchaseOrder(organization, ViewTypeI.buyer)
}

export const useCreateDraftPurchaseOrder = (organization: OrganizationI | undefined) => {
    const dispatch = useAppDispatch()
    const purchaseOrder = useAppSelector(selectPurchaseOrder)

    const createDraft = useCallback(() => {
        if (organization?.id) {
            dispatch(purchaseOrdersActions.createDraftPurchaseOrder(createEmptyBuyerPurchaseOrder(organization)))
        }
    }, [organization, dispatch])

    useEffect(() => {
        if (organization?.id) {
            createDraft()
        }
    }, [createDraft, organization])

    if (!organization?.id) return null

    return purchaseOrder
}
