import React, { useCallback } from "react"
import { useESGMetrics } from "../../hooks"
import { Loader } from "~/components"
import { DataTable, DataTableColumn } from "~/components/DataTable"
import { defineMessages, useIntl } from "react-intl"
import { Tooltip } from "@mui/material"
import { ESGCustomFieldCell } from "./ESGCustomFieldCell"
import { CustomFieldObjectType } from "../../types/CustomFieldObjectType"
import { OrganizationId } from "~/types"

export interface ItemWithDescriptionAndQuantity {
    description?: string | null
    quantity: number
}

export interface ESGCustomFieldsTableProps<T extends ItemWithDescriptionAndQuantity> {
    items: T[]
    idKey: keyof T
    temporaryIdKey?: keyof T
    contextType: CustomFieldObjectType
    contextId: string
    organizationId?: OrganizationId
}

export const customFieldsMessages = defineMessages({
    description: {
        id: "purchase.requests.request.items.description",
        defaultMessage: "Description",
    },
    quantity: {
        id: "purchase.requests.request.items.quantity",
        defaultMessage: "Qty",
    },
})

export function ESGCustomFieldsTable<T extends ItemWithDescriptionAndQuantity>({
    items,
    idKey,
    temporaryIdKey,
    contextType,
    contextId,
    organizationId,
}: ESGCustomFieldsTableProps<T>) {
    const { formatMessage, locale } = useIntl()
    const esgMetrics = useESGMetrics()

    const itemHasId = useCallback(
        (item: T) => !!item[idKey] || (temporaryIdKey && !!item[temporaryIdKey]),
        [idKey, temporaryIdKey]
    )

    if (!esgMetrics) {
        return (
            <div>
                <Loader />
            </div>
        )
    }

    const columns: DataTableColumn<T>[] = [
        {
            id: "description",
            key: "description",
            label: formatMessage(customFieldsMessages.description),
            sorter: true,
            renderCell: (line) => (
                <Tooltip title={line.description}>
                    <span className={"truncate-text item-description"}>{line.description}</span>
                </Tooltip>
            ),
        },
        {
            id: "quantity",
            key: "quantity",
            label: formatMessage(customFieldsMessages.quantity),
            sorter: true,
        },
        ...esgMetrics.map((metric): DataTableColumn<T> => {
            const translations = metric.translations ? metric.translations[locale.split("-")[0].toLowerCase()] : null
            const name = translations?.name ?? metric.name
            const description = translations?.description ?? metric.description
            return {
                id: metric.id,
                getValue: () => 0,
                label: (
                    <Tooltip title={description}>
                        <div>
                            <div>{name}</div>
                            <div>({metric.unit[0]})</div>
                        </div>
                    </Tooltip>
                ),
                renderCell: (line) => (
                    <ESGCustomFieldCell
                        customField={metric}
                        objectId={temporaryIdKey ? `${line[idKey] ?? line[temporaryIdKey]}` : `${line[idKey]}`}
                        isTemporaryId={temporaryIdKey ? !line[idKey] : false}
                        contextId={contextId}
                        contextType={contextType}
                        quantity={line.quantity}
                        organizationId={organizationId}
                    />
                ),
            }
        }),
    ]

    return (
        <div className="items block-container">
            <DataTable hidePagination={true} data={items.filter(itemHasId)} columns={columns} />
        </div>
    )
}
