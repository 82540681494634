import { FilterFieldProperty, FilterFieldType } from "../types"

const propertyTypePerProperty: Record<FilterFieldProperty, FilterFieldType> = {
    [FilterFieldProperty.AMOUNT]: FilterFieldType.NUMERICAL_CONDITION,
    [FilterFieldProperty.LINE_AMOUNT]: FilterFieldType.NUMERICAL_CONDITION,
    [FilterFieldProperty.SUPPLIER]: FilterFieldType.STRING_CONDITION,
    [FilterFieldProperty.TAGS]: FilterFieldType.TAG_CONDITION,
    [FilterFieldProperty.TAGS_V2]: FilterFieldType.TAG_CONDITION,
    [FilterFieldProperty.AUTHOR]: FilterFieldType.STRING_CONDITION,
    [FilterFieldProperty.UNKNOWN]: FilterFieldType.STRING_CONDITION,
}

export const getFilterPropertyType = (property: FilterFieldProperty): FilterFieldType =>
    propertyTypePerProperty[property]
