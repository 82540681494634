import React from "react"
import { LinearProgressBar } from "./LinearProgressBar"

interface Props extends React.PropsWithChildren {
    progress: number
}

export const ProgressBar: React.FC<Props> = ({ progress, children }) => {
    return <LinearProgressBar progress={progress}>{children}</LinearProgressBar>
}
