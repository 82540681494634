import {
    AttachmentI,
    CreateInvoiceResponseI,
    EventI,
    InvoiceUserType,
    NewOcrInvoiceI,
    OcrInvoiceI,
    OrganizationId,
} from "~/types"
import axiosClient from "~/api/axiosClient"
import { parseCreateInvoiceResponse } from "~/types/InvoiceParsers"
import { OcrDocumentId } from "~/features/ocr/types"
import { PaginationQuery } from "~/utils"
import {
    AttachementI,
    AttachementId,
    PaginatedReceivedEmailsI,
    parseAttachment,
    parsePaginatedReceivedEmails,
} from "~/features/ocr/types/ReceivedEmail"
import { FetchError, FetchErrorType } from "~/domains/common/apiClient/errors"
import { Result, ResultError, getResultErrorValue, isResultError } from "~/core/Result"

const OCR_BASE_URL = import.meta.env.VITE_API_OCR_URL

export interface UploadOCRResultI {
    invoice?: CreateInvoiceResponseI
    rawDocument?: unknown
    images?: string[]
}

export interface NewUploadOCRResultI {
    id: OcrDocumentId
}

export const ocrApi = {
    getAttachmentLink(fileId: string) {
        return `${OCR_BASE_URL}resources/attachment/${fileId}`
    },
    async createInvoiceFromOcrData(
        initiator: InvoiceUserType,
        ocrInvoice: NewOcrInvoiceI
    ): Promise<CreateInvoiceResponseI> {
        const response: Record<string | number | symbol, any> = await axiosClient.post(
            `${OCR_BASE_URL}invoice/create-from-ocr?initiator=${initiator}`,
            ocrInvoice
        )
        return parseCreateInvoiceResponse({
            ...response,
            invoice: {
                ...response?.invoice,
                id: ocrInvoice.id ?? "",
            },
        })
    },
    async newUpload(
        file: File,
        initiator: InvoiceUserType,
        organizationId?: OrganizationId
    ): Promise<NewUploadOCRResultI> {
        const formData = new FormData()
        formData.append("file", file)
        const response: NewUploadOCRResultI = await axiosClient.post(
            `${OCR_BASE_URL}upload-invoice?initiator=${initiator}${
                organizationId ? `&organizationId=${organizationId}` : ""
            }`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        return response
    },
    upload(file: File): Promise<OcrInvoiceI> {
        const formData = new FormData()
        formData.append("file", file)
        const url = "/v1/ocr/invoice"
        return axiosClient.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
    getOcrResult(invoiceId: string): Promise<EventI[]> {
        const url = `/v1/invoices/${invoiceId}/ocr-result`
        return axiosClient.get(url)
    },

    async fetchOrganizationEmails(
        organizationId: OrganizationId,
        query?: PaginationQuery
    ): Promise<Result<PaginatedReceivedEmailsI, FetchError<PaginatedReceivedEmailsI>>> {
        const url = `${OCR_BASE_URL}inbox/organization/${organizationId}${
            query ? `?${new URLSearchParams(query as Record<string, string>).toString()}` : ""
        }`

        try {
            const response = await axiosClient.get(url)
            const result = parsePaginatedReceivedEmails(response)
            if (isResultError(result)) {
                return ResultError({
                    type: FetchErrorType.PARSING_ERROR,
                    partialResults: [],
                    errors: [getResultErrorValue(result)],
                })
            }
            return result
        } catch {
            return ResultError({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: 500,
                error: `An unexpected error occurred`,
            })
        }
    },

    async relaunchAttachmentProcessing(
        organizationId: OrganizationId,
        attachmentId: AttachementId
    ): Promise<Result<AttachementI, FetchError<AttachmentI>>> {
        const url = `${OCR_BASE_URL}inbox/organization/${organizationId}/attachment/${attachmentId}/relaunch`
        try {
            const response = await axiosClient.post(url)
            const result = parseAttachment(response)

            if (isResultError(result)) {
                return ResultError({
                    type: FetchErrorType.PARSING_ERROR,
                    partialResults: [],
                    errors: [getResultErrorValue(result)],
                })
            }
            return result
        } catch (error) {
            return ResultError({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: 500,
                error: `${error}`,
            })
        }
    },

    async markReceivedEmailAsRead(organizationId: OrganizationId, emailId: string): Promise<boolean> {
        const url = `${OCR_BASE_URL}inbox/organization/${organizationId}/emails/${emailId}/read`
        return await axiosClient.post(url)
    },
}
