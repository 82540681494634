import { AxiosResponse } from "axios"

import { Result, ResultError, getResultErrorValue, isResultError } from "~/core/Result"
import { FetchError, FetchErrorType } from "./errors"
import { ParsingErrorType } from "~/utils"

export function parseResponse<T>(
    response: AxiosResponse<unknown>,
    parser: (data: unknown) => Result<T, ParsingErrorType>
): Result<T, FetchError<T>> {
    if (response.status < 200 || response.status >= 300) {
        return ResultError({
            type: FetchErrorType.HTTP_REQUEST_ERROR,
            code: response.status,
            error: `${response.data}`,
        })
    }
    const result = parser(response.data)
    if (isResultError(result)) {
        return ResultError({
            type: FetchErrorType.PARSING_ERROR,
            partialResults: [],
            errors: [getResultErrorValue(result)],
        })
    }
    return result
}
