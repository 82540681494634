import React from "react"
import { Box } from "@mui/material"
import "./PurchaseOrderLink.scss"
import { PurchaseRequestDetailsPurchaseOrder } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { generatePath, Link } from "react-router-dom"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"

interface Props {
    purchaseOrder: PurchaseRequestDetailsPurchaseOrder
    label: string
}

export function PurchaseOrderLink({ purchaseOrder, label }: Props) {
    return (
        <Box component={"div"} className={"purchase-order-link"}>
            <label>{label} :</label>
            <div>
                <span>
                    <Link to={generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: purchaseOrder.id })}>
                        #{purchaseOrder.shortId}
                    </Link>
                </span>
            </div>
        </Box>
    )
}
