import { useCallback, useState } from "react"
import { OrganizationId } from "~/types"
import { toast } from "react-toastify"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"
import { PurchaseOrderLineStatus } from "../../types/PurchaseOrders"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api/purchaseOrdersApi"
import { useFetchPurchaseOrder } from "~/domains/transactions/purchase-orders/store/hooks/useFetchPurchaseOrder"

type MessageType =
    | PurchaseOrderLineStatus.APPROVED
    | PurchaseOrderLineStatus.REJECTED
    | PurchaseOrderLineStatus.IN_PREPARATION
    | PurchaseOrderLineStatus.OUT_FOR_DELIVERY
    | PurchaseOrderLineStatus.DELIVERED
    | PurchaseOrderLineStatus.RECEIVED

const statusMessages: Record<MessageType, MessageDescriptor> = defineMessages({
    [PurchaseOrderLineStatus.APPROVED]: {
        id: "purchase.orders.order.line.statusUpdate.approved",
        defaultMessage: "Line marked as approved",
    },
    [PurchaseOrderLineStatus.REJECTED]: {
        id: "purchase.orders.order.line.statusUpdate.rejected",
        defaultMessage: "Line marked as rejected",
    },
    [PurchaseOrderLineStatus.IN_PREPARATION]: {
        id: "purchase.orders.order.line.statusUpdate.in_preparation",
        defaultMessage: "Line marked as in preparation",
    },
    [PurchaseOrderLineStatus.OUT_FOR_DELIVERY]: {
        id: "purchase.orders.order.line.statusUpdate.dispatched",
        defaultMessage: "Line marked as dispatched",
    },
    [PurchaseOrderLineStatus.DELIVERED]: {
        id: "purchase.orders.order.line.statusUpdate.delivered",
        defaultMessage: "Line marked as delivered",
    },
    [PurchaseOrderLineStatus.RECEIVED]: {
        id: "purchase.orders.order.line.statusUpdate.received",
        defaultMessage: "Line marked as received",
    },
})

const messages = defineMessages({
    error: {
        id: "purchase.orders.order.line.statusUpdate.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const useUpdatePurchaseOrderLineStatus = (organizationId: OrganizationId, purchaseOrdersId: string) => {
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organizationId)

    const updateLineStatus = useCallback(
        async (lineId: string, status: PurchaseOrderLineStatus, note?: string) => {
            try {
                setLoading(true)
                let message: MessageType = PurchaseOrderLineStatus.IN_PREPARATION
                switch (status) {
                    case PurchaseOrderLineStatus.APPROVED:
                        await purchaseOrdersApi.lineApprove(organizationId, purchaseOrdersId, lineId)
                        message = status
                        break
                    case PurchaseOrderLineStatus.REJECTED:
                        await purchaseOrdersApi.lineReject(organizationId, purchaseOrdersId, lineId)
                        message = status
                        break
                    case PurchaseOrderLineStatus.OUT_FOR_DELIVERY:
                        await purchaseOrdersApi.lineDispatched(organizationId, purchaseOrdersId, lineId, note)
                        message = status
                        break
                    case PurchaseOrderLineStatus.DELIVERED:
                        await purchaseOrdersApi.lineDelivered(organizationId, purchaseOrdersId, lineId)
                        message = status
                        break
                    case PurchaseOrderLineStatus.RECEIVED:
                        await purchaseOrdersApi.lineReceived(organizationId, purchaseOrdersId, lineId)
                        message = status
                        break
                }
                await fetchPurchaseOrder(purchaseOrdersId)
                setLoading(false)
                // TODO: dispatch update line status
                return toast.success(formatMessage(statusMessages[message]))
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                toast.error(formatMessage(messages.error))
                throw e
            }
        },
        [organizationId, purchaseOrdersId]
    )

    return {
        updateLineStatus,
        error,
        loading,
    }
}
