import React from "react"
import { FormattedMessage, defineMessages } from "react-intl"
import { Link } from "react-router-dom"
import { LeftChevronIcon } from "../Icons"

const messages = defineMessages({
    back: {
        id: "common.h1.backLink",
        defaultMessage: "Back",
    },
})

interface Props {
    urlOrFunction: string | (() => void)
}

export const BackLink = ({ urlOrFunction }: Props) => {
    return (
        <>
            {typeof urlOrFunction === "string" ? (
                <Link to={urlOrFunction}>
                    <span>
                        <LeftChevronIcon size={14} color="var(--primary-color)" strokeWidth="3" />
                        <FormattedMessage {...messages.back} />
                    </span>
                </Link>
            ) : (
                <span className="cursor-pointer" onClick={urlOrFunction}>
                    <LeftChevronIcon size={14} color="var(--primary-color)" strokeWidth="3" />
                    <FormattedMessage {...messages.back} />
                </span>
            )}
        </>
    )
}
