import { ProtectedRoutes } from "~/features/auth/ProtectedRoute"
import Tabs from "./Tabs"

export * from "./ComingSoon"
export * from "./Button"
export * from "./InvoiceViewer"
export * from "./Loader"
export * from "./Modal"
export * from "./Steps"
export * from "./OneTimePasword"
export * from "./ModalFinalizeAccount"
export * from "./ModalReconnect"
export * from "./BulkActions"
export * from "./HrWithText"

enum Size {
    SM = "sm",
    MD = "md",
    LG = "lg",
    XL = "xl",
}

export { ProtectedRoutes, Size, Tabs }
