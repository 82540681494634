import axios, { AxiosInstance } from "axios"
import React, { PropsWithChildren, createContext, useMemo } from "react"
// import pako from "pako"
import { getJWT } from "./jwt"
import { getLanguageCookie } from "~/utils"

interface ApiContextI {
    axiosClient: AxiosInstance
}

const defaultLanguage = "en"

export const ApiContext = createContext<ApiContextI>({ axiosClient: axios.create() })

export const WithApiContext: React.FC<PropsWithChildren> = ({ children }) => {
    const axiosClient = useMemo(() => {
        const client = axios.create({
            headers: {
                "Content-Type": "application/json",
            },
            validateStatus: () => true,
        })

        client.interceptors.request.use(
            async (config) => {
                const jwt = await getJWT()
                if (jwt) {
                    //const compressedJwt = pako.deflate(`${jwt}`)
                    //const binString = String.fromCodePoint(...compressedJwt)
                    //const b64encodedCompressedJwt = btoa(binString)
                    const b64encodedCompressedJwt = `${jwt}`
                    config.headers["Authorization"] = `Bearer ${b64encodedCompressedJwt}`
                }
                const language = getLanguageCookie() ?? defaultLanguage
                config.headers["Accept-Language"] = language
                return config
            },
            (error) => {
                return Promise.reject(error)
            }
        )
        return client
    }, [])

    return <ApiContext.Provider value={{ axiosClient }}>{children}</ApiContext.Provider>
}
