import { useCallback, useState } from "react"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { ImportBatchResponseI } from "~/types/ImportBatch"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"

export const useImportBatchPurchaseOrder = (organizationId: OrganizationId) => {
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const importBatchPO: (file: File) => Promise<ImportBatchResponseI> = useCallback(
        async (file: File) => {
            try {
                setLoading(true)
                const result: ImportBatchResponseI = await purchaseOrdersApi.importBatchPO(organizationId, file)
                setLoading(false)
                return result
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                throw e
            } finally {
                setLoading(false)
            }
        },
        [organizationId, dispatch]
    )

    return {
        importBatchPO,
        loading,
        error,
    }
}
