import React, { useMemo } from "react"
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import { Tooltip } from "@mui/material"
import { WarningIcon, SuccessIcon } from "~/components/Icons"
import { electronicFormatIBAN, validateIBAN, ValidationErrorsIBAN } from "ibantools"
import "./IbanValidationCheck.scss"

const messages = defineMessages({
    validIBAN: {
        id: "iban.validationCheck.valid",
        defaultMessage: "Valid IBAN",
    },
    invalidIBAN: {
        id: "iban.validationCheck.invalid",
        defaultMessage: "Invalid IBAN",
    },
})

const ibanErrorMessages: Record<ValidationErrorsIBAN, MessageDescriptor> = {
    [ValidationErrorsIBAN.NoIBANProvided]: {
        id: "iban.validationCheck.errors.noIbanProvided",
        defaultMessage: "No IBAN provided",
    },
    [ValidationErrorsIBAN.NoIBANCountry]: {
        id: "iban.validationCheck.errors.noIbanCountry",
        defaultMessage: "Invalid country code in IBAN",
    },
    [ValidationErrorsIBAN.WrongBBANLength]: {
        id: "iban.validationCheck.errors.wrongBBANLength",
        defaultMessage: "Incorrect BBAN length",
    },
    [ValidationErrorsIBAN.WrongBBANFormat]: {
        id: "iban.validationCheck.errors.wrongBBANFormat",
        defaultMessage: "Incorrect BBAN format",
    },
    [ValidationErrorsIBAN.ChecksumNotNumber]: {
        id: "iban.validationCheck.errors.checksumNotNumber",
        defaultMessage: "IBAN checksum contains non-numeric characters",
    },
    [ValidationErrorsIBAN.WrongIBANChecksum]: {
        id: "iban.validationCheck.errors.wrongIBANChecksum",
        defaultMessage: "Incorrect IBAN checksum",
    },
    [ValidationErrorsIBAN.WrongAccountBankBranchChecksum]: {
        id: "iban.validationCheck.errors.wrongAccountBankBranchChecksum",
        defaultMessage: "Incorrect bank or branch account checksum",
    },
    [ValidationErrorsIBAN.QRIBANNotAllowed]: {
        id: "iban.validationCheck.errors.qrIbanNotAllowed",
        defaultMessage: "QR IBANs are not allowed",
    },
}

interface Props {
    iban: string | null | undefined
    hideValidDisplay?: boolean
}

export const IBANValidationWarning: React.FC<Props> = ({ iban, hideValidDisplay = false }) => {
    const { formatMessage } = useIntl()
    const electronicIban = electronicFormatIBAN(iban ?? "")
    const validationResult = validateIBAN(electronicIban ?? "")
    const isValidIban = validationResult.valid

    const getErrorMessages = useMemo(() => {
        return validationResult.errorCodes.map((errorCode) => formatMessage(ibanErrorMessages[errorCode]))
    }, [validationResult.errorCodes, formatMessage])

    const renderTooltipMessage = (
        <>
            {getErrorMessages.map((errorMsg, index) => (
                <div key={index}>{errorMsg}</div>
            ))}
        </>
    )

    if (!iban || !electronicIban) return null

    return (
        <div className="iban-validation">
            {isValidIban && !hideValidDisplay && (
                <div className="iban-validation-success">
                    <FormattedMessage {...messages.validIBAN} />
                    <SuccessIcon size={16} />
                </div>
            )}
            {!isValidIban && (
                <div className="iban-validation-warning">
                    <Tooltip title={renderTooltipMessage} placement="bottom" arrow>
                        <div className="iban-validation-warning-sub-group">
                            <span>
                                <FormattedMessage {...messages.invalidIBAN} />
                            </span>
                            <WarningIcon size={16} />
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    )
}
