import * as Sentry from "@sentry/browser"
import {
    CreateInvoiceErrorI,
    CreateInvoiceErrorIO,
    CreateInvoiceResponseI,
    CreateInvoiceResponseIO,
    ImportInvoiceCompanyInfoI,
    ImportInvoiceCompanyInfoIO,
    ImportInvoiceError,
    ImportingInvoiceI,
    ImportingInvoiceIO,
    InvoiceCompanyIO,
    InvoiceI,
    InvoiceIO,
    InvoiceStatus,
    ComplianceStatusI,
    ComplianceStatusResponseIO,
} from "./InvoiceTypes"
import { TypeOf, partial } from "io-ts"
import { CountryCode, validateCountryCodeOrSetUnknown } from "./CountryCode"
import { parseCompany } from "./CompanyParsers"
import { ParsingErrorType, genericParser } from "~/utils"
import { Result } from "~/core/Result"
import dayjs from "dayjs"

export const parseInvoiceStatus = (strStatus: string): InvoiceStatus => {
    const status = strStatus as InvoiceStatus
    if (Object.values(InvoiceStatus).includes(status)) return status
    Sentry.captureMessage(`Unknown invoice status: ${strStatus}`, "warning")
    return InvoiceStatus.DRAFT
}

export const parseImportInvoiceErrorType = (errorStr: string): ImportInvoiceError => {
    const error = errorStr as ImportInvoiceError
    if (Object.values(ImportInvoiceError).includes(error)) return error
    Sentry.captureMessage(`Unknown invoice importing error: ${errorStr}`, "warning")
    return ImportInvoiceError.UNKNOWN
}

export const parseImportInvoiceError = (
    errorData: TypeOf<typeof CreateInvoiceErrorIO> | undefined | null
): CreateInvoiceErrorI | null =>
    errorData
        ? {
              ...errorData,
              type: parseImportInvoiceErrorType(errorData.type),
          }
        : null

export const parseImportInvoiceCompanyInfoI = (
    companyInfoData: TypeOf<typeof ImportInvoiceCompanyInfoIO>
): ImportInvoiceCompanyInfoI => ({
    ...companyInfoData,
    countryCode: validateCountryCodeOrSetUnknown(companyInfoData.countryCode),
    contactName: "",
    organizationId: companyInfoData.organizationId ?? null,
})

export const parseImportingInvoice = (invoiceData: TypeOf<typeof ImportingInvoiceIO>): ImportingInvoiceI => {
    return {
        ...invoiceData,
        loaded: true,
        status: parseInvoiceStatus(invoiceData.status),
        buyer: parseImportInvoiceCompanyInfoI(invoiceData.buyer),
        supplier: parseImportInvoiceCompanyInfoI(invoiceData.supplier),
        buyerTags: [],
        supplierTags: [],
        total: invoiceData.total ?? null,
        totalExcludedTaxes: invoiceData.totalExcludedTaxes ?? null,
        purchaseOrderNumber: invoiceData.purchaseOrderNumber ?? undefined,
        reference: invoiceData.reference ?? "",
        dueDate: invoiceData.dueDate ?? undefined,
        issueDate: invoiceData.issueDate ?? dayjs().format("YYYY-MM-DD"),
        totalDiscount: invoiceData.totalDiscount ?? 0,
    }
}

export const parseCreateInvoiceResponse = (response: unknown): CreateInvoiceResponseI => {
    const result = genericParser(response, CreateInvoiceResponseIO)
    if (!result.success) {
        console.error(result.error)
        const error = new Error(`Invalid data return by the API for CreateInvoiceResponseI`)
        Sentry.captureException(error, {
            extra: result.error,
        })
        throw error
    }
    const invoice = parseImportingInvoice(result.result.invoice)
    return {
        invoice,
        ocrBuyer: invoice.buyer,
        ocrSupplier: invoice.supplier,
        suggestedSupplierCompanies: result.result.suggestedSupplierCompanies?.map(parseCompany) ?? null,
        suggestedBuyerCompanies: result.result.suggestedBuyerCompanies?.map(parseCompany) ?? null,
        paymentDetails: invoice.paymentDetails,
        error: parseImportInvoiceError(result.result.error),
        otherError: null,
    }
}

const convertInvoiceCompanyInfoToCompanyInfo = (
    companyData: TypeOf<typeof InvoiceCompanyIO> | null | undefined
): ImportInvoiceCompanyInfoI => ({
    countryCode: validateCountryCodeOrSetUnknown(companyData?.registrations?.countryCode ?? CountryCode.UNKNOWN),
    name: companyData?.name ?? "",
    contactName: companyData?.contactName ?? "",
    email: companyData?.contactEmail ?? "",
    organizationId: companyData?.organizationId ?? null,
    taxId: companyData?.registrations?.vatNumber,
    registrationNumber: companyData?.registrations?.registrationNumber ?? null,
})

export const parseInvoice = (invoiceData: unknown): InvoiceI => {
    const decodedResult = genericParser(invoiceData, InvoiceIO)
    if (decodedResult.success) {
        return {
            ...decodedResult.result,
            reference: decodedResult.result.reference ?? "",
            buyer: convertInvoiceCompanyInfoToCompanyInfo(decodedResult.result.buyer),
            supplier: convertInvoiceCompanyInfoToCompanyInfo(decodedResult.result.supplier),
            buyerTags: decodedResult.result.buyerTags ?? undefined,
            supplierTags: decodedResult.result.supplierTags ?? undefined,
            total: decodedResult.result.total ?? null,
            totalDiscount: decodedResult.result.totalDiscount ?? 0,
            totalExcludedTaxes: decodedResult.result.totalExcludedTaxes ?? null,
            purchaseOrderNumber: decodedResult.result.purchaseOrderNumber ?? undefined,
            issueDate: decodedResult.result.issueDate ?? dayjs().format("YYYY-MM-DD"),
            dueDate: decodedResult.result.dueDate ?? undefined,
            status: parseInvoiceStatus(decodedResult.result.status),
            signed: decodedResult.result.signed === true,
            possibleDuplicates: decodedResult.result.possibleDuplicates ?? [],
        }
    }
    console.error(decodedResult.error)
    const error = new Error(`Invalid data return by the API for parseInvoice`)
    Sentry.captureException(error, {
        extra: decodedResult.error,
    })
    throw error
}

const PartialInvoiceIO = partial(
    InvoiceIO.types
        .map((typeIO) => typeIO.props)
        .reduce(
            (acc, props) => ({
                ...acc,
                ...props,
            }),
            {}
        )
)
export const parsePartialInvoice = (invoiceData: unknown): Result<Partial<InvoiceI>, ParsingErrorType> => {
    const result = genericParser<Partial<InvoiceI>>(invoiceData, PartialInvoiceIO)
    return result
}

export const parseComplianceStatus = (complianceStatus: unknown[]): ComplianceStatusI[] => {
    const decodedResult = genericParser(complianceStatus, ComplianceStatusResponseIO)
    if (decodedResult.success) {
        return decodedResult.result
    }
    console.error(decodedResult.error)
    const error = new Error(`Invalid data return by the API for parseComplianceStatus`)
    Sentry.captureException(error, {
        extra: decodedResult.error,
    })
    throw error
}
