import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "~/store"
import { DocumentRelationsState } from "./documentRelationsState"
import { DocumentRelationId, InvoiceRelationI } from "../types/InvoiceRelation"
import { FetchError } from "~/domains/common/apiClient/errors"
import { PurchaseOrderRelationI } from "../types/PurchaseOrderRelation"

const initialState: DocumentRelationsState = {
    currentInvoiceId: null,
    invoiceRelationsLoading: false,
    invoiceRelationsErrors: null,
    invoiceRelations: [],

    currentPurchaseOrderId: null,
    purchaseOrderRelationsLoading: false,
    purchaseOrderRelationsErrors: null,
    purchaseOrderRelations: [],
}

const documentRelationsSlice = createSlice({
    name: "document-relations",
    initialState: initialState,
    reducers: {
        fetchInvoiceRelations(state, action: PayloadAction<{ invoiceId: string }>) {
            state.currentInvoiceId = action.payload.invoiceId
            state.invoiceRelations = []
            state.invoiceRelationsErrors = null
            state.invoiceRelationsLoading = true
        },
        fetchInvoiceRelationsSuccess(
            state,
            {
                payload: { invoiceId, invoiceRelations },
            }: PayloadAction<{ invoiceId: string; invoiceRelations: InvoiceRelationI[] }>
        ) {
            if (state.currentInvoiceId === invoiceId) {
                state.invoiceRelations = invoiceRelations
                state.invoiceRelationsLoading = false
            }
        },
        fetchInvoiceRelationsFailure(
            state,
            { payload: { invoiceId, error } }: PayloadAction<{ invoiceId: string; error: FetchError<InvoiceRelationI> }>
        ) {
            if (state.currentInvoiceId === invoiceId) {
                state.invoiceRelationsErrors = error
                state.invoiceRelationsLoading = false
            }
        },
        addInvoiceRelation(state, { payload }: PayloadAction<InvoiceRelationI>) {
            state.invoiceRelations = [...state.invoiceRelations, payload]
        },
        removeInvoiceRelation(state, { payload }: PayloadAction<DocumentRelationId>) {
            state.invoiceRelations = state.invoiceRelations.filter(
                (invoiceRelation) => invoiceRelation.relationId !== payload
            )
        },

        fetchPurchaseOrderRelations(state, action: PayloadAction<{ purchaseOrderId: string }>) {
            state.currentPurchaseOrderId = action.payload.purchaseOrderId
            state.purchaseOrderRelations = []
            state.purchaseOrderRelationsErrors = null
            state.purchaseOrderRelationsLoading = true
        },
        fetchPurchaseOrderRelationsSuccess(
            state,
            {
                payload: { purchaseOrderId, purchaseOrderRelations },
            }: PayloadAction<{ purchaseOrderId: string; purchaseOrderRelations: PurchaseOrderRelationI[] }>
        ) {
            if (state.currentPurchaseOrderId === purchaseOrderId) {
                state.purchaseOrderRelations = purchaseOrderRelations
                state.purchaseOrderRelationsLoading = false
            }
        },
        fetchPurchaseOrderRelationsFailure(
            state,
            {
                payload: { purchaseOrderId, error },
            }: PayloadAction<{ purchaseOrderId: string; error: FetchError<PurchaseOrderRelationI> }>
        ) {
            if (state.currentPurchaseOrderId === purchaseOrderId) {
                state.purchaseOrderRelationsErrors = error
                state.purchaseOrderRelationsLoading = false
            }
        },
        addPurchaseOrderRelation(state, { payload }: PayloadAction<PurchaseOrderRelationI>) {
            state.purchaseOrderRelations = [...state.purchaseOrderRelations, payload]
        },
        removePurchaseOrderRelation(state, { payload }: PayloadAction<DocumentRelationId>) {
            state.purchaseOrderRelations = state.purchaseOrderRelations.filter(
                (purchaseOrderRelation) => purchaseOrderRelation.relationId !== payload
            )
        },
    },
})

export const documentRelationsActions = documentRelationsSlice.actions

// // Selectors
export const selectDocumentRelationCurrentInvoiceId = (state: RootState) => state.documentRelations.currentInvoiceId

export const selectInvoiceRelations = (state: RootState) => state.documentRelations.invoiceRelations
const selectInvoiceRelationsLoading = (state: RootState) => state.documentRelations.invoiceRelationsLoading
const selectInvoiceRelationsErrors = (state: RootState) => state.documentRelations.invoiceRelationsErrors

export const selectInvoiceRelationsState = createSelector(
    [selectInvoiceRelations, selectInvoiceRelationsLoading, selectInvoiceRelationsErrors],
    (invoiceRelations, loading, error) => ({
        invoiceRelations,
        loading,
        error,
    })
)

const selectPurchaseOrderRelations = (state: RootState) => state.documentRelations.purchaseOrderRelations
const selectPurchaseOrderRelationsLoading = (state: RootState) => state.documentRelations.purchaseOrderRelationsLoading
const selectPurchaseOrderRelationsErrors = (state: RootState) => state.documentRelations.purchaseOrderRelationsErrors

export const selectPurchaseOrderRelationsState = createSelector(
    [selectPurchaseOrderRelations, selectPurchaseOrderRelationsLoading, selectPurchaseOrderRelationsErrors],
    (purchaseOrderRelations, loading, error) => ({
        purchaseOrderRelations,
        loading,
        error,
    })
)

// // Reducer
const documentRelationsReducer = documentRelationsSlice.reducer

export default documentRelationsReducer
