import { useAppSelector } from "~/store/hooks"
import { useTagsApi } from "~/domains/tags/tagsApi"
import { useCallback } from "react"
import { CreateTagObjectI, TagObjectType } from "~/domains/tags/types"
import { selectUser } from "~/store/account/accountSlice"
import { OrganizationId } from "~/types"
import { isDefined } from "~/utils/isDefined"
import { selectPendingLineTags } from "~/domains/tags/store/tagsSlice"
import { CreatePurchaseOrderDTO, PurchaseOrders } from "../../types/PurchaseOrders"

export const useSavePurchaseOrderPendingLineTags = (organizationId?: OrganizationId) => {
    const user = useAppSelector(selectUser)
    const pendingLineTags = useAppSelector(selectPendingLineTags)
    const tagsApi = useTagsApi()

    return useCallback(
        async (purchaseOrder: PurchaseOrders | CreatePurchaseOrderDTO, updatedPurchaseOrder: PurchaseOrders) => {
            if (!organizationId) return
            try {
                const tagsToCreate = updatedPurchaseOrder.lines
                    .map((line, lineIndex) => {
                        const temporaryId = purchaseOrder.lines[lineIndex]?.temporaryId
                        const tags = temporaryId ? pendingLineTags[temporaryId] : null
                        const lineId = line.id
                        if (tags && lineId) {
                            return tags.map(
                                (tag): CreateTagObjectI => ({
                                    tagId: tag.tagId,
                                    objectId: lineId,
                                    ratio: tag.ratio,
                                    objectType: TagObjectType.PURCHASE_ORDER_LINE,
                                    contextId: updatedPurchaseOrder.id,
                                    objectContext: {
                                        organizationId,
                                        user,
                                        purchaseOrder: updatedPurchaseOrder,
                                    },
                                })
                            )
                        }
                        return null
                    })
                    .filter(isDefined)
                    .flat()
                if (tagsToCreate.length > 0) {
                    await tagsApi.createObjectTags(organizationId, tagsToCreate)
                }
            } catch (error) {
                console.error("error")
            }
        },
        [pendingLineTags, tagsApi, user, organizationId]
    )
}
