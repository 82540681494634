import { genericParser } from "~/utils"
import { MessagesListI, MessagesListIO } from "./MessagesList"
import { ResultSuccess, isResultSuccess } from "~/core/Result"

export const parseMessagesList = (data: unknown) => {
    const result = genericParser<MessagesListI>(data, MessagesListIO)
    if (isResultSuccess(result)) {
        return ResultSuccess({
            ...result.result,
            messages: result.result.messages.map((message) => ({
                ...message,
                views: message.views ?? [],
            })),
        })
    }
    return result
}
