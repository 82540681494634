import { useCallback, useEffect, useRef } from "react"
import { OrganizationId } from "~/types"
import { useTagsApi } from "../tagsApi"
import { CreateTagObjectI, TagObjectType } from "../types"

export const useCreateTagObject = (
    organizationId?: OrganizationId,
    objectId?: string | string[],
    objectType?: TagObjectType,
    context?: any,
    contextId?: string
) => {
    const tagsApi = useTagsApi()
    const contextRef = useRef(context)

    useEffect(() => {
        contextRef.current = context
    }, [context])

    return useCallback(
        async (payload: Omit<CreateTagObjectI, "objectId">, objId?: string | string[]) => {
            if (!organizationId || (!objectId && !objId)) return
            const realObjectId = objectId ?? objId
            if (Array.isArray(objectId)) {
                return await Promise.allSettled(
                    (realObjectId as string[]).map((oId) =>
                        tagsApi.createTagObject(organizationId, {
                            ...payload,
                            objectId: oId,
                            objectType,
                            objectContext: { ...contextRef.current, ...payload.objectContext },
                            contextId,
                        })
                    )
                )
            } else {
                return await tagsApi.createTagObject(organizationId, {
                    ...payload,
                    objectId: realObjectId as string,
                    objectType,
                    objectContext: { ...contextRef.current, ...payload.objectContext },
                    contextId,
                })
            }
        },
        [organizationId, objectId]
    )
}
