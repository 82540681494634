import { useMemo } from "react"
import { FromRecommandationValues, SelectedTagI, TagAssignmentRule, TagGroupI } from "../types"
import { TagRecommandationI } from "../types/TagRecommandation"
import { isDefined } from "~/utils/isDefined"

export const useRecommandationsAsTagsToSelect = (
    recommandations: TagRecommandationI[] | undefined,
    tagGroups: TagGroupI[],
    selectedTags: SelectedTagI[],
    fromRecommandation: FromRecommandationValues
) => {
    return useMemo((): SelectedTagI[] => {
        if (recommandations && recommandations.length > 0 && tagGroups.length > 0) {
            const tagsToAdd = recommandations
                .map((tagRecommandation) =>
                    tagGroups
                        .flatMap((tagGroup) =>
                            tagGroup.tags
                                .map((tag) => ({ ...tag, tagGroup }))
                                .find((tag) => tag.tagId === tagRecommandation.tagId)
                        )
                        .find(isDefined)
                )
                .filter(isDefined)
                .filter((tag) => !selectedTags.some((selectedTag) => selectedTag.tagId === tag.tagId))

            const tagsToAddWithRatio = tagsToAdd.map((tag) => {
                const tagGroup = tagGroups.find((tagGroup) => tagGroup.tagGroupId === tag.tagGroupId)
                if (tagGroup && tagGroup.assignmentRule === TagAssignmentRule.MULTIPLE) {
                    const countTagsInTagGroup =
                        selectedTags.filter((tag) => tag.tagGroupId === tagGroup.tagGroupId).length +
                        tagsToAdd.filter((t) => t.tagId !== tag.tagId && t.tagGroupId === tag.tagGroupId).length
                    return {
                        ...tag,
                        ratio: 1 / (countTagsInTagGroup + 1),
                        fromRecommandation,
                    }
                }
                return { ...tag, fromRecommandation }
            })
            return tagsToAddWithRatio
        }
        return []
    }, [recommandations, tagGroups, selectedTags])
}
