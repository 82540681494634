import { useMemo } from "react"
import { MessageI, MessageWithUserI } from "../types"
import { useGetAllUsersQuery } from "~/store/users/hooks"

export const useMessagesWithUsers = (messages: MessageI[] | null | undefined): MessageWithUserI[] => {
    const allUserIds = useMemo(() => messages?.map((message) => message.senderId) ?? [], [messages])
    const { users } = useGetAllUsersQuery(allUserIds)
    const messagesWithUsers = useMemo(
        () =>
            messages?.map(
                (message): MessageWithUserI => ({
                    ...message,
                    user: users.find((user) => user.id === message.senderId),
                })
            ) ?? [],
        [users, messages]
    )

    return messagesWithUsers
}
