import React from "react"
import { createRoot } from "react-dom/client"
import * as Sentry from "@sentry/react"
import App from "./App"
import { LicenseInfo } from "@mui/x-license-pro"
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENCE_KEY)

if (import.meta.env.VITE_ENV !== "development") {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: [import.meta.env.VITE_APP_URL],
            }),
        ],
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,
    })
}

const container = document.getElementById("root")
if (container) {
    const root = createRoot(container)
    container.setAttribute("notranslate", "true")
    root.render(<App />)
}
