import React from "react"
import { Autocomplete, TextField, TextFieldVariants, TextFieldProps, styled } from "@mui/material"
import { CurrencyCodes, Currencies, CurrencyI } from "~/types"

interface CurrencySelectorProps {
    value: CurrencyCodes | null
    disabled?: boolean
    onChange: (value: CurrencyI | null) => void
    label?: string
    variant?: TextFieldVariants
    className?: string
    editMode?: boolean
}

const CurrencyContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
})

const CurrencyLabel = styled("div")({
    color: "var(--color-light-grey)",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
})

const CurrencyValue = styled("div")({
    color: "var(--color-black)",
})

export const CurrencySelector: React.FC<CurrencySelectorProps> = ({
    className,
    value,
    onChange,
    label = "Currency",
    variant = "filled",
    disabled = false,
    editMode = false,
}) => {
    const currencyValue = Currencies.find((currency) => currency.code === value) || null

    const getOptionLabel = (option: CurrencyI | null): string => {
        return option ? `${option.name} (${option.code})` : ""
    }

    const isOptionEqualToValue = (option: CurrencyI, value: CurrencyI) => option.code === value?.code

    const handleChange = (_: React.SyntheticEvent<Element, Event>, newValue: CurrencyI | null): void => {
        onChange(newValue)
    }

    const renderInput = (params: TextFieldProps) => <TextField {...params} label={label} variant={variant} />

    if (!editMode) {
        return (
            <CurrencyContainer>
                <CurrencyLabel>{label}</CurrencyLabel>
                <CurrencyValue>{getOptionLabel(currencyValue)}</CurrencyValue>
            </CurrencyContainer>
        )
    }

    return (
        <Autocomplete
            className={className}
            options={Currencies}
            getOptionLabel={getOptionLabel}
            value={currencyValue}
            disabled={disabled}
            onChange={handleChange}
            renderInput={renderInput}
            fullWidth
            isOptionEqualToValue={isOptionEqualToValue}
        />
    )
}
