import { UserI } from "~/types"
import { Location } from "react-router-dom"
import { Callback, EventProperties, SegmentEvent } from "@segment/analytics-next"
import { useCallback } from "react"

function useSegment() {
    const segmentEnabled = !!window.analytics
    const segmentIdentify = useCallback(
        (userId: string, user: UserI) => {
            if (segmentEnabled) {
                window.analytics.identify(userId, {
                    name: user.fullName,
                    email: user.email,
                    company: {
                        name: undefined, // TODO: complete with user current organization
                    },
                })
            }
        },
        [segmentEnabled]
    )

    const segmentPage = useCallback(
        (location: Location, referer: string | null) => {
            if (segmentEnabled) {
                window.analytics.page(
                    "",
                    location.pathname,
                    {},
                    {
                        context: {
                            page: {
                                referrer: referer ?? "",
                            },
                        },
                    }
                )
            }
        },
        [segmentEnabled]
    )

    const segmentTrack = useCallback(
        (eventName: string | SegmentEvent, properties: Callback | EventProperties | undefined) => {
            if (segmentEnabled) {
                window.analytics.track(eventName, properties, {})
            }
        },
        [segmentEnabled]
    )

    const segmentLogout = useCallback(() => {
        if (segmentEnabled) {
            window.analytics.reset()
        }
    }, [segmentEnabled])

    return {
        segmentIdentify,
        segmentTrack,
        segmentPage,
        segmentLogout,
    }
}

export { useSegment }
