import { useCallback } from "react"
import * as Sentry from "@sentry/react"
import { useCommunicationApi } from "../communicationApi"
import { MessageI, RoomId } from "../types"
import { pipeResult } from "~/core/Result"
import { communicationActions } from "../store/CommunicationSlice"
import { useAppDispatch } from "~/store/hooks"
import { toast } from "react-toastify"

export const useTogglePinMessage = (message: MessageI, roomId: RoomId) => {
    const communicationApi = useCommunicationApi()
    const dispatch = useAppDispatch()
    return useCallback(async () => {
        const result = await communicationApi.updateMessage(message.id, { isPinned: !message.isPinned })
        pipeResult(
            result,
            (updatedMessage) => {
                dispatch(communicationActions.addMessageInRoom({ roomId, message: updatedMessage }))
                if (updatedMessage.isPinned) {
                    dispatch(communicationActions.pinMessage({ roomId, message: updatedMessage }))
                } else {
                    dispatch(communicationActions.unpinMessage({ roomId, messageId: updatedMessage.id }))
                }
            },
            (error) => {
                Sentry.captureException(`Failed to update message: ${error}`, { extra: { message, error } })
                toast.error("Failed to update message")
            }
        )
    }, [message, communicationApi, roomId])
}
