import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "~/store/hooks"
import { documentApi } from "../Api/DocumentApi"
import { DocumentDataI } from "../Documents"
import { documentActions, selectDocuments } from "../store/documentSlice"

type FetchDocumentsResult = {
    documents: DocumentDataI[]
    loading: boolean
    loadingUpload: boolean
    error: string | null
}

export const useFetchObjectDocuments = (objectId: string) => {
    const dispatch = useDispatch()
    const { documents, loadingUpload, error } = useAppSelector(selectDocuments)
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        if (!objectId) {
            dispatch(documentActions.fetchDocumentsSuccess([]))
        } else {
            setLoading(true)
            documentApi
                .findObjectDocuments(objectId)
                .then((data) => {
                    dispatch(documentActions.fetchDocumentsSuccess(data))
                })
                .catch((error) => {
                    console.error(error)
                    dispatch(documentActions.fetchDocumentsFailed(`${error}`))
                }).finally(() => {
                setLoading(false)
            })
        }
    }, [dispatch, objectId])

    return useMemo((): FetchDocumentsResult => {
        return { documents, loading, loadingUpload, error }
    }, [documents, loading, loadingUpload, error])
}
