import { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"
import { OrganizationId } from "~/types"
import { defineMessages, useIntl } from "react-intl"
import { useAppSelector } from "~/store/hooks"
import { rolesPermissionsActions, selectSharedObjects, selectSharedObjectsLoading } from "../rolesPermissionsSlice"
import { rolesPermissionsApi } from "~/api/rolesPermissionsApi"
import { SharedObjectWithName } from "~/types/SharedObjects"
import { userApi } from "~/api"

const messages = defineMessages({
    error: {
        id: "share.object.list.errorFetch",
        defaultMessage: "We can't retrieve the shared objects. Please contact your administrator",
    },
})

export const useFetchSharedObjects = (organizationId?: OrganizationId): SharedObjectWithName[] => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const sharedObjects = useAppSelector(selectSharedObjects)
    const loading = useAppSelector(selectSharedObjectsLoading)

    const fetchSharedObjects = useCallback(async () => {
        try {
            if (!loading && organizationId && !sharedObjects.length) {
                dispatch(rolesPermissionsActions.fetchSharedObjects())
                const sharedObjects = await rolesPermissionsApi.fetchAllSharedObjects(organizationId)

                const userIds = sharedObjects.map((sharedObject) => sharedObject.userId)
                const users = await userApi.findUsersByIds([...new Set(userIds)])

                const sharedObjectsWithNames = sharedObjects.map((sharedObject) => {
                    const user = users.find((user) => user.id === sharedObject.userId)
                    return {
                        ...sharedObject,
                        userFullName: user?.fullName ?? "",
                        userEmail: user?.email ?? "",
                    }
                })

                dispatch(rolesPermissionsActions.fetchSharedObjectsSuccess(sharedObjectsWithNames))
                return sharedObjects
            } else {
                return sharedObjects
            }
        } catch (error) {
            dispatch(rolesPermissionsActions.fetchSharedObjectsFailed(formatMessage(messages.error)))
        }
    }, [organizationId])

    useEffect(() => {
        fetchSharedObjects()
    }, [fetchSharedObjects])

    return sharedObjects
}
