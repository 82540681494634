import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "~/store"
import { paymentState } from "./paymentState"
import { BankConnectorsI, ExecutionDate, InitiatePaymentPayload, PaymentStatus } from "~/types"

const initialState = paymentState

const paymentSlice = createSlice({
    name: "payment",
    initialState: initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        initPayment(state, _action: PayloadAction<InitiatePaymentPayload>) {
            state.paymentInProgress = true
            state.status = PaymentStatus.INITIATED
        },
        getBankConnectors(state) {
            state.loading = true
        },
        getBankConnectorsSuccess(state, action: PayloadAction<BankConnectorsI[]>) {
            state.loading = false
            state.bankConnectors = action.payload
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getBankConnectorsFailed(state, _action: PayloadAction<string>) {
            state.loading = false
        },
        setStatus(state, action: PayloadAction<PaymentStatus>) {
            state.status = action.payload
        },
        setUpdateTimestamp(state, action: PayloadAction<string>) {
            state.updateTimestamp = action.payload
        },
        setPaymentSuccess(state) {
            state.paymentInProgress = false
            state.status = PaymentStatus.EXECUTED
        },
        setPaymentValidated(state) {
            state.paymentInProgress = false
            state.status = PaymentStatus.VALIDATED
        },
        setPaymentFailed(state) {
            state.paymentInProgress = false
            state.status = PaymentStatus.FAILED
        },
        setPaymentScheduled(state) {
            state.paymentInProgress = false
            state.status = PaymentStatus.SCHEDULED
        },
        setPaymentCanceled(state) {
            state.paymentInProgress = false
            state.status = PaymentStatus.CANCELLED
        },
        setScheduleDate(state, action: PayloadAction<Date | null>) {
            state.scheduleDate = action.payload
        },
        setBankConnectorSelected(state, action: PayloadAction<BankConnectorsI | null>) {
            state.bankConnectorSelected = action.payload
        },
        setExecutionDateSelected(state, action: PayloadAction<ExecutionDate | null>) {
            state.executionDateSelected = action.payload
        },
        setPaymentUrl(state, action: PayloadAction<string>) {
            state.paymentUrl = action.payload
        },
        setInitWs(state) {
            state.initWs = !state.initWs
        },
        resetState() {
            return initialState
        },
    },
})

export const paymentActions = paymentSlice.actions

export const selectBankConnectors = (state: RootState) => state.payment.bankConnectors
export const selectBankConnectorSelected = (state: RootState) => state.payment.bankConnectorSelected
export const selectPaymentStatus = (state: RootState) => state.payment.status
export const selectPaymentUpdateTimestamp = (state: RootState) => state.payment.updateTimestamp
export const selectScheduleDate = (state: RootState) => state.payment.scheduleDate
export const selectExecutionDateSelected = (state: RootState) => state.payment.executionDateSelected
export const selectPaymentUrl = (state: RootState) => state.payment.paymentUrl
export const selectPaymentInProgress = (state: RootState) => state.payment.paymentInProgress
export const selectPaymentInitWs = (state: RootState) => state.payment.initWs

const paymentReducer = paymentSlice.reducer
export default paymentReducer
