import { call, put, select } from "redux-saga/effects"
import { invoiceApi } from "~/api"
import { invoiceActions, selectInvoice } from "~/store/invoice/invoiceSlice"
import { InvoiceI } from "~/types"

export function* markAsResolved() {
    try {
        const invoice: InvoiceI | null = yield select(selectInvoice)
        if (invoice) {
            yield call(invoiceApi.markAsResolved, invoice.id)
            yield put(invoiceActions.markAsResolvedSuccess())
        } else {
            yield put(invoiceActions.markAsResolvedFailed(`No invoice to be marked as resolved`))
        }
    } catch (error) {
        console.error("failed to mark invoice as resolved", error)
        yield put(invoiceActions.markAsResolvedFailed(`${error}`))
    }
}
