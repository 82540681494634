import React, { Suspense, lazy } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/features/auth/ProtectedRoute"
import { EMAILS_INBOX } from "./routes"
import { Loader } from "~/components"

const EmailInbox = lazy(() =>
    import("./pages/EmailInbox").then(({ EmailInbox }) => ({
        default: EmailInbox,
    }))
)

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="prs_route">
            <Route
                path={EMAILS_INBOX}
                element={
                    <Suspense fallback={<Loader />}>
                        <EmailInbox />
                    </Suspense>
                }
            />
        </Route>,
    ],
}
