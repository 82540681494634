import { AxiosResponse } from "axios"
import axiosClient from "../../../../api/axiosClient"
import {
    CreatePurchaseOrderDTO,
    CreatePurchaseOrderResponse,
    PurchaseOrderPDFDTO,
    PurchaseOrders,
    PurchaseOrdersResponseV2,
    ListPurchaseOrdersResponse,
    CreatePurchaseOrderDTOV2,
    UpdatePurchaseOrderDTOV2,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { ImportBatchResponseI } from "~/types/ImportBatch"
import { Result } from "~/core/Result"
import fileDownload from "js-file-download"
import {
    convertPOV2toPO,
    convertPOtoPOV2,
    convertPOtoPOV2ForUpdate,
} from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { Languages } from "~/store/global/globalSlice"

const BASE_URL = import.meta.env.VITE_API_PURCHASE_URL + "organizations/"
const BASE_URL_V2 = import.meta.env.VITE_API_PURCHASE_URL_V2 + "organizations/"
const IMPORT_BASE_URL = import.meta.env.VITE_API_IMPORT_FROM_FILES_URL
const PO_TO_PDF_URL = import.meta.env.VITE_API_PO_TO_PDF_URL

export const purchaseOrdersApi = {
    create(organizationId: string, purchaseOrder: CreatePurchaseOrderDTO): Promise<CreatePurchaseOrderResponse> {
        const url = `${BASE_URL_V2}${organizationId}/purchase-orders`
        const purchaseOrderV2: CreatePurchaseOrderDTOV2 = convertPOtoPOV2(purchaseOrder)

        return axiosClient.post(url, purchaseOrderV2)
    },
    async findAll(organizationId: string): Promise<ListPurchaseOrdersResponse> {
        const url = `${BASE_URL_V2}${organizationId}/purchase-orders`
        const purchaseOrdersV2: PurchaseOrdersResponseV2[] = await axiosClient.get(url)
        const purchaseOrders: ListPurchaseOrdersResponse = purchaseOrdersV2.map(convertPOV2toPO)

        return purchaseOrders
    },
    async findById(organizationId: string, purchaseOrderId: string): Promise<PurchaseOrders> {
        const url = `${BASE_URL_V2}${organizationId}/purchase-orders/${purchaseOrderId}`
        const purchaseOrderV2: PurchaseOrdersResponseV2 = await axiosClient.get(url)
        const purchaseOrder: PurchaseOrders = convertPOV2toPO(purchaseOrderV2)

        return purchaseOrder
    },
    delete(organizationId: string, purchaseOrderId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseOrderId}`
        return axiosClient.delete(url)
    },
    update(organizationId: string, purchaseOrderId: string, purchaseOrder: PurchaseOrders): Promise<AxiosResponse> {
        const url = `${BASE_URL_V2}${organizationId}/purchase-orders/${purchaseOrderId}`
        const purchaseOrderV2: UpdatePurchaseOrderDTOV2 = convertPOtoPOV2ForUpdate(purchaseOrder)

        return axiosClient.put(url, purchaseOrderV2)
    },
    submit(organizationId: string, purchaseOrderId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseOrderId}/submit`
        return axiosClient.post(url)
    },
    approve(organizationId: string, purchaseOrderId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseOrderId}/approve`
        return axiosClient.post(url)
    },
    reject(organizationId: string, purchaseOrderId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseOrderId}/reject`
        return axiosClient.post(url)
    },
    send(organizationId: string, purchaseRequestId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseRequestId}/send`
        return axiosClient.post(url)
    },
    unSend(organizationId: string, purchaseRequestId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseRequestId}/unSend`
        return axiosClient.post(url)
    },
    dispatched(organizationId: string, purchaseRequestId: string, note?: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseRequestId}/dispatched`
        return axiosClient.post(url, { note: note ?? null })
    },
    delivered(organizationId: string, purchaseRequestId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseRequestId}/delivered`
        return axiosClient.post(url)
    },
    received(organizationId: string, purchaseRequestId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseRequestId}/received`
        return axiosClient.post(url)
    },
    lineApprove(organizationId: string, purchaseOrderId: string, lineId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseOrderId}/lines/${lineId}/approve`
        return axiosClient.post(url)
    },
    lineReject(organizationId: string, purchaseOrderId: string, lineId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseOrderId}/lines/${lineId}/reject`
        return axiosClient.post(url)
    },
    lineDispatched(
        organizationId: string,
        purchaseOrderId: string,
        lineId: string,
        note?: string
    ): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseOrderId}/lines/${lineId}/dispatched`
        return axiosClient.post(url, { note: note ?? null })
    },
    lineDelivered(organizationId: string, purchaseOrderId: string, lineId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseOrderId}/lines/${lineId}/delivered`
        return axiosClient.post(url)
    },
    lineReceived(organizationId: string, purchaseOrderId: string, lineId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-orders/${purchaseOrderId}/lines/${lineId}/received`
        return axiosClient.post(url)
    },
    importBatchPO(organizationId: string, attachedFile: File): Promise<Result<ImportBatchResponseI>> {
        const formData = new FormData()
        formData.append("file", attachedFile)
        formData.append("organizationId", organizationId)

        return axiosClient.post(`${IMPORT_BASE_URL}purchase-order/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
    toPDF(payload: PurchaseOrderPDFDTO) {
        const url = `${PO_TO_PDF_URL}pdf`
        return axiosClient
            .post(url, payload, {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "blob",
            })
            .then((response) => {
                const { buyer, supplier, purchaseOrderId, language } = payload
                const prefix = language === Languages.FR ? "Bon de commande" : "Purchase Order"
                const fileName: string = `[${prefix}]${buyer.name}-${supplier.name}-${purchaseOrderId}.pdf`

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fileDownload(response, fileName)
            })
            .catch((error) => {
                console.error(error)
            })
    },
}
