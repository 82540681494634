import React, { useCallback, useEffect, useMemo, useState } from "react"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"
import { Button, Loader, Modal, Size } from "~/components"
import { StyledTextField } from "~/domains/identity/features/organizations/components/ModalOrganizationSelectorDetails/StyledTextField"
import { SharedObjectType, SharedObjectWithName } from "~/types/SharedObjects"
import { OrganizationId, ViewTypeI } from "~/types"
import { useCreateSharedObject } from "~/domains/identity/store/hooks/useCreateSharedObject"
import { ErrorMessage } from "~/components/ErrorMessage"
import { getOrCreateUserByEmail } from "~/store/users/utils"
import { useFetchSharedObjects } from "~/domains/identity/store/hooks/useFetchSharedObjects"
import { Trash2 } from "react-feather"
import { ConfirmModal } from "~/components/ConfirmModal"
import { useDeleteSharedObject } from "~/domains/identity/store/hooks/useDeleteSharedObject"
import { MenuItem } from "@mui/material"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"
import { useAppSelector } from "~/store/hooks"
import { selectPurchaseViewType } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { selectPartnersBrandNames } from "~/domains/transactions/book-of-relations/store/bookOfRelationsSlice"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { PartnerProfileContactI } from "~/domains/transactions/book-of-relations/types"
import { selectInputVariant } from "~/store/global/globalSlice"
import { PartnerContactsSelector } from "~/domains/transactions/book-of-relations/components/PartnerSelectors"
import "./ShareObjectModal.scss"
import { usePartnerContactSave } from "~/domains/transactions/book-of-relations/store/hooks"

const messages = defineMessages({
    title: { id: "share.object.modal.title", defaultMessage: "Share {objectType}" },
    email: { id: "share.object.modal.email", defaultMessage: "Email" },
    supplierEmail: { id: "share.object.modal.supplierEmail", defaultMessage: "Supplier email" },
    buyerEmail: { id: "share.object.modal.buyerEmail", defaultMessage: "Buyer email" },
    selectOrganization: { id: "share.object.modal.selectOrganization", defaultMessage: "Select company" },
    cancel: { id: "share.object.modal.cancel", defaultMessage: "Cancel" },
    share: { id: "share.object.modal.share", defaultMessage: "Share" },
    supplier: {
        id: "purchase.organization.supplier",
        defaultMessage: "Supplier",
    },
    buyer: {
        id: "purchase.organization.buyer",
        defaultMessage: "Buyer",
    },
    confirmDeleteSharing: {
        id: "share.object.modal.confirmDeleteSharing",
        defaultMessage: "Are you sure you want to delete this sharing?",
    },
    confirmDeleteTitle: {
        id: "share.object.modal.deleteSharing",
        defaultMessage: "Delete a sharing",
    },
})

export const sharedObjectMessages: Record<SharedObjectType, MessageDescriptor> = {
    [SharedObjectType.PurchaseRequest]: {
        id: "share.object.modal.purchaseRequest",
        defaultMessage: "purchase request",
    },
    [SharedObjectType.PurchaseOrder]: {
        id: "share.object.modal.purchaseOrder",
        defaultMessage: "purchase order",
    },
    [SharedObjectType.Invoice]: {
        id: "share.object.modal.invoice",
        defaultMessage: "invoice",
    },
    [SharedObjectType.Partnership]: {
        id: "share.object.modal.partnership",
        defaultMessage: "partnership",
    },
}

interface Props {
    open: boolean
    close: () => void
    organizationId: OrganizationId
    objectType: SharedObjectType
    objectId: string
    buyerOrganization: OrganizationSummary | undefined
    supplierOrganization: OrganizationSummary | undefined
    onlyEmail?: boolean
    sendShareEmail: boolean
    callback?: () => void
}

export const ShareObjectModal: React.FC<Props> = ({
    open,
    close,
    organizationId,
    objectType,
    objectId,
    buyerOrganization,
    supplierOrganization,
    onlyEmail,
    sendShareEmail = true,
    callback,
}) => {
    const { formatMessage } = useIntl()
    const [sharedEmail, setSharedEmail] = useState<string>("")
    const [targetOrganizationId, setTargetOrganizationId] = useState<OrganizationId>("")
    const [partnerId, setPartnerId] = useState<OrganizationId | null>(null)
    const [selectedContact, setSelectedContact] = useState<PartnerProfileContactI | null>(null)
    const [confirmDeleteSharing, setConfirmDeleteSharing] = useState<boolean>(false)
    const [currentSharing, setCurrentSharing] = useState<SharedObjectWithName | null>(null)
    const [loadingTimeout, setLoadingTimeout] = useState<boolean>(false)
    const organizationSharings = useFetchSharedObjects(organizationId)
    const { createSharedObject, error, loading } = useCreateSharedObject(organizationId)
    const { deleteSharedObject, error: errorDelete, loading: loadingDelete } = useDeleteSharedObject(organizationId)
    const viewType = useAppSelector(selectPurchaseViewType)
    const brandNames = useAppSelector(selectPartnersBrandNames)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const inputVariant = useAppSelector(selectInputVariant)

    const objectSharings = useMemo(
        () => organizationSharings.filter((share) => share.objectId === objectId && share.objectType === objectType),
        [organizationSharings, objectId, objectType]
    )

    useEffect(() => {
        const partnerIdFromViewType = viewType === ViewTypeI.buyer ? supplierOrganization?.id : buyerOrganization?.id
        setPartnerId(partnerIdFromViewType ?? null)
    }, [buyerOrganization?.id, supplierOrganization?.id, viewType])

    const handleClose = useCallback(() => {
        close()
        setSharedEmail("")
        setTargetOrganizationId("")
    }, [close, setSharedEmail, setTargetOrganizationId])

    const handleChangeTargetOrganization = useCallback(
        (event: React.ChangeEvent<{ value: string }>) => {
            setTargetOrganizationId(event.target.value)
        },
        [setTargetOrganizationId]
    )

    const handleDeleteSharing = useCallback(async () => {
        if (currentSharing) {
            await deleteSharedObject(currentSharing.id)
            setCurrentSharing(null)
            return Promise.resolve(true)
        }

        setCurrentSharing(null)
        return Promise.resolve(false)
    }, [confirmDeleteSharing])

    const handleContactSave = usePartnerContactSave(currentOrganizationId, partnerId)

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            if (!sharedEmail.length) {
                return
            }
            setLoadingTimeout(true)
            try {
                const user = await getOrCreateUserByEmail({ email: sharedEmail })
                if (selectedContact && targetOrganizationId !== currentOrganizationId) {
                    handleContactSave(selectedContact)
                }
                setTimeout(async () => {
                    const sharedUserOrganizationId =
                        onlyEmail && supplierOrganization && buyerOrganization
                            ? viewType === ViewTypeI.buyer
                                ? supplierOrganization?.id
                                : buyerOrganization?.id
                            : targetOrganizationId
                    await createSharedObject(
                        sharedUserOrganizationId as string,
                        objectId,
                        objectType,
                        user.userId,
                        sharedEmail,
                        sendShareEmail
                    )

                    setSharedEmail("")
                    setTargetOrganizationId("")
                    setSelectedContact(null)
                    if (callback) {
                        return setTimeout(() => {
                            setLoadingTimeout(false)
                            callback()
                        }, 500)
                    }
                    setLoadingTimeout(false)
                }, 500)
            } catch (e) {
                console.error(e)
            }
        },
        [
            objectType,
            objectId,
            sharedEmail,
            organizationId,
            targetOrganizationId,
            onlyEmail,
            viewType,
            buyerOrganization,
            supplierOrganization,
            sendShareEmail,
            createSharedObject,
            callback,
        ]
    )

    const renderSharedObject = (share: SharedObjectWithName) => {
        return (
            <div key={share.id} className="shared-object">
                <span>
                    {share.userFullName && share.userFullName !== "" ? `${share.userFullName} - ` : ""}
                    {share.userEmail}
                </span>
                <Trash2 size={18} onClick={() => showConfirmDeleteSharing(share)} />
            </div>
        )
    }

    const showConfirmDeleteSharing = useCallback(
        (sharing: SharedObjectWithName) => {
            setConfirmDeleteSharing(true)
            setCurrentSharing(sharing)
        },
        [setConfirmDeleteSharing]
    )
    const hideConfirmDeleteSharing = useCallback(() => {
        setConfirmDeleteSharing(false)
    }, [setConfirmDeleteSharing])

    const getOrganizationName = (name: string, organizationId?: string | null) => {
        const fallbackName = name ?? ""
        if (!organizationId) return fallbackName
        return brandNames[organizationId] ?? fallbackName
    }

    const onPartnerContactChange = useCallback(
        (contact: PartnerProfileContactI | null) => {
            setSelectedContact(contact)
            setSharedEmail(contact?.email ?? "")
        },
        [setSelectedContact, setSharedEmail]
    )

    return (
        <>
            <Modal open={open} onClose={handleClose} size={Size.SM}>
                <Modal.Header>
                    <h4>
                        {formatMessage(messages.title, {
                            objectType: formatMessage(sharedObjectMessages[objectType]),
                        })}
                    </h4>
                </Modal.Header>
                <form onSubmit={onSubmit}>
                    <Modal.Content>
                        {loading || loadingDelete || loadingTimeout ? (
                            <Loader />
                        ) : (
                            <div className="modal-shared-body">
                                {!onlyEmail && buyerOrganization && supplierOrganization ? (
                                    <StyledTextField
                                        className="modal-shared-select-organization"
                                        label={formatMessage(messages.selectOrganization)}
                                        value={targetOrganizationId}
                                        onChange={handleChangeTargetOrganization}
                                        select
                                        required
                                        variant={inputVariant}
                                    >
                                        {[buyerOrganization, supplierOrganization].map((option, index) => (
                                            <MenuItem key={option.id as string} value={option.id as string}>
                                                {getOrganizationName(option.name, option.id)} -{" "}
                                                {index === 0
                                                    ? formatMessage(messages.buyer)
                                                    : formatMessage(messages.supplier)}
                                            </MenuItem>
                                        ))}
                                    </StyledTextField>
                                ) : null}
                                <div className="modal-shared-partner-contacts">
                                    {(targetOrganizationId || onlyEmail) && (
                                        <PartnerContactsSelector
                                            currentOrganizationId={currentOrganizationId}
                                            partnerId={
                                                partnerId === targetOrganizationId || onlyEmail ? partnerId : null
                                            }
                                            onContactChange={onPartnerContactChange}
                                            selectedContact={selectedContact}
                                        />
                                    )}
                                </div>
                                <ErrorMessage>{error}</ErrorMessage>
                                <ErrorMessage>{errorDelete}</ErrorMessage>
                                <div className="shared-objects">
                                    {objectSharings?.map((share) => renderSharedObject(share))}
                                </div>
                            </div>
                        )}
                    </Modal.Content>
                    <Modal.Footer>
                        <Button type="neutral" buttonType="button" onClick={handleClose}>
                            {formatMessage(messages.cancel)}
                        </Button>
                        <Button type="primary" buttonType="submit" disabled={loading || !sharedEmail.length}>
                            {formatMessage(messages.share)}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <ConfirmModal
                open={confirmDeleteSharing}
                close={hideConfirmDeleteSharing}
                onConfirm={handleDeleteSharing}
                title={formatMessage(messages.confirmDeleteTitle)}
            >
                {formatMessage(messages.confirmDeleteSharing)}
            </ConfirmModal>
        </>
    )
}
