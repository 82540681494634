import { toast } from "react-toastify"
import { commonMessages } from "~/common-messages"
import { documentApi } from "../Api/DocumentApi"
import { DocumentErrorResponseI, DocumentSuccessResponseI, DuplicateDocumentPayload } from "../Documents"
import { useCallback } from "react"
import { useIntl } from "react-intl"

export const useDuplicateDocumentLinks = () => {
    const { formatMessage } = useIntl()

    const duplicateDocumentLinks = useCallback(
        async (
            payload: DuplicateDocumentPayload
        ): Promise<DocumentSuccessResponseI | DocumentErrorResponseI | undefined> => {
            if (!payload.sourceObjectId || !payload.targetObjectId) return
            try {
                return await documentApi.duplicateDocumentLink(payload)
            } catch (error) {
                console.error(error)
                toast.error(formatMessage(commonMessages.errorContact))
            }
        },
        []
    )

    return { duplicateDocumentLinks }
}
