import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId, ViewTypeI } from "~/types"
import {
    purchaseOrdersActions,
    selectPurchaseOrders,
    selectPurchaseOrdersError,
    selectPurchaseOrdersLoading,
} from "../purchaseOrdersSlice"
import { purchaseOrdersApi } from "../../api"
import { organizationApi, userApi } from "~/api"
import { defineMessages, useIntl } from "react-intl"
import { isDefined } from "~/utils/isDefined"

const messages = defineMessages({
    error: {
        id: "purchase.orders.list.errorFetch",
        defaultMessage: "We can't retrieve the purchase orders. Please contact your administrator",
    },
})

export const useFetchOrganizationPurchaseOrders = (view?: ViewTypeI | string, organizationId?: OrganizationId) => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const purchaseOrders = useAppSelector(selectPurchaseOrders)
    const loading = useAppSelector(selectPurchaseOrdersLoading)
    const error = useAppSelector(selectPurchaseOrdersError)

    const fetchPurchaseOrders = useCallback(
        async (withNames: boolean = true) => {
            if (!organizationId) {
                return null
            }
            try {
                dispatch(purchaseOrdersActions.fetchPOs())
                const purchaseOrders = await purchaseOrdersApi.findAll(organizationId)

                if (withNames) {
                    const organizationIdsSet: Set<string> = new Set()
                    purchaseOrders.forEach((po) => {
                        organizationIdsSet.add(po.buyerId)
                        organizationIdsSet.add(po.supplierId)
                    })

                    const organizationIds: string[] = Array.from(organizationIdsSet)
                    const userIds = purchaseOrders
                        .map((purchaseItem) =>
                            purchaseItem.requesterUserId && purchaseItem.requesterUserId !== ""
                                ? purchaseItem.requesterUserId
                                : null
                        )
                        .filter(isDefined)

                    const organizations = organizationIds.length
                        ? await organizationApi.fetchOrganizationsByIds([...new Set(organizationIds)])
                        : []
                    const users = userIds.length ? await userApi.findUsersByIds([...new Set(userIds)]) : []

                    const POWithNames = purchaseOrders.map((po) => {
                        const supplier = organizations.find((organization) => organization.id === po.supplierId)
                        const buyer = organizations.find((organization) => organization.id === po.buyerId)
                        const user = users.find((user) => user.id === po.requesterUserId)
                        return {
                            ...po,
                            supplierName: supplier?.name ?? "",
                            buyerName: buyer?.name ?? "",
                            requesterName: user?.fullName ?? "",
                        }
                    })

                    const POWithNamesFiltered = view
                        ? POWithNames.filter((po) => {
                              if (view === ViewTypeI.supplier) {
                                  return po.supplierId === organizationId
                              }
                              return po.buyerId === organizationId
                          })
                        : POWithNames
                    dispatch(purchaseOrdersActions.fetchPOsSuccess(POWithNamesFiltered))
                    return POWithNamesFiltered
                } else {
                    const POWithFakeNames = purchaseOrders.map((po) => {
                        return {
                            ...po,
                            supplierName: "",
                            buyerName: "",
                            requesterName: "",
                        }
                    })

                    dispatch(purchaseOrdersActions.fetchPOsSuccess(POWithFakeNames))
                    return POWithFakeNames
                }
            } catch (error) {
                dispatch(purchaseOrdersActions.fetchPOsFailed(formatMessage(messages.error)))
            }
        },
        [organizationId, view, dispatch, formatMessage]
    )

    return { purchaseOrders, loading, error, fetchPurchaseOrders }
}
