export enum PurchaseViewType {
    "ADD" = "ADD",
    "EDIT" = "EDIT",
    "VIEW" = "VIEW",
}

export interface CreatePurchaseResponse {
    id: string
}

export enum PurchaseType {
    REQUEST = "REQUEST",
    ORDER = "ORDER",
}
