import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { catalogActions, selectProductsVersionsByContext } from "../store/catalogSlice"
import { useCallback } from "react"

type ReturnType = [string, (query: string) => void]

const defaultQuery = ""

export const useProductVersionsQueryFilter = (context: string): ReturnType => {
    const productsVersionsByContext = useAppSelector(selectProductsVersionsByContext)
    const state = productsVersionsByContext[context]
    const dispatch = useAppDispatch()
    const updateQuery = useCallback(
        (query: string) => {
            dispatch(catalogActions.setCatalogQueryFilter({ context, query }))
        },
        [context, dispatch]
    )
    return [state?.query ?? defaultQuery, updateQuery]
}
