export const mergeDeep = <T extends object>(target: T, ...sources: T[]): T => {
    if (!sources.length) return target
    const source = sources.shift()

    if (source && isJSON(target) && isJSON(source)) {
        for (const [k, v] of Object.entries(source)) {
            if (isJSON(v)) {
                if (!target[k]) {
                    Object.assign(target, { [k]: {} })
                }
                mergeDeep(target[k], v)
            } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                Object.assign(target, { [k]: v } as Partial<T>)
            }
        }
    }

    return mergeDeep(target, ...sources)
}

export const isJSON = (item: unknown) => {
    return item && typeof item === "object" && !Array.isArray(item)
}

export const removeNull = <T extends object>(obj: T) => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([, value]) => value || value !== "")
            .map(([key, value]) => [key, value === Object(value) ? removeNull(value) : value])
    )
}
