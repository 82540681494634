import axios, { AxiosError, AxiosResponse } from "axios"
import { getJWT, getLanguageCookie } from "~/utils"
import { store } from "~/store"

const API_URL = import.meta.env.VITE_API_INVOICES_URL
const axiosClient = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
})

const defaultLanguage = "en"

axiosClient.interceptors.request.use(
    async (config) => {
        const jwt = await getJWT()
        if (jwt) {
            config.headers.Authorization = `Bearer ${jwt}`
        }
        config.headers["Accept-Language"] = getLanguageCookie() ??
            defaultLanguage
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

interface ErrorResponseMessage {
    message?: string
    localizedError?: string
    localizedMessage?: string
    error?: string
}

type ErrorResponse = ErrorResponseMessage | string

axiosClient.interceptors.response.use(
    (response: AxiosResponse) => {
        return response.data
    },
    (error: AxiosError<ErrorResponse>) => {
        const code = error?.response?.status ?? error.code ?? null
        const message =
            error?.response?.data instanceof Object ? `${error?.response?.data?.message}` : error.message ?? null
        if (code === 401) {
            store.dispatch({
                type: "errors/setErrorAuth",
                payload: true,
            })
            return Promise.reject(message)
        }

        const errorMessage = getErrorMessage(error, message)
        if (code === 404) {
            return Promise.reject(errorMessage + " - Not found - 404")
        }

        store.dispatch({
            type: "errors/setError",
            payload: errorMessage,
        })
        sendErrorToSegment(error, errorMessage, code)
        return Promise.reject(errorMessage)
    }
)

function getErrorMessage(error: AxiosError<ErrorResponse>, message: string): string {
    if (error?.response?.data instanceof Object && error.response?.data?.localizedError) {
        return error.response.data.localizedError
    }
    if (error?.response?.data instanceof Object && error.response?.data?.localizedMessage) {
        return error.response.data.localizedMessage
    }
    if (error?.response?.data instanceof Object && error.response?.data?.error) {
        return error.response.data.error
    }
    if (typeof error?.response?.data === "string") {
        return error.response.data.length ? error.response.data : "An error occurred"
    }
    if (typeof error?.response?.data === "object") {
        return JSON.stringify(error.response.data)
    }
    if (typeof error === "string") {
        return error ? error : "An error occurred"
    }
    if (typeof message === "string") {
        return message.length ? message : "An error occurred"
    }
    if (typeof message === "object") {
        return JSON.stringify(message)
    }
    return "An error occurred, please retry again ! If the error persists, please contact us"
}

function sendErrorToSegment(error: AxiosError<ErrorResponse>, message: string, code: string | number | null) {
    const segmentEnabled = !!window.analytics
    if (segmentEnabled && code !== 404) {
        window.analytics.track(
            "ERROR",
            {
                message,
                code,
                details: typeof error === "object" ? JSON.stringify(error) : error,
            },
            {}
        )
    }
}

export default axiosClient
