import React, { useCallback } from "react"
import { styled } from "@mui/material"
import { BoltIcon } from "~/components/Icons"
import { PartnerProfileContactI } from "~/domains/transactions/book-of-relations/types"
import { ImportInvoiceCompanyInfoI } from "~/types"

const SuggestedStringContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "8px",
})

const SuggestedString = styled("span")({
    cursor: "pointer",
    height: "22px",
    padding: "0px 4px",
    background: "var(--color-violet-lighter)",
    display: "inline-flex",
    gap: "4px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "var(--color-grey)",
    alignItems: "center",
})

const SuggestedStringContent = styled("span")({
    flex: "1 1 auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
})

const StyledBoltIcon = styled(BoltIcon)({
    flex: "0 0 18px",
})

interface ContactSuggestionsProps {
    suggestedContact: ImportInvoiceCompanyInfoI | undefined | null
    onContactChange: (contact: PartnerProfileContactI | null) => void
}

export const ContactSuggestions = ({ suggestedContact, onContactChange }: ContactSuggestionsProps) => {
    const renderSuggestedString = useCallback(
        (suggestedString: string, contactKey: keyof PartnerProfileContactI) => (
            <SuggestedString
                onClick={() => onContactChange({ [contactKey]: suggestedString } as PartnerProfileContactI)}
            >
                <SuggestedStringContent>{suggestedString}</SuggestedStringContent>
                <StyledBoltIcon />
            </SuggestedString>
        ),
        [onContactChange]
    )

    return (
        <SuggestedStringContainer>
            {suggestedContact?.contactName || suggestedContact?.email ? (
                <>
                    {suggestedContact.contactName && renderSuggestedString(suggestedContact.contactName, "lastName")}
                    {suggestedContact.email && renderSuggestedString(suggestedContact.email, "email")}
                </>
            ) : null}
        </SuggestedStringContainer>
    )
}
