import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { TagsStore } from "./TagsStore"
import { OrganizationId } from "~/types"
import { TagGroupI } from "../types/TagGroup"
import { SelectedTagI, SelectedTagWithObjectIdI, TagI } from "../types"
import { RootState } from "~/store"

const initialState: TagsStore = {
    loadingTagGroups: false,
    tagGroups: null,
    linesTags: [],
    pendingTags: [],
    pendingLineTags: {},
}

const tagsSlice = createSlice({
    name: "tags",
    initialState: initialState,
    reducers: {
        fetchOrganizationTagGroups: (
            state,
            { payload }: PayloadAction<{ organizationId: OrganizationId; loading?: boolean }>
        ) => {
            state.tagsForOrganizationId = payload.organizationId
            state.tagGroups = null
            state.errorLoadingTagGroups = undefined
            state.loadingTagGroups = payload.loading !== false
        },
        fetchOrganizationTagGroupsSuccess: (
            state,
            { payload }: PayloadAction<{ organizationId: OrganizationId; tagGroups: TagGroupI[] }>
        ) => {
            if (state.tagsForOrganizationId === payload.organizationId) {
                state.tagGroups = payload.tagGroups
                state.loadingTagGroups = false
            }
        },
        fetchOrganizationTagGroupsFailed: (
            state,
            { payload }: PayloadAction<{ organizationId: OrganizationId; error: string }>
        ) => {
            if (state.tagsForOrganizationId === payload.organizationId) {
                state.errorLoadingTagGroups = payload.error
                state.loadingTagGroups = false
            }
        },
        createTagGroup: (
            state,
            {
                payload: { organizationId, tagGroup },
            }: PayloadAction<{ organizationId: OrganizationId; tagGroup: TagGroupI }>
        ) => {
            if (state.tagsForOrganizationId === organizationId) {
                state.tagGroups = [...(state.tagGroups ?? []), tagGroup]
            }
        },
        updateTagGroup: (state, { payload: { tagGroup } }: PayloadAction<{ tagGroup: TagGroupI }>) => {
            if (state.tagGroups) {
                state.tagGroups = state.tagGroups.map((stateTagGroup) =>
                    stateTagGroup.tagGroupId === tagGroup.tagGroupId ? tagGroup : stateTagGroup
                )
            }
        },
        deleteTagGroup: (state, { payload: { tagGroup } }: PayloadAction<{ tagGroup: TagGroupI }>) => {
            if (state.tagGroups) {
                state.tagGroups = state.tagGroups.filter(
                    (stateTagGroup) => stateTagGroup.tagGroupId !== tagGroup.tagGroupId
                )
            }
        },
        deleteTagGroups: (state, { payload: { tagGroups } }: PayloadAction<{ tagGroups: TagGroupI[] }>) => {
            if (state.tagGroups) {
                const tagGroupIdsToRemove = tagGroups.map((tagGroup) => tagGroup.tagGroupId)
                state.tagGroups = state.tagGroups.filter(
                    (stateTagGroup) => !tagGroupIdsToRemove.includes(stateTagGroup.tagGroupId)
                )
            }
        },
        addTag: (
            state,
            { payload: { organizationId, tag } }: PayloadAction<{ organizationId: OrganizationId; tag: TagI }>
        ) => {
            if (state.tagsForOrganizationId === organizationId && state.tagGroups) {
                state.tagGroups = state.tagGroups.map((stateTagGroup) =>
                    stateTagGroup.tagGroupId === tag.tagGroupId
                        ? {
                              ...stateTagGroup,
                              tags: [...stateTagGroup.tags, tag],
                          }
                        : stateTagGroup
                )
            }
        },
        updateTag: (
            state,
            { payload: { organizationId, tag } }: PayloadAction<{ organizationId: OrganizationId; tag: TagI }>
        ) => {
            if (state.tagsForOrganizationId === organizationId && state.tagGroups) {
                const existingGroupTagIndex = state.tagGroups.findIndex(
                    (stateTagGroup) => !!stateTagGroup.tags.find((t) => t.tagId === tag.tagId)
                )
                const tabGroupIndex = state.tagGroups.findIndex(
                    (stateTagGroup) => stateTagGroup.tagGroupId === tag.tagGroupId
                )
                if (existingGroupTagIndex !== -1 && tabGroupIndex !== -1 && existingGroupTagIndex !== tabGroupIndex) {
                    const existingGroupTag = state.tagGroups[existingGroupTagIndex]
                    existingGroupTag.tags = existingGroupTag.tags.filter((t) => t.tagId !== tag.tagId)
                    const tabGroup = state.tagGroups[tabGroupIndex]
                    tabGroup.tags.push(tag)
                } else {
                    state.tagGroups = state.tagGroups.map((stateTagGroup) =>
                        stateTagGroup.tagGroupId === tag.tagGroupId
                            ? {
                                  ...stateTagGroup,
                                  tags: stateTagGroup.tags.map((stateTag) =>
                                      stateTag.tagId === tag.tagId ? tag : stateTag
                                  ),
                              }
                            : stateTagGroup
                    )
                }
            }
        },
        deleteTag: (
            state,
            { payload: { organizationId, tag } }: PayloadAction<{ organizationId: OrganizationId; tag: TagI }>
        ) => {
            if (state.tagsForOrganizationId === organizationId && state.tagGroups) {
                state.tagGroups = state.tagGroups.map((stateTagGroup) =>
                    stateTagGroup.tagGroupId === tag.tagGroupId
                        ? {
                              ...stateTagGroup,
                              tags: stateTagGroup.tags.filter((stateTag) => stateTag.tagId !== tag.tagId),
                          }
                        : stateTagGroup
                )
            }
        },
        deleteTags: (
            state,
            { payload: { organizationId, tags } }: PayloadAction<{ organizationId: OrganizationId; tags: TagI[] }>
        ) => {
            if (state.tagsForOrganizationId === organizationId && state.tagGroups) {
                state.tagGroups = state.tagGroups.map((stateTagGroup) => {
                    const tagIdsToRemove = tags
                        .filter((tag) => tag.tagGroupId === stateTagGroup.tagGroupId)
                        .map((tag) => tag.tagId)
                    return tagIdsToRemove.length
                        ? {
                              ...stateTagGroup,
                              tags: stateTagGroup.tags.filter((stateTag) => !tagIdsToRemove.includes(stateTag.tagId)),
                          }
                        : stateTagGroup
                })
            }
        },
        setLinesTags(state, action: PayloadAction<SelectedTagWithObjectIdI[]>) {
            state.linesTags = action.payload
        },
        addNewLinesTags(state, action: PayloadAction<SelectedTagWithObjectIdI[]>) {
            state.linesTags = [...state.linesTags, ...action.payload]
        },
        setPendingTags(state, action: PayloadAction<SelectedTagI[] | ((state: SelectedTagI[]) => SelectedTagI[])>) {
            state.pendingTags = Array.isArray(action.payload) ? action.payload : action.payload(state.pendingTags)
        },
        setPendingLineTags(state, action: PayloadAction<{ lineId: string; tags: SelectedTagI[] }>) {
            state.pendingLineTags[action.payload.lineId] = action.payload.tags
        },
        setPendingLinesTags(state, action: PayloadAction<Record<string, SelectedTagI[]>>) {
            for (const lineId in action.payload) {
                state.pendingLineTags[lineId] = action.payload[lineId]
            }
        },
        resetPendingTags(state) {
            state.pendingTags = []
            state.pendingLineTags = {}
        },
    },
})

export const tagsActions = tagsSlice.actions
export const tagsReducer = tagsSlice.reducer

export const selectTagGroups = (state: RootState) => state.tags.tagGroups
export const selectLinesTags = (state: RootState) => state.tags.linesTags
export const selectPendingTags = (state: RootState) => state.tags.pendingTags
export const selectPendingLineTags = (state: RootState) => state.tags.pendingLineTags
