import { useCallback, useState } from "react"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { toast } from "react-toastify"
import { defineMessages, useIntl } from "react-intl"
import {
    CreatePurchaseRequestDTO,
    PurchaseRequestDetails,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { purchaseRequestApi } from "~/domains/transactions/purchase-requests/api/purchaseRequestsApi"
import { SelectedTagI, SelectedTagWithObjectIdI, TagI } from "~/domains/tags/types"
import { useTagsApi } from "~/domains/tags/tagsApi"
import { selectUser } from "~/store/account/accountSlice"
import { useSavePurchaseRequestPendingLineTags } from "./useSavePurchaseRequestPendingLineTags"
import { useSavePurchaseRequestPendingTags } from "./useSavePurchaseRequestPendingTags"
import { useSavePurchaseRequestPendingCustomFieldValues } from "~/domains/transactions/custom-fields/hooks"
import { selectPendingCustomFieldValues } from "~/domains/transactions/custom-fields/store"
import { CreateCustomFieldPayload } from "~/domains/transactions/custom-fields/types/CreateCustomFieldPayload"
import { selectPendingLineTags, tagsActions } from "~/domains/tags/store/tagsSlice"
import { isDefined } from "~/utils/isDefined"

const messages = defineMessages({
    success: {
        id: "purchase.requests.create.success",
        defaultMessage: "Purchase request created",
    },
    error: {
        id: "purchase.requests.create.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const hasPendingLineTags = (pendingTags: Record<string, TagI[]>, purchaseRequest: CreatePurchaseRequestDTO) =>
    purchaseRequest.lines.some((line) => line.temporaryId && pendingTags[line.temporaryId]?.length > 0)

const hasPendingCustomFieldValues = (
    pendingCustomFieldValues: Record<string, Record<string, CreateCustomFieldPayload>>,
    purchaseRequest: CreatePurchaseRequestDTO
) =>
    purchaseRequest.lines.some(
        (line) => line.temporaryId && Object.values(pendingCustomFieldValues[line.temporaryId] ?? {})?.length > 0
    )

export const addLineIdToTags = (tags: SelectedTagI[], lineId: string): SelectedTagWithObjectIdI[] => {
    return tags.map((tag) => ({ ...tag, objectId: lineId }))
}

export const useCreatePurchaseRequest = (organizationId: OrganizationId) => {
    const dispatch = useAppDispatch()
    const pendingLineTags = useAppSelector(selectPendingLineTags)
    const pendingCustomFieldValues = useAppSelector(selectPendingCustomFieldValues)
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const tagsApi = useTagsApi()
    const user = useAppSelector(selectUser)
    const savePurchaseRequestPendingLineTags = useSavePurchaseRequestPendingLineTags(organizationId)
    const savePurchaseRequestPendingTags = useSavePurchaseRequestPendingTags(organizationId)
    const savePurchaseRequestPendingCustomFieldValues = useSavePurchaseRequestPendingCustomFieldValues(organizationId)

    const createPR = useCallback(
        async (newPR: PurchaseRequestDetails) => {
            try {
                setLoading(true)

                const result = await purchaseRequestApi.create(organizationId, newPR)

                await savePurchaseRequestPendingTags(result.id)

                const hasPendingTags = hasPendingLineTags(pendingLineTags, newPR)
                const hasPendingCustomFields = hasPendingCustomFieldValues(pendingCustomFieldValues, newPR)
                if (hasPendingTags || hasPendingCustomFields) {
                    try {
                        const purchaseRequest = await purchaseRequestApi.findById(organizationId, result.id)
                        await Promise.allSettled([
                            hasPendingTags && savePurchaseRequestPendingLineTags(newPR, purchaseRequest),
                            hasPendingCustomFields &&
                                savePurchaseRequestPendingCustomFieldValues(newPR, purchaseRequest),
                        ])
                        if (hasPendingTags) {
                            const tags = purchaseRequest.lines
                                .flatMap((line, lineIndex) => {
                                    const temporaryId = newPR.lines[lineIndex]?.temporaryId
                                    const tags = temporaryId ? pendingLineTags[temporaryId] : null
                                    return tags && line.id ? addLineIdToTags(tags, line.id) : []
                                })
                                .filter(isDefined)
                            dispatch(tagsActions.setLinesTags(tags))
                            dispatch(tagsActions.resetPendingTags())
                        }
                    } catch (error) {
                        console.error("error")
                    }
                }

                setLoading(false)
                toast.success(formatMessage(messages.success))
                return result
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                toast.error(formatMessage(messages.error))
                throw e
            }
        },
        [
            savePurchaseRequestPendingTags,
            savePurchaseRequestPendingLineTags,
            savePurchaseRequestPendingCustomFieldValues,
            organizationId,
            user,
            pendingLineTags,
            dispatch,
            tagsApi,
        ]
    )

    return {
        createPR,
        error,
        loading,
    }
}
