export const sanitizeIban = (iban: string | undefined | null) => {
    if (!iban) return ""
    return iban.replace(/\s+/g, "")
}

export const displayIban = (sanitizedIban: string | undefined | null) => {
    if (!sanitizedIban) return ""
    return sanitizeIban(sanitizedIban)
        .replace(/(.{4})/g, "$1 ")
        .trim()
}
