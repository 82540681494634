import { useContext } from "react"
import { AxiosInstance } from "axios"
import { OrganizationId } from "~/types"
import { ApiContext } from "~/domains/common/apiClient"
import { CreateRoomOptions, CreateRoomPayload } from "./types/CreateRoom"
import { parseResponseAsArray } from "../common/apiClient/parseResponseAsArray"
import { MessageId, RoomId, parseMessageView, parseRoom } from "./types"
import { CreateMessagePayload } from "./types/CreateMessage"
import { parseResponse } from "../common/apiClient/parseResponse"
import { parseMessagesList } from "./types/parseMessagesList"
import { parseMessage } from "./types/parseMessage"
//import { parseRoom } from "./types/parseRoom"
//import { SharedObjectType } from "~/types/SharedObjects"

const BASE_URL = import.meta.env.VITE_API_COMMUNICATION_URL

class CommunicationApi {
    private static instance: CommunicationApi
    private constructor(private axiosClient: AxiosInstance) {}

    static getInstance(axiosClient: AxiosInstance) {
        if (!CommunicationApi.instance) {
            CommunicationApi.instance = new CommunicationApi(axiosClient)
        }
        return CommunicationApi.instance
    }

    buildUrl(organizationId: OrganizationId, path: string) {
        return `${BASE_URL}organizations/${organizationId}${path}`
    }

    async findRoomsForObjectIds(organizationId: OrganizationId, objectId: string) {
        const url = this.buildUrl(organizationId, `/rooms/object/${objectId}`)
        const response = await this.axiosClient.get(url)
        return parseResponseAsArray(response, parseRoom)
    }

    async createRoom(organizationId: OrganizationId, createPayload: CreateRoomPayload, options?: CreateRoomOptions) {
        const url = `${this.buildUrl(organizationId, "/rooms")}${
            options?.onlySharedRoom ? `?onlySharedRoom=True` : options?.onlyPrivateRoom ? "?onlyPrivateRoom=True" : ""
        }`
        const response = await this.axiosClient.post(url, createPayload)
        return parseResponseAsArray(response, parseRoom)
    }

    async updateRoom(organizationId: OrganizationId, roomId: RoomId, updatePayload: Partial<CreateRoomPayload>) {
        const url = this.buildUrl(organizationId, `/rooms/${roomId}`)
        const response = await this.axiosClient.put(url, updatePayload)
        return parseResponse(response, parseRoom)
    }

    async getMessages(organizationId: OrganizationId, roomId: RoomId, page = 1, perPage = 20) {
        const url = this.buildUrl(organizationId, `/rooms/${roomId}/messages?page=${page}&per_page=${perPage}`)
        const response = await this.axiosClient.get(url)
        return parseResponse(response, parseMessagesList)
    }

    async sendMessage(organizationId: OrganizationId, roomId: RoomId, createMessagePayload: CreateMessagePayload) {
        const url = this.buildUrl(organizationId, `/rooms/${roomId}/messages`)
        const response = await this.axiosClient.post(url, createMessagePayload)
        return parseResponse(response, parseMessage)
    }

    async updateMessage(messageId: MessageId, updatePayload: Partial<CreateMessagePayload>) {
        const url = `${BASE_URL}/messages/${messageId}`
        const response = await this.axiosClient.put(url, updatePayload)
        return parseResponse(response, parseMessage)
    }

    async getMessageView(messageId: MessageId) {
        const url = `${BASE_URL}/messages/${messageId}`
        const response = await this.axiosClient.get(url)
        return response.data
    }

    async setMessageAsViewed(messageId: MessageId) {
        const url = `${BASE_URL}/messages/${messageId}/viewed`
        const response = await this.axiosClient.post(url)
        return parseResponse(response, parseMessageView)
    }
}

export const useCommunicationApi = () => {
    const { axiosClient } = useContext(ApiContext)
    return CommunicationApi.getInstance(axiosClient)
}
