import { MessageDescriptor } from "react-intl"
import {
    CreatePurchaseOrderDTO,
    CreatePurchaseOrderStatus,
    ListPurchaseOrders,
    PurchaseOrderApprovalStatus,
    PurchaseOrderLine,
    PurchaseOrderLineStatus,
    PurchaseOrderStatus,
    PurchaseOrders,
    PurchaseOrdersTab,
    PurchaseOrdersResponseV2,
    CreatePurchaseOrderDTOV2,
    UpdatePurchaseOrderDTOV2,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { CurrencyCodes, ViewTypeI } from "~/types"
import { BulkActionButton } from "~/core"
import { isDefined } from "~/utils/isDefined"
import { SharedObjectType, SharedObjectWithName } from "~/types/SharedObjects"
import { CreateLineDTOV2, CreatePOLineDTO } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

export const purchaseOrdersTabMessages: Record<PurchaseOrdersTab, MessageDescriptor> = {
    [PurchaseOrdersTab.ALL]: {
        id: "purchase.orders.tabs.all",
        defaultMessage: "All",
    },
    [PurchaseOrdersTab.DRAFT]: {
        id: "purchase.orders.tabs.drafts",
        defaultMessage: "Drafts",
    },
    [PurchaseOrdersTab.SUBMITTED]: {
        id: "purchase.orders.tabs.submitted",
        defaultMessage: "Submitted",
    },
    [PurchaseOrdersTab.AWAITING_YOUR_APPROVAL]: {
        id: "purchase.orders.tabs.awaitingYourApproval",
        defaultMessage: "Awaiting your approval",
    },
    [PurchaseOrdersTab.AWAITING_BUYER_APPROVAL]: {
        id: "purchase.orders.tabs.awaitingBuyerApproval",
        defaultMessage: "Awaiting client acceptance",
    },
    [PurchaseOrdersTab.AWAITING_SUPPLIER_APPROVAL]: {
        id: "purchase.orders.tabs.awaitingSupplierApproval",
        defaultMessage: "Awaiting vendor approval",
    },
    [PurchaseOrdersTab.IN_PREPARATION]: {
        id: "purchase.orders.tabs.inPreparation",
        defaultMessage: "In preparation",
    },
    [PurchaseOrdersTab.OUT_FOR_DELIVERY]: {
        id: "purchase.orders.tabs.outForDelivery",
        defaultMessage: "Shipped",
    },
    [PurchaseOrdersTab.DELIVERED]: {
        id: "purchase.orders.tabs.delivered",
        defaultMessage: "Delivered",
    },
    [PurchaseOrdersTab.RECEIVED]: {
        id: "purchase.orders.tabs.received",
        defaultMessage: "Received",
    },
}

export const ToDoTabsActionBySupplier: Partial<Record<PurchaseOrdersTab, boolean>> = {
    [PurchaseOrdersTab.DRAFT]: true,
    [PurchaseOrdersTab.SUBMITTED]: true,
    [PurchaseOrdersTab.AWAITING_YOUR_APPROVAL]: true,
    [PurchaseOrdersTab.AWAITING_SUPPLIER_APPROVAL]: true,
    [PurchaseOrdersTab.AWAITING_BUYER_APPROVAL]: false,
    [PurchaseOrdersTab.IN_PREPARATION]: false,
    [PurchaseOrdersTab.OUT_FOR_DELIVERY]: false,
    [PurchaseOrdersTab.DELIVERED]: false,
    [PurchaseOrdersTab.RECEIVED]: false,
}

export const ToDoTabsActionByBuyer: Partial<Record<PurchaseOrdersTab, boolean>> = {
    [PurchaseOrdersTab.DRAFT]: true,
    [PurchaseOrdersTab.SUBMITTED]: true,
    [PurchaseOrdersTab.AWAITING_YOUR_APPROVAL]: true,
    [PurchaseOrdersTab.AWAITING_SUPPLIER_APPROVAL]: false,
    [PurchaseOrdersTab.AWAITING_BUYER_APPROVAL]: true,
    [PurchaseOrdersTab.IN_PREPARATION]: false,
    [PurchaseOrdersTab.OUT_FOR_DELIVERY]: false,
    [PurchaseOrdersTab.DELIVERED]: true,
    [PurchaseOrdersTab.RECEIVED]: false,
}

export type POStatusCounts = Partial<Record<PurchaseOrdersTab, number>>
export const statusCounts = (purchaseOrders: { status: string }[], view: ViewTypeI | string) => {
    const countsInitial: POStatusCounts = {
        DRAFT: 0,
        SUBMITTED: 0,
        AWAITING_BUYER_APPROVAL: 0,
        AWAITING_SUPPLIER_APPROVAL: 0,
        IN_PREPARATION: 0,
        OUT_FOR_DELIVERY: 0,
        DELIVERED: 0,
        RECEIVED: 0,
    }

    return purchaseOrders.reduce((count, purchaseOrder) => {
        switch (purchaseOrder.status) {
            case PurchaseOrderStatus.DRAFT_BUYER:
                if (view === ViewTypeI.buyer) {
                    count[PurchaseOrdersTab.DRAFT] = count[PurchaseOrdersTab.DRAFT]
                        ? count[PurchaseOrdersTab.DRAFT] + 1
                        : 1
                }
                break
            case PurchaseOrderStatus.DRAFT_SUPPLIER:
                if (view === ViewTypeI.supplier) {
                    count[PurchaseOrdersTab.DRAFT] = count[PurchaseOrdersTab.DRAFT]
                        ? count[PurchaseOrdersTab.DRAFT] + 1
                        : 1
                }
                break
            case PurchaseOrderStatus.SUBMITTED_BUYER:
                if (view === ViewTypeI.buyer) {
                    count[PurchaseOrdersTab.SUBMITTED] = count[PurchaseOrdersTab.SUBMITTED]
                        ? count[PurchaseOrdersTab.SUBMITTED] + 1
                        : 1
                }
                break
            case PurchaseOrderStatus.SUBMITTED_SUPPLIER:
                if (view === ViewTypeI.supplier) {
                    count[PurchaseOrdersTab.SUBMITTED] = count[PurchaseOrdersTab.SUBMITTED]
                        ? count[PurchaseOrdersTab.SUBMITTED] + 1
                        : 1
                }
                break
            case PurchaseOrderStatus.AWAITING_BUYER_APPROVAL:
                if (view === ViewTypeI.buyer) {
                    count[PurchaseOrdersTab.AWAITING_YOUR_APPROVAL] = count[PurchaseOrdersTab.AWAITING_YOUR_APPROVAL]
                        ? count[PurchaseOrdersTab.AWAITING_YOUR_APPROVAL] + 1
                        : 1
                } else {
                    count[PurchaseOrdersTab.AWAITING_BUYER_APPROVAL] = count[PurchaseOrdersTab.AWAITING_BUYER_APPROVAL]
                        ? count[PurchaseOrdersTab.AWAITING_BUYER_APPROVAL] + 1
                        : 1
                }
                break
            case PurchaseOrderStatus.AWAITING_SUPPLIER_APPROVAL:
                if (view === ViewTypeI.supplier) {
                    count[PurchaseOrdersTab.AWAITING_YOUR_APPROVAL] = count[PurchaseOrdersTab.AWAITING_YOUR_APPROVAL]
                        ? count[PurchaseOrdersTab.AWAITING_YOUR_APPROVAL] + 1
                        : 1
                } else {
                    count[PurchaseOrdersTab.AWAITING_SUPPLIER_APPROVAL] = count[
                        PurchaseOrdersTab.AWAITING_SUPPLIER_APPROVAL
                    ]
                        ? count[PurchaseOrdersTab.AWAITING_SUPPLIER_APPROVAL] + 1
                        : 1
                }
                break
            default:
                count[purchaseOrder.status] = count[purchaseOrder.status] ? count[purchaseOrder.status] + 1 : 1
                break
        }
        return count
    }, countsInitial)
}

export const getPurchaseOrderTabs = (view: ViewTypeI | string): PurchaseOrdersTab[] => {
    return [
        PurchaseOrdersTab.ALL,
        PurchaseOrdersTab.DRAFT,
        PurchaseOrdersTab.SUBMITTED,
        view === ViewTypeI.buyer ? PurchaseOrdersTab.AWAITING_YOUR_APPROVAL : PurchaseOrdersTab.AWAITING_BUYER_APPROVAL,
        view === ViewTypeI.supplier
            ? PurchaseOrdersTab.AWAITING_YOUR_APPROVAL
            : PurchaseOrdersTab.AWAITING_SUPPLIER_APPROVAL,
        PurchaseOrdersTab.IN_PREPARATION,
        PurchaseOrdersTab.OUT_FOR_DELIVERY,
        PurchaseOrdersTab.DELIVERED,
        PurchaseOrdersTab.RECEIVED,
    ]
}

export const getPOsByViewAndTab = (
    view: ViewTypeI | string,
    tab: PurchaseOrdersTab,
    purchaseOrders: ListPurchaseOrders
): ListPurchaseOrders => {
    switch (tab) {
        case PurchaseOrdersTab.ALL:
            return filterPOsByStatus(Object.values(PurchaseOrderStatus), purchaseOrders)
        case PurchaseOrdersTab.DRAFT:
            return view === ViewTypeI.buyer
                ? filterPOsByStatus([PurchaseOrderStatus.DRAFT_BUYER], purchaseOrders)
                : filterPOsByStatus([PurchaseOrderStatus.DRAFT_SUPPLIER], purchaseOrders)
        case PurchaseOrdersTab.SUBMITTED:
            return view === ViewTypeI.buyer
                ? filterPOsByStatus([PurchaseOrderStatus.SUBMITTED_BUYER], purchaseOrders)
                : filterPOsByStatus([PurchaseOrderStatus.SUBMITTED_SUPPLIER], purchaseOrders)
        case PurchaseOrdersTab.AWAITING_YOUR_APPROVAL:
            return view === ViewTypeI.buyer
                ? filterPOsByStatus([PurchaseOrderStatus.AWAITING_BUYER_APPROVAL], purchaseOrders)
                : filterPOsByStatus([PurchaseOrderStatus.AWAITING_SUPPLIER_APPROVAL], purchaseOrders)
        case PurchaseOrdersTab.AWAITING_BUYER_APPROVAL:
            return filterPOsByStatus([PurchaseOrderStatus.AWAITING_BUYER_APPROVAL], purchaseOrders)
        case PurchaseOrdersTab.AWAITING_SUPPLIER_APPROVAL:
            return filterPOsByStatus([PurchaseOrderStatus.AWAITING_SUPPLIER_APPROVAL], purchaseOrders)
        case PurchaseOrdersTab.IN_PREPARATION:
            return filterPOsByStatus([PurchaseOrderStatus.IN_PREPARATION], purchaseOrders)
        case PurchaseOrdersTab.OUT_FOR_DELIVERY:
            return filterPOsByStatus([PurchaseOrderStatus.OUT_FOR_DELIVERY], purchaseOrders)
        case PurchaseOrdersTab.DELIVERED:
            return filterPOsByStatus([PurchaseOrderStatus.DELIVERED], purchaseOrders)
        case PurchaseOrdersTab.RECEIVED:
            return filterPOsByStatus([PurchaseOrderStatus.RECEIVED], purchaseOrders)
    }
}

const filterPOsByStatus = (statuses: PurchaseOrderStatus[], purchaseOrders: ListPurchaseOrders): ListPurchaseOrders => {
    return purchaseOrders.filter((po) => statuses.includes(po.status))
}

export const filterResults = (pos: ListPurchaseOrders, searchTerm: string, side: ViewTypeI): ListPurchaseOrders => {
    return pos.filter((po) =>
        side === ViewTypeI.buyer
            ? po.buyerName?.toLowerCase().includes(searchTerm)
            : po.supplierName?.toLowerCase().includes(searchTerm)
    )
}

export const allLinesApproved = (lines: PurchaseOrderLine[], viewType: ViewTypeI) => {
    return lines.every(
        (line) =>
            line.status === PurchaseOrderLineStatus.RECEIVED ||
            line[viewType === ViewTypeI.supplier ? "supplierApprovalStatus" : "buyerApprovalStatus"] ===
                PurchaseOrderApprovalStatus.APPROVED
    )
}

export const allLinesRejected = (lines: PurchaseOrderLine[], viewType: ViewTypeI) => {
    return lines.every(
        (line) =>
            line[viewType === ViewTypeI.supplier ? "supplierApprovalStatus" : "buyerApprovalStatus"] ===
            PurchaseOrderApprovalStatus.REJECTED
    )
}

export const getPOBulkActionButtons = (
    currentTab: PurchaseOrdersTab,
    view: ViewTypeI | string,
    handleValidate: (currentTab: PurchaseOrdersTab) => BulkActionButton,
    handleSend: (currentTab: PurchaseOrdersTab) => BulkActionButton,
    handleReceived: (currentTab: PurchaseOrdersTab) => BulkActionButton,
    handleAddTags: (currentTab: PurchaseOrdersTab) => BulkActionButton
): BulkActionButton[] => {
    switch (currentTab) {
        case PurchaseOrdersTab.ALL:
            return [
                handleAddTags(currentTab),
                handleValidate(currentTab),
                handleSend(currentTab),
                handleReceived(currentTab),
            ]
        case PurchaseOrdersTab.AWAITING_YOUR_APPROVAL:
            return [handleAddTags(currentTab), handleValidate(currentTab), handleSend(currentTab)]
        case PurchaseOrdersTab.IN_PREPARATION:
            return view === ViewTypeI.supplier
                ? [handleAddTags(currentTab)]
                : [handleAddTags(currentTab), handleReceived(currentTab)]
        case PurchaseOrdersTab.OUT_FOR_DELIVERY:
            return view === ViewTypeI.supplier
                ? [handleAddTags(currentTab)]
                : [handleAddTags(currentTab), handleReceived(currentTab)]
        case PurchaseOrdersTab.DELIVERED:
            return view === ViewTypeI.supplier
                ? [handleAddTags(currentTab)]
                : [handleAddTags(currentTab), handleReceived(currentTab)]
        default:
            return [handleAddTags(currentTab)]
    }
}

export const getApprovable = (
    purchaseOrderIds: string[],
    purchaseOrders: ListPurchaseOrders,
    view: ViewTypeI | string
): string[] => {
    return purchaseOrderIds
        .map((id) => purchaseOrders.find((po) => po.id === id))
        .filter(isDefined)
        .filter(
            (po) =>
                ((view === ViewTypeI.buyer && po.status === PurchaseOrderStatus.AWAITING_BUYER_APPROVAL) ||
                    (view === ViewTypeI.supplier && po.status === PurchaseOrderStatus.AWAITING_SUPPLIER_APPROVAL)) &&
                !allLinesApproved(po.lines, view)
        )
        .map((po) => po.id)
}

export const getSendable = (
    purchaseOrderIds: string[],
    purchaseOrders: ListPurchaseOrders,
    view: ViewTypeI | string,
    organizationSharings: SharedObjectWithName[]
): string[] => {
    const sharedPOs = organizationSharings.filter(
        (share) => purchaseOrderIds.includes(share.objectId) && share.objectType === SharedObjectType.PurchaseOrder
    )
    return purchaseOrderIds
        .map((id) => purchaseOrders.find((po) => po.id === id))
        .filter(isDefined)
        .filter(
            (po) =>
                sharedPOs.find((share) => share.objectId === po.id) &&
                ((view === ViewTypeI.buyer && po.status === PurchaseOrderStatus.AWAITING_BUYER_APPROVAL) ||
                    (view === ViewTypeI.supplier && po.status === PurchaseOrderStatus.AWAITING_SUPPLIER_APPROVAL)) &&
                allLinesApproved(po.lines, view)
        )
        .map((po) => po.id)
}

const receivablePurchaseOrderStatus: Partial<Record<PurchaseOrderStatus, boolean>> = {
    [PurchaseOrderStatus.IN_PREPARATION]: true,
    [PurchaseOrderStatus.OUT_FOR_DELIVERY]: true,
    [PurchaseOrderStatus.DELIVERED]: true,
}
export const getReceivable = (purchaseOrderIds: string[], purchaseOrders: ListPurchaseOrders): string[] => {
    return purchaseOrderIds
        .map((id) => purchaseOrders.find((po) => po.id === id))
        .filter(isDefined)
        .filter((po) => receivablePurchaseOrderStatus[po.status])
        .map((po) => po.id)
}

export const getPurchaseOrderId = (
    r: PromiseSettledResult<unknown>,
    index: number,
    purchaseOrderIds: string[]
): string | undefined => (r.status === "rejected" ? undefined : purchaseOrderIds[index])

export const showBulkActionButtonCount: Partial<Record<PurchaseOrdersTab, boolean>> = {
    [PurchaseOrdersTab.ALL]: true,
    [PurchaseOrdersTab.AWAITING_YOUR_APPROVAL]: true,
}

export const convertLineToCreatePOLineDTO = (line: PurchaseOrderLine): CreatePOLineDTO => {
    return {
        description: line.description,
        quantity: line.quantity,
        unitPrice: line.unitPrice,
        unitPriceExcludingTax: line.unitPriceExcludingTax,
        taxRate: line.taxRate,
        totalAmount: line.totalAmount,
        totalAmountExcludingTax: line.totalAmountExcludingTax,
        ...(line.supplierItemId ? { supplierItemId: line.supplierItemId } : { buyerItemId: line.buyerItemId }),
        totalTax: line.totalTax,
    }
}

export const convertPOtoCreatePODTO = (
    PO: PurchaseOrders,
    status: CreatePurchaseOrderStatus = CreatePurchaseOrderStatus.DRAFT
): CreatePurchaseOrderDTO => {
    return {
        supplierId: PO.supplierId,
        buyerId: PO.buyerId,
        status,
        expectedDeliveryDate: PO.expectedDeliveryDate,
        requesterUserId: PO.requesterUserId ?? undefined,
        billingAddress: PO.billingAddress ?? undefined,
        shippingAddress: PO.shippingAddress ?? undefined,
        description: PO.description,
        currency: PO.currency,
        lines: PO.lines.map((line) => {
            return convertLineToCreatePOLineDTO(line)
        }),
    }
}

export const convertPOV2toPO = (purchaseOrder: PurchaseOrdersResponseV2): PurchaseOrders => {
    const {
        buyerId,
        creationDate,
        expectedDeliveryDate,
        id,
        requesterUserId,
        shippingAddress,
        shortId,
        status,
        supplierId,
        purchaseRequest,
        description,
        totals: purchaseOrderTotals,
        lines: purchaseOrderLines,
    } = purchaseOrder

    const currencyKey = Object.keys(purchaseOrderTotals)[0]

    const lines = purchaseOrderLines.map((line) => {
        const {
            id,
            status,
            description,
            quantity,
            taxRate,
            totals: lineTotals,
            unitPrice,
            unitPriceExcludingTax,
            deliveryNote = "",
            temporaryId = "",
            hasChanged = false,
            supplierItemId,
            buyerItemId,
            buyerApprovalStatus,
            supplierApprovalStatus,
        } = line

        return {
            id,
            status,
            description,
            quantity,
            taxRate,
            totalAmount: lineTotals ? +lineTotals.amount.amount : 0,
            totalAmountExcludingTax: lineTotals ? +lineTotals.amountExcludingTax.amount : 0,
            totalTax: lineTotals ? +lineTotals.tax.amount : 0,
            unitPrice: unitPrice ? +unitPrice.amount : 0,
            unitPriceExcludingTax: unitPriceExcludingTax ? +unitPriceExcludingTax.amount : 0,
            deliveryNote,
            temporaryId,
            hasChanged,
            supplierItemId,
            buyerItemId,
            buyerApprovalStatus,
            supplierApprovalStatus,
        }
    })

    return {
        buyerId,
        creationDate,
        currency: currencyKey as CurrencyCodes,
        expectedDeliveryDate,
        id,
        requesterUserId,
        shippingAddress,
        shortId,
        status,
        supplierId,
        totalAmount: purchaseOrderTotals ? +purchaseOrderTotals[currencyKey].amount?.amount : 0,
        totalAmountExcludingTax: purchaseOrderTotals ? +purchaseOrderTotals[currencyKey].amountExcludingTax?.amount : 0,
        totalTax: purchaseOrderTotals ? +purchaseOrderTotals[currencyKey].tax?.amount : 0,
        purchaseRequest,
        description,
        lines,
        supplierName: "",
        buyerName: "",
    }
}

const convertLines = (lines: CreatePOLineDTO[] | PurchaseOrderLine[], currency: CurrencyCodes): CreateLineDTOV2[] => {
    return lines.map((line: CreatePOLineDTO | PurchaseOrderLine) => {
        const { description, quantity, taxRate, unitPrice, unitPriceExcludingTax, id, buyerItemId, supplierItemId } =
            line
        return {
            description,
            quantity,
            taxRate: taxRate.toFixed(8),
            unitPrice: { amount: unitPrice.toFixed(8), currency },
            unitPriceExcludingTax: { amount: unitPriceExcludingTax.toString(), currency },
            ...(id ? { id } : {}),
            ...(buyerItemId ? { buyerItemId } : {}),
            ...(supplierItemId ? { supplierItemId } : {}),
        }
    })
}

export const convertPOtoPOV2 = (purchaseOrder: CreatePurchaseOrderDTO): CreatePurchaseOrderDTOV2 => {
    const {
        status,
        billingAddress,
        description,
        expectedDeliveryDate,
        shippingAddress,
        supplierId,
        buyerId,
        lines,
        currency,
    } = purchaseOrder

    return {
        status,
        billingAddress,
        description,
        expectedDeliveryDate,
        shippingAddress,
        supplierId,
        buyerId,
        lines: convertLines(lines, currency),
    }
}

export const convertPOtoPOV2ForUpdate = (purchaseOrder: PurchaseOrders): UpdatePurchaseOrderDTOV2 => {
    const { billingAddress, description, expectedDeliveryDate, shippingAddress, lines, currency } = purchaseOrder

    return {
        billingAddress,
        description,
        expectedDeliveryDate,
        shippingAddress,
        lines: convertLines(lines, currency),
    }
}
