import React from "react"
import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { Button, Loader } from "~/components"
import { FileText } from "react-feather"
import "./Actions.scss"
import { useConvertToPurchaseOrder } from "~/domains/transactions/purchase-requests/store/hooks/useConvertToPurchaseOrder"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { toast } from "react-toastify"
import { SubmittedPRActions } from "./SubmittedPRActions"
import { useDuplicateDocumentLinks } from "~/components/UploadDocument/hooks/useDuplicateDocumentLinks"
// import { ModalStatusConversion } from "../ModalStatusConversion/ModalStatusConversion"
import { PurchaseRequestConversionStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { OrganizationId } from "~/types"
import { Tooltip } from "@mui/material"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"

interface PropsPRActions {
    PR: PurchaseRequestDetails
}

const messages = defineMessages({
    toPO: {
        id: "purchase.requests.request.toPO",
        defaultMessage: "Convert to PO",
    },
    supplierNeeded: {
        id: "purchase.requests.request.supplierNeeded",
        defaultMessage: "You need to select a supplier before converting this PR to a PO",
    },
    deliveryDateNeeded: {
        id: "purchase.requests.request.deliveryDateNeeded",
        defaultMessage: "You need to select a delivery date before converting this PR to a PO",
    },
    title: {
        id: "purchase.requests.request.actions.title",
        defaultMessage: "Actions",
    },
})

export function ActionsColumn({ PR }: PropsPRActions) {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    // const [displayStatusModal, setDisplayStatusModal] = useState<boolean>(false)
    const { duplicateDocumentLinks } = useDuplicateDocumentLinks()

    const { convertToPO, convertLoading } = useConvertToPurchaseOrder(PR.organizationId)
    const convertPermission = PR.permissions?.convertToPurchaseOrder
    const canConvert = (convertPermission && typeof convertPermission !== "undefined") ?? false

    const handleConvertToPO = (e: React.MouseEvent | null) => {
        e && e.preventDefault
        if (!PR.supplierId) {
            return toast.error(formatMessage(messages.supplierNeeded))
        }
        if (!PR.expectedDeliveryDate) {
            return toast.error(formatMessage(messages.deliveryDateNeeded))
        }
        convert(PR.expectedDeliveryDate, PurchaseRequestConversionStatus.SUBMITTED)
        // setDisplayStatusModal(true)
    }

    // const handleStatusAndConvert = (status: PurchaseRequestConversionStatus, sharedEmail?: string) => {
    //     convert(null, status, sharedEmail, sharedEmail ? (PR.supplierId as string) : undefined)
    // }

    const convert = (
        deliveryDate: string | null = null,
        status?: PurchaseRequestConversionStatus,
        sharedEmail?: string,
        supplierId?: OrganizationId
    ) => {
        convertToPO(PR, deliveryDate, status, sharedEmail, supplierId).then(async (purchaseOrder) => {
            if (!purchaseOrder) return
            await duplicateDocumentLinks({ sourceObjectId: PR.id, targetObjectId: purchaseOrder.id })
            navigate(generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: purchaseOrder.id }))
        })
    }

    if (PR.status === PurchaseRequestStatus.SUBMITTED) {
        return <SubmittedPRActions purchaseRequest={PR} />
    }
    if (PR.status === PurchaseRequestStatus.APPROVED) {
        return (
            <div className="actions">
                {convertLoading ? (
                    <Loader small={true} />
                ) : (
                    <Tooltip
                        arrow
                        title={!canConvert ? formatMessage(permissionMessages.errorNoAccessAdministrator) : ""}
                    >
                        <span className="flex-1">
                            <Button
                                type="tertiary"
                                className="status"
                                disabled={!canConvert}
                                {...(canConvert ? { onClick: handleConvertToPO } : {})}
                            >
                                <>
                                    {formatMessage(messages.toPO)}
                                    <FileText size={14} />
                                </>
                            </Button>
                        </span>
                    </Tooltip>
                )}
                {/* <ModalStatusConversion
                    open={displayStatusModal}
                    close={() => setDisplayStatusModal(false)}
                    confirm={handleStatusAndConvert}
                    partnerId={PR.supplierId}
                /> */}
            </div>
        )
    }
    return <></>
}
