import { useCallback } from "react"
import { CreateCustomFieldPayload } from "../types/CreateCustomFieldPayload"
import { useAppDispatch } from "~/store/hooks"
import { customFieldsActions } from "../store"
import { CustomFieldI } from "../types/CustomField"

export const useSavePendingCustomFieldValue = (customField: CustomFieldI) => {
    const dispatch = useAppDispatch()
    return useCallback(
        (payload: CreateCustomFieldPayload) => {
            dispatch(
                customFieldsActions.setPendingCustomFieldValue({
                    objectId: payload.objectId,
                    customField,
                    value: payload,
                })
            )
        },
        [dispatch, customField]
    )
}
