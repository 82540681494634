import { OrganizationId } from "~/types"
import { useAppSelector } from "~/store/hooks"
import { selectPartnersBrandNames } from "~/domains/transactions/book-of-relations/store/bookOfRelationsSlice"
import { useFetchOrganization } from "~/store/organization/hooks"

export const usePartnerComputedName = (partnerOrganizationId: OrganizationId | undefined | null): string | null => {
    const brandNames = useAppSelector(selectPartnersBrandNames)
    const { organization } = useFetchOrganization(partnerOrganizationId)

    if (!partnerOrganizationId || !brandNames) {
        return null
    }

    return brandNames[partnerOrganizationId] ?? organization?.name
}
