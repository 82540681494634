import React, { useRef, useState } from "react"
import { Upload } from "react-feather"
import { FormattedMessage, defineMessages } from "react-intl"
import IconUpload from "../images/upload.svg"
import { toast } from "react-toastify"

import "./UploadDocument.scss"

const messages = defineMessages({
    headline: { id: "documents.modal.uploadFile.headline", defaultMessage: "Drag or <em>upload</em> " },
    defaultConditions: {
        id: "documents.modal.uploadFile.defaultConditions",
        defaultMessage: "Formats: all file types accepted. 2MB limit",
    },
})

export enum boxSize {
    SMALL = "small",
    LARGE = "large",
}

export interface CheckFileTypeResult {
    result: boolean
    error: string | null
}

interface UploadFileBoxProps {
    handleFile: (file: File) => void
    conditionMessage?: string
    bottomMessage?: string
    accept?: string
    checkFileType?: (file: File) => CheckFileTypeResult
    size?: boxSize
}

export const UploadDocumentBox = ({
    handleFile,
    conditionMessage,
    bottomMessage,
    accept,
    checkFileType,
    size = boxSize.SMALL,
    ...props
}: UploadFileBoxProps) => {
    const [dragActive, setDragActive] = useState<boolean>(false)
    const inputFileRef = useRef<HTMLInputElement>(null)

    const triggerInput = () => {
        inputFileRef.current?.click()
    }

    const handleClickUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files) {
            if (checkFileType) {
                const { result, error } = checkFileType(e.target.files[0])
                if (result) {
                    handleFile(e.target.files[0])
                } else {
                    toast.error(error)
                }
            } else {
                handleFile(e.target.files[0])
            }
        }
    }

    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true)
        } else if (e.type === "dragleave") {
            setDragActive(false)
        }
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            if (checkFileType) {
                const { result, error } = checkFileType(e.dataTransfer.files[0])
                if (result) {
                    handleFile(e.dataTransfer.files[0])
                } else {
                    toast.error(error)
                }
            } else {
                handleFile(e.dataTransfer.files[0])
            }
        }
    }

    return (
        <div className="upload-document-container" {...props}>
            <div
                className={`upload-document ${size}`}
                onClick={triggerInput}
                onDragEnter={(e) => handleDrag(e)}
                role="button"
                tabIndex={0}
                aria-label="upload a file"
            >
                <div className="upload-document-block">
                    {size === boxSize.SMALL ? <Upload size={24} /> : <img alt="Upload" src={IconUpload} />}
                    <div className="upload-document-text">
                        <FormattedMessage {...messages.headline} values={{ em: (msg) => <em>{msg}</em> }} />
                    </div>
                    <div className="upload-document-conditions">
                        {conditionMessage ? conditionMessage : <FormattedMessage {...messages.defaultConditions} />}
                    </div>
                </div>
                <input
                    type="file"
                    name="file"
                    className="upload-document-file"
                    ref={inputFileRef}
                    accept={accept}
                    onChange={handleClickUpload}
                />
                {dragActive && (
                    <div
                        className="upload-document-drag"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                    />
                )}
            </div>
            {bottomMessage ? <div className="upload-document-tip">{bottomMessage}</div> : null}
        </div>
    )
}
