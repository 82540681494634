import * as t from "io-ts"
import { optional } from "~/types/utils"

export const CustomFieldValueIO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        objectId: t.string,
        value: t.string,
    }),
    t.partial({
        quantity: t.number,
        unit: optional(t.string),
        contextId: t.string,
        contextType: t.string,
        organizationId: t.string,
        createdAt: t.string,
        updatedAt: t.string,
    }),
])

export type CustomFieldValueI = t.TypeOf<typeof CustomFieldValueIO>

export const AggregatedCustomFieldValueIO = t.record(t.string, t.array(CustomFieldValueIO))
export type AggregatedCustomFieldValueI = t.TypeOf<typeof AggregatedCustomFieldValueIO>
