import { useCallback, useState } from "react"
import { OrganizationId } from "~/types"
import { useTagsApi } from "../tagsApi"
import { isResultSuccess } from "~/core/Result"
import { defineMessages, useIntl } from "react-intl"
import { parseTagObjectsRecord } from "../parsers/parseTagObjectsRecord"

const messages = defineMessages({
    errorParsing: {
        id: "account.organization.tags.getObjectsTags.errorParsing",
        defaultMessage: "Error parsing TagObjectRecordI in getObjectsTags function.",
    },
})

export const useGetBulkObjectsTags = (organizationId?: OrganizationId) => {
    const { formatMessage } = useIntl()
    const tagsApi = useTagsApi()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    const getBulkObjectsTags = useCallback(
        async (objectIds: string[], addNames?: boolean) => {
            if (organizationId) {
                try {
                    setLoading(true)
                    const response = await tagsApi.getBulkObjectsTags(organizationId, objectIds, addNames)
                    if (response?.status >= 200 && response.status < 400 && !response.data) return
                    const result = parseTagObjectsRecord(response.data)
                    if (isResultSuccess(result)) {
                        return result.result
                    }
                    setError(formatMessage(messages.errorParsing))
                } catch (e) {
                    setError(`${e}`)
                    throw e
                } finally {
                    setLoading(false)
                }
            }
        },
        [organizationId, setLoading, setError]
    )

    return {
        getBulkObjectsTags,
        loading,
        error,
    }
}
