import { ParsingErrorType, genericParser } from "~/utils"
import { SubscriptionEventI, SubscriptionEventIO, SubscriptionEventType } from "./subscriptionsTypes"
import { Result, ResultSuccess, isResultError } from "~/core/Result"

const AllSubscriptionEventType: Record<SubscriptionEventType, true> = Object.values(SubscriptionEventType).reduce(
    (acc, key) => {
        acc[key] = true
        return acc
    },
    {}
) as Record<SubscriptionEventType, true>
const parseSubscriptionEventType = (data: string): SubscriptionEventType => {
    const subscriptionEventType = data as SubscriptionEventType
    if (AllSubscriptionEventType[subscriptionEventType]) return subscriptionEventType
    return SubscriptionEventType.SELF
}

export const parseSubcriptionEvent = (data: unknown): Result<SubscriptionEventI, ParsingErrorType> => {
    const result = genericParser(data, SubscriptionEventIO)
    if (isResultError(result)) {
        return result
    }
    return ResultSuccess({
        type: parseSubscriptionEventType(result.result.type),
        objectId: result.result.objectId,
    })
}
