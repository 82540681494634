import { OrganizationId } from "~/types"
import { RoomId } from "../types"
import { useCommunicationApi } from "../communicationApi"
import { useCallback } from "react"
import { Descendant } from "slate"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { CreateMessagePayload } from "../types/CreateMessage"
import { isResultSuccess } from "~/core/Result"
import { communicationActions } from "../store/CommunicationSlice"

export const useSendMessage = (organizationId: OrganizationId, roomId: RoomId) => {
    const communicationApi = useCommunicationApi()
    const user = useAppSelector(selectUser)
    const dispatch = useAppDispatch()

    const sendMessage = useCallback(
        async (content: Descendant[]) => {
            const createMessagePayload: CreateMessagePayload = {
                content,
                senderId: user.id,
                isPinned: false,
            }
            const result = await communicationApi.sendMessage(organizationId, roomId, createMessagePayload)
            if (isResultSuccess(result)) {
                dispatch(communicationActions.addMessageInRoom({ roomId, message: result.result }))
            }
        },
        [communicationApi, organizationId, roomId, user.id, dispatch]
    )

    return sendMessage
}
