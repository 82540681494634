import React from "react"
import { Box } from "@mui/material"
import "./PurchaseRequestLink.scss"
import { generatePath, Link } from "react-router-dom"
import {
    PURCHASE_REQUEST_ROUTE
} from "~/domains/transactions/purchase-requests/routes"
import {
    PurchaseOrderPurchaseRequest
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"

interface Props {
    purchaseRequest: PurchaseOrderPurchaseRequest
    label: string
}

export function PurchaseRequestLink({
    purchaseRequest,
    label,
}: Props) {
    return <Box component={"div"} className={"purchase-request-link"}>
        <label>{label}</label>
        <span><Link to={generatePath(PURCHASE_REQUEST_ROUTE, {purchaseRequestId: purchaseRequest.id})}>#{purchaseRequest.number}</Link></span>
    </Box>
}
