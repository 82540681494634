import axiosClient from "./axiosClient"
import { OrganizationId, ReceivedDocumentI, SentDocumentI } from "~/types"

const BASE_URL = import.meta.env.VITE_API_INVOICES_URL + "v1"
const SUPPLIERS_BASE_URL = `${BASE_URL}/suppliers`
const BUYERS_BASE_URL = `${BASE_URL}/buyers`

export const documentApi = {
    getSentDocuments: async (userId: string): Promise<SentDocumentI[]> => {
        const url = `${SUPPLIERS_BASE_URL}/${userId}/documents`
        return axiosClient.get(url)
    },
    getReceivedDocuments: async (userId: string): Promise<ReceivedDocumentI[]> => {
        const url = `${BUYERS_BASE_URL}/${userId}/documents`
        return axiosClient.get(url)
    },
    getSuppliersDocuments(organizationId: OrganizationId): Promise<SentDocumentI[]> {
        return axiosClient.get(`${SUPPLIERS_BASE_URL}/${organizationId}/organization-documents`)
    },
    getBuyersDocuments(organizationId: OrganizationId): Promise<ReceivedDocumentI[]> {
        return axiosClient.get(`${BUYERS_BASE_URL}/${organizationId}/organization-documents`)
    },
}
