import React, { ChangeEvent, lazy, useCallback, useEffect, useMemo, useState } from "react"
import Grid from "@mui/material/Unstable_Grid2"
import { defineMessages, useIntl } from "react-intl"
import { Loader, useTitle } from "~/core"
import { HeaderH1 } from "~/components/Header/HeaderH1"
import {
    PURCHASE_ORDER_EDIT_ROUTE,
    PURCHASE_ORDER_ROUTE,
    PURCHASE_ORDERS_ROUTE,
} from "~/domains/transactions/purchase-orders/routes"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { organizationActions, selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { TotalAmount } from "~/domains/transactions/components/TotalAmount/TotalAmount"
import {
    purchaseOrdersActions,
    selectPurchaseOrdersError,
    selectPurchaseOrdersLoading,
    selectPurchaseViewType,
} from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { Dayjs } from "dayjs"
import {
    useFetchPurchaseOrder,
    useUpdatePurchaseOrder,
    useUpdatePurchaseOrderStatus,
} from "~/domains/transactions/purchase-orders/store/hooks"
import { Description } from "~/domains/transactions/components/Description/Description"
import { PurchaseViewType } from "~/domains/transactions/types/Purchases"
import { Dates } from "~/domains/transactions/components/Dates/Dates"
import { PurchaseRequestLink } from "~/domains/transactions/purchase-orders/components/PurchaseRequestLink/PurchaseRequestLink"
import { RequesterName } from "~/domains/transactions/components/RequesterName/RequesterName"
import { CurrencyCodes, OrganizationAddressI, OrganizationI, OrganizationId, ViewTypeI, CurrencyI } from "~/types"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"
import { ActionsColumn } from "~/domains/transactions/purchase-orders/components/Actions/ActionsColumn"
import { StepsPurchaseOrder } from "~/domains/transactions/components/Steps/StepsPurchaseOrder"
import {
    Address,
    PurchaseOrderApprovalStatus,
    PurchaseOrderLine,
    PurchaseOrders,
    PurchaseOrderStatus,
    PurchaseOrderStatusUpdate,
} from "../types/PurchaseOrders"
import { SavePayloadType } from "~/domains/identity/features/organizations/components/ModalOrganizationSelectorDetails/types"
import { ErrorMessage } from "~/components/ErrorMessage"
import { TagsSelector } from "~/domains/tags/components/TagsSelector"
import { selectUser } from "~/store/account/accountSlice"
import { Documents } from "~/components/UploadDocument/Documents/Documents"
import { TagGroupI, TagObjectType } from "~/domains/tags/types"
import { toast } from "react-toastify"
import { ModalAddToBudget } from "../../components/ModalAddToBudget/ModalAddToBudget"
import { useFetchBudgetsData } from "~/store/budget/hooks"
import { AddTransactionPayloadI, TransactionType } from "~/features/budget/types"
import { TransactionInBudgets } from "../../components/TransactionInBudgets"
import { CustomFieldObjectType } from "../../custom-fields/types/CustomFieldObjectType"
import { LinesTabs } from "../../custom-fields/components/LinesTabs"
import { ActionsHeader } from "../components/Actions/ActionsHeader"
import useQueryParams from "~/utils/hooks/useQueryParams"
import { selectIsConnected } from "~/store/auth/authSlice"
import { organizationApi } from "~/api"
import { useFetchSharedObjects } from "~/domains/identity/store/hooks/useFetchSharedObjects"
import { SharedObjectType } from "~/types/SharedObjects"
import { ShareObjectModal } from "~/components/ShareObjectModal"
import { PurchaseOrderDocumentRelations } from "../../document-relations/components/PurchaseOrderDocumentRelations"
import { usePurchaseOrder } from "../hooks"
import { withSocketIOProvider } from "../../common/subscriptions/components/SocketIOContext"
import { CommunicationRoom } from "~/domains/communication/components/CommunicationRoom"
import { selectPartnersBrandNames } from "../../book-of-relations/store/bookOfRelationsSlice"
import { useOrganizationTagGroups } from "~/domains/tags/hooks"
import { useTagsForLines } from "../../components/Items/hooks/useTagsForLines"
import {
    TagsSelectorForLineCells,
    TagsSelectorWithStateForLineCells,
} from "~/domains/tags/components/TagsSelector/TagsSelectorForLineCells"

import "../../assets/css/Purchases.scss"
import { DocumentObjectType, DraftDocumentI } from "~/components/UploadDocument/Documents"
import { ActionsHeaderCreate } from "../components/Actions/ActionsHeaderCreate"
import { tagsActions } from "~/domains/tags/store/tagsSlice"
import { useCreateDraftPurchaseOrder } from "../store/hooks/useCreateDraftPurchaseOrder"
import { TagsForCreatingPurchaseOrder } from "../components/TagsForCreatingPurchaseOrder"
import { CurrencySelector } from "~/components/CurrencySelector"

const Items = lazy(() => import("../../components/Items/ItemsPO").then(({ ItemsPO }) => ({ default: ItemsPO })))
const Organizations = lazy(() =>
    import("../../components/Organizations/Organizations").then(({ Organizations }) => ({ default: Organizations }))
)

const messages = defineMessages({
    htmlTitle: { id: "purchase.orders.order.htmlTitle", defaultMessage: "Purchase order" },
    titleEdit: { id: "purchase.orders.order.titleEdit", defaultMessage: "Edit PO" },
    titleView: { id: "purchase.orders.order.titleView", defaultMessage: "PO" },
    titleNew: { id: "purchase.orders.order.titleNew", defaultMessage: "New PO" },
    items: { id: "purchase.orders.order.items", defaultMessage: "Items" },
    esgCustomFields: { id: "purchase.orders.order.esgCustomFields", defaultMessage: "ESG" },
    taxLabel: { id: "purchase.orders.order.taxLabel", defaultMessage: "Tax excl." },
    purchaseRequest: { id: "purchase.orders.order.purchaseRequest", defaultMessage: "Purchase request" },
    cancel: { id: "purchase.orders.order.cancel", defaultMessage: "Cancel this PO" },
    addToBudget: { id: "purchase.orders.order.addToBudget", defaultMessage: "Add to budget" },
    budgets: { id: "purchase.orders.order.budgets", defaultMessage: "Budgets" },
    errorChangedOrganization: {
        id: "purchase.requests.request.errorChangedOrganization",
        defaultMessage: "You have changed the organization. This organization does not have this purchase request.",
    },
    tagsTitle: { id: "purchase.orders.order.tagsTitle", defaultMessage: "Tags" },
    currency: {
        id: "purchase.requests.modalNewPR.currency",
        defaultMessage: "Currency",
    },
})

interface Props extends JSX.IntrinsicAttributes {
    edit?: boolean
}

interface ViewProps extends Props {
    organization?: OrganizationI
    purchaseOrder: PurchaseOrders
    isFromSharedEmail: boolean
    sharedOrganizationId?: OrganizationId
    sharedUserId?: string
    loading?: boolean
    newPO?: boolean
}

const editablePurchaseOrderStatuses: Partial<Record<PurchaseOrderStatus, boolean>> = {
    [PurchaseOrderStatus.AWAITING_BUYER_APPROVAL]: true,
    [PurchaseOrderStatus.DRAFT_BUYER]: true,
    [PurchaseOrderStatus.SUBMITTED_BUYER]: true,
}

const purchaseOrderStatusesWithoutCommunication: Partial<Record<PurchaseOrderStatus, boolean>> = {
    [PurchaseOrderStatus.DRAFT_BUYER]: true,
    [PurchaseOrderStatus.DRAFT_SUPPLIER]: true,
    [PurchaseOrderStatus.SUBMITTED_BUYER]: true,
    [PurchaseOrderStatus.SUBMITTED_SUPPLIER]: true,
}

export const organizationAddressToAddress = (address: OrganizationAddressI): Address => ({
    city: address.city,
    country: address.country,
    street: address.addressLine,
    street2: address.secondaryAddressLine,
    zipCode: address.zipCode,
})

const PurchaseOrderView: React.FC<ViewProps> = ({
    edit,
    organization,
    purchaseOrder,
    isFromSharedEmail,
    sharedOrganizationId,
    sharedUserId,
    loading = false,
    newPO = false,
}) => {
    const { formatMessage } = useIntl()
    const user = useAppSelector(selectUser)
    const isConnected = useAppSelector(selectIsConnected)
    const viewType = useAppSelector(selectPurchaseViewType)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [isShared, setIshared] = useState<boolean>(isFromSharedEmail)
    const [modalAddToBudgetVisible, setModalAddToBudgetVisible] = useState<boolean>(false)
    const [shareObjectModalOpen, setShareObjectModalOpen] = useState<boolean>(false)
    const [draftDocuments, setDraftDocuments] = useState<DraftDocumentI[]>([])

    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organization?.id)
    const organizationSharings = useFetchSharedObjects(organization?.id)
    const brandNames = useAppSelector(selectPartnersBrandNames)

    const { tagGroups } = useOrganizationTagGroups(organization?.id)
    const {
        selectedTags: tags,
        setSelectedTags: setTags,
        fetchTags,
    } = useTagsForLines({
        lines: purchaseOrder.lines,
        organizationId: organization?.id,
        tagGroups,
        objectType: TagObjectType.PURCHASE_ORDER_LINE,
    })

    const pageMode = newPO ? PurchaseViewType.ADD : edit ? PurchaseViewType.EDIT : PurchaseViewType.VIEW
    const title = formatMessage(messages[`title${pageMode === PurchaseViewType.EDIT ? "Edit" : "View"}`])

    const { budgetsData } = useFetchBudgetsData(organization?.id, false)
    const transactionPayload: AddTransactionPayloadI = {
        transactionRefId: purchaseOrder.id,
        transactionStatus: purchaseOrder.status,
        transactionType: TransactionType.PURCHASE_ORDER,
        supplierOrgId: purchaseOrder.supplierId,
        buyerOrgId: organization?.id ?? "",
        description: purchaseOrder.description,
        amount: purchaseOrder.totalAmount ?? 0,
        amountWithoutTaxes: purchaseOrder.totalAmountExcludingTax,
        amountRemainingToPay: 0,
        expectedDeliveryDate: purchaseOrder.expectedDeliveryDate,
        partialAmount: 0,
        partialRate: 0,
    }

    const {
        updatePO,
        loading: updateLoading,
        error: updateError,
    } = useUpdatePurchaseOrder(purchaseOrder.id, organization?.id)
    const {
        updatePOStatus,
        loading: updateStatusLoading,
        error: updateStatusError,
    } = useUpdatePurchaseOrderStatus(purchaseOrder.id, viewType)

    const getOrganizationName = useCallback(
        (sideId: string, organizationId?: string, sideName?: string): string => {
            if (sideId && organizationId && sideId !== organizationId) {
                return brandNames[sideId] ?? sideName ?? ""
            }
            return sideName ?? ""
        },
        [brandNames]
    )

    const updateAddresses = useCallback(
        async (payload: SavePayloadType) => {
            if (pageMode === PurchaseViewType.ADD) {
                if (payload.organizationAddress) {
                    dispatch(
                        purchaseOrdersActions.updateData({
                            field: "billingAddress",
                            value: organizationAddressToAddress(payload.organizationAddress),
                        })
                    )
                }
                if (payload.sendingAddress) {
                    dispatch(
                        purchaseOrdersActions.updateData({
                            field: "shippingAddress",
                            value: organizationAddressToAddress(payload.sendingAddress),
                        })
                    )
                }
            } else {
                const updatePayload: PurchaseOrders = {
                    ...purchaseOrder,
                    ...(payload.sendingAddress
                        ? { shippingAddress: organizationAddressToAddress(payload.sendingAddress) }
                        : {}),
                    ...(payload.organizationAddress
                        ? { billingAddress: organizationAddressToAddress(payload.organizationAddress) }
                        : {}),
                }
                await updatePO(updatePayload)
            }
        },
        [purchaseOrder, pageMode, updatePO]
    )

    const setDeliveryDate = useCallback((date: Dayjs | null) => {
        if (date) {
            dispatch(
                purchaseOrdersActions.updateData({
                    field: "expectedDeliveryDate",
                    value: date.add(18, "hours").toISOString(),
                })
            )
        }
    }, [])

    const handleChangeDescription = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(
            purchaseOrdersActions.updateData({
                field: "description",
                value: e.target.value,
            })
        )
    }, [])

    const handleCurrencyChange = useCallback(
        (currency: CurrencyI | null) => {
            if (currency) {
                dispatch(
                    purchaseOrdersActions.updateData({
                        field: "currency",
                        value: currency.code as CurrencyCodes,
                    })
                )
            }
        },
        [dispatch]
    )

    const from: OrganizationSummary = {
        id: purchaseOrder.buyerId,
        name: getOrganizationName(purchaseOrder.buyerId, organization?.id, purchaseOrder.buyerName),
        type: ViewTypeI.buyer,
    }
    const to: OrganizationSummary = {
        id: purchaseOrder.supplierId,
        name: getOrganizationName(purchaseOrder.supplierId, organization?.id, purchaseOrder.supplierName),
        type: ViewTypeI.supplier,
    }

    const tagSelectorContext = useMemo(
        () => ({
            page: "purchase-order",
            viewType,
            purchaseOrder,
            organization,
            user,
        }),
        [purchaseOrder, organization, viewType, user]
    )

    const objectSharings = useMemo(
        () =>
            organizationSharings.filter(
                (share) => share.objectId === purchaseOrder.id && share.objectType === SharedObjectType.PurchaseOrder
            ),
        [organizationSharings, purchaseOrder.id]
    )

    useEffect(() => {
        if (tagGroups && tagGroups.length) {
            fetchTags()
        }
    }, [tagGroups])

    useEffect(() => {
        if (isConnected && isFromSharedEmail && sharedOrganizationId && sharedUserId && user.id === sharedUserId) {
            const organizationAlreadyExists = user.organizations.find((org) => org.id === sharedOrganizationId)
            if (!organizationAlreadyExists) {
                const membershipAlreadyRequested = user.membershipRequests?.find(
                    (org) => org.id === sharedOrganizationId
                )
                if (!membershipAlreadyRequested) {
                    organizationApi.requestMembership(sharedOrganizationId).then(() => {
                        document.location.reload()
                    })
                }
            } else if (purchaseOrder && purchaseOrder.id) {
                dispatch(organizationActions.setCurrentOrganization(sharedOrganizationId))
                setIshared(false)
            }
        }
    }, [isConnected, user, isFromSharedEmail, sharedOrganizationId, purchaseOrder, sharedUserId])

    const showModalAddToBudget = useCallback(() => setModalAddToBudgetVisible(true), [])
    const hideModalAddToBudget = useCallback(() => setModalAddToBudgetVisible(false), [])

    const showShareObjectModal = useCallback(() => setShareObjectModalOpen(true), [setShareObjectModalOpen])
    const hideShareObjectModal = useCallback(() => setShareObjectModalOpen(false), [setShareObjectModalOpen])

    const handleSuccess = useCallback(async () => {
        await fetchPurchaseOrder(purchaseOrder.id, true)
    }, [dispatch, purchaseOrder])

    const updatePurchaseOrderStatus = useCallback(
        async (status: PurchaseOrderStatusUpdate) => {
            if (organization) {
                dispatch(purchaseOrdersActions.updatePO())
                await updatePOStatus(organization.id, status, purchaseOrder.status)
                await fetchPurchaseOrder(purchaseOrder.id)
                dispatch(purchaseOrdersActions.updatePOSuccess())
            }
        },
        [purchaseOrder, updatePOStatus, organization]
    )

    const handleSend = useCallback(async () => {
        if (viewType === ViewTypeI.buyer && objectSharings.length === 0) {
            showShareObjectModal()
        } else if (organization) {
            await updatePurchaseOrderStatus(PurchaseOrderStatusUpdate.SEND)
        }
    }, [purchaseOrder, organization, objectSharings, updatePurchaseOrderStatus, showShareObjectModal])

    const handleSave = async () => {
        await updatePO(purchaseOrder)
        navigate(generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: purchaseOrder.id }))
    }

    const handleSubmit = useCallback(async () => {
        await updatePurchaseOrderStatus(PurchaseOrderStatusUpdate.SUBMIT)
    }, [purchaseOrder])

    const handleEdit = useCallback(() => {
        navigate(generatePath(PURCHASE_ORDER_EDIT_ROUTE, { purchaseOrderId: purchaseOrder.id }))
    }, [purchaseOrder])

    const handleSharedObject = useCallback(async () => {
        if (organization) {
            await updatePurchaseOrderStatus(PurchaseOrderStatusUpdate.SEND)
            setShareObjectModalOpen(false)
        }
    }, [purchaseOrder, organization, updatePurchaseOrderStatus])

    const renderLineTags = useCallback(
        (line: PurchaseOrderLine, tagGroupId?: string, usedTagGroups?: TagGroupI[]) => {
            if (!user || !organization) return null

            if (line.id) {
                return (
                    <TagsSelectorForLineCells
                        objectId={line.id}
                        tags={tags}
                        setTags={setTags}
                        tagGroups={tagGroups ?? []}
                        tagGroupId={tagGroupId ?? ""}
                        usedTagGroups={usedTagGroups ?? []}
                    />
                )
            }

            if (line.temporaryId) {
                return (
                    <TagsSelectorWithStateForLineCells
                        temporaryId={line.temporaryId}
                        tagGroups={tagGroups ?? []}
                        tagGroupId={tagGroupId ?? ""}
                        usedTagGroups={usedTagGroups ?? []}
                    />
                )
            }

            return null
        },
        [user, organization, tags, setTags, purchaseOrder]
    )

    const otherOrganizations = useMemo(() => {
        const otherOrganizations: OrganizationId[] = []
        if (purchaseOrderStatusesWithoutCommunication[purchaseOrder.status]) return otherOrganizations
        if (purchaseOrder.buyerId && (!organization || purchaseOrder.buyerId !== organization.id)) {
            otherOrganizations.push(purchaseOrder.buyerId)
        }
        if (purchaseOrder.supplierId && (!organization || purchaseOrder.supplierId !== organization.id)) {
            otherOrganizations.push(purchaseOrder.supplierId)
        }
        return otherOrganizations
    }, [organization, purchaseOrder])

    const allLinesApproved = useMemo(
        () =>
            purchaseOrder.lines.every(
                (line) =>
                    line[viewType === ViewTypeI.supplier ? "supplierApprovalStatus" : "buyerApprovalStatus"] ===
                    PurchaseOrderApprovalStatus.APPROVED
            ),
        [purchaseOrder.lines, viewType]
    )

    const isOnApprovalSide = useMemo(
        () =>
            (viewType === ViewTypeI.supplier && purchaseOrder.status === PurchaseOrderStatus.SUBMITTED_SUPPLIER) ||
            (viewType === ViewTypeI.supplier &&
                purchaseOrder.status === PurchaseOrderStatus.AWAITING_SUPPLIER_APPROVAL) ||
            (viewType === ViewTypeI.buyer && purchaseOrder.status === PurchaseOrderStatus.SUBMITTED_BUYER) ||
            (viewType === ViewTypeI.buyer && purchaseOrder.status === PurchaseOrderStatus.AWAITING_BUYER_APPROVAL),
        [purchaseOrder.status, viewType]
    )

    return (
        <div className={"purchase-page"}>
            <HeaderH1
                title={title + "#" + purchaseOrder.shortId}
                backLink={generatePath(PURCHASE_ORDERS_ROUTE, { viewType })}
                isShared={isShared}
            >
                {!isShared &&
                    organization &&
                    (newPO ? (
                        <ActionsHeaderCreate
                            organizationId={organization.id}
                            draftDocuments={draftDocuments}
                            setDraftDocuments={setDraftDocuments}
                        />
                    ) : (
                        <ActionsHeader
                            PO={purchaseOrder}
                            organization={organization}
                            buyerOrganization={{
                                id: purchaseOrder.buyerId,
                                name: getOrganizationName(
                                    organization.id,
                                    purchaseOrder.buyerId,
                                    purchaseOrder.buyerName
                                ),
                            }}
                            supplierOrganization={{
                                id: purchaseOrder.supplierId,
                                name: getOrganizationName(
                                    organization.id,
                                    purchaseOrder.supplierId,
                                    purchaseOrder.supplierName
                                ),
                            }}
                            handleSend={handleSend}
                            handleEdit={handleEdit}
                            handleSave={handleSave}
                            handleSubmit={handleSubmit}
                            mode={pageMode}
                            viewType={viewType}
                            allLinesApproved={allLinesApproved}
                            isOnApprovalSide={isOnApprovalSide}
                        />
                    ))}
            </HeaderH1>
            {updateLoading || updateStatusLoading || loading ? <Loader fullscreen /> : null}
            <StepsPurchaseOrder PO={purchaseOrder} viewType={viewType} />
            <Grid container className={"main-box purchase-order"} spacing={0} gap={2}>
                <Grid xs className={"pr-column pr-column-left"}>
                    <TotalAmount
                        amount={purchaseOrder.totalAmountExcludingTax}
                        currency={purchaseOrder.currency}
                        taxLabel={formatMessage(messages.taxLabel)}
                    />
                    <Dates
                        creationDate={purchaseOrder.creationDate}
                        expectedDeliveryDate={purchaseOrder.expectedDeliveryDate}
                        pageMode={pageMode}
                        setDeliveryDate={setDeliveryDate}
                        required={true}
                    />
                    <RequesterName requesterName={purchaseOrder.requesterName} />

                    {!isShared && organization ? (
                        <>
                            <CurrencySelector
                                value={purchaseOrder.currency}
                                label={formatMessage(messages.currency)}
                                editMode={!purchaseOrder.id || pageMode === PurchaseViewType.EDIT}
                                onChange={handleCurrencyChange}
                            />
                            {purchaseOrder.buyerId === organization.id &&
                            purchaseOrder.purchaseRequest &&
                            purchaseOrder.purchaseRequest.id ? (
                                <PurchaseRequestLink
                                    purchaseRequest={purchaseOrder.purchaseRequest}
                                    label={formatMessage(messages.purchaseRequest)}
                                />
                            ) : null}
                            {purchaseOrder.id && (
                                <PurchaseOrderDocumentRelations
                                    organizationId={organization.id}
                                    purchaseOrder={purchaseOrder}
                                    viewType={viewType}
                                />
                            )}
                            {purchaseOrder.id ? (
                                <TransactionInBudgets
                                    transaction={purchaseOrder}
                                    showModalAddToBudget={showModalAddToBudget}
                                />
                            ) : null}

                            <h4 className="tags-title">{formatMessage(messages.tagsTitle)}</h4>
                            {!newPO ? (
                                <>
                                    <TagsSelector
                                        organizationId={organization.id}
                                        objectId={purchaseOrder.id}
                                        userId={user.id}
                                        objectType={TagObjectType.PURCHASE_REQUEST}
                                        context={tagSelectorContext}
                                    />
                                    <Documents
                                        objectId={purchaseOrder.id}
                                        objectType={DocumentObjectType.PURCHASE_REQUEST}
                                        organizationId={organization.id}
                                    />
                                </>
                            ) : (
                                <>
                                    <TagsForCreatingPurchaseOrder organizationId={organization.id} />
                                    <Documents
                                        organizationId={organization.id}
                                        objectType={DocumentObjectType.PURCHASE_REQUEST}
                                        draftDocuments={draftDocuments}
                                        setDraftDocuments={setDraftDocuments}
                                    />
                                </>
                            )}

                            {pageMode === PurchaseViewType.VIEW ? (
                                <ActionsColumn
                                    PO={purchaseOrder}
                                    organizationId={organization.id}
                                    viewType={viewType}
                                    isOnApprovalSide={isOnApprovalSide}
                                    handleSend={handleSend}
                                />
                            ) : null}
                        </>
                    ) : null}
                </Grid>
                <Grid lg={7} xs={12} className={"main-content"}>
                    <Organizations
                        from={from}
                        to={to}
                        updateAddresses={updateAddresses}
                        billingAddress={purchaseOrder.billingAddress}
                        shippingAddress={purchaseOrder.shippingAddress}
                        readOnly={isShared || !editablePurchaseOrderStatuses[purchaseOrder.status]}
                        currentOrgSide={viewType}
                        showOrganizationSelector={!purchaseOrder.supplierId || newPO}
                        objectType={DocumentObjectType.PURCHASE_ORDER}
                    />
                    <ErrorMessage>{updateError}</ErrorMessage>
                    <ErrorMessage>{updateStatusError}</ErrorMessage>
                    <Description
                        description={purchaseOrder.description}
                        pageMode={pageMode}
                        handleChange={handleChangeDescription}
                    />

                    {purchaseOrder ? (
                        <LinesTabs
                            items={purchaseOrder.lines}
                            idKey="id"
                            temporaryIdKey="temporaryId"
                            contextType={CustomFieldObjectType.PURCHASE_ORDER}
                            contextId={purchaseOrder.id}
                            currency={purchaseOrder.currency}
                            organizationId={sharedOrganizationId ?? (organization?.id as OrganizationId)}
                            renderLineTags={renderLineTags}
                        >
                            <Items
                                id={purchaseOrder.id}
                                organizationId={sharedOrganizationId ?? (organization?.id as OrganizationId)}
                                lines={purchaseOrder.lines}
                                totalAmount={purchaseOrder.totalAmount ?? 0}
                                totalAmountExcludingTax={purchaseOrder.totalAmountExcludingTax}
                                currency={purchaseOrder.currency}
                                status={purchaseOrder.status}
                                mode={pageMode}
                                viewType={viewType}
                                isShared={isShared}
                                purchaseOrder={purchaseOrder}
                            />
                        </LinesTabs>
                    ) : null}
                </Grid>
                {organization && purchaseOrder.id && (
                    <Grid xs className="pr-column pr-column-left">
                        <CommunicationRoom
                            organizationId={organization.id}
                            objectId={purchaseOrder.id}
                            objectType={SharedObjectType.PurchaseOrder}
                            otherOrganizations={otherOrganizations}
                        />
                    </Grid>
                )}
            </Grid>
            {!isShared && organization ? (
                <>
                    <ModalAddToBudget
                        open={modalAddToBudgetVisible}
                        close={hideModalAddToBudget}
                        organizationId={organization.id}
                        budgets={budgetsData}
                        transaction={purchaseOrder}
                        transactionPayload={transactionPayload}
                        handleSuccess={handleSuccess}
                    />
                    {shareObjectModalOpen ? (
                        <ShareObjectModal
                            open={shareObjectModalOpen}
                            close={hideShareObjectModal}
                            objectType={SharedObjectType.PurchaseOrder}
                            objectId={purchaseOrder.id}
                            organizationId={organization.id}
                            buyerOrganization={from}
                            supplierOrganization={to}
                            onlyEmail={true}
                            sendShareEmail={false}
                            callback={handleSharedObject}
                        />
                    ) : null}
                </>
            ) : null}
        </div>
    )
}

export const PurchaseOrder = withSocketIOProvider(({ edit }: Props) => {
    const navigate = useNavigate()
    const { formatMessage } = useIntl()
    const { purchaseOrderId } = useParams()
    const pageName = formatMessage(messages.htmlTitle)
    useTitle(pageName)
    const dispatch = useAppDispatch()
    const organization = useAppSelector(selectCurrentOrganization)
    const loading = useAppSelector(selectPurchaseOrdersLoading)
    const { s, o: sharedOrganizationId, u: sharedUserId } = useQueryParams()
    const isFromSharedEmail = !!(s && s === "t")

    const purchaseOrderOrganizationId =
        isFromSharedEmail && sharedOrganizationId ? sharedOrganizationId : organization?.id
    const {
        purchaseOrder: PO,
        fetchPurchaseOrder,
        changedOrganization,
    } = usePurchaseOrder(purchaseOrderId, purchaseOrderOrganizationId, organization?.id === purchaseOrderOrganizationId)
    const error = useAppSelector(selectPurchaseOrdersError)

    useEffect(() => {
        if (error) {
            toast.error(changedOrganization ? formatMessage(messages.errorChangedOrganization) : error)
            navigate(generatePath(PURCHASE_ORDERS_ROUTE, { viewType: ViewTypeI.buyer }))
        }
    }, [error, changedOrganization])

    useEffect(() => {
        if (isFromSharedEmail && purchaseOrderId && sharedOrganizationId) {
            fetchPurchaseOrder(purchaseOrderId, false).then((data) => {
                if (data) {
                    dispatch(
                        purchaseOrdersActions.setViewType(
                            sharedOrganizationId === data.buyerId ? ViewTypeI.buyer : ViewTypeI.supplier
                        )
                    )
                }
            })
        }
    }, [purchaseOrderId, isFromSharedEmail, sharedOrganizationId])

    useEffect(() => {
        return () => {
            dispatch(purchaseOrdersActions.resetData())
        }
    }, [])

    if (!organization && !sharedOrganizationId) {
        return null
    }

    if (!PO || !PO.id) {
        return <Loader fullscreen={true} />
    }

    return (
        <PurchaseOrderView
            purchaseOrder={PO}
            organization={organization}
            sharedOrganizationId={sharedOrganizationId}
            sharedUserId={sharedUserId}
            edit={edit}
            isFromSharedEmail={isFromSharedEmail}
            loading={loading}
        />
    )
})

export function PurchaseOrderNew() {
    const { formatMessage } = useIntl()
    const pageName = formatMessage(messages.htmlTitle)
    useTitle(pageName)
    const dispatch = useAppDispatch()
    const organization = useAppSelector(selectCurrentOrganization)
    const sharedEmail = false

    useEffect(() => {
        dispatch(purchaseOrdersActions.resetData())
        dispatch(tagsActions.resetPendingTags())

        return () => {
            dispatch(purchaseOrdersActions.resetData())
            dispatch(tagsActions.resetPendingTags())
        }
    }, [])

    const purchaseOrder = useCreateDraftPurchaseOrder(organization)

    if (!organization || !purchaseOrder) {
        return null
    }
    return (
        <PurchaseOrderView
            edit={true}
            newPO={true}
            isFromSharedEmail={sharedEmail}
            organization={organization}
            purchaseOrder={purchaseOrder}
        />
    )
}
