import * as t from "io-ts"
import { optional } from "~/types/utils"
import { TagId } from "./Tag"

export const CreateTagObjectIO = t.intersection([
    t.type({
        objectId: t.string,
        tagId: t.string,
    }),
    t.partial({
        objectType: optional(t.string),
        contextId: optional(t.string),
        ratio: optional(t.number),
        objectContext: optional(t.any),
    }),
])

export type CreateTagObjectI = t.TypeOf<typeof CreateTagObjectIO> & {
    tagId: TagId
}
