/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { all, call, put, takeLatest } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { authActions } from "./authSlice"
import { auth0, LoginPageType } from "~/utils"
import { accountActions } from "~/store/account/accountSlice"

function* isAuthenticated() {
    try {
        const result: boolean = yield call(auth0().isAuthenticated)
        if (result) {
            yield put(accountActions.fetchUser())
        } else {
            yield put(authActions.setIsConnected(false))
            yield put(accountActions.disconnect())
        }
    } catch (error) {
        yield put(authActions.setIsLoading(false))
        yield put(accountActions.disconnect())
        yield call(auth0().logout)
        console.error(`isAuthenticated`, error)
    }
}

function* openPopupSignIn(action: PayloadAction<LoginPageType | undefined>) {
    try {
        const page = action.payload ?? "signin"
        const result = yield call(auth0("fr-FR").login, page)
        if (result === "cancelled") {
            yield put(authActions.setClosePopup())
        } else {
            yield put(accountActions.fetchUser())
            yield put(authActions.setIsConnected(true))
        }
    } catch (error) {
        console.error(`openPopupSignIn`, error)
    }
}

function* logout() {
    try {
        yield call(auth0().logout)
        yield put(accountActions.disconnect())
    } catch (error) {
        console.error(`logout`, error)
        yield put(accountActions.disconnect())
    }
}

export default function* authSaga() {
    yield all([
        takeLatest(authActions.isAuthenticated.type, isAuthenticated),
        takeLatest(authActions.openPopupSignIn.type, openPopupSignIn),
        takeLatest(authActions.logout.type, logout),
    ])
}
