import React from "react"
import { Tooltip } from "@mui/material"
import { Button, ButtonType, Loader } from "~/components"
import { LineStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

import { Check, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { OrganizationId, SpiceDBPermissionsResult } from "~/types"
import { usePurchaseRequestLineValidationActions } from "../../purchase-requests/store/hooks"
import { CircularProgressBar } from "~/domains/common/components/ProgressBar/CircularProgressBar"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"

const messages = defineMessages({
    reject: {
        id: "purchase.requests.request.items.label.reject",
        defaultMessage: "Reject Line",
    },
    rejected: {
        id: "purchase.requests.request.items.label.rejected",
        defaultMessage: "Line rejected",
    },
    approve: {
        id: "purchase.requests.request.items.label.approve",
        defaultMessage: "Approve Line",
    },
    approved: {
        id: "purchase.requests.request.items.label.approved",
        defaultMessage: "Line approved",
    },
})

interface Props {
    organizationId: OrganizationId
    purchaseRequestId: string
    lineId: string | null
    status: LineStatus
    permissions?: SpiceDBPermissionsResult
}

export function ActionsPR({ organizationId, purchaseRequestId, lineId, status, permissions }: Props) {
    const { formatMessage } = useIntl()
    const {
        loading,
        withWorkflow,
        statusLoading,
        currentUserApproval,
        currentUserCanValidate,
        approveLine,
        refuseLine,
        checkDone,
        totalChecks,
    } = usePurchaseRequestLineValidationActions(organizationId, purchaseRequestId, lineId)
    const approvePermission = permissions?.approve
    const canApprove = (approvePermission && typeof approvePermission !== "undefined") ?? false
    const permissionLabel = formatMessage(permissionMessages.errorNoAccessAdministrator)

    let classReject: ButtonType = "error-light"
    let classApprove: ButtonType = "success"
    let labelReject = formatMessage(messages.reject)
    let labelApprove = formatMessage(messages.approve)

    switch (status) {
        case LineStatus.APPROVED:
            classApprove = "primary"
            labelApprove = formatMessage(messages.approved)
            break
        case LineStatus.REJECTED:
            classReject = "error"
            labelReject = formatMessage(messages.rejected)
            break
    }

    if (loading || statusLoading) {
        return <Loader small={true} />
    }

    if (withWorkflow) {
        return (
            <span className="actions-wrapper">
                {currentUserCanValidate && (
                    <>
                        <Tooltip title={!canApprove ? permissionLabel : labelReject} arrow={true}>
                            <span>
                                <Button
                                    type={currentUserApproval ? "error" : "error-light"}
                                    disabled={!canApprove || !currentUserApproval}
                                    {...(canApprove ? { onClick: refuseLine } : {})}
                                >
                                    <X size={14} />
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title={!canApprove ? permissionLabel : labelApprove} arrow={true}>
                            <span>
                                <Button
                                    type={currentUserApproval ? "primary" : "success"}
                                    disabled={!canApprove || currentUserApproval}
                                    {...(canApprove ? { onClick: approveLine } : {})}
                                >
                                    <Check size={14} />
                                </Button>
                            </span>
                        </Tooltip>
                    </>
                )}

                <CircularProgressBar progress={checkDone / totalChecks} />
                <span className="workflow-progress-label">
                    {checkDone} / {totalChecks}
                </span>
            </span>
        )
    }

    if (currentUserCanValidate) {
        return (
            <span className={"actions-wrapper"}>
                <Tooltip title={!canApprove ? permissionLabel : labelReject} arrow={true}>
                    <span>
                        <Button
                            type={classReject}
                            disabled={!canApprove || status === LineStatus.REJECTED}
                            {...(canApprove ? { onClick: refuseLine } : {})}
                        >
                            <X size={14} />
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip title={!canApprove ? permissionLabel : labelApprove} arrow={true}>
                    <span>
                        <Button
                            type={classApprove}
                            disabled={!canApprove || status === LineStatus.APPROVED}
                            {...(canApprove ? { onClick: approveLine } : {})}
                        >
                            <Check size={14} />
                        </Button>
                    </span>
                </Tooltip>
            </span>
        )
    }
    return null
}
