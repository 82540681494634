import React, { useCallback, useEffect, useMemo } from "react"
import { Loader } from "~/components"
import { PurchaseRequestLine, PurchaseRequestSummary } from "../../types/PurchaseRequests"
import { usePurchaseRequest } from "../../store/hooks/usePurchaseRequest"
import { OrganizationId } from "~/types"
import { LinesTabs } from "~/domains/transactions/custom-fields/components/LinesTabs"
import { CustomFieldObjectType } from "~/domains/transactions/custom-fields/types/CustomFieldObjectType"
import { ItemsPR } from "~/domains/transactions/components/Items/ItemsPR"
import { PurchaseViewType } from "~/domains/transactions/types/Purchases"
import { Box, styled } from "@mui/material"
import { useAppSelector } from "~/store/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { useOrganizationTagGroups } from "~/domains/tags/hooks"
import { useTagsForLines } from "~/domains/transactions/components/Items/hooks/useTagsForLines"
import { TagGroupI, TagObjectType } from "~/domains/tags/types"
import { TagsSelectorForLineCells } from "~/domains/tags/components/TagsSelector/TagsSelectorForLineCells"

interface Props {
    organizationId: OrganizationId
    purchaseRequest: PurchaseRequestSummary
}

const LinesContainer = styled(Box)({
    paddingLeft: "50px",
    paddingBottom: "16px",
})

export const PRListDetails: React.FC<Props> = ({ organizationId, purchaseRequest: purchaseRequestSummary }) => {
    const user = useAppSelector(selectUser)
    const { purchaseRequest } = usePurchaseRequest(organizationId, purchaseRequestSummary.id)

    const linesForAnalytics = useMemo(() => {
        return purchaseRequest.lines.map((line, index) => ({ ...line, id: line.id ?? `${index}` }))
    }, [purchaseRequest.lines])

    const { tagGroups } = useOrganizationTagGroups(organizationId)
    const {
        selectedTags: tags,
        setSelectedTags: setTags,
        fetchTags,
    } = useTagsForLines({
        lines: linesForAnalytics,
        organizationId,
        tagGroups,
        objectType: TagObjectType.PURCHASE_REQUEST_LINE,
    })

    useEffect(() => {
        if (tagGroups && tagGroups.length && purchaseRequest.id) {
            fetchTags()
        }
    }, [tagGroups, purchaseRequest.id])

    const renderLineTags = useCallback(
        (line: PurchaseRequestLine, tagGroupId?: string, usedTagGroups?: TagGroupI[]) => {
            if (!user || !organizationId) return null

            const objectId = `${line.id}`
            return (
                <TagsSelectorForLineCells
                    objectId={objectId}
                    tags={tags}
                    setTags={setTags}
                    tagGroups={tagGroups ?? []}
                    tagGroupId={tagGroupId}
                    usedTagGroups={usedTagGroups}
                />
            )
        },
        [user, organizationId, tags, setTags, purchaseRequest]
    )

    if (purchaseRequest.id !== purchaseRequestSummary.id) {
        return (
            <div>
                <Loader />
            </div>
        )
    }

    return (
        <LinesContainer>
            <LinesTabs
                items={linesForAnalytics}
                idKey="id"
                temporaryIdKey="temporaryId"
                contextType={CustomFieldObjectType.PURCHASE_REQUEST}
                contextId={purchaseRequest.id}
                organizationId={organizationId}
                currency={purchaseRequest.currency}
                renderLineTags={renderLineTags}
            >
                <ItemsPR
                    mode={PurchaseViewType.VIEW}
                    id={purchaseRequest.id}
                    organizationId={organizationId}
                    lines={purchaseRequest.lines}
                    totalAmount={purchaseRequest.totalAmount ?? 0}
                    totalAmountExcludingTax={purchaseRequest.totalAmountExcludingTax}
                    currency={purchaseRequest.currency}
                    status={purchaseRequest.status}
                    withoutTitle
                />
            </LinesTabs>
        </LinesContainer>
    )
}
