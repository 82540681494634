import React from "react"
import { AmountInList } from "~/domains/transactions/components/AmountInList"
import { CurrencyCodes } from "~/types"

import "./TotalAmount.scss"

interface TotalAmountProps {
    amount: number
    currency: CurrencyCodes
    taxLabel: string
}

export function TotalAmount({ amount, currency, taxLabel }: TotalAmountProps) {
    return (
        <div className="total-amount">
            <AmountInList amount={amount} currency={currency} />
            <small>({taxLabel})</small>
        </div>
    )
}
