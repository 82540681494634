import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { RootState } from "~/store"

export interface ErrorsState {
    isError: boolean
    isErrorAuth: boolean
    error: string | null
    lastErrorTime: number
}

const initialState: ErrorsState = {
    isError: false,
    isErrorAuth: false,
    error: null,
    lastErrorTime: 0,
}

const errorsSlice = createSlice({
    name: "errors",
    initialState: initialState,
    reducers: {
        setError: (state, action: PayloadAction<string>) => {
            const previousError = state.error
            const now = Date.now() / 1000
            if (action.payload !== "Login required" && (previousError !== action.payload || !now || now - state.lastErrorTime > 3)) {
                state.lastErrorTime = now
                state.error = action.payload
                if(!action.payload.includes("- 404")) {
                    toast.error(action.payload)
                }
            }
        },
        setErrorAuth: (state, action: PayloadAction<boolean>) => {
            state.isErrorAuth = action.payload
        },
        cleanErrors: () => {
            toast.dismiss()
            return initialState
        },
    },
})

// Actions
export const errorsActions = errorsSlice.actions

// // Selectors
export const selectErrorAuth = (state: RootState) => state.errors.isErrorAuth

// // Reducer
const errorsReducer = errorsSlice.reducer
export default errorsReducer
