import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { isDefined } from "~/utils/isDefined"
import { purchaseRequestsActions } from "../purchaseRequestsSlice"
import { PurchaseRequestConversionStatus, PurchaseRequestSummary } from "../../types/PurchaseRequests"
import { useConvertToPurchaseOrder } from "./useConvertToPurchaseOrder"

const messages = defineMessages({
    purchaseRequestConverted: {
        id: "purchase.requests.purchaseRequestConverted",
        defaultMessage:
            "Your purchase {count, plural, =0 {request} one {request} other {requests}} has been successfully converted.",
    },
    unableToConvertPurchaseRequest: {
        id: "purchase.requests.unableToConvertPurchaseRequest",
        defaultMessage:
            "An error occured: your purchase {count, plural, =0 {request} one {request} other {requests}} could not be converted.",
    },
    unableToConvertSomePurchaseRequests: {
        id: "purchase.requests.unableToConvertSomePurchaseRequests",
        defaultMessage:
            "An error occured: {count} purchase {count, plural, =0 {request} one {request} other {requests}} could not be converted.",
    },
})

export const useConvertPurchaseRequests = (organizationId?: OrganizationId) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const { convertToPO } = useConvertToPurchaseOrder(organizationId)

    return async (purchaseRequests: PurchaseRequestSummary[]) => {
        if (!organizationId) return
        const results = await Promise.allSettled(
            purchaseRequests.map(async (purchaseRequest) => {
                const purchaseOrder = await convertToPO(
                    purchaseRequest,
                    purchaseRequest.expectedDeliveryDate,
                    PurchaseRequestConversionStatus.SUBMITTED,
                    undefined,
                    undefined,
                    false
                )
                if (purchaseOrder) {
                    return { ...purchaseRequest, purchaseOrder }
                }

                return null
            })
        )

        const successConverted = results
            .map((r) => (r.status === "fulfilled" && r.value ? r.value : null))
            .filter(isDefined)

        const failConvertedLength = results.length - successConverted.length
        dispatch(purchaseRequestsActions.convertPurchaseRequests(successConverted))
        if (failConvertedLength > 0) {
            toast.error(
                formatMessage(
                    failConvertedLength === results.length
                        ? messages.unableToConvertPurchaseRequest
                        : messages.unableToConvertSomePurchaseRequests,
                    { count: failConvertedLength }
                )
            )
        } else {
            toast.success(formatMessage(messages.purchaseRequestConverted, { count: purchaseRequests.length }))
        }
        return true
    }
}
