import { AxiosResponse } from "axios"
import axiosClient from "./axiosClient"
import {
    ConfirmInvoicePayload,
    EventI,
    FileI,
    InvoiceI,
    InvoiceRequestI,
    MessageI,
    OrganizationId,
    PayloadValidationI,
    PaymentFileI,
    UpdateDraftInvoicePayload,
    UpdateInvoicePayload,
    ComplianceStatusI,
    InvoiceId,
} from "~/types"
import fileDownload from "js-file-download"
import { WorkflowApprovePayload } from "~/features/workflow/types"
import { DocumentType } from "~/components/UploadDocument/Documents"
import dayjs from "dayjs"

const BASE_URL_INVOICES = import.meta.env.VITE_API_INVOICES_URL + "v1/"
const BASE_URL = BASE_URL_INVOICES + "invoices/"

const STAKEHOLDERS_BASE_URL = import.meta.env.VITE_API_STAKEHOLDERS_URL

interface ProvidedTax {
    taxRateId: string
    taxAmount: number
}

export interface ProvideTaxes {
    taxes: ProvidedTax[]
}

export const invoiceApi = {
    getById(id: string): Promise<InvoiceI> {
        const url = BASE_URL + id
        return axiosClient.get(url)
    },
    getCommunications(id: string): Promise<MessageI[]> {
        const url = BASE_URL + id + "/communication"
        return axiosClient.get(url)
    },

    addCommunication(id: string, message: string /*, file?: File*/): Promise<AxiosResponse> {
        const url = `${BASE_URL}${id}/comment`
        /*         const formData = new FormData()
                formData.append("file", file) */
        return axiosClient.post(
            url,
            {
                message,
            },
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
    },

    getEvents(id: string): Promise<EventI[]> {
        const url = BASE_URL + id + "/events"
        return axiosClient.get(url)
    },

    addInvolvedPerson(invoiceId: string, userId: string): Promise<AxiosResponse> {
        return axiosClient.post(BASE_URL + invoiceId + "/involve", { userId })
    },

    removeInvolvedPerson(invoiceId: string, userId: string): Promise<AxiosResponse> {
        return axiosClient.post(BASE_URL + invoiceId + "/remove-involved", { userId: userId })
    },

    saveDraft(id: string, invoicePayload: UpdateDraftInvoicePayload) {
        return axiosClient.patch(`${BASE_URL}${id}`, invoicePayload)
    },

    async add(id: string, invoice: ConfirmInvoicePayload) {
        await axiosClient.post(BASE_URL + id + "/confirm", invoice)
    },

    updateInvoice(id: string, invoice: UpdateInvoicePayload): Promise<InvoiceI> {
        return axiosClient.post(BASE_URL + id + "/update", invoice)
    },

    async updateInvoiceType(organizationId: OrganizationId, id: InvoiceId, type: DocumentType): Promise<boolean> {
        try {
            await axiosClient.put(`${BASE_URL_INVOICES}organizations/${organizationId}/invoices/${id}/type`, { type })
        } catch {
            return false
        }
        return true
    },

    markAsResolved(id: string): Promise<InvoiceI> {
        return axiosClient.post(BASE_URL + id + "/resolve", {})
    },

    attachFile(id: string, attachedFile: FileI): Promise<AxiosResponse> {
        const formData = new FormData()
        formData.append("file", attachedFile.file)
        formData.append("type", attachedFile.type)
        formData.append("description", attachedFile.description)
        const url = BASE_URL + id + "/attach"
        return axiosClient.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },

    validate({ id, comment, tags }: PayloadValidationI): Promise<AxiosResponse> {
        const url = `${BASE_URL}${id}/validate`
        return axiosClient.post(url, {
            message: comment,
            tags,
        })
    },

    fetchWorkflowReviews(invoiceId: string): Promise<AxiosResponse> {
        const url = `${STAKEHOLDERS_BASE_URL}v1/workflows/invoices/${invoiceId}`
        return axiosClient.get(url)
    },

    approve(payload: WorkflowApprovePayload) {
        const url = `${STAKEHOLDERS_BASE_URL}v1/workflows/invoices/${payload.invoiceId}/approve`
        return axiosClient.post(url, payload)
    },

    refuse(payload: WorkflowApprovePayload) {
        const url = `${STAKEHOLDERS_BASE_URL}v1/workflows/invoices/${payload.invoiceId}/refuse`
        return axiosClient.post(url, payload)
    },

    schedulePayment(invoice: InvoiceI): Promise<AxiosResponse> {
        const url = `${BASE_URL}${invoice.id}/schedule`
        return axiosClient.post(url, {
            paymentDate: invoice.scheduledPaymentDate,
            iban: invoice?.paymentDetails?.iban,
        })
    },

    markAsPaid(id: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${id}/mark-paid`
        return axiosClient.post(url, {})
    },
    unmarkAsPaid(id: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${id}/unmark-paid`
        return axiosClient.post(url, {})
    },

    confirmPaid(id: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${id}/confirm-paid`
        return axiosClient.post(url, {})
    },

    sendRequest(request: InvoiceRequestI): Promise<AxiosResponse> {
        const url = `${BASE_URL}${request.invoiceId}/request`
        request.body = request.body.replace(/\n/g, "<br>")
        delete request.invoiceId
        return axiosClient.post(url, request)
    },

    downloadFile(file: string, name: string) {
        axiosClient
            .get(file, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                responseType: "blob",
            })
            .then((response) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fileDownload(response, name)
            })
            .catch((error) => {
                console.error(error)
            })
    },

    downloadPaymentFile({ invoiceIds, scheduleDate, fileName }: PaymentFileI) {
        axiosClient
            .post(
                `${BASE_URL}/payment-instruction`,
                {
                    ids: invoiceIds,
                    scheduledDate: scheduleDate ?? dayjs().format("YYYY-MM-DD"),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    responseType: "blob",
                }
            )
            .then((response) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fileDownload(response, fileName ? fileName + ".xml" : "payment_instruction.xml")
            })
            .catch((error) => {
                console.error(error)
            })
    },

    addBuyerTags(invoiceId: string, tagIds: string[]) {
        const url = `${BASE_URL}${invoiceId}/add-buyer-tags`
        return axiosClient.post(url, {
            ids: tagIds,
        })
    },

    addSupplierTags(invoiceId: string, tagIds: string[]) {
        const url = `${BASE_URL}${invoiceId}/add-supplier-tags`
        return axiosClient.post(url, {
            ids: tagIds,
        })
    },

    removeBuyerTags(invoiceId: string, tagIds: string[]) {
        const url = `${BASE_URL}${invoiceId}/remove-buyer-tags`
        return axiosClient.post(url, {
            ids: tagIds,
        })
    },

    removeSupplierTags(invoiceId: string, tagIds: string[]) {
        const url = `${BASE_URL}${invoiceId}/remove-supplier-tags`
        return axiosClient.post(url, {
            ids: tagIds,
        })
    },

    provideBuyerTaxes(invoiceId: string, taxes: ProvideTaxes) {
        const url = `${BASE_URL}${invoiceId}/provide-buyer-taxes`
        return axiosClient.put(url, taxes)
    },

    provideSupplierTaxes(invoiceId: string, taxes: ProvideTaxes) {
        const url = `${BASE_URL}${invoiceId}/provide-supplier-taxes`
        return axiosClient.put(url, taxes)
    },
    async getVatRates(): Promise<unknown[]> {
        const url = `/v1/vat-rates`
        return (await axiosClient.get(url)) as unknown[]
    },
    reject(invoiceId: string, organizationId: OrganizationId): Promise<AxiosResponse> {
        return axiosClient.post(`${BASE_URL_INVOICES}organizations/${organizationId}/invoices/${invoiceId}/reject`)
    },
    getComplianceStatus(invoiceId: string): Promise<ComplianceStatusI[]> {
        return axiosClient.get(`${BASE_URL}${invoiceId}/compliance-status`)
    },
    removeApproval(invoiceId: string, organizationId: OrganizationId, message?: string): Promise<AxiosResponse> {
        return axiosClient.post(`${BASE_URL}${invoiceId}/revoke-validation`, {
            buyerId: organizationId,
            message,
        })
    },
}
