import * as t from "io-ts"
import { CountryCode } from "./CountryCode"
import { Opaque } from "~/utils"

export type VatRateId = Opaque<string, { readonly T: unique symbol }>

const VatRateLabelIO = t.type({
    translations: t.record(t.string, t.string),
    defaultLanguage: t.string,
})

export const VatRateIO = t.type({
    id: t.string,
    rate: t.number,
    label: VatRateLabelIO,
    countryCode: t.string,
})
export type VatRateI = t.TypeOf<typeof VatRateIO> & {
    id: VatRateId
    countryCode: CountryCode
}
