import { useEffect } from "react"
import { MessageI } from "../types"
import { useCommunicationApi } from "../communicationApi"
import { UserI } from "~/types"
import { useAppDispatch } from "~/store/hooks"
import { communicationActions } from "../store/CommunicationSlice"
import { isResultSuccess } from "~/core/Result"

export const useSetMessageAsViewed = (currentUser: UserI, message: MessageI) => {
    const communicationApi = useCommunicationApi()
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!message.views.some((view) => view.userId === currentUser.id)) {
            communicationApi.setMessageAsViewed(message.id).then((result) => {
                if (isResultSuccess(result)) {
                    dispatch(communicationActions.addMessageView(result.result))
                }
            })
        }
    }, [currentUser.id, message.id, message.views, dispatch])
}
