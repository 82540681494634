export type ResultSuccessType<ResultType> = { success: true; result: ResultType; error?: undefined }
export type ResultErrorType<ErrorType = string> = { success: false; result?: undefined; error: ErrorType }

export type Result<ResultType, ErrorType = string> = ResultSuccessType<ResultType> | ResultErrorType<ErrorType>

export const isResultSuccess = <ResultType, ErrorType = string>(
    result: Result<ResultType, ErrorType>
): result is ResultSuccessType<ResultType> => result.success

export const isResultError = <ResultType, ErrorType = string>(
    result: Result<ResultType, ErrorType>
): result is ResultErrorType<ErrorType> => !result.success

export const ResultSuccess = <ResultType>(result: ResultType): ResultSuccessType<ResultType> => ({
    success: true,
    result,
})
export const ResultError = <ErrorType>(error: ErrorType): ResultErrorType<ErrorType> => ({
    success: false,
    error,
})

export const getResultSuccessValue = <ResultType>(result: ResultSuccessType<ResultType>): ResultType => result.result

export const getResultErrorValue = <ErrorType>(result: ResultErrorType<ErrorType>): ErrorType => result.error

export function pipeResult<SuccessType, ErrorType, OnSuccessReturnValue, OnErrorResultValue = ErrorType>(
    result: Result<SuccessType, ErrorType>,
    onSuccess: (value: SuccessType) => OnSuccessReturnValue,
    onError?: (error: ErrorType) => OnErrorResultValue
): OnErrorResultValue | OnSuccessReturnValue | ErrorType {
    if (isResultSuccess(result)) {
        return onSuccess(result.result)
    } else {
        if (onError) {
            return onError(result.error)
        } else {
            return result.error
        }
    }
}
