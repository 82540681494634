import { SelectedTagI } from "../tags/types"

export const getTagTooltip = (tag: SelectedTagI | undefined): string => {
    if (!tag) return ""
    const tagGroupShortName = tag.tagGroup
        ? tag.tagGroup.shortName
            ? `${tag.tagGroup.shortName}:`
            : `${tag.tagGroup.name.substring(0, 3)}:`
        : ""
    const prefix = tag.value ? `${tag.value}: ` : ""
    const suffix = tag.externalId ? ` (${tag.externalId})` : ""
    if (tag.description) {
        return `${tagGroupShortName}${prefix}${tag.description}${suffix}`
    }
    if (tag.value) {
        return `${tagGroupShortName}${tag.value}${suffix}`
    }
    return `${tagGroupShortName}${tag.name}${suffix}`
}
