import React from "react"
import { SelectedTagI } from "../../types"
import { TagContainer } from "./Tag"
import { FormattedMessage, defineMessages } from "react-intl"
import { Plus, Zap } from "react-feather"
import { Typography, styled } from "@mui/material"

const messages = defineMessages({
    tagSuggestionsTitle: {
        id: "tags.TagSelector.tagSuggestionsTitle",
        defaultMessage: "AI tags suggestions:",
    },
})

interface Props {
    setSelectedTags: (value: React.SetStateAction<SelectedTagI[]>) => void
    suggestions: SelectedTagI[]
}

const Title = styled(Typography)(({ theme }) => ({
    display: "flex",
    gap: "4px",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: theme.palette.grey[900],
    minHeight: "34px",
    paddingLeft: "20px",
}))

const SuggestionsContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "4px",
    flexWrap: "wrap",
})

export const TagInputSuggestions: React.FC<Props> = ({ setSelectedTags, suggestions }) => {
    if (!suggestions.length) return null
    return (
        <>
            <Title>
                <Zap size={16} />
                <FormattedMessage {...messages.tagSuggestionsTitle} />
            </Title>
            <SuggestionsContainer>
                {suggestions.map((suggestion) => (
                    <TagContainer
                        key={suggestion.tagId}
                        onClick={() => setSelectedTags((currentSelection) => [...currentSelection, suggestion])}
                        hasRatio={!!suggestion.ratio}
                    >
                        <Plus size={16} />
                        {suggestion.tagGroup &&
                            (suggestion.tagGroup.shortName
                                ? `${suggestion.tagGroup.shortName}: `
                                : `${suggestion.tagGroup.name.substring(0, 3)}: `)}
                        {suggestion.name}
                    </TagContainer>
                ))}
            </SuggestionsContainer>
        </>
    )
}
