import { Portal, styled } from "@mui/material"
import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react"
import { TagGroupI } from "../../types/TagGroup"
import { stopPropagation } from "~/utils"
import { getTagsFilter } from "./getTagsFilter"
import { SelectedTagI } from "../../types"
import { TagGroup } from "./TagGroup"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { ORGANIZATION_TAGS_ROUTE } from "~/features/account/routes"
import { TagInputSuggestions } from "./TagInputSuggestions"

const messages = defineMessages({
    placeholder: {
        id: "tags.TagSelector.placeholder",
        defaultMessage: "Add tag…",
    },
    noResults: {
        id: "tags.TagSelector.noResults",
        defaultMessage: 'No matching tags for "{searchTerm}"',
    },
    noTagsInOrganization: {
        id: "tags.TagSelector.noTagsInOrganization",
        defaultMessage: "There are currently no tags in this organization. Create some in your <em>settings</em>.",
    },
})

interface Props {
    tagGroups: TagGroupI[]
    selectedTags: SelectedTagI[]
    setSelectedTags: Dispatch<SetStateAction<SelectedTagI[]>>
    suggestions?: SelectedTagI[]
}

const Input = styled("input")({
    display: "block",
    border: 0,
    flex: "1 1 auto",
    width: "1%",
    background: "transparent",
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-body-size)",
})

const TagsListContainer = styled("div")({
    display: "block",
    width: "100%",
    position: "absolute",
    backgroundColor: "white",
    zIndex: 1300,
    boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
    minHeight: "56px",
    overflowY: "auto",
    overflowX: "hidden",
})

const HiddenValueContainer = styled("span")({
    position: "absolute",
    opacity: 0,
    zIndex: -1,
})

const StyledMessage = styled("div")({
    marginTop: "8px",
    marginLeft: "8px",
    color: "var(--color-light-grey)",
})

const tagGroupHasTag = (tagGroup: TagGroupI) => tagGroup.tags.length > 0

const countTags = (acc: number, tagGroup: TagGroupI): number => acc + tagGroup.tags.length

const getTagByIndex = (tagGroups: TagGroupI[], index: number) => {
    let j = 0
    for (let i = 0, tagGroup: TagGroupI; i < tagGroups.length; ++i) {
        tagGroup = tagGroups[i]
        j += tagGroup.tags.length
        if (j > index) {
            return tagGroup.tags[index + tagGroup.tags.length - j]
        }
    }
    return null
}

enum KEYBOARD_KEYS {
    ArrowDown = "ArrowDown",
    ArrowUp = "ArrowUp",
    Escape = "Escape",
    Enter = "Enter",
}

export const TagInput = forwardRef<HTMLInputElement, Props>(
    ({ tagGroups, selectedTags, setSelectedTags, suggestions }, ref) => {
        const { formatMessage } = useIntl()
        const [value, setValue] = useState<string>("")
        const [width, setWidth] = useState(0)
        const [isFocused, setIsFocused] = useState<boolean>(false)
        const hiddenValueRef = useRef<HTMLSpanElement>(null)
        const tagListRef = useRef<HTMLDivElement>(null)
        const inputRef = useRef<HTMLInputElement>(null)
        const [index, setIndex] = useState(0)
        const navigate = useNavigate()

        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement)

        useEffect(() => {
            setValue("")
            setIndex(0)
        }, [selectedTags])

        useEffect(() => {
            if (hiddenValueRef.current) {
                setWidth(hiddenValueRef.current.offsetWidth)
            }
        }, [value])

        const onChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                setValue(event.currentTarget.value)
                setIndex(0)
            },
            [setValue]
        )

        const onFocus = useCallback(() => {
            if (!isFocused) {
                setIsFocused(true)
                const onDocumentClick = () => {
                    setIsFocused(false)
                    document.removeEventListener("click", onDocumentClick)
                }
                document.addEventListener("click", onDocumentClick, false)
            }
        }, [setIsFocused, isFocused])

        const filteredTagGroups = useMemo(() => {
            if (value.trim().length === 0) return tagGroups
            return tagGroups
                .map((tagGroup) => ({
                    ...tagGroup,
                    tags: tagGroup.tags.filter(getTagsFilter(value, tagGroup)),
                }))
                .filter(tagGroupHasTag)
        }, [tagGroups, value])

        const totalTags = useMemo(
            () => (isFocused ? filteredTagGroups.reduce(countTags, 0) : 0),
            [isFocused, filteredTagGroups]
        )

        const highlightTag = useMemo(
            () => (isFocused ? getTagByIndex(filteredTagGroups, index) : null),
            [isFocused, filteredTagGroups, index]
        )

        const onKeyDown = useCallback(
            (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === KEYBOARD_KEYS.ArrowDown) {
                    setIndex((index + 1) % totalTags)
                } else if (event.key === KEYBOARD_KEYS.ArrowUp) {
                    if (index === 0) {
                        setIndex(totalTags - 1)
                    } else {
                        setIndex(index - 1)
                    }
                } else if (event.key === KEYBOARD_KEYS.Enter) {
                    if (tagListRef.current && highlightTag) {
                        const item = tagListRef.current.querySelector(
                            `[data-itemid="${highlightTag.tagId}"]`
                        ) as HTMLElement | null
                        if (item) {
                            item.click()
                        }
                    }
                } else if (event.key === KEYBOARD_KEYS.Escape) {
                    document.body.click()
                    inputRef.current?.blur()
                }
            },
            [totalTags, index, highlightTag]
        )

        const renderTagGroup = useCallback(
            (tagGroup: TagGroupI) => {
                const unfilteredTagGroup = tagGroups.find((tg) => tg.tagGroupId === tagGroup.tagGroupId)
                if (!unfilteredTagGroup) return null
                return (
                    <TagGroup
                        key={tagGroup.tagGroupId}
                        tagGroup={tagGroup}
                        unfilteredTags={unfilteredTagGroup.tags}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                        highlightTag={highlightTag}
                    />
                )
            },
            [tagGroups, selectedTags, setSelectedTags, highlightTag]
        )

        const setTagsContainerPosition = useCallback(() => {
            if (tagListRef.current && inputRef.current && inputRef.current.parentElement) {
                const rect = inputRef.current.parentElement.getBoundingClientRect()
                const width = Math.max(200, rect.width + 10)
                const left = rect.left + window.scrollX - 10
                const top = rect.bottom + window.scrollY
                let maxHeight = Math.max(200, Math.min(400, window.innerHeight - top))
                if (left + width >= window.innerWidth) {
                    tagListRef.current.style.right = `${
                        document.body.clientWidth - (rect.left + rect.width + window.scrollX)
                    }px`
                    tagListRef.current.style.left = "auto"
                } else {
                    tagListRef.current.style.left = `${left}px`
                }
                if (top + maxHeight > window.innerHeight) {
                    const bottom = document.body.clientHeight - (rect.top + window.scrollY)
                    maxHeight = Math.max(maxHeight, 200, Math.min(400, window.innerHeight - bottom))
                    tagListRef.current.style.top = "auto"
                    tagListRef.current.style.bottom = `${document.body.clientHeight - (rect.top + window.scrollY)}px`
                } else {
                    tagListRef.current.style.top = `${top}px`
                }
                tagListRef.current.style.maxWidth = `${width}px`
                tagListRef.current.style.maxHeight = `${maxHeight}px`
                tagListRef.current.scrollTop = 0
            }
        }, [])

        useEffect(() => {
            requestAnimationFrame(setTagsContainerPosition)
        }, [isFocused, selectedTags])

        const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
        const handleClick = useCallback(() => {
            navigate(generatePath(ORGANIZATION_TAGS_ROUTE, { organizationId: currentOrganizationId as string }))
        }, [currentOrganizationId])

        const placeholder = formatMessage(messages.placeholder)
        const tagsToDisplay = filteredTagGroups.map(renderTagGroup)

        return (
            <>
                <Input
                    value={value}
                    onChange={onChange}
                    onFocus={onFocus}
                    onClick={stopPropagation}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder}
                    ref={inputRef}
                    sx={{ width }}
                />
                <HiddenValueContainer ref={hiddenValueRef}>{value || placeholder}</HiddenValueContainer>
                {isFocused && (
                    <Portal>
                        <TagsListContainer onClick={stopPropagation} ref={tagListRef}>
                            {suggestions && (
                                <TagInputSuggestions setSelectedTags={setSelectedTags} suggestions={suggestions} />
                            )}
                            {!tagGroups.length ? (
                                <StyledMessage onClick={handleClick}>
                                    <FormattedMessage
                                        {...messages.noTagsInOrganization}
                                        values={{ em: (msg) => <em>{msg}</em> }}
                                    />
                                </StyledMessage>
                            ) : tagsToDisplay.length ? (
                                tagsToDisplay
                            ) : value ? (
                                <StyledMessage>
                                    {formatMessage(messages.noResults, { searchTerm: value })}
                                </StyledMessage>
                            ) : null}
                        </TagsListContainer>
                    </Portal>
                )}
            </>
        )
    }
)
TagInput.displayName = "TagInput"
