import React, { lazy, useCallback, useEffect, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { BulkActionButton, BulkActions, Tabs, useTitle } from "~/core"
import { Box } from "@mui/material"
import { Check, DollarSign, FileText, Trash2, X } from "react-feather"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { HeaderH1 } from "~/components/Header/HeaderH1"
import { Filters } from "~/domains/transactions/purchase-requests/components"
import "../../assets/css/Purchases.scss"
import { useFetchPurchaseRequests } from "../store/hooks"
import { useNavigate } from "react-router-dom"
import { PURCHASE_REQUEST_NEW_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import { useImportBatchPurchaseRequest } from "../store/hooks/useImportBatchPurchaseRequest"
import { CheckFileTypeResult } from "~/components/UploadDocument/UploadDocument"
import { ImportBatchResponseI } from "~/types/ImportBatch"
import { useOrganizationTagGroups } from "~/domains/tags/hooks"
import { TagAndTagGroupNameI } from "~/features/workflow/core/hooks"
import { useDeletePurchaseRequests } from "../store/hooks/useDeletePurchaseRequests"
import { ConfirmModal } from "~/components/ConfirmModal"
import { PurchaseRequestStatus, PurchaseRequestSummary, PurchaseRequestsTab } from "../types/PurchaseRequests"
import { toast } from "react-toastify"
import { useApprovePurchaseRequests } from "../store/hooks/useApprovePurchaseRequests"
import { useRejectPurchaseRequests } from "../store/hooks/useRejectPurchaseRequests"
import { purchaseRequestsActions, selectPurchaseRequestsPermissions } from "../store/purchaseRequestsSlice"
import {
    getNonConvertedToPOForDeletion,
    getRefusable,
    getApprovable,
    purchaseRequestsTabMessages,
    statusCounts,
    getPRBulkActionButtons,
    getConvertable,
} from "../core/purchaseRequest"
import { withSocketIOProvider } from "../../common/subscriptions/components/SocketIOContext"
import { isDefined } from "~/utils/isDefined"
import { useCheckPurchaseRequestsPermission } from "~/domains/transactions/purchase-requests/store/hooks/useCheckPurchaseRequestsPermission"
import { useConvertPurchaseRequests } from "~/domains/transactions/purchase-requests/store/hooks/useConvertPurchaseRequests"
import { Actions } from "~/components/DataTable/components/DatatableFilters"
import { AuthorizationName } from "~/types"

const ModalNewPR = lazy(() =>
    import("../components/ModalQuickPR/ModalQuickPR").then(({ ModalQuickPR }) => ({ default: ModalQuickPR }))
)
const ModalBatchImport = lazy(() =>
    import("~/components/ModalBatchImport/ModalBatchImport").then(({ ModalBatchImport }) => ({
        default: ModalBatchImport,
    }))
)
const ModalResultImport = lazy(() =>
    import("~/components/ModalBatchImport/ModalResultImport").then(({ ModalResultImport }) => ({
        default: ModalResultImport,
    }))
)
const List = lazy(() => import("../components/PRList/List").then(({ List }) => ({ default: List })))

const messages = defineMessages({
    title: { id: "purchase.requests.list.title", defaultMessage: "Purchase requests" },
    fullPR: { id: "purchase.requests.fullPR", defaultMessage: "$ Create a full PR" },
    quickPR: { id: "purchase.requests.quickPR", defaultMessage: "$ Create a quick PR" },
    batchPR: { id: "purchase.requests.batchPR", defaultMessage: "$ Import PRs in batch (csv, xlsx)" },
    errorWrongFileType: {
        id: "purchase.requests.modalImportBatch.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept CSV and XLSX files.",
    },
    modalUploadTitle: {
        id: "purchase.requests.modalImportBatch.title",
        defaultMessage: "Import Purchase Requests in batch",
    },
    modalUploadTip: {
        id: "purchase.requests.modalImportBatch.uploadBox.tip",
        defaultMessage: "Supported format : csv, xlsx. No more than 2mb",
    },
    selected: { id: "purchase.requests.table.selected", defaultMessage: "selected" },
    delete: { id: "purchase.requests.table.selected.delete", defaultMessage: "Delete" },
    approve: { id: "purchase.requests.table.selected.approve", defaultMessage: "Approve" },
    reject: { id: "purchase.requests.table.selected.reject", defaultMessage: "Reject" },
    convert: { id: "purchase.requests.table.selected.convert", defaultMessage: "Convert" },
    confirmDeleteTitle: {
        id: "purchase.requests.table.bulk.deleteInvoice",
        defaultMessage: "Delete purchase requests",
    },
    confirmDeleteMessage: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmDeleteMessage",
        defaultMessage:
            "Are you sure to delete {count} purchase {count, plural, =0 {request} one {request} other {requests}}? Any deletion is permanent.",
    },
    nothingToDelete: {
        id: "purchase.requests.table.bulk.deleteInvoice.nothingToDelete",
        defaultMessage:
            "Nothing to delete. To be able to delete a purchase request, you must have the permission and it shouldn't be converted to a PO yet.",
    },
    nothingToApprove: {
        id: "purchase.requests.table.bulk.deleteInvoice.nothingToApprove",
        defaultMessage:
            "Nothing to approve. To be able to approve a purchase request, you must have the permission and it shouldn't be a draft or be converted to a PO yet.",
    },
    nothingToConvert: {
        id: "purchase.requests.table.bulk.deleteInvoice.nothingToConvert",
        defaultMessage:
            "Nothing to convert. To be able to convert a purchase request, you must have the permission and it should have an expected delivery date and a vendor.",
    },
    confirmApproveTitle: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmApproveTitle",
        defaultMessage: "Approve purchase requests",
    },
    confirmApproveMessage: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmApproveMessage",
        defaultMessage:
            "Are you sure to approve {count} purchase {count, plural, =0 {request} one {request} other {requests}}?",
    },
    nothingToReject: {
        id: "purchase.requests.table.bulk.deleteInvoice.nothingToReject",
        defaultMessage:
            "Nothing to reject. To be able to reject a purchase request, you must have the permission and it shouldn't be a draft or be converted to a PO yet.",
    },
    confirmRejectTitle: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmRejectTitle",
        defaultMessage: "Reject purchase requests",
    },
    confirmRejectMessage: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmRejectMessage",
        defaultMessage:
            "Are you sure to reject {count} purchase {count, plural, =0 {request} one {request} other {requests}}?",
    },
    confirmConvertTitle: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmConvertTitle",
        defaultMessage: "Convert purchase requests",
    },
    confirmConvertMessage: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmConvertMessage",
        defaultMessage:
            "Are you sure to convert {count} purchase {count, plural, =0 {request} one {request} other {requests}}?",
    },
})

const getBulkActionButtonLabel = (message: string, currentTab: PurchaseRequestsTab, count?: number): JSX.Element => (
    <>
        <span>{message}</span>
        {currentTab === PurchaseRequestsTab.ALL ? <div className="button-count">{count}</div> : null}
    </>
)

const getTabLabel = (message: string, tab: PurchaseRequestsTab, count: number = 0): JSX.Element => {
    return (
        <span>
            {message}
            {tab === PurchaseRequestsTab.ALL || count === 0 ? null : <div className="tab-count"> {count} </div>}
        </span>
    )
}

const ACCEPTED_FILE_EXTENSIONS: string[] = ["csv", "xlsx"]
const NOTION_URL_BATCH_PR =
    "https://get-flowie.notion.site/Import-Batch-Purchase-Request-fa65a4aedcf344fda1bd1168d66ca675"

export const PurchaseRequests = withSocketIOProvider(() => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const purchaseRequestsPermissions = useAppSelector(selectPurchaseRequestsPermissions)
    const [modalNewPRVisible, setModalNewPRVisible] = useState<boolean>(false)
    const [modalBatchImportVisible, setModalBatchImportVisible] = useState<boolean>(false)
    const [modalResultImportVisible, setModalResultImportVisible] = useState<boolean>(false)
    const [resultBatchImport, setResultBatchImport] = useState<ImportBatchResponseI | undefined>(undefined)
    const [selectedPRs, setSelectedPRs] = useState<string[]>([])
    const [bulkActionButtons, setBulkActionButtons] = useState<BulkActionButton[]>([])
    const [selectedForDelete, setSelectedForDelete] = useState<string[]>([])
    const [displayModalDelete, setDisplayModalDelete] = useState<boolean>(false)
    const [selectedForApprove, setSelectedForApprove] = useState<string[]>([])
    const [displayModalApprove, setDisplayModalApprove] = useState<boolean>(false)
    const [selectedForReject, setSelectedForReject] = useState<string[]>([])
    const [displayModalReject, setDisplayModalReject] = useState<boolean>(false)
    const [selectedForConvert, setSelectedForConvert] = useState<PurchaseRequestSummary[]>([])
    const [displayModalConvert, setDisplayModalConvert] = useState<boolean>(false)
    const [tabValue, setTabValue] = useState<PurchaseRequestsTab>(PurchaseRequestsTab.ALL)

    const { importBatchPR, loading } = useImportBatchPurchaseRequest(organizationId as string)
    const { refetchPurchaseRequests, purchaseRequests } = useFetchPurchaseRequests(organizationId)
    const navigate = useNavigate()
    const deletePurchaseRequests = useDeletePurchaseRequests()
    const approvePurchaseRequests = useApprovePurchaseRequests()
    const rejectPurchaseRequests = useRejectPurchaseRequests()
    const convertPurchaseRequests = useConvertPurchaseRequests(organizationId)
    const { checkPurchaseRequestsPermission, loading: loadingPermissions } = useCheckPurchaseRequestsPermission()

    const pageName = formatMessage(messages.title)
    useTitle(pageName)

    const { tagGroups, loading: loadingTagGroups } = useOrganizationTagGroups(organizationId)

    const tags = useMemo((): TagAndTagGroupNameI[] => {
        if (tagGroups) {
            const tags = Object.values(tagGroups)
                .map((tagGroup) => {
                    return tagGroup.tags.map(
                        (tag): TagAndTagGroupNameI => ({
                            ...tag,
                            tagGroupName: tagGroup.name,
                            tagGroupShortName: tagGroup.shortName,
                        })
                    )
                })
                .flat()
            return tags
        } else {
            return []
        }
    }, [tagGroups])

    const getApproveBulkActionButton = useCallback(
        (currentTab: PurchaseRequestsTab): BulkActionButton => {
            const approvablePRs = getApprovable(selectedPRs, purchaseRequests)
            return {
                label: getBulkActionButtonLabel(formatMessage(messages.approve), currentTab, approvablePRs.length),
                icon: <Check size={14} />,
                type: "neutral",
                onClick: handleApprovePurchaseRequest(approvablePRs),
            }
        },
        [selectedPRs, purchaseRequests, formatMessage]
    )

    const getRejectBulkActionButton = useCallback(
        (currentTab: PurchaseRequestsTab): BulkActionButton => {
            const refusablePRs = getRefusable(selectedPRs, purchaseRequests)
            return {
                label: getBulkActionButtonLabel(formatMessage(messages.reject), currentTab, refusablePRs.length),
                icon: <X color="var(--color-red)" size={14} />,
                type: "error-light",
                onClick: handleRejectPurchaseRequest(refusablePRs),
            }
        },
        [selectedPRs, purchaseRequests, formatMessage]
    )

    const getDeleteBulkActionButton = useCallback(
        (currentTab: PurchaseRequestsTab): BulkActionButton => {
            const deletablePRs = getNonConvertedToPOForDeletion(selectedPRs, purchaseRequests)
            return {
                label: getBulkActionButtonLabel(formatMessage(messages.delete), currentTab, deletablePRs.length),
                icon: <Trash2 color="var(--color-red)" size={14} />,
                type: "error-light",
                onClick: handleDeletePurchaseRequest(deletablePRs),
            }
        },
        [selectedPRs, purchaseRequests, formatMessage]
    )

    const getConvertBulkActionButton = useCallback(
        (currentTab: PurchaseRequestsTab): BulkActionButton => {
            const convertablePRs = getConvertable(selectedPRs, purchaseRequests)
            return {
                label: getBulkActionButtonLabel(formatMessage(messages.convert), currentTab, convertablePRs.length),
                icon: <FileText size={14} />,
                type: "neutral",
                onClick: handleConvertPurchaseRequest(convertablePRs),
            }
        },
        [selectedPRs, purchaseRequests, formatMessage]
    )

    useEffect(() => {
        if (organizationId) {
            checkPurchaseRequestsPermission(organizationId, [AuthorizationName.READ, AuthorizationName.CREATE])
        }
    }, [organizationId, checkPurchaseRequestsPermission])

    useEffect(() => {
        const buttons = getPRBulkActionButtons(
            tabValue,
            getApproveBulkActionButton,
            getRejectBulkActionButton,
            getDeleteBulkActionButton,
            getConvertBulkActionButton
        )
        setBulkActionButtons(buttons)
    }, [
        formatMessage,
        selectedPRs,
        purchaseRequests,
        tabValue,
        getApproveBulkActionButton,
        getRejectBulkActionButton,
        getDeleteBulkActionButton,
        getConvertBulkActionButton,
    ])

    const handleFile = useCallback(
        async (file: File) => {
            try {
                const result: ImportBatchResponseI = await importBatchPR(file)
                setResultBatchImport(result)
                closeModalBatchImport()
                showResultImportModal()
                refetchPurchaseRequests()
            } catch (error) {
                console.error(error)
            }
        },
        [importBatchPR]
    )

    const checkFileType = useCallback(
        (file: File): CheckFileTypeResult => {
            const fileExtension = file.name.split(".").pop()
            if (fileExtension && ACCEPTED_FILE_EXTENSIONS.includes(fileExtension)) {
                return { result: true, error: null }
            }

            return { result: false, error: formatMessage(messages.errorWrongFileType) }
        },
        [formatMessage]
    )

    const handleSelectedPR = useCallback(
        (rowSelectionIds: string[]) => {
            setSelectedPRs(rowSelectionIds)
        },
        [setSelectedPRs]
    )

    const handleApprovePurchaseRequest = useCallback(
        (selected: string[]) => () => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToApprove))
            }

            setSelectedForApprove(selected)
            setDisplayModalApprove(true)
        },
        [setSelectedForApprove, setDisplayModalApprove]
    )

    const handleRejectPurchaseRequest = useCallback(
        (selected: string[]) => () => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToReject))
            }

            setSelectedForReject(selected)
            setDisplayModalReject(true)
        },
        [setSelectedForReject, setDisplayModalReject]
    )

    const handleDeletePurchaseRequest = useCallback(
        (selected: string[]) => () => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToDelete))
            }

            setSelectedForDelete(selected)
            setDisplayModalDelete(true)
        },
        [setSelectedForDelete, setDisplayModalDelete]
    )

    const handleConvertPurchaseRequest = useCallback(
        (selected: string[]) => () => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToConvert))
            }

            setSelectedForConvert(selected.map((id) => purchaseRequests.find((pr) => pr.id === id)).filter(isDefined))
            setDisplayModalConvert(true)
        },
        [purchaseRequests, setSelectedForConvert, setDisplayModalConvert]
    )

    const handleHideModalDelete = useCallback(() => setDisplayModalDelete(false), [])
    const handleHideModalApprove = useCallback(() => setDisplayModalApprove(false), [])
    const handleHideModalReject = useCallback(() => setDisplayModalReject(false), [])
    const handleHideModalConvert = useCallback(() => setDisplayModalConvert(false), [])

    const handleConfirmModalApprove = useCallback(async () => {
        if (organizationId) {
            setDisplayModalApprove(false)
            await approvePurchaseRequests(selectedForApprove, organizationId)
            setSelectedPRs([])
            setSelectedForApprove([])
            return Promise.resolve(true)
        }
        return Promise.resolve(false)
    }, [selectedForApprove, organizationId])

    const handleConfirmModalReject = useCallback(async () => {
        if (organizationId) {
            setDisplayModalReject(false)
            await rejectPurchaseRequests(selectedForReject, organizationId)
            setSelectedPRs([])
            setSelectedForReject([])
            return Promise.resolve(true)
        }
        return Promise.resolve(false)
    }, [selectedForReject, organizationId])

    const handleConfirmModalDelete = useCallback(async () => {
        if (organizationId) {
            setDisplayModalDelete(false)
            await deletePurchaseRequests(selectedForDelete, organizationId)
            setSelectedPRs([])
            setSelectedForDelete([])
            return Promise.resolve(true)
        }
        return Promise.resolve(false)
    }, [selectedForDelete, organizationId])

    const handleConfirmModalConvert = useCallback(async () => {
        if (organizationId) {
            setDisplayModalConvert(false)
            await convertPurchaseRequests(selectedForConvert)
            setSelectedPRs([])
            setSelectedForConvert([])
            return Promise.resolve(true)
        }
        return Promise.resolve(false)
    }, [selectedForConvert, organizationId])

    const handleClickInstructions = () => {
        window.open(NOTION_URL_BATCH_PR, "_blank")
    }

    const onClickNewPr = useCallback(() => navigate(PURCHASE_REQUEST_NEW_ROUTE), [])
    const onClickQuickPR = useCallback(() => setModalNewPRVisible(true), [])
    const closeModalNewPR = useCallback(() => setModalNewPRVisible(false), [])

    const onClickBatchImport = () => setModalBatchImportVisible(true)
    const closeModalBatchImport = () => setModalBatchImportVisible(false)

    const showResultImportModal = () => setModalResultImportVisible(true)
    const closeModalResultImport = () => setModalResultImportVisible(false)

    const clearSelection = useCallback(() => setSelectedPRs([]), [setSelectedPRs])

    const handleChangeTab = useCallback(
        (newValue: number | string) => {
            setTabValue(newValue as PurchaseRequestsTab)
            setSelectedPRs([])
            dispatch(purchaseRequestsActions.setCurrentPurchaseRequestsTab(newValue as PurchaseRequestsTab))
        },
        [setTabValue]
    )

    const actions: Actions[] = [
        { label: formatMessage(messages.fullPR), action: onClickNewPr },
        { label: formatMessage(messages.quickPR), action: onClickQuickPR },
        { label: formatMessage(messages.batchPR), action: onClickBatchImport },
    ]

    const countByStatus = useMemo(() => statusCounts(purchaseRequests), [purchaseRequests])

    const tabConfig = useMemo(
        () => [
            {
                label: getTabLabel(
                    formatMessage(purchaseRequestsTabMessages[PurchaseRequestsTab.ALL]),
                    PurchaseRequestsTab.ALL
                ),
                value: PurchaseRequestsTab.ALL,
                purchaseRequests: purchaseRequests,
            },
            {
                label: getTabLabel(
                    formatMessage(purchaseRequestsTabMessages[PurchaseRequestsTab.DRAFT]),
                    PurchaseRequestsTab.DRAFT,
                    countByStatus.DRAFT
                ),
                value: PurchaseRequestsTab.DRAFT,
                purchaseRequests: purchaseRequests.filter((pr) => pr.status === PurchaseRequestStatus.DRAFT),
            },
            {
                label: getTabLabel(
                    formatMessage(purchaseRequestsTabMessages[PurchaseRequestsTab.SUBMITTED]),
                    PurchaseRequestsTab.SUBMITTED,
                    countByStatus.SUBMITTED
                ),
                value: PurchaseRequestsTab.SUBMITTED,
                purchaseRequests: purchaseRequests.filter((pr) => pr.status === PurchaseRequestStatus.SUBMITTED),
            },
            {
                label: getTabLabel(
                    formatMessage(purchaseRequestsTabMessages[PurchaseRequestsTab.APPROVED]),
                    PurchaseRequestsTab.APPROVED,
                    countByStatus.APPROVED
                ),
                value: PurchaseRequestsTab.APPROVED,
                purchaseRequests: purchaseRequests.filter(
                    (pr) => !pr.purchaseOrder && pr.status === PurchaseRequestStatus.APPROVED
                ),
            },
            {
                label: getTabLabel(
                    formatMessage(purchaseRequestsTabMessages[PurchaseRequestsTab.REJECTED]),
                    PurchaseRequestsTab.REJECTED,
                    countByStatus.REJECTED
                ),
                value: PurchaseRequestsTab.REJECTED,
                purchaseRequests: purchaseRequests.filter((pr) => pr.status === PurchaseRequestStatus.REJECTED),
            },
            {
                label: getTabLabel(
                    formatMessage(purchaseRequestsTabMessages[PurchaseRequestsTab.CONVERTED]),
                    PurchaseRequestsTab.CONVERTED,
                    countByStatus.CONVERTED
                ),
                value: PurchaseRequestsTab.CONVERTED,
                purchaseRequests: purchaseRequests.filter((pr) => !!pr.purchaseOrder),
            },
        ],
        [countByStatus, formatMessage, purchaseRequests, purchaseRequestsTabMessages]
    )

    return (
        <>
            <HeaderH1 title={pageName} icon={<DollarSign />} />
            <Box className="main-box" component="div">
                {organizationId && !loadingPermissions ? (
                    <>
                        <Filters actions={actions} permissions={purchaseRequestsPermissions} />
                        <Tabs
                            className="purchase-requests-tabs"
                            defaultValue={tabValue}
                            setChange={handleChangeTab}
                            tabs={tabConfig.map((tab) => ({
                                ...tab,
                                component: (
                                    <List
                                        purchaseRequests={tab.purchaseRequests}
                                        organizationId={organizationId}
                                        tags={tags}
                                        handleSelectedRows={handleSelectedPR}
                                        rowSelectionModel={selectedPRs}
                                        currentTab={tabValue}
                                        loadingTagGroups={loadingTagGroups}
                                    />
                                ),
                            }))}
                        />
                        <ModalNewPR organizationId={organizationId} open={modalNewPRVisible} close={closeModalNewPR} />
                        <ModalBatchImport
                            open={modalBatchImportVisible}
                            close={closeModalBatchImport}
                            handleFile={handleFile}
                            checkFileType={checkFileType}
                            handleClickInstructions={handleClickInstructions}
                            loading={loading}
                            uploadTip={messages.modalUploadTip}
                            title={messages.modalUploadTitle}
                        />
                        <ModalResultImport
                            open={modalResultImportVisible}
                            close={closeModalResultImport}
                            showNewImportModal={onClickBatchImport}
                            resultBatchImport={resultBatchImport}
                            title={messages.modalUploadTitle}
                        />
                        <ConfirmModal
                            title={formatMessage(messages.confirmDeleteTitle)}
                            open={displayModalDelete}
                            close={handleHideModalDelete}
                            onConfirm={handleConfirmModalDelete}
                        >
                            {formatMessage(messages.confirmDeleteMessage, {
                                count: selectedForDelete.length,
                                s: selectedForDelete.length > 1 ? "s" : "",
                            })}
                        </ConfirmModal>
                        <ConfirmModal
                            title={formatMessage(messages.confirmApproveTitle)}
                            open={displayModalApprove}
                            close={handleHideModalApprove}
                            onConfirm={handleConfirmModalApprove}
                            confirmButtonType="primary"
                        >
                            {formatMessage(messages.confirmApproveMessage, {
                                count: selectedForApprove.length,
                                s: selectedForApprove.length > 1 ? "s" : "",
                            })}
                        </ConfirmModal>
                        <ConfirmModal
                            title={formatMessage(messages.confirmRejectTitle)}
                            open={displayModalReject}
                            close={handleHideModalReject}
                            onConfirm={handleConfirmModalReject}
                        >
                            {formatMessage(messages.confirmRejectMessage, {
                                count: selectedForReject.length,
                                s: selectedForReject.length > 1 ? "s" : "",
                            })}
                        </ConfirmModal>
                        <ConfirmModal
                            title={formatMessage(messages.confirmConvertTitle)}
                            open={displayModalConvert}
                            close={handleHideModalConvert}
                            onConfirm={handleConfirmModalConvert}
                        >
                            {formatMessage(messages.confirmConvertMessage, {
                                count: selectedForConvert.length,
                                s: selectedForConvert.length > 1 ? "s" : "",
                            })}
                        </ConfirmModal>
                        {selectedPRs.length ? (
                            <BulkActions
                                selected={selectedPRs}
                                onClose={clearSelection}
                                buttons={bulkActionButtons}
                                selectedMessage={formatMessage(messages.selected, {
                                    s: selectedPRs.length > 1 ? "s" : "",
                                })}
                            />
                        ) : null}
                    </>
                ) : null}
            </Box>
        </>
    )
})
