import React from "react"
import { OrganizationId } from "~/types"
import { useTagsForCreatingPurchaseOrder } from "../store/hooks/useTagsForCreatingPurchaseOrder"
import { TagsSelector } from "~/domains/tags/components/TagsSelector"

interface Props {
    organizationId: OrganizationId
}

export const TagsForCreatingPurchaseOrder: React.FC<Props> = ({ organizationId }) => {
    const [selectedTags, setSelectedTags] = useTagsForCreatingPurchaseOrder()
    return (
        <TagsSelector
            organizationId={organizationId}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            tagsRecommandations={null}
        />
    )
}
