import React, { useCallback } from "react"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"
import { Button, Loader, Modal } from "~/components"
import { CheckFileTypeResult, UploadDocumentBox } from "~/components/UploadDocument/UploadDocument"

const messages = defineMessages({
    cancel: { id: "purchase.orders.modalUploadInvoice.cancel", defaultMessage: "Cancel" },
    loadingMessage: {
        id: "purchase.orders.modalUploadInvoice.loadingMessage",
        defaultMessage: "Upload in progress...",
    },
})

interface Props {
    open: boolean
    close: () => void
    handleFile: (file: File) => void
    checkFileType: (file: File) => CheckFileTypeResult
    loading: boolean
    uploadTip: MessageDescriptor
    title: MessageDescriptor
    acceptedFileTypes: string
}

export const ModalUploadInvoice: React.FC<Props> = ({
    open,
    close,
    handleFile,
    checkFileType,
    loading,
    uploadTip,
    title,
    acceptedFileTypes,
}) => {
    const { formatMessage } = useIntl()

    const handleChangeFile = useCallback(
        (file: File) => {
            handleFile(file)
        },
        [handleFile]
    )

    return (
        <Modal open={open} onClose={close} className="size-sm">
            <Modal.Header>
                <h4>{formatMessage(title)}</h4>
            </Modal.Header>
            <Modal.Content>
                {loading ? (
                    <div className="loading-block">
                        <Loader small />
                        <span> {formatMessage(messages.loadingMessage)}</span>
                    </div>
                ) : (
                    <>
                        <UploadDocumentBox
                            handleFile={handleChangeFile}
                            conditionMessage={formatMessage(uploadTip)}
                            checkFileType={checkFileType}
                            accept={acceptedFileTypes}
                        />
                    </>
                )}
            </Modal.Content>
            <Modal.Footer>
                <Button type="neutral" buttonType="button" onClick={close}>
                    {formatMessage(messages.cancel)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
