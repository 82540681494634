import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
    PartnerProfileAddressI,
    PartnerProfileContactI,
    PartnerProfilePaymentDetailI,
    PartnersDataI,
    PartnershipI,
    PartnershipTypeOption,
    PartialPartnerProfile,
    PartnerProfileI,
} from "~/domains/transactions/book-of-relations/types"
import { OrganizationId } from "~/types"
import { RootState } from "~/store"
import { bookOfRelationsState } from "./bookOfRelationsState"

const initialState = bookOfRelationsState

const bookOfRelationsSlice = createSlice({
    name: "bookOfRelations",
    initialState: initialState,
    reducers: {
        fetchPartnersData(state) {
            state.partners.loading = true
            state.partners.error = null
        },
        fetchPartnersDataSuccess(state, action: PayloadAction<PartnersDataI[]>) {
            state.partners.loading = false
            state.partners.partnersData = action.payload
            const newBrandNames: Record<OrganizationId, string> = {}
            action.payload.forEach((partner: PartnersDataI) => {
                if (partner.brandName) {
                    newBrandNames[partner.organizationId] = partner.brandName
                }
            })
            state.partners.brandNames = newBrandNames
        },
        fetchPartnersDataFailed(state, action: PayloadAction<string>) {
            state.partners.loading = false
            state.partners.error = action.payload
        },
        setPartnersFilter(state, action: PayloadAction<string>) {
            state.partners.partnersFilter = action.payload
        },
        deletePartner(state) {
            state.partners.loading = true
            state.partners.error = null
        },
        deletePartnerSuccess(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; partnershipType: PartnershipTypeOption }>
        ) {
            state.partners.loading = false
            state.partners.partnersData = state.partners.partnersData.map((partner: PartnersDataI) => {
                if (partner.organizationId === action.payload.organizationId) {
                    const { buyer, supplier, ...otherData } = partner
                    if (action.payload.partnershipType === PartnershipTypeOption.BUYER) {
                        return { ...otherData, supplier }
                    } else if (action.payload.partnershipType === PartnershipTypeOption.SUPPLIER) {
                        return { ...otherData, buyer }
                    } else {
                        return { ...otherData }
                    }
                }
                return partner
            })
        },
        deletePartnerFailed(state) {
            state.partners.loading = false
        },
        fetchPartnershipData(state) {
            state.partnership.loading = true
            state.partnership.error = null
        },
        fetchPartnershipDataSuccess(state, action: PayloadAction<PartnershipI[]>) {
            state.partnership.loading = false
            state.partnership.partnershipData = action.payload
        },
        fetchPartnershipDataFailed(state, action: PayloadAction<string>) {
            state.partnership.loading = false
            state.partnership.error = action.payload
            state.partnership.partnershipData = []
        },
        fetchPartnerProfile(state) {
            state.partnerProfile.loading = true
            state.partnerProfile.error = null
        },
        fetchPartnerProfileSuccess(state, action: PayloadAction<PartnerProfileI | null>) {
            state.partnerProfile.loading = false
            state.partnerProfile.data = action.payload
        },
        fetchPartnerProfileFailed(state, action: PayloadAction<string>) {
            state.partnerProfile.loading = false
            state.partnerProfile.error = action.payload
        },
        setPartnerProfile(state, action: PayloadAction<PartnerProfileI | null>) {
            state.partnerProfile.data = action.payload
            if (action.payload && action.payload.partnerId) {
                const newBrandNames = { ...state.partners.brandNames }
                action.payload.brandName
                    ? (newBrandNames[action.payload.partnerId] = action.payload.brandName)
                    : delete newBrandNames[action.payload.partnerId]
                state.partners.brandNames = newBrandNames
            }
        },
        setPartialPartnerProfile(state, action: PayloadAction<PartialPartnerProfile | null>) {
            if (state.partnerProfile.data?.initiatorId && state.partnerProfile.data?.partnerId) {
                state.partnerProfile.data = { ...state.partnerProfile.data, ...action.payload }
            } else if (action?.payload && action.payload.initiatorId && action.payload.partnerId) {
                state.partnerProfile.data = {
                    brandName: undefined,
                    description: undefined,
                    paymentTerms: undefined,
                    responsibleUserId: undefined,
                    addresses: [],
                    paymentDetails: [],
                    contacts: [],
                    ...action.payload,
                }
            } else {
                state.partnerProfile.data = null
            }
        },
        createPartnerProfileAddress(state, action: PayloadAction<PartnerProfileAddressI>) {
            if (state.partnerProfile.data?.addresses) {
                state.partnerProfile.data.addresses = [...state.partnerProfile.data.addresses, action.payload]
            }
        },
        updatePartnerProfileAddress(state, action: PayloadAction<PartnerProfileAddressI>) {
            if (state.partnerProfile.data) {
                const index = state.partnerProfile.data.addresses.findIndex(
                    (address) => address.id === action.payload.id
                )
                if (index !== -1) {
                    const addresses = [...state.partnerProfile.data.addresses]
                    addresses[index] = action.payload
                    state.partnerProfile.data = {
                        ...state.partnerProfile.data,
                        addresses,
                    }
                }
            }
        },
        deletePartnerProfileAddress(state, action: PayloadAction<string>) {
            if (state.partnerProfile.data?.addresses) {
                state.partnerProfile.data.addresses = state.partnerProfile.data.addresses.filter(
                    (address) => address.id !== action.payload
                )
            }
        },
        createPartnerProfileContact(state, action: PayloadAction<PartnerProfileContactI>) {
            if (state.partnerProfile.data?.contacts) {
                state.partnerProfile.data.contacts = [...state.partnerProfile.data.contacts, action.payload]
            }
        },
        updatePartnerProfileContact(state, action: PayloadAction<PartnerProfileContactI>) {
            if (state.partnerProfile.data) {
                const index = state.partnerProfile.data.contacts.findIndex(
                    (contact) => contact.id === action.payload.id
                )
                if (index !== -1) {
                    const contacts = [...state.partnerProfile.data.contacts]
                    contacts[index] = action.payload
                    state.partnerProfile.data = {
                        ...state.partnerProfile.data,
                        contacts,
                    }
                }
            }
        },
        deletePartnerProfileContact(state, action: PayloadAction<string>) {
            if (state.partnerProfile.data?.contacts) {
                state.partnerProfile.data.contacts = state.partnerProfile.data.contacts.filter(
                    (contact) => contact.id !== action.payload
                )
            }
        },
        createPartnerProfilePaymentDetail(state, action: PayloadAction<PartnerProfilePaymentDetailI>) {
            if (state.partnerProfile.data?.paymentDetails) {
                state.partnerProfile.data.paymentDetails = [...state.partnerProfile.data.paymentDetails, action.payload]
            }
        },
        updatePartnerProfilePaymentDetail(state, action: PayloadAction<PartnerProfilePaymentDetailI>) {
            if (state.partnerProfile.data) {
                const index = state.partnerProfile.data.paymentDetails.findIndex(
                    (contact) => contact.id === action.payload.id
                )
                if (index !== -1) {
                    const paymentDetails = [...state.partnerProfile.data.paymentDetails]
                    paymentDetails[index] = action.payload
                    state.partnerProfile.data = {
                        ...state.partnerProfile.data,
                        paymentDetails,
                    }
                }
            }
        },
        deletePartnerProfilePaymentDetail(state, action: PayloadAction<string>) {
            if (state.partnerProfile.data?.paymentDetails) {
                state.partnerProfile.data.paymentDetails = state.partnerProfile.data.paymentDetails.filter(
                    (paymentDetail) => paymentDetail.id !== action.payload
                )
            }
        },
        fetchPartnerProfileDetails(state) {
            state.partnerProfile.loading = true
            state.partnerProfile.error = null
        },
        fetchPartnerProfileDetailsSuccess(state) {
            state.partnerProfile.loading = false
        },
        fetchPartnerProfileDetailsFailed(state, action: PayloadAction<string>) {
            state.partnerProfile.loading = false
            state.partnerProfile.error = action.payload
        },
    },
})

// Actions
export const bookOfRelationsActions = bookOfRelationsSlice.actions

export const selectPartnersDataField = (state: RootState) => state.bookOfRelations.partners.partnersData
export const selectPartnersFilter = (state: RootState) => state.bookOfRelations.partners.partnersFilter
const selectPartnersDataLoading = (state: RootState) => state.bookOfRelations.partners.loading
const selectPartnersDataError = (state: RootState) => state.bookOfRelations.partners.error
export const selectPartnersData = createSelector(
    [selectPartnersDataField, selectPartnersFilter, selectPartnersDataLoading, selectPartnersDataError],
    (partnersData, partnersFilter, loading, error) => ({
        partnersData,
        partnersFilter,
        loading,
        error,
    })
)

const selectPartnershipDataField = (state: RootState) => state.bookOfRelations.partnership.partnershipData
const selectPartnershipDataLoading = (state: RootState) => state.bookOfRelations.partnership.loading
const selectPartnershipDataError = (state: RootState) => state.bookOfRelations.partnership.error
export const selectPartnershipData = createSelector(
    [selectPartnershipDataField, selectPartnershipDataLoading, selectPartnershipDataError],
    (partnershipData, loading, error) => ({
        partnershipData,
        loading,
        error,
    })
)

const selectPartnerProfileField = (state: RootState) => state.bookOfRelations.partnerProfile.data
const selectPartnerProfileInitiatorId = (state: RootState) => state.bookOfRelations.partnerProfile.data?.initiatorId
const selectPartnerProfilePartnerId = (state: RootState) => state.bookOfRelations.partnerProfile.data?.partnerId
const selectPartnerProfileContactsField = (state: RootState) => state.bookOfRelations.partnerProfile.data?.contacts
const selectPartnerProfilePaymentDetailsField = (state: RootState) =>
    state.bookOfRelations.partnerProfile.data?.paymentDetails
const selectPartnerProfileLoading = (state: RootState) => state.bookOfRelations.partnerProfile.loading
const selectPartnerProfileError = (state: RootState) => state.bookOfRelations.partnerProfile.error
export const selectPartnerProfile = createSelector(
    [selectPartnerProfileField, selectPartnerProfileLoading, selectPartnerProfileError],
    (partnerProfile, loading, error) => ({
        partnerProfile,
        loading,
        error,
    })
)
export const selectPartnerProfileContacts = createSelector(
    [
        selectPartnerProfileContactsField,
        selectPartnerProfileInitiatorId,
        selectPartnerProfilePartnerId,
        selectPartnerProfileLoading,
        selectPartnerProfileError,
    ],
    (contacts, initiatorId, partnerId, loading, error) => ({
        details: contacts || [],
        initiatorId,
        partnerId,
        loading,
        error,
    })
)
export const selectPartnerProfilePaymentDetails = createSelector(
    [
        selectPartnerProfilePaymentDetailsField,
        selectPartnerProfileInitiatorId,
        selectPartnerProfilePartnerId,
        selectPartnerProfileLoading,
        selectPartnerProfileError,
    ],
    (paymentDetails, initiatorId, partnerId, loading, error) => ({
        details: paymentDetails || [],
        initiatorId,
        partnerId,
        loading,
        error,
    })
)

export const selectPartnersBrandNames = (state: RootState) => state.bookOfRelations.partners.brandNames

// Reducer
const bookOfRelationsReducer = bookOfRelationsSlice.reducer
export default bookOfRelationsReducer
