export const checkOneFileType = (file: File, acceptedFileTypes: string, errorMessage: string) => {
    const fileTypes = acceptedFileTypes.split(";")
    if (fileTypes.includes(file.type)) {
        return { result: true, error: null }
    }

    return { result: false, error: errorMessage }
}

export const checkMultiFileTypeByExtensions = (file: File, acceptedFileExtensions: string[], errorMessage: string) => {
    const fileExtension = file.name.split(".").pop()
    if (fileExtension && acceptedFileExtensions.includes(fileExtension)) {
        return { result: true, error: null }
    }

    return { result: false, error: errorMessage }
}
