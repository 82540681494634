import { combineReducers, configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import rootSaga from "./rootSaga"
import invoiceReducer from "~/store/invoice/invoiceSlice"
import ocrReducer from "~/store/ocr/ocrSlice"
import accountReducer from "./account/accountSlice"
import authReducer from "~/store/auth/authSlice"
import errorsReducer from "~/store/errors/errorsSlice"
import globalReducer from "~/store/global/globalSlice"
import organizationReducer from "./organization/organizationSlice"
import paymentReducer from "~/store/payment/paymentSlice"
import usersReducer from "./users/usersSlice"
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist"
import workflowReducer from "./workflow/workflowSlice"
import purchaseRequestsReducer from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import rolesPermissionsReducer from "~/domains/identity/store/rolesPermissionsSlice"
import budgetReducer from "./budget/budgetSlice"
import purchaseOrdersReducer from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { tagsReducer } from "~/domains/tags/store/tagsSlice"
import documentReducer from "~/components/UploadDocument/store/documentSlice"
import { customFieldsReducer } from "~/domains/transactions/custom-fields/store/customFieldsSlice"
import documentRelationsReducer from "~/domains/transactions/document-relations/store/documentRelationsSlice"
import { communicationReducer } from "~/domains/communication/store/CommunicationSlice"
import bookOfRelationsReducer from "~/domains/transactions/book-of-relations/store/bookOfRelationsSlice"
import { catalogReducer } from "~/domains/identity/features/catalog/store/catalogSlice"
import { emailsReducer } from "~/domains/transactions/emails/store/emailsSlice"

const rootReducer = combineReducers({
    errors: errorsReducer,
    global: globalReducer,
    auth: authReducer,
    invoice: invoiceReducer,
    ocr: ocrReducer,
    organization: organizationReducer,
    account: accountReducer,
    payment: paymentReducer,
    tags: tagsReducer,
    users: usersReducer,
    workflow: workflowReducer,
    purchaseRequests: purchaseRequestsReducer,
    purchaseOrders: purchaseOrdersReducer,
    rolesPermissions: rolesPermissionsReducer,
    budget: budgetReducer,
    document: documentReducer,
    customFields: customFieldsReducer,
    documentRelations: documentRelationsReducer,
    communication: communicationReducer,
    bookOfRelations: bookOfRelationsReducer,
    catalog: catalogReducer,
    emails: emailsReducer,
})

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    "ocr/uploadDocument",
                    "ocr/confirmInvoiceFailed",
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(sagaMiddleware /* , routerMiddleware(history) */),
})
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
