import React from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/features/auth/ProtectedRoute"
import { COPILOT_PROMPT_ROUTE } from "./routes"

import { Prompt } from "./pages/Prompt"

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="prs_route">
            <Route path={COPILOT_PROMPT_ROUTE}
                   element={<Prompt />} />
        </Route>,
    ],
}
