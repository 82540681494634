import { WHITE_SPACE_REGEXP } from "~/utils/string"
import { TagI, TagGroupI } from "../../types"

export function getTagsFilter(filter: string, tagGroup: TagGroupI) {
    const filterWords = filter.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
    return (obj: TagI) => {
        const objectWords =
            `${tagGroup.name} ${obj.name} ${obj.keywords} ${obj.externalId} ${obj.value} ${obj.description}`
                .toLocaleLowerCase()
                .split(WHITE_SPACE_REGEXP)
        return filterWords.every((word) => objectWords.some((objWord) => objWord.indexOf(word) >= 0))
    }
}
