import { TagObjectI } from "~/domains/tags/types"
import { BudgetDataWithMetricsI } from "~/features/budget/types"
import { CreateLineDTO } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import {
    OrganizationI,
    UserId,
    TotalAmountsMonetaryData,
    MonetaryInfo,
    AmountsMonetaryData,
    CurrencyCodes,
} from "~/types"
import { ProductVersionId } from "~/domains/identity/features/catalog/types"
import { Languages } from "~/store/global/globalSlice"

export enum PurchaseOrderViewType {
    "ADD" = "ADD",
    "EDIT" = "EDIT",
    "VIEW" = "VIEW",
}
export interface CreatePurchaseOrderResponse {
    id: string
}

export interface Address {
    city: string
    country: string
    street: string
    street2?: string | null
    zipCode: string
}

export enum PurchaseOrderLineStatus {
    PENDING_APPROVAL = "PENDING_APPROVAL",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    IN_PREPARATION = "IN_PREPARATION",
    OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY",
    DELIVERED = "DELIVERED",
    RECEIVED = "RECEIVED",
}

export enum PurchaseOrderApprovalStatus {
    PENDING = "PENDING_APPROVAL",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
}

export interface PurchaseOrderLineWithApprovalStatus {
    buyerApprovalStatus: PurchaseOrderApprovalStatus
    supplierApprovalStatus: PurchaseOrderApprovalStatus
}

export interface PurchaseOrderLineV2 extends PurchaseOrderLineWithApprovalStatus {
    id: string | null
    status: PurchaseOrderLineStatus
    description: string
    quantity: number
    taxRate: number
    unitPrice: MonetaryInfo
    unitPriceExcludingTax: MonetaryInfo
    deliveryNote?: string
    temporaryId?: string
    hasChanged?: boolean
    supplierItemId?: ProductVersionId
    buyerItemId?: ProductVersionId
    totals: AmountsMonetaryData
}

export interface PurchaseOrderLine extends PurchaseOrderLineWithApprovalStatus {
    id: string | null
    status: PurchaseOrderLineStatus
    description: string
    quantity: number
    taxRate: number
    totalAmount: number
    totalAmountExcludingTax: number
    totalTax: number
    unitPrice: number
    unitPriceExcludingTax: number
    deliveryNote?: string
    temporaryId?: string
    hasChanged?: boolean
    supplierItemId?: ProductVersionId
    buyerItemId?: ProductVersionId
}

export enum PurchaseOrderStatus {
    DRAFT_SUPPLIER = "DRAFT_SUPPLIER",
    DRAFT_BUYER = "DRAFT_BUYER",
    SUBMITTED_SUPPLIER = "SUBMITTED_SUPPLIER",
    SUBMITTED_BUYER = "SUBMITTED_BUYER",
    AWAITING_SUPPLIER_APPROVAL = "AWAITING_SUPPLIER_APPROVAL",
    AWAITING_BUYER_APPROVAL = "AWAITING_BUYER_APPROVAL",
    IN_PREPARATION = "IN_PREPARATION",
    OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY",
    DELIVERED = "DELIVERED",
    RECEIVED = "RECEIVED",
}

export enum CreatePurchaseOrderStatus {
    DRAFT = "DRAFT",
    SUBMITTED = "SUBMITTED",
}

export const PurchaseOrderStatusOrder = [
    "DRAFT", // Issue
    "DRAFT_SUPPLIER",
    "DRAFT_BUYER",
    "SUBMITTED_BUYER",
    "SUBMITTED_SUPPLIER",
    "AWAITING_SUPPLIER_APPROVAL",
    "AWAITING_BUYER_APPROVAL",
    "IN_PREPARATION",
    "OUT_FOR_DELIVERY",
    "DELIVERED",
    "RECEIVED",
]

export enum PurchaseOrderStatusUpdate {
    APPROVE = "APPROVE",
    REJECT = "REJECT",
    SUBMIT = "SUBMIT",
    SEND = "SEND",
    UN_SEND = "UN_SEND",
    DISPATCHED = "DISPATCHED",
    DELIVERED = "DELIVERED",
    RECEIVED = "RECEIVED",
}

export interface PurchaseOrdersResponseV2 {
    billingAddress?: Address | null
    buyerId: string
    creationDate: string
    expectedDeliveryDate: string
    id: string
    lines: PurchaseOrderLineV2[]
    requesterUserId?: string
    shippingAddress?: Address | null
    shortId: string
    status: PurchaseOrderStatus
    supplierId: string
    purchaseRequest: PurchaseOrderPurchaseRequest
    description: string
    totals: TotalAmountsMonetaryData
}

export interface PurchaseOrdersResponse {
    billingAddress?: Address | null
    buyerId: string
    creationDate: string
    currency: CurrencyCodes
    expectedDeliveryDate: string
    id: string
    lines: PurchaseOrderLine[]
    requesterUserId?: string
    shippingAddress?: Address | null
    shortId: string
    status: PurchaseOrderStatus
    supplierId: string
    totalAmount: number
    totalAmountExcludingTax: number
    totalTax: number
    purchaseRequest: PurchaseOrderPurchaseRequest
    description: string
}

export interface PurchaseOrders extends PurchaseOrdersResponse {
    supplierName: string
    buyerName: string
    requesterName?: string
    tags?: TagObjectI[]
    budgets?: BudgetDataWithMetricsI[] | null
    buyer?: OrganizationI
    supplier?: OrganizationI
}

// For List-ResponsesGetPurchaseOrdersResponse, we can simply use an array of ResponsesGetPurchaseOrdersResponse
export type ListPurchaseOrdersResponse = PurchaseOrdersResponse[]
export type ListPurchaseOrders = PurchaseOrders[]

export interface PurchaseOrderPurchaseRequest {
    id: string
    number: string
}

export interface CreateLineDTOV2 {
    id?: string | null
    description: string
    quantity: number
    unitPrice: MonetaryInfo
    unitPriceExcludingTax: MonetaryInfo
    taxRate: string
    buyerItemId?: ProductVersionId
    supplierItemId?: ProductVersionId
    itemId?: ProductVersionId
}

export interface CreatePurchaseOrderDTOV2 {
    buyerId: string
    supplierId: string
    status: CreatePurchaseOrderStatus
    description: string
    expectedDeliveryDate: string
    shippingAddress?: Address | null
    shortId?: string | null
    requesterUserId?: UserId
    billingAddress?: Address | null
    lines: CreateLineDTOV2[]
}

export interface CreatePurchaseOrderDTO {
    buyerId: string
    supplierId: string
    status: CreatePurchaseOrderStatus
    description: string
    currency: CurrencyCodes
    expectedDeliveryDate: string // Should be formatted as a Date in TypeScript
    lines: CreateLineDTO[]
    shippingAddress?: Address | null
    shortId?: string | null
    requesterUserId?: UserId
    billingAddress?: Address | null
}

export interface UpdatePurchaseOrderDTOV2 {
    billingAddress?: Address | null
    description: string
    expectedDeliveryDate: string
    lines: CreateLineDTOV2[]
    shippingAddress?: Address | null
    shortId?: string | null
}

export enum PurchaseOrdersTab {
    ALL = "ALL",
    DRAFT = "DRAFT",
    SUBMITTED = "SUBMITTED",
    AWAITING_YOUR_APPROVAL = "AWAITING_YOUR_APPROVAL",
    AWAITING_BUYER_APPROVAL = "AWAITING_BUYER_APPROVAL",
    AWAITING_SUPPLIER_APPROVAL = "AWAITING_SUPPLIER_APPROVAL",
    IN_PREPARATION = "IN_PREPARATION",
    OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY",
    DELIVERED = "DELIVERED",
    RECEIVED = "RECEIVED",
}
export interface PurchaseOrderPDFDTO {
    logo?: string
    description: string
    purchaseOrderId: string
    url?: string
    issueDate: string
    deliveryDate: string
    paymentTerms: string
    buyer: {
        name: string
        billingAddress: Address
        shippingAddress: Address
        contact?: Contact
    }
    supplier: {
        name: string
        address: Address
    }
    items: Item[]
    currency: CurrencyCodes
    language: Languages
    totalAmount: number
    totalAmountExcludingTax: number
    totalTax: number
}
export interface Item {
    description: string
    quantity: number
    unitPriceExcludingTax: number
    taxRate: number
    totalAmount: number
}

export interface Contact {
    name: string
    phone: string
    email?: string
}
