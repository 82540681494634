import React, { ReactNode, SyntheticEvent, useCallback, useEffect, useRef } from "react"
import { OrganizationId } from "~/types"
import { useProductVersions } from "../../hooks"
import {
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteRenderInputParams,
    CircularProgress,
    TextField,
} from "@mui/material"
import { Loader } from "~/components"
import { ProductVersionI } from "../../types"
import { isDefined } from "~/utils/isDefined"

interface Props {
    organizationId: OrganizationId
    initialValue: string
    setInputValue: (value: string) => void
    context: string
    label: ReactNode
    onChange: (value: ProductVersionI | null) => void
    disabled?: boolean
    size?: "small" | "medium"
    lineId?: string | null
}

const getOptionLabel = (option: string | ProductVersionI) => {
    if (typeof option === "string") return option
    if (option.sku) return `${option.name} - ${option.sku}`
    return option.name
}

export const ProductVersionAutocomplete: React.FC<Props> = ({
    organizationId,
    lineId,
    initialValue,
    setInputValue,
    context,
    label,
    disabled,
    size,
    onChange,
}) => {
    const { products, loading, setQuery, query } = useProductVersions(organizationId, context)
    const initialQuerySet = useRef<boolean>(false)
    const [currentLineId, setCurrentLineId] = React.useState<string | undefined | null>(null)
    useEffect(() => {
        setCurrentLineId(lineId)
    }, [lineId])
    useEffect(() => {
        if (!initialQuerySet.current && currentLineId === lineId) {
            setQuery(initialValue)
            initialQuerySet.current = true
        } else if (currentLineId !== lineId) {
            setQuery(initialValue)
            initialQuerySet.current = true
        }
    }, [setQuery, initialValue, lineId, currentLineId])

    const onInputChange = useCallback(
        (_event: SyntheticEvent, value: string, reason: string) => {
            // reason can be input, reset or clear
            setQuery(value)
            if (reason === "input") {
                setInputValue(value)
            }
        },
        [setQuery, setInputValue]
    )

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                label={label}
                required={true}
                {...params}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        ),
        [label, loading]
    )

    const onAutocompleteChange = useCallback(
        (
            _event: React.SyntheticEvent<Element, Event>,
            value: string | ProductVersionI | null,
            reason: AutocompleteChangeReason
        ) => {
            if (reason === "selectOption" && typeof value !== "string" && isDefined(value)) {
                onChange(value)
            } else if (reason === "clear") {
                onChange(null)
            }
        },
        [onChange]
    )

    return (
        <Autocomplete
            freeSolo
            onChange={onAutocompleteChange}
            inputValue={query}
            onInputChange={onInputChange}
            options={products}
            getOptionLabel={getOptionLabel}
            loading={loading}
            loadingText={<Loader small />}
            renderInput={renderInput}
            disabled={disabled}
            size={size}
        />
    )
}
