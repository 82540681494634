import { OrganizationId } from "~/types"
import { DocumentRelationId } from "../types/InvoiceRelation"
import { documentRelationsActions } from "../store/documentRelationsSlice"
import { useDeleteRelation } from "./useDeleteRelation"

export const useDeletePurchaseOrderRelation = (organizationId: OrganizationId, relationId: DocumentRelationId) => {
    return useDeleteRelation(
        organizationId,
        relationId,
        documentRelationsActions.removePurchaseOrderRelation(relationId)
    )
}
