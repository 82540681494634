import { styled } from "@mui/material"

export const TagsSelectorContainer = styled("div")({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "6px",
    padding: "8px 0",
    zIndex: 10,
})
