import React, { Dispatch, SetStateAction, useCallback, useState } from "react"
import "./Documents.scss"
import { Grid } from "@mui/material"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { DocumentDataI, DraftDocumentI } from "../core/Documents"
import { ConfirmModal } from "~/components/ConfirmModal"
import { useDeleteDocument } from "../hooks/useDeleteDocument"
import DocumentChip from "../DocumentChip/DocumentChip"
import { documentTypeMessages } from "../UploadDocument"
import { toast } from "react-toastify"

const messages = defineMessages({
    noFiles: {
        id: "documents.filesList.noFiles",
        defaultMessage: "No document attached",
    },
    delete: { id: "common.menuAction.actions.delete", defaultMessage: "Delete" },
    update: { id: "common.menuAction.actions.update", defaultMessage: "Edit" },
    confirmDeleteTitle: {
        id: "documents.filesList.deleteDocument",
        defaultMessage: "Delete a document",
    },
    confirmDeleteMessage: {
        id: "documents.filesList.confirmDeleteMessage",
        defaultMessage: "Are you sure to delete {name}? Any deletion is permanent.",
    },
    downloadError: {
        id: "documents.filesList.downloadError",
        defaultMessage: "Couldn't download the file, an issue occurred",
    },
})

interface DocumentsListProps {
    documents: DocumentDataI[] | DraftDocumentI[] | undefined
    setDraftDocuments?: Dispatch<SetStateAction<DraftDocumentI[]>>
    readonly?: boolean
}

export const DocumentsList: React.FC<DocumentsListProps> = ({ documents, setDraftDocuments, readonly = false }) => {
    const { formatMessage } = useIntl()
    const [currentDocument, setCurrentDocument] = useState<DocumentDataI | DraftDocumentI | undefined>()
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
    const { deleteDocument } = useDeleteDocument()

    const handleDownload = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, document: DocumentDataI | DraftDocumentI) => {
            event.preventDefault()
            if ("latestFileVersion" in document && document.latestFileVersion) {
                window.open(document.latestFileVersion, "_blank")
            } else if ("file" in document && document.file) {
                const fileUrl = URL.createObjectURL(document.file)
                const newWindow = window.open(fileUrl, "_blank")
                if (newWindow) {
                    newWindow.addEventListener(
                        "load",
                        () => {
                            URL.revokeObjectURL(fileUrl)
                        },
                        { once: true }
                    )
                }
            } else {
                toast.error(formatMessage(messages.downloadError))
            }
        },
        []
    )

    const displayConfirmModal = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, document: DocumentDataI | DraftDocumentI) => {
            event.preventDefault()
            setCurrentDocument(document)
            setShowConfirmModal(true)
        },
        []
    )

    const hideConfirmModal = useCallback(() => setShowConfirmModal(false), [])

    const handleDeleteDocument = useCallback(() => {
        if (currentDocument && "id" in currentDocument && currentDocument?.id) {
            deleteDocument(currentDocument.id)
            setCurrentDocument(undefined)
            return Promise.resolve(true)
        } else if (currentDocument && "file" in currentDocument && currentDocument?.file && setDraftDocuments) {
            setDraftDocuments((documents) => documents?.filter((doc) => doc !== currentDocument))
            setCurrentDocument(undefined)
            return Promise.resolve(true)
        }
        setCurrentDocument(undefined)
        return Promise.resolve(false)
    }, [currentDocument])
    return (
        <>
            {documents && documents.length ? (
                <>
                    <Grid container className="documents-content">
                        {documents.map(
                            (document: DraftDocumentI | DocumentDataI, index: React.Key | null | undefined) => (
                                <Grid item key={index}>
                                    <DocumentChip
                                        type={formatMessage(documentTypeMessages[document.documentType])}
                                        name={document.name}
                                        onClick={(e) => handleDownload(e, document)}
                                        onDelete={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                                            displayConfirmModal(e, document)
                                        }
                                        readonly={readonly}
                                    />
                                </Grid>
                            )
                        )}
                    </Grid>
                    <ConfirmModal
                        title={formatMessage(messages.confirmDeleteTitle)}
                        open={showConfirmModal}
                        close={hideConfirmModal}
                        onConfirm={handleDeleteDocument}
                    >
                        {formatMessage(messages.confirmDeleteMessage, { name: currentDocument?.name })}
                    </ConfirmModal>
                </>
            ) : (
                <div className="no-document">
                    <FormattedMessage {...messages.noFiles} />
                </div>
            )}
        </>
    )
}
