// TODO: finish the footer
import {
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { commonMessages } from "~/common-messages"
import { getComparator, tableSort } from "~/utils"

import "./DataTable.scss"
import classnames from "classnames"

type GetValueFunc<T> = (o: T) => string | number | null
type RenderCellFunc<T> = (o: T, index: number) => React.ReactNode

export type DataTableColumn<T> = {
    label: React.ReactNode
    id: string
    renderCell?: RenderCellFunc<T>
    sorter?: boolean
    style?: React.CSSProperties
    visible?: boolean
    aggregationFunction?: (items: T[]) => React.ReactNode
} & (
    | {
          key: keyof T
          getValue?: undefined
      }
    | {
          key?: undefined
          getValue: GetValueFunc<T>
      }
)

type Props<T> = {
    data: T[] | undefined
    columns: DataTableColumn<T>[]
    handleClickRow?: (item: T) => void
    classNames?: string
    defaultOrderBy?: string
    defaultOrderDirection?: OrderDirection
    onRequestSort?: (orderBy: string, orderDirection: OrderDirection) => void
    hidePagination?: boolean
    withAggregatedFooter?: boolean
} & (
    | {
          selectable?: false
          defaultSelected?: never
          onSelect?: never
          keySelect?: never
      }
    | {
          selectable: true
          defaultSelected: unknown[]
          onSelect: (selected: unknown[]) => void
          keySelect: keyof T
      }
)

export enum OrderDirection {
    ASC = "asc",
    DESC = "desc",
}

const getComparatorKey = <T,>(
    columns: DataTableColumn<T>[],
    id: string | undefined
): keyof T | GetValueFunc<T> | undefined => {
    const column = columns.find((column) => column.id === id)
    if (!column) return undefined
    return column.getValue || column.key
}

const isColumnVisible = <T,>(column: DataTableColumn<T>): boolean => {
    return column.visible === undefined || column.visible !== false
}

export const DataTable = <T,>({
    data,
    columns,
    selectable,
    defaultSelected,
    onSelect,
    keySelect,
    handleClickRow,
    classNames,
    defaultOrderBy,
    defaultOrderDirection,
    onRequestSort,
    hidePagination,
    withAggregatedFooter,
}: Props<T>) => {
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [valueToOrderBy, setValueToOrderBy] = useState<string | undefined>(defaultOrderBy)
    const [orderDirection, setOrderDirection] = useState<OrderDirection>(defaultOrderDirection ?? OrderDirection.DESC)
    const [selected, setSelected] = useState<unknown[]>(defaultSelected ?? [])
    const { formatMessage } = useIntl()

    const classes = classnames("flowie-table", classNames, {
        "click-row": !!handleClickRow,
    })

    const countData = data?.length ?? 0
    columns = columns.filter(isColumnVisible)

    useEffect(() => {
        if (defaultSelected) {
            setSelected(defaultSelected)
        }
    }, [defaultSelected])

    const handleRequestSort = (property: string) => () => {
        const isAscending = valueToOrderBy === property && orderDirection === "asc"
        setValueToOrderBy(property)
        setOrderDirection(isAscending ? OrderDirection.DESC : OrderDirection.ASC)
        onRequestSort && onRequestSort(property, isAscending ? OrderDirection.DESC : OrderDirection.ASC)
    }

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const onSelectAllClick = useCallback(() => {
        if (selected.length === countData) {
            setSelected([])
            onSelect && onSelect([])
        } else {
            if (selectable && keySelect) {
                const newSelected = data ? data?.map((d) => d[keySelect]) : []
                setSelected(newSelected)
                onSelect && onSelect(newSelected)
            }
        }
    }, [selected, setSelected, data])

    const checkSelected = useCallback(
        (entityId: unknown) => {
            const index = selected.indexOf(entityId)
            if (index >= 0) {
                const newSelected = [...selected.slice(0, index), ...selected.slice(index + 1)]
                setSelected(newSelected)
                onSelect && onSelect(newSelected)
            } else {
                const newSelected = [...selected, entityId]
                setSelected(newSelected)
                onSelect && onSelect(newSelected)
            }
        },
        [selected, setSelected]
    )

    const getCellStyle = (column: DataTableColumn<T>) => {
        if (!column.style) {
            return undefined
        }

        const style: React.CSSProperties = {}
        if (column.style.maxWidth) {
            style.maxWidth = column.style.maxWidth
            style.textOverflow = "ellipsis"
            style.whiteSpace = "nowrap"
            style.overflow = "hidden"
        }

        return { ...column.style, ...style }
    }

    return (
        <>
            <TableContainer component={Paper} className={classes}>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {selectable ? (
                                <TableCell align="left">
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < countData}
                                        checked={selected.length > 0 && selected.length === countData}
                                        onChange={onSelectAllClick}
                                    />
                                </TableCell>
                            ) : null}
                            {columns.map((column, i) => (
                                <TableCell align="left" key={i}>
                                    {column.sorter ? (
                                        <TableSortLabel
                                            active={valueToOrderBy === column.id}
                                            direction={orderDirection}
                                            onClick={handleRequestSort(column.id)}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    ) : (
                                        column.label
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data &&
                            tableSort(data, getComparator(orderDirection, getComparatorKey(columns, valueToOrderBy)))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((dataLine, i) => (
                                    <TableRow
                                        key={i}
                                        onClick={() => handleClickRow && handleClickRow(dataLine)}
                                        hover={!!handleClickRow}
                                    >
                                        {selectable && keySelect ? (
                                            <TableCell align="left" className="select-multi">
                                                <Checkbox
                                                    checked={selected.indexOf(dataLine[keySelect]) >= 0}
                                                    onClick={(e) => e.stopPropagation()}
                                                    onChange={() => checkSelected(dataLine[keySelect])}
                                                />
                                            </TableCell>
                                        ) : null}
                                        {columns.map((column, j) => (
                                            <TableCell align="left" key={j} sx={getCellStyle(column)}>
                                                {column.renderCell
                                                    ? column.renderCell(dataLine, i)
                                                    : column.getValue
                                                    ? column.getValue(dataLine)
                                                    : `${dataLine[column.key]}`}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                    </TableBody>
                    {data && withAggregatedFooter ? (
                        <TableFooter>
                            <TableRow>
                                {columns.map((column, i) => (
                                    <TableCell align="left" key={`footer-${i}`} sx={getCellStyle(column)}>
                                        {column.aggregationFunction ? column.aggregationFunction(data) : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableFooter>
                    ) : null}
                </Table>
            </TableContainer>
            {!hidePagination ? (
                <TablePagination
                    labelRowsPerPage={formatMessage(commonMessages.rowsPerPage)}
                    labelDisplayedRows={(pagination) => {
                        if (pagination.count === -1) {
                            return formatMessage(commonMessages.labelDisplayedRowsNoCount, {
                                from: pagination.from,
                                to: pagination.to,
                            })
                        }
                        return formatMessage(commonMessages.labelDisplayedRows, {
                            from: pagination.from,
                            to: pagination.to,
                            count: pagination.count,
                        })
                    }}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={data?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            ) : null}
        </>
    )
}
