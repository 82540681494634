import { MessageDescriptor, defineMessages } from "react-intl"
import { FilterFieldProperty } from "../types"

const fieldNamesLabels: Record<FilterFieldProperty, MessageDescriptor> = defineMessages({
    [FilterFieldProperty.SUPPLIER]: {
        id: "workflow.blockSettings.fieldType.SUPPLIER",
        defaultMessage: "a supplier",
    },
    [FilterFieldProperty.AMOUNT]: {
        id: "workflow.blockSettings.fieldType.AMOUNT",
        defaultMessage: "an amount",
    },
    [FilterFieldProperty.LINE_AMOUNT]: {
        id: "workflow.blockSettings.fieldType.LINE_AMOUNT",
        defaultMessage: "a line amount",
    },
    [FilterFieldProperty.TAGS]: {
        id: "workflow.blockSettings.fieldType.TAGS",
        defaultMessage: "a tag",
    },
    [FilterFieldProperty.TAGS_V2]: {
        id: "workflow.blockSettings.fieldType.TAGS",
        defaultMessage: "a tag",
    },
    [FilterFieldProperty.AUTHOR]: {
        id: "workflow.blockSettings.fieldType.AUTHOR",
        defaultMessage: "a requester",
    },
    [FilterFieldProperty.UNKNOWN]: {
        id: "workflow.blockSettings.fieldType.UNKNOWN",
        defaultMessage: "an unknown property",
    },
})

export const getFieldLabel = (fieldProperty: FilterFieldProperty): MessageDescriptor => fieldNamesLabels[fieldProperty]
