import React from "react"
import { defineMessages, useIntl } from "react-intl"
import { PurchaseRequestDetails } from "../../purchase-requests/types/PurchaseRequests"
import { InvoiceI, ReceivedDocumentI } from "~/types"
import { PurchaseOrders } from "../../purchase-orders/types/PurchaseOrders"
import { Button } from "~/components"
import { DollarSign } from "react-feather"
import { BudgetLink } from "../BudgetLink/BudgetLink"

const messages = defineMessages({
    budgets: {
        id: "transactions.in.budgets",
        defaultMessage: "{count, plural, =0 {Budget} one {Budget} other {Budgets}}",
    },
    addToBudget: {
        id: "transactions.in.addToBudget",
        defaultMessage: "Add to budget",
    },
})

interface Props {
    transaction: ReceivedDocumentI | PurchaseRequestDetails | PurchaseOrders | InvoiceI
    showModalAddToBudget: () => void
}

export const TransactionInBudgets: React.FC<Props> = ({ transaction, showModalAddToBudget }) => {
    const { formatMessage } = useIntl()

    return (
        <>
            {transaction.budgets && transaction.budgets.length ? (
                <BudgetLink
                    budgets={transaction.budgets}
                    label={formatMessage(messages.budgets, { count: transaction.budgets.length })}
                    handleEdit={showModalAddToBudget}
                />
            ) : (
                <Button type="tertiary" buttonType="button" onClick={showModalAddToBudget}>
                    <DollarSign size={16} />
                    {formatMessage(messages.addToBudget)}
                </Button>
            )}
        </>
    )
}
