import React, { UIEvent, useCallback, useState } from "react"
import { MessageWithUserI, RoomI } from "../../types"
import { InputMessage } from "../InputMessage"
import { useRoomMessages, useSendMessage } from "../../hooks"
import { OrganizationId } from "~/types"
import { Loader } from "~/components"
import { Message } from "../Message"
import { useAppSelector } from "~/store/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { styled } from "@mui/material"
import { MessageViewersModal } from "../MessageViewersModal"
import { PinnedMessages } from "./PinnedMessages"

interface Props {
    organizationId: OrganizationId
    room: RoomI
    visible?: boolean
}

const ChatRoomContainer = styled("div")({
    flex: "1 1 auto",
    overflowY: "auto",
    paddingRight: "16px",
    marginRight: "-8px",
})

export const ChatRoom: React.FC<Props> = ({ organizationId, room, visible = true }) => {
    const currentUser = useAppSelector(selectUser)
    const { messagesInRoom, loading, fetchMoreMessages } = useRoomMessages(organizationId, room.id)
    const sendMessage = useSendMessage(organizationId, room.id)
    const [selectedMessage, setSelectedMessage] = useState<MessageWithUserI | null>(null)

    const areMessagesLoaded = messagesInRoom !== null

    const setRef = useCallback(
        (element: HTMLDivElement) => {
            if (element && areMessagesLoaded) {
                window.requestAnimationFrame(() => {
                    element.scrollTo({ top: element.scrollHeight })
                })
            }
        },
        [areMessagesLoaded]
    )

    const onScroll = useCallback(
        (event: UIEvent<HTMLDivElement>) => {
            if (event.currentTarget.scrollTop === 0) {
                fetchMoreMessages()
            }
        },
        [fetchMoreMessages]
    )

    const renderMessage = useCallback(
        (message: MessageWithUserI) => {
            return (
                <Message
                    key={message.id}
                    roomId={room.id}
                    message={message}
                    currentUser={currentUser}
                    selectMessage={setSelectedMessage}
                />
            )
        },
        [currentUser, room.id]
    )

    if (!visible) {
        return null
    }

    return (
        <ChatRoomContainer ref={setRef} onScroll={onScroll}>
            {loading && <Loader small />}
            <PinnedMessages messages={room.pinnedMessages} roomId={room.id} currentUser={currentUser} />
            {messagesInRoom?.map(renderMessage)}
            <InputMessage involvedOrganization={room.involvedOrganization} onSendMessage={sendMessage} />
            <MessageViewersModal
                message={selectedMessage}
                currentUser={currentUser}
                roomId={room.id}
                close={() => setSelectedMessage(null)}
            />
        </ChatRoomContainer>
    )
}
