import React from "react"
import { SocketIOContextProvider } from "./SocketIOContextProvider"

export function withSocketIOProvider<T extends JSX.IntrinsicAttributes>(
    WrappedComponent: React.ComponentType<T>
): React.ComponentType<T> {
    const wrapped = (props: T) => (
        <SocketIOContextProvider>
            <WrappedComponent {...props} />
        </SocketIOContextProvider>
    )
    wrapped.displayName = "WithSocketIOContextProvider"
    return wrapped
}
