import * as t from "io-ts"
import { MessageIO } from "./Message"

const PaginationInfoIO = t.type({
    current_page: t.number,
    per_page: t.number,
    total_items: t.number,
    total_pages: t.number,
})

export const MessagesListIO = t.type({
    messages: t.array(MessageIO),
    pagination_info: PaginationInfoIO,
})

export type PaginationInfoI = t.TypeOf<typeof PaginationInfoIO>
export type MessagesListI = t.TypeOf<typeof MessagesListIO>
