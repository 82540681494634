import React, { ReactNode, useEffect } from "react"
import Layout from "~/components/Layout"
import { Loader, ModalReconnect } from "~/components"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import {
    authActions,
    selectAuthLoading,
    selectDisplayModalReconnect,
    selectOpenPopupSignIn,
} from "~/store/auth/authSlice"
import { useAuth } from "~/store/auth/hooks/useAuth"

interface RouterLayoutProps {
    modulesRoutes: ReactNode[]
    modulesNavItems: []
}

function RouterLayout({ modulesRoutes, modulesNavItems }: RouterLayoutProps) {
    const dispatch = useAppDispatch()
    const { addFocusListener, removeFocusListener } = useAuth()

    const isAuthLoading = useAppSelector(selectAuthLoading)
    const displayModalReconnect = useAppSelector(selectDisplayModalReconnect)
    const openPopupSignIn = useAppSelector(selectOpenPopupSignIn)

    useEffect(() => {
        dispatch(authActions.isAuthenticated())
    }, [])

    useEffect(() => {
        if (openPopupSignIn) {
            addFocusListener()
        } else {
            removeFocusListener()
        }
    }, [openPopupSignIn])

    return (
        <>
            {isAuthLoading && openPopupSignIn ? <Loader fullscreen={true} /> : null}
            <Layout modulesRoutes={modulesRoutes} modulesNavItems={modulesNavItems} />
            {!displayModalReconnect || isAuthLoading ? null : <ModalReconnect />}
        </>
    )
}

export default RouterLayout
