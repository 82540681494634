import * as t from "io-ts"

export const CreateRoomPayloadIO = t.intersection([
    t.type({
        objectId: t.string,
        objectType: t.string,
    }),
    t.partial({
        name: t.string,
        contextId: t.string,
        contextType: t.string,
        organizations: t.array(t.string),
    }),
])

export type CreateRoomPayload = t.TypeOf<typeof CreateRoomPayloadIO>

export type CreateRoomOptions =
    | { onlyPrivateRoom: true; onlySharedRoom?: false | never }
    | { onlyPrivateRoom?: false | never; onlySharedRoom: true }
