import React, { lazy } from "react"
import { Route } from "react-router-dom"

import { NEW_UPLOAD, OCR_IMPORT } from "./routes"

const OcrUpload = lazy(() => import("./pages/OcrUpload").then(({ OcrUpload }) => ({ default: OcrUpload })))
const OcrImport = lazy(() => import("./pages/OcrImport").then(({ OcrImport }) => ({ default: OcrImport })))
export default {
    routes: [
        <Route key="new_ocr_route">
            <Route path={NEW_UPLOAD} element={<OcrUpload />} />
            <Route path={OCR_IMPORT} element={<OcrImport />} />
        </Route>,
    ],
}
