import { useCallback, useEffect, useMemo, useState } from "react"
import { CreateLineDTO, PurchaseRequestLine } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { getPriceTaxIncluded, taxRateFromBack } from "~/domains/transactions/purchase-requests/utils/lines"
import { purchaseRequestsActions } from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { useAppDispatch } from "~/store/hooks"
import {
    PurchaseOrderApprovalStatus,
    PurchaseOrderLine,
    PurchaseOrderLineStatus,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { v4 as uuidv4 } from "uuid"
import { isDefined } from "~/utils/isDefined"
import {
    purchaseOrdersActions
} from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { PurchaseType } from "~/domains/transactions/types"

const createEmptyItem = (): CreateLineDTO => ({
    description: "",
    quantity: 1,
    taxRate: 0,
    unitPrice: 0,
    unitPriceExcludingTax: 0,
    totalAmount: 0,
    totalAmountExcludingTax: 0,
    temporaryId: uuidv4(),
    status: PurchaseOrderLineStatus.PENDING_APPROVAL,
    buyerApprovalStatus: PurchaseOrderApprovalStatus.PENDING,
    supplierApprovalStatus: PurchaseOrderApprovalStatus.PENDING,
})


const isPurchaseOrderLine = (data: PurchaseRequestLine | PurchaseOrderLine): data is PurchaseOrderLine => {
    return "buyerApprovalStatus" in data && "supplierApprovalStatus" in data && "status" in data
}

const isPurchaseRequestLine = (data: PurchaseRequestLine | PurchaseOrderLine): data is PurchaseRequestLine => {
    return !isPurchaseOrderLine(data)
}
export function useItemsInModal(
    open: boolean,
    lines: PurchaseRequestLine[] | PurchaseOrderLine[] | undefined,
    addNewItem: boolean,
    purchaseType: PurchaseType
) {
    const dispatch = useAppDispatch()
    const [items, setItems] = useState<CreateLineDTO[]>([])
    const [totalExcludingTaxes, setTotalExcludingTaxes] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    useEffect(() => {
        if (open) {
            const itemsLines: CreateLineDTO[] =
                initItems.length === 0
                    ? [createEmptyItem()]
                    : addNewItem
                    ? [...initItems, createEmptyItem()]
                    : initItems
            setItems(itemsLines)
            setTotals(itemsLines)
        }
    }, [open, addNewItem])

    const initItems = useMemo<(PurchaseRequestLine | PurchaseOrderLine)[]>(() => {
        if (!lines) return []

        return lines.map((line: PurchaseRequestLine | PurchaseOrderLine): PurchaseRequestLine | PurchaseOrderLine => {
            return {
                id: line.id ?? null,
                description: line.description,
                quantity: line.quantity,
                unitPrice: line.unitPrice,
                unitPriceExcludingTax: line.unitPriceExcludingTax,
                taxRate: taxRateFromBack(line.taxRate),
                totalAmount: line.totalAmount,
                totalAmountExcludingTax: line.totalAmountExcludingTax,
                buyerApprovalStatus:
                    isPurchaseOrderLine(line) && line.buyerApprovalStatus
                        ? line.buyerApprovalStatus
                        : PurchaseOrderApprovalStatus.PENDING,
                supplierApprovalStatus:
                    isPurchaseOrderLine(line) && line.supplierApprovalStatus
                        ? line.supplierApprovalStatus
                        : PurchaseOrderApprovalStatus.PENDING,
                status:
                    isPurchaseOrderLine(line) && line.status ? line.status : PurchaseOrderLineStatus.PENDING_APPROVAL,
                totalTax: line.totalAmount - line.totalAmountExcludingTax,
                temporaryId: line.id ? undefined : line.temporaryId ?? uuidv4(),
                ...(isPurchaseOrderLine(line) && line.supplierItemId
                    ? { supplierItemId: line.supplierItemId }
                    : isPurchaseOrderLine(line) && line.buyerItemId
                    ? { buyerItemId: line.buyerItemId }
                    : isPurchaseRequestLine(line) && line.itemId
                    ? { itemId: line.itemId }
                    : {}),
            }
        })
    }, [lines])

    const setTotals = useCallback(
        (items: CreateLineDTO[]) => {
            const total = items.reduce((accumulator, item) => {
                return accumulator + item.totalAmount
            }, 0)
            setTotal(total)
            if (purchaseType === PurchaseType.ORDER) {
                dispatch(purchaseOrdersActions.updateData({ field: "totalAmount", value: total }))
            } else {
                dispatch(purchaseRequestsActions.updateData({ field: "totalAmount", value: total }))
            }
            const totalExcludingTax = items.reduce((accumulator, item) => {
                return accumulator + item.totalAmountExcludingTax
            }, 0)
            setTotalExcludingTaxes(totalExcludingTax)

            if (purchaseType === PurchaseType.ORDER) {
                dispatch(purchaseOrdersActions.updateData({
                    field: "totalAmountExcludingTax",
                    value: totalExcludingTax
                }))
            } else {
                dispatch(purchaseRequestsActions.updateData({
                    field: "totalAmountExcludingTax",
                    value: totalExcludingTax
                }))
            }
        },
        [setTotal, setTotalExcludingTaxes, dispatch]
    )

    const handleChangeItem = useCallback(
        (payload: Partial<CreateLineDTO>, index: number, id: string | undefined | null) => {
            setItems((currentItems) => {
                const newItems = currentItems.map((item, i) => {
                    if (i !== index) return item
                    const updatedItem = { ...item, ...payload, hasChanged: true }

                    if (
                        isDefined(payload.taxRate) ||
                        isDefined(payload.unitPriceExcludingTax) ||
                        isDefined(payload.quantity)
                    ) {
                        const { unitPriceExcludingTax, quantity, taxRate } = updatedItem
                        updatedItem.unitPrice = getPriceTaxIncluded(unitPriceExcludingTax, taxRate)
                        updatedItem.totalAmountExcludingTax = unitPriceExcludingTax * quantity
                        updatedItem.totalAmount = getPriceTaxIncluded(updatedItem.totalAmountExcludingTax, taxRate)
                        if (id) {
                            updatedItem.id = id
                        }
                    }
                    return updatedItem
                })
                if (
                    isDefined(payload.taxRate) ||
                    isDefined(payload.unitPriceExcludingTax) ||
                    isDefined(payload.quantity)
                ) {
                    setTotals(newItems)
                }
                return newItems
            })
        },
        [setTotals]
    )

    const handleDeleteItem = (key: number) => {
        const itemsLines = [...[], ...items]
        itemsLines.splice(key, 1)
        setItems(itemsLines)
        setTotals(itemsLines)
    }
    const addEmptyItem = () => {
        const itemsLines = [...items, ...[createEmptyItem()]]
        setItems(itemsLines)
    }
    const resetItems = () => {
        const itemsLines: CreateLineDTO[] = initItems
        setItems(itemsLines)
        setTotals(itemsLines)
        setTotal(0)
        setTotalExcludingTaxes(0)
    }
    return {
        items,
        total,
        totalExcludingTaxes,
        resetItems,
        addEmptyItem,
        handleChangeItem,
        handleDeleteItem,
    }
}
