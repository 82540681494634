import React, { useState } from "react"
import { Button, Loader } from "~/components"
import { Download } from "react-feather"
import { PurchaseOrderPDFDTO, PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"
import { toast } from "react-toastify"
import { useFetchOrganization } from "~/store/organization/hooks"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    pdf: {
        id: "purchase.orders.order.download.pdf",
        defaultMessage: "PDF",
    },
    errorAddress: {
        id: "purchase.orders.order.download.errorAddress",
        defaultMessage: "Please fill in the billing and shipping addresses before downloading the PDF",
    },
})
interface Props {
    purchaseOrder: PurchaseOrders
}
export default function DownloadPOPdf({ purchaseOrder }: Props) {
    const [loading, setLoading] = useState(false)
    const { organization } = useFetchOrganization(purchaseOrder.supplierId)
    const language = useAppSelector((state) => state.global.lang)
    const { formatMessage } = useIntl()

    const purchaseOrderToPdf = (purchaseOrder: PurchaseOrders, supplier) => {
        const items = purchaseOrder.lines.map((line) => {
            return {
                description: line.description,
                quantity: line.quantity,
                unitPriceExcludingTax: line.unitPriceExcludingTax,
                taxRate: line.taxRate,
                totalAmount: line.totalAmount,
            }
        })
        if (purchaseOrder.billingAddress && purchaseOrder.shippingAddress) {
            const purchaseOrderPdf: PurchaseOrderPDFDTO = {
                supplier: {
                    name: purchaseOrder.supplierName,
                    address: {
                        street: supplier.address?.addressLine ?? "",
                        street2: supplier.address?.secondaryAddressLine ?? "",
                        zipCode: supplier.address?.zipCode ?? "",
                        city: supplier.address?.city ?? "",
                        country: supplier.address?.country ?? "",
                    },
                },
                buyer: {
                    name: purchaseOrder.buyerName,
                    billingAddress: purchaseOrder.billingAddress,
                    shippingAddress: purchaseOrder.shippingAddress,
                },
                description: purchaseOrder.description,
                purchaseOrderId: purchaseOrder.shortId,
                issueDate: purchaseOrder.creationDate,
                deliveryDate: purchaseOrder.expectedDeliveryDate,
                paymentTerms: "A définir",
                items: items,
                currency: purchaseOrder.currency,
                totalAmount: purchaseOrder.totalAmount,
                totalAmountExcludingTax: purchaseOrder.totalAmountExcludingTax,
                totalTax: purchaseOrder.totalTax,
                language,
            }
            return purchaseOrderPdf
        } else {
            toast.error(formatMessage(messages.errorAddress))
            return false
        }
    }

    const handleDownload = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            if (purchaseOrder && organization) {
                const poData = purchaseOrderToPdf(purchaseOrder, organization)

                if (poData) {
                    await purchaseOrdersApi.toPDF(poData)
                }
            }
        } catch (error) {
            console.error(error)
            toast.error("An error occurred while downloading the PDF")
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            {!loading && organization ? (
                <Button onClick={handleDownload} type={"tertiary"}>
                    <Download size={16} /> <FormattedMessage {...messages.pdf} />
                </Button>
            ) : (
                <Loader small={true} />
            )}
        </div>
    )
}
