import React, { useCallback, useMemo } from "react"
import { TagsSelectorStateless } from "~/domains/tags/components/TagsSelector"
import { SelectedTagWithObjectIdI, TagObjectType } from "~/domains/tags/types"
import { InvoiceLineI, OrganizationId } from "~/types"
import { PurchaseRequestLine } from "../../purchase-requests/types/PurchaseRequests"
import { PurchaseOrderLine } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { useOrganizationTagGroups } from "~/domains/tags/hooks"
import { useTagsForLines } from "./hooks/useTagsForLines"
import { styled } from "@mui/material"
import { Loader } from "~/components"
import { ErrorMessage } from "~/components/ErrorMessage"

interface Props {
    organizationId: OrganizationId
    line: PurchaseRequestLine | PurchaseOrderLine | InvoiceLineI
    objectId: string
    objectType: TagObjectType
}

const LineTagsContainer = styled("div")({
    flex: "1 1 150px",
})

const isLineInvoiceLine = (line: PurchaseRequestLine | PurchaseOrderLine | InvoiceLineI): line is InvoiceLineI =>
    "linePosition" in line

export const LineTags: React.FC<Props> = ({ organizationId, line, objectId, objectType }) => {
    const { loading: tagGroupsLoading, error, tagGroups } = useOrganizationTagGroups(organizationId)
    const {
        loading,
        selectedTags: tags,
        setSelectedTags: setTags,
    } = useTagsForLines({
        lines: [isLineInvoiceLine(line) ? { ...line, id: objectId } : line],
        organizationId,
        tagGroups,
        objectType,
    })

    const lineTags = useMemo(() => {
        if (!tags || !tags.length) return []
        return tags.filter((tag) => tag.objectId === objectId)
    }, [objectId, tags])

    const setSelectedTags = useCallback(
        (
            tagsOrFunction:
                | SelectedTagWithObjectIdI[]
                | ((state: SelectedTagWithObjectIdI[]) => SelectedTagWithObjectIdI[])
        ) => {
            setTags((currentSelection) => {
                const currentSelectionForLine = currentSelection.filter(
                    (selectedTag) => selectedTag.objectId === objectId
                )
                if (typeof tagsOrFunction === "function") {
                    const newSelection = tagsOrFunction(currentSelectionForLine)
                    return [
                        ...currentSelection.filter((tag) => tag.objectId !== objectId),
                        ...newSelection.map((tag) => ({ ...tag, objectId })),
                    ]
                }
                return [
                    ...currentSelection.filter((tag) => tag.objectId !== objectId),
                    ...tagsOrFunction.map((tag) => ({ ...tag, objectId })),
                ]
            })
        },
        [lineTags]
    )

    if (loading || tagGroupsLoading) {
        return <Loader small position="relative" />
    }

    if (error) {
        return <ErrorMessage>{error}</ErrorMessage>
    }

    return (
        <LineTagsContainer>
            <TagsSelectorStateless
                selectedTags={lineTags}
                setSelectedTags={setSelectedTags}
                tagsRecommandations={null}
                tagGroups={tagGroups ?? []}
            />
        </LineTagsContainer>
    )
}
