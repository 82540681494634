import { useCallback, useEffect, useMemo } from "react"
import { useAppSelector } from "~/store/hooks"
import { useDispatch } from "react-redux"
import { PartnersDataI, PartnersDataIOArray } from "~/domains/transactions/book-of-relations/types"
import { partnerApi } from "~/api/partnerApi"
import { NO_ORGANIZATION_ID, OrganizationId } from "~/types"
import { genericParser } from "~/utils"
import { isResultSuccess } from "~/core/Result"
import * as Sentry from "@sentry/browser"
import { bookOfRelationsActions, selectPartnersData } from "../bookOfRelationsSlice"
import { WHITE_SPACE_REGEXP } from "~/utils/string"

type FetchPartnersResult = {
    partnersData: PartnersDataI[]
    filteredPartners?: PartnersDataI[]
    fetchPartners: () => void
    loading: boolean
    error: string | null
}

const noFilter = () => true

const getPartnersFilter = (filter: string) => {
    if (!filter || !filter.length) {
        return noFilter
    }
    const filterWords = filter.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)

    return (partnersData: PartnersDataI) => {
        const organizationNameWords = partnersData.organizationName
            ? partnersData.organizationName.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
            : []
        const brandNameWords = partnersData.brandName
            ? partnersData.brandName.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
            : []

        const searchArray = [...organizationNameWords, ...brandNameWords]

        return filterWords.every((word) => searchArray.some((partnersWord) => partnersWord.indexOf(word) >= 0))
    }
}

export const useFetchPartnersData = (
    organizationId: OrganizationId | undefined,
    withMetrics?: boolean,
    withDetails?: boolean
): FetchPartnersResult => {
    const dispatch = useDispatch()
    const { partnersData, partnersFilter, loading, error } = useAppSelector(selectPartnersData)

    const fetchPartners = useCallback(async () => {
        if (!organizationId || organizationId === NO_ORGANIZATION_ID) return

        try {
            dispatch(bookOfRelationsActions.fetchPartnersData())
            const data = await partnerApi.fetchPartnersData(organizationId, withMetrics, withDetails)
            const result = genericParser(data, PartnersDataIOArray)
            if (isResultSuccess(result)) {
                dispatch(bookOfRelationsActions.fetchPartnersDataSuccess(data ?? []))
            } else {
                throw new Error("Issue parsing partners data")
            }
        } catch (error) {
            Sentry.captureException(error)
            dispatch(bookOfRelationsActions.fetchPartnersDataFailed(`${error}`))
        }
    }, [organizationId, withMetrics, withDetails, dispatch])

    useEffect(() => {
        fetchPartners()
    }, [fetchPartners])

    return useMemo(() => {
        if (partnersFilter) {
            return {
                partnersData,
                filteredPartners: partnersData.filter(getPartnersFilter(partnersFilter)),
                fetchPartners,
                loading,
                error,
            }
        }
        return { partnersData, fetchPartners, loading, error }
    }, [partnersData, partnersFilter, fetchPartners, loading, error])
}
