import { FilterFieldProperty } from "../types"


const propertyIsArray: Record<FilterFieldProperty, boolean> = {
    [FilterFieldProperty.AMOUNT]: false,
    [FilterFieldProperty.LINE_AMOUNT]: false,
    [FilterFieldProperty.SUPPLIER]: true,
    [FilterFieldProperty.AUTHOR]: false,
    [FilterFieldProperty.TAGS]: true,
    [FilterFieldProperty.TAGS_V2]: true,
    [FilterFieldProperty.UNKNOWN]: false,
}

export const getPropertyIsArray = (property: FilterFieldProperty): boolean => propertyIsArray[property]
