import { AxiosInstance } from "axios"
import { isLeft } from "fp-ts/Either"
import { useContext } from "react"
import { Result } from "~/core/Result"
import { ApiContext } from "~/domains/common/apiClient"
import { FetchError } from "~/domains/common/apiClient/errors"
import { parseResponse } from "~/domains/common/apiClient/parseResponse"
import { parseResponseAsArray } from "~/domains/common/apiClient/parseResponseAsArray"
import { parseOrganizationMetrics } from "~/domains/transactions/book-of-relations/types"
import {
    CompanyI,
    CompanyIO,
    CountryCode,
    DunsId,
    WhitePagesIdTypes,
    WhitePagesResultI,
    parseCompany,
    parseWhitePagesResult,
    validateCountryCodeOrSetUnknown,
} from "~/types"
import { isDefined } from "~/utils/isDefined"

const BASE_URL = import.meta.env.VITE_API_WHITE_PAGES_URL

class WhitePagesApi {
    private static instance: WhitePagesApi
    private constructor(private axiosClient: AxiosInstance) {}

    static getInstance(axiosClient: AxiosInstance) {
        if (!WhitePagesApi.instance) {
            WhitePagesApi.instance = new WhitePagesApi(axiosClient)
        }
        return WhitePagesApi.instance
    }

    async autocomplete(countryCode: CountryCode, query: string): Promise<WhitePagesResultI[]> {
        const url = `${BASE_URL}autocomplete/${countryCode}?q=${encodeURIComponent(query)}`
        const response = await this.axiosClient.get<WhitePagesResultI[]>(url)
        return response.data.map(parseWhitePagesResult).filter(isDefined) as WhitePagesResultI[]
    }

    async fetchCompanyBySiren(siren: string): Promise<CompanyI> {
        const response = await this.axiosClient.get<CompanyI>(`${BASE_URL}pappers/company/${siren}`)
        const result = CompanyIO.decode(response.data)
        if (isLeft(result)) {
            console.warn("Unexpected data return from API", result.left)
            throw new Error("Unexpected data return from API")
        }
        return {
            ...result.right,
            idType: result.right.idType as WhitePagesIdTypes,
            countryCode: validateCountryCodeOrSetUnknown(result.right.countryCode),
        }
    }

    async fetchCompanyByDunsNumber(dunsNumber: DunsId): Promise<CompanyI> {
        const url = `${BASE_URL}dnb/company-by-duns/${encodeURIComponent(dunsNumber)}`
        const response = await this.axiosClient.get(url)
        const result = CompanyIO.decode(response.data)
        if (isLeft(result)) {
            console.warn("Unexpected data return from API", result.left)
            throw new Error("Unexpected data return from API")
        }
        return {
            ...result.right,
            idType: result.right.idType as WhitePagesIdTypes,
            countryCode: validateCountryCodeOrSetUnknown(result.right.countryCode),
        }
    }

    async searchDnBCompanyFromRegistrationNumber(
        registrationNumber: string,
        countryCode: CountryCode
    ): Promise<Result<CompanyI[], FetchError<CompanyI>>> {
        const url = `${BASE_URL}dnb/search-company`
        const response = await this.axiosClient.post(url, { registrationNumber, countryISOAlpha2Code: countryCode })
        return parseResponseAsArray(response, parseCompany)
    }

    async fetchMetricsFromDunsNumber(dunsNumber: string) {
        const url = `${BASE_URL}dnb/company-metrics/${dunsNumber}`
        const response = await this.axiosClient.get(url)
        return parseResponse(response, parseOrganizationMetrics)
    }
}

export const useWhitePagesApi = () => {
    const { axiosClient } = useContext(ApiContext)
    return WhitePagesApi.getInstance(axiosClient)
}
