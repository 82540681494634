import { useCallback, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { budgetActions, selectBudgetsData } from "../budgetSlice"
import { useAppSelector } from "~/store/hooks"
import { BudgetDataI } from "~/features/budget/types"
import { budgetApi } from "~/api/budgetApi"
import { NO_ORGANIZATION_ID } from "~/types"
import { WHITE_SPACE_REGEXP } from "~/utils/string"
import { getIsConnected } from "~/store/auth/authSlice"

type FetchBudgetsResult = {
    budgetsData: BudgetDataI[]
    filteredBudgets?: BudgetDataI[]
    loading: boolean
    error: string | null
    reFetchBudgetsData: () => void
}

const noFilter = () => true

const getBudgetFilter = (filter: string) => {
    if (!filter || !filter.length) {
        return noFilter
    }
    const filterWords = filter.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
    return (budget: BudgetDataI) => {
        const nameWords = `${budget.name}`.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
        const descriptionWords = `${budget.description}`.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
        const budgetWords = [...nameWords, ...descriptionWords]
        return filterWords.every((word) => budgetWords.some((budgetWord) => budgetWord.indexOf(word) >= 0))
    }
}

export const useFetchBudgetsData = (organizationId: string | undefined, withMetrics = true) => {
    const dispatch = useDispatch()
    const { budgetsData, budgetsFilter, loading, error } = useAppSelector(selectBudgetsData)
    const isConnected = useAppSelector(getIsConnected)

    const fetchBudgetsData = useCallback(() => {
        if (organizationId === NO_ORGANIZATION_ID || !isConnected) {
            dispatch(budgetActions.fetchBudgetsDataSuccess([]))
        } else if (organizationId) {
            dispatch(budgetActions.fetchBudgetsData())
            budgetApi
                .fetchBudgetsData(organizationId, withMetrics)
                .then((data) => {
                    dispatch(budgetActions.fetchBudgetsDataSuccess(data))
                })
                .catch((error) => {
                    console.error(error)
                    dispatch(budgetActions.fetchBudgetsDataFailed(error))
                })
        }
    }, [organizationId, dispatch])

    useEffect(() => {
        fetchBudgetsData()
    }, [fetchBudgetsData])

    return useMemo((): FetchBudgetsResult => {
        if (budgetsFilter) {
            const filteredBudgets = budgetsData.filter(getBudgetFilter(budgetsFilter))
            return { budgetsData, filteredBudgets, loading, error, reFetchBudgetsData: fetchBudgetsData }
        }
        return { budgetsData, loading, error, reFetchBudgetsData: fetchBudgetsData }
    }, [organizationId, budgetsData, budgetsFilter, loading, error, fetchBudgetsData])
}
