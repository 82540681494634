import React, { useCallback, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { Button, Loader } from "~/components"
import { X } from "react-feather"
import "./Actions.scss"
import {
    PurchaseOrders,
    PurchaseOrderStatus,
    PurchaseOrderStatusUpdate,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { OrganizationId, ViewTypeI } from "~/types"
import {
    useFetchPurchaseOrder,
    useUpdatePurchaseOrder,
    useUpdatePurchaseOrderStatus,
} from "~/domains/transactions/purchase-orders/store/hooks"
import { ModalNote } from "~/domains/transactions/purchase-orders/components/ModalNote/ModalNote"
import { allLinesApproved, allLinesRejected } from "../../core/purchaseOrder"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { purchaseOrdersActions, selectNeedSaveShippingAndBillingAddress } from "../../store/purchaseOrdersSlice"

interface PropsPRActions {
    PO: PurchaseOrders
    organizationId: OrganizationId
    viewType: ViewTypeI
    isOnApprovalSide: boolean
    handleSend: () => void
}

const messages = defineMessages({
    approve: {
        id: "purchase.orders.order.action.approve",
        defaultMessage: "Mark as approved",
    },
    dispatched: {
        id: "purchase.orders.order.action.dispatched",
        defaultMessage: "Mark as dispatched",
    },
    delivered: {
        id: "purchase.orders.order.action.markAsDelivered",
        defaultMessage: "Mark all as delivered",
    },
    received: {
        id: "purchase.orders.order.action.markAsReceived",
        defaultMessage: "Mark all as received",
    },
    send: {
        id: "purchase.orders.order.action.send",
        defaultMessage: "Send",
    },
})

const receivableStatuses: Partial<Record<PurchaseOrderStatus, boolean>> = {
    [PurchaseOrderStatus.AWAITING_SUPPLIER_APPROVAL]: true,
    [PurchaseOrderStatus.IN_PREPARATION]: true,
    [PurchaseOrderStatus.OUT_FOR_DELIVERY]: true,
    [PurchaseOrderStatus.DELIVERED]: true,
}

export function ActionsColumn({ PO, organizationId, viewType, isOnApprovalSide, handleSend }: PropsPRActions) {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const [openModalNote, setOpenModalNote] = useState<boolean>(false)
    const { updatePOStatus, loading } = useUpdatePurchaseOrderStatus(PO.id, viewType)
    const { updatePO } = useUpdatePurchaseOrder(PO.id, organizationId)
    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organizationId)
    const needSaveShippingAndBillingAddress = useAppSelector(selectNeedSaveShippingAndBillingAddress)

    const areAllLinesApproved = useMemo(() => allLinesApproved(PO.lines, viewType), [PO.lines, viewType])

    const areAllLinesRejected = useMemo(() => allLinesRejected(PO.lines, viewType), [PO.lines, viewType])

    const handleUpdatePOStatus = useCallback(
        async (
            newStatus: PurchaseOrderStatusUpdate,
            e?: React.MouseEvent | null,
            currentStatus?: PurchaseOrderStatus | undefined,
            note?: string | undefined
        ) => {
            e && e.preventDefault()
            await updatePOStatus(organizationId, newStatus, currentStatus, note)
            await fetchPurchaseOrder(PO.id)
        },
        [PO, organizationId]
    )

    const handleReject = useCallback(
        async (e: React.MouseEvent) => {
            if (needSaveShippingAndBillingAddress) {
                await updatePO(PO, false)
                dispatch(purchaseOrdersActions.needSaveShippingAndBillingAddress(false))
            }
            handleUpdatePOStatus(PurchaseOrderStatusUpdate.REJECT, e)
        },
        [PO, needSaveShippingAndBillingAddress, handleUpdatePOStatus, updatePO]
    )
    const handleValidate = async (e: React.MouseEvent) => {
        if (needSaveShippingAndBillingAddress) {
            await updatePO(PO, false)
            dispatch(purchaseOrdersActions.needSaveShippingAndBillingAddress(false))
        }
        handleUpdatePOStatus(PurchaseOrderStatusUpdate.APPROVE, e)
    }

    const handleDispatch = (e: React.MouseEvent) => {
        e.preventDefault()
        setOpenModalNote(true)
    }

    const markAsDispatched = (note: string) => {
        setOpenModalNote(false)
        handleUpdatePOStatus(PurchaseOrderStatusUpdate.DISPATCHED, null, PO.status, note)
    }

    const handleDelivered = (e: React.MouseEvent) => {
        handleUpdatePOStatus(PurchaseOrderStatusUpdate.DELIVERED, e)
    }

    const handleReceived = (e: React.MouseEvent) => {
        handleUpdatePOStatus(PurchaseOrderStatusUpdate.RECEIVED, e)
    }

    if (loading) {
        return (
            <div className="actions">
                <Loader small={true} />
            </div>
        )
    }

    if (isOnApprovalSide) {
        return (
            <div className="actions">
                {!areAllLinesRejected && (
                    <Button type={"error"} onClick={handleReject} className="reject">
                        <X />
                    </Button>
                )}
                {areAllLinesApproved ? (
                    <Button type="primary" onClick={handleSend} className="validate">
                        {formatMessage(messages.send)}
                    </Button>
                ) : (
                    <Button type="primary" onClick={handleValidate} className="validate">
                        {formatMessage(messages.approve)}
                    </Button>
                )}
            </div>
        )
    }
    if (viewType === ViewTypeI.supplier) {
        if (PO.status === PurchaseOrderStatus.IN_PREPARATION) {
            return (
                <div className="actions">
                    <Button type="secondary" onClick={handleDispatch} className="sent full">
                        {formatMessage(messages.dispatched)}
                    </Button>
                    <ModalNote
                        open={openModalNote}
                        cancel={() => setOpenModalNote(false)}
                        confirm={(note) => markAsDispatched(note)}
                    />
                </div>
            )
        } else if (PO.status === PurchaseOrderStatus.OUT_FOR_DELIVERY) {
            return (
                <div className={"actions"}>
                    <Button type="tertiary" onClick={handleDelivered} className="delivered full">
                        {formatMessage(messages.delivered)}
                    </Button>
                </div>
            )
        }
    } else if (viewType === ViewTypeI.buyer) {
        if (receivableStatuses[PO.status]) {
            return (
                <div className={"actions"}>
                    <Button type="tertiary" onClick={handleReceived} className="received full">
                        {formatMessage(messages.received)}
                    </Button>
                </div>
            )
        }
    }

    return null
}
