import { ParsingErrorType, genericParser } from "~/utils"
import {
    Result,
    ResultError,
    ResultSuccess,
    getResultSuccessValue,
    isResultError,
    isResultSuccess,
} from "~/core/Result"
import {
    BatchImportDefferResult,
    BatchImportImmediateResult,
    BatchImportProductVersionResultI,
    BatchImportProductVersionResultIO,
} from "./ProductVersion"
import { parseProductVersion } from "./parseProductVersion"

export const parseBatchImportProductVersionResult = (
    data: unknown
): Result<BatchImportProductVersionResultI, ParsingErrorType> => {
    const result = genericParser(data, BatchImportProductVersionResultIO)
    if (isResultSuccess(result)) {
        if (Array.isArray(result.result.data)) {
            return ResultSuccess<BatchImportImmediateResult>({
                data: result.result.data.map(parseProductVersion).filter(isResultSuccess).map(getResultSuccessValue),
            })
        } else if (!result.result.data) {
            return ResultSuccess<BatchImportDefferResult>({
                objectsToImport: result.result.objectsToImport,
                expectedProcessingTimeInSeconds: result.result.expectedProcessingTimeInSeconds,
            })
        }
    }
    if (isResultError(result)) return result
    return ResultError({
        data,
        errorsKey: [],
    })
}
