import React, { lazy, useCallback, useEffect, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { BulkActionButton, BulkActions, Loader, Tabs, useTitle } from "~/core"
import { Box } from "@mui/material"
import { Check, DollarSign, Package, Send, Tag } from "react-feather"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization, selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { HeaderH1 } from "~/components/Header/HeaderH1"
import { ViewSwitcherTab } from "~/components/ViewSwitcherTab"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { ViewTypeI, AuthorizationName } from "~/types"
import { PURCHASE_ORDERS_ROUTE, PURCHASE_ORDER_NEW_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { useOrganizationTagGroups } from "~/domains/tags/hooks"
import { TagAndTagGroupNameI } from "~/features/workflow/core/hooks"
import { withSocketIOProvider } from "../../common/subscriptions/components/SocketIOContext"
import { Filters } from "../components/Filters"
import { ModalBatchImport } from "~/components/ModalBatchImport/ModalBatchImport"
import { ModalResultImport } from "~/components/ModalBatchImport/ModalResultImport"
import { useImportBatchPurchaseOrder } from "../../purchase-requests/store/hooks/useImportBatchPurchaseOrder"
import { ImportBatchResponseI } from "~/types/ImportBatch"
import { useFetchPurchaseOrders } from "../store/hooks"
import {
    ToDoTabsActionByBuyer,
    ToDoTabsActionBySupplier,
    getPOBulkActionButtons,
    getPOsByViewAndTab,
    getPurchaseOrderTabs,
    getApprovable,
    purchaseOrdersTabMessages,
    statusCounts,
    getSendable,
    showBulkActionButtonCount,
    getReceivable,
} from "../core/purchaseOrder"
import { CheckFileTypeResult } from "~/components/UploadDocument/UploadDocument"
import { PurchaseOrdersTab } from "../types/PurchaseOrders"
import { purchaseOrdersActions } from "../store/purchaseOrdersSlice"
import classNames from "classnames"
import { toast } from "react-toastify"
import { ConfirmModal } from "~/components/ConfirmModal"
import { useApprovePurchaseOrders } from "../hooks/useApprovePurchaseOrders"
import { useSendPurchaseOrders } from "../hooks/useSendPurchaseOrders"
import { useFetchSharedObjects } from "~/domains/identity/store/hooks/useFetchSharedObjects"
import { useMarkAsReceivedPurchaseOrders } from "../hooks/useMarkAsReceivedPurchaseOrders"
import { useAddTagsToPurchaseOrders } from "../hooks/useAddTagsToPurchaseOrders"
import { ModalAddTags } from "~/features/account/components/ModalAddTags"
import { TagI, TagObjectType } from "~/domains/tags/types"
import { useHasPermissions } from "~/domains/identity/store/hooks/useHasPermissions"
import { DomainName, ScopeName } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { Actions } from "~/components/DataTable/components/DatatableFilters"

const List = lazy(() => import("../components/POList/List").then(({ List }) => ({ default: List })))

const messages = defineMessages({
    title: {
        id: "purchase.orders.list.title",
        defaultMessage: "Purchase orders",
    },
    labelSupplier: {
        id: "purchase.orders.list.labelSupplier",
        defaultMessage: "From buyer",
    },
    labelBuyer: {
        id: "purchase.orders.list.labelBuyer",
        defaultMessage: "Sent to supplier",
    },
    errorWrongFileType: {
        id: "purchase.list.modalImportBatch.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept CSV and XLSX files.",
    },
    modalUploadTitle: {
        id: "purchase.list.modalImportBatch.title",
        defaultMessage: "Import Purchase Orders in batch",
    },
    modalUploadTip: {
        id: "purchase.list.modalImportBatch.uploadBox.tip",
        defaultMessage: "Supported format : csv, xlsx. No more than 2mb",
    },
    selected: { id: "purchase.requests.table.selected", defaultMessage: "selected" },
    approve: { id: "purchase.orders.list.approve", defaultMessage: "Approve" },
    send: { id: "purchase.orders.list.send", defaultMessage: "Send" },
    received: { id: "purchase.orders.list.received", defaultMessage: "Received" },
    addTags: { id: "purchase.orders.list.addTags", defaultMessage: "Add tags" },
    nothingToApprove: {
        id: "purchase.orders.list.bulk.approve.nothingToApprove",
        defaultMessage:
            'Nothing to approve. To be able to approve a purchase order, it should be in status "Awaiting your approval".',
    },
    confirmApproveTitle: {
        id: "purchase.orders.list.bulk.approve.confirmApproveTitle",
        defaultMessage: "Approve purchase orders",
    },
    confirmApproveMessage: {
        id: "purchase.orders.list.bulk.approve.confirmApproveMessage",
        defaultMessage: "Are you sure to approve {count} purchase {count, plural, =0 {} one {order} other {orders}}?",
    },
    nothingToSend: {
        id: "purchase.orders.list.bulk.send.nothingToSend",
        defaultMessage:
            "Nothing to send. To be able to send a purchase order, it must be shared with a supplier and all lines need to be approved.",
    },
    confirmSendTitle: {
        id: "purchase.orders.list.bulk.send.confirmSendTitle",
        defaultMessage: "Send purchase orders",
    },
    confirmSendMessage: {
        id: "purchase.orders.list.bulk.send.confirmSendMessage",
        defaultMessage: "Are you sure to send {count} purchase {count, plural, =0 {} one {order} other {orders}}?",
    },
    nothingToMarkAsReceived: {
        id: "purchase.orders.list.bulk.received.nothingToMarkAsReceived",
        defaultMessage:
            "Nothing to mark as received. To be able to mark as received a purchase order, it must be at least mutually accepted.",
    },
    confirmReceivedTitle: {
        id: "purchase.orders.list.bulk.received.confirmReceivedTitle",
        defaultMessage: "Mark purchase orders as received",
    },
    confirmReceivedMessage: {
        id: "purchase.orders.list.bulk.received.confirmReceivedMessage",
        defaultMessage:
            "Are you sure to mark as received {count} purchase {count, plural, =0 {} one {order} other {orders}}?",
    },
    nothingToAddTags: { id: "purchase.orders.list.bulk.addTags.nothingToAddTags", defaultMessage: "No tag to add" },
    createSinglePO: { id: "purchase.orders.createSinglePo", defaultMessage: "Create a single PO" },
    importBatch: { id: "purchase.orders.importBatch", defaultMessage: "Import in batch" },
})

const getTabLabel = (
    message: string,
    tab: PurchaseOrdersTab,
    view: ViewTypeI | string,
    count: number = 0
): JSX.Element => {
    const classes = classNames("tab-count", {
        todo:
            (view === ViewTypeI.supplier && ToDoTabsActionBySupplier[tab]) ||
            (view === ViewTypeI.buyer && ToDoTabsActionByBuyer[tab]),
    })
    return (
        <span>
            {message}
            {tab === PurchaseOrdersTab.ALL || count === 0 ? null : <div className={classes}> {count} </div>}
        </span>
    )
}

const getBulkActionButtonLabel = (message: string, currentTab: PurchaseOrdersTab, count?: number): JSX.Element => (
    <>
        <span>{message}</span>
        {showBulkActionButtonCount[currentTab] ? <div className="button-count">{count}</div> : null}
    </>
)

const ACCEPTED_FILE_EXTENSIONS: string[] = ["csv", "xlsx"]
const NOTION_URL_BATCH_PR =
    "https://get-flowie.notion.site/Flowie-Documentation-File-Format-for-Purchase-Order-Upload-5c146e1f97ac4277baea19ff61a5d9d9?pvs=4"

export const PurchaseOrders = withSocketIOProvider(() => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const organization = useAppSelector(selectCurrentOrganization)
    const [selectedPOs, setSelectedPOs] = useState<string[]>([])
    const [modalBatchImportVisible, setModalBatchImportVisible] = useState<boolean>(false)
    const [modalResultImportVisible, setModalResultImportVisible] = useState<boolean>(false)
    const [resultBatchImport, setResultBatchImport] = useState<ImportBatchResponseI | undefined>(undefined)
    const [tabValue, setTabValue] = useState<PurchaseOrdersTab>(PurchaseOrdersTab.ALL)
    const [bulkActionButtons, setBulkActionButtons] = useState<BulkActionButton[]>([])
    const [selectedForApprove, setSelectedForApprove] = useState<string[]>([])
    const [displayModalApprove, setDisplayModalApprove] = useState<boolean>(false)
    const [selectedForSend, setSelectedForSend] = useState<string[]>([])
    const [displayModalSend, setDisplayModalSend] = useState<boolean>(false)
    const [selectedForReceived, setSelectedForReceived] = useState<string[]>([])
    const [displayModalReceived, setDisplayModalReceived] = useState<boolean>(false)
    const [selectedForAddTags, setSelectedForAddTags] = useState<string[]>([])
    const [displayModalAddTags, setDisplayModalAddTags] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const pageName = formatMessage(messages.title)
    useTitle(pageName)
    const { viewType } = useParams()
    const navigate = useNavigate()
    const view = useMemo(() => viewType ?? ViewTypeI.buyer, [viewType])

    const { tagGroups } = useOrganizationTagGroups(organizationId)
    const { importBatchPO, loading: loadingBatchImport } = useImportBatchPurchaseOrder(organizationId as string)
    const { purchaseOrders, refetchPurchaseOrders } = useFetchPurchaseOrders(view, organizationId)
    const organizationSharings = useFetchSharedObjects(organizationId)

    const approvePurchaseOrders = useApprovePurchaseOrders()
    const sendPurchaseOrders = useSendPurchaseOrders()
    const receivedPurchaseOrders = useMarkAsReceivedPurchaseOrders()
    const { addTagsToPurchaseOrders, loading: loadingAddTags } = useAddTagsToPurchaseOrders()

    const tags = useMemo((): TagAndTagGroupNameI[] => {
        if (tagGroups) {
            const tags = Object.values(tagGroups)
                .map((tagGroup) => {
                    return tagGroup.tags.map(
                        (tag): TagAndTagGroupNameI => ({
                            ...tag,
                            tagGroupName: tagGroup.name,
                            tagGroupShortName: tagGroup.shortName,
                        })
                    )
                })
                .flat()
            return tags
        } else {
            return []
        }
    }, [tagGroups])

    const getApproveBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            return {
                label: getBulkActionButtonLabel(
                    formatMessage(messages.approve),
                    currentTab,
                    getApprovable(selectedPOs, purchaseOrders, view).length
                ),
                icon: <Check size={14} />,
                type: "neutral",
                onClick: () => handleApprovePurchaseOrder(getApprovable(selectedPOs, purchaseOrders, view)),
            }
        },
        [selectedPOs, purchaseOrders, formatMessage]
    )

    const getSendBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            return {
                label: getBulkActionButtonLabel(
                    formatMessage(messages.send),
                    currentTab,
                    getSendable(selectedPOs, purchaseOrders, view, organizationSharings).length
                ),
                icon: <Send size={14} />,
                type: "neutral",
                onClick: () =>
                    handleSendPurchaseOrder(getSendable(selectedPOs, purchaseOrders, view, organizationSharings)),
            }
        },
        [selectedPOs, purchaseOrders, organizationSharings, formatMessage]
    )

    const getReceivedBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            return {
                label: getBulkActionButtonLabel(
                    formatMessage(messages.received),
                    currentTab,
                    getReceivable(selectedPOs, purchaseOrders).length
                ),
                icon: <Package size={14} />,
                type: "neutral",
                onClick: () => handleReceivedPurchaseOrder(getReceivable(selectedPOs, purchaseOrders)),
            }
        },
        [selectedPOs, purchaseOrders, formatMessage]
    )

    const getAddTagsBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            return {
                label: getBulkActionButtonLabel(formatMessage(messages.addTags), currentTab, selectedPOs.length),
                icon: <Tag size={14} />,
                type: "neutral",
                onClick: () => handleAddTagsPurchaseOrder(selectedPOs),
            }
        },
        [selectedPOs, purchaseOrders, formatMessage]
    )

    useEffect(() => {
        const buttons = getPOBulkActionButtons(
            tabValue,
            view,
            getApproveBulkActionButton,
            getSendBulkActionButton,
            getReceivedBulkActionButton,
            getAddTagsBulkActionButton
        )
        setBulkActionButtons(buttons)
    }, [formatMessage, selectedPOs, purchaseOrders, tabValue, getApproveBulkActionButton, getSendBulkActionButton])

    const switchView = (view: ViewTypeI) => {
        document.location.href = generatePath(PURCHASE_ORDERS_ROUTE, { viewType: view })
    }

    const handleApprovePurchaseOrder = useCallback(
        (selected: string[]) => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToApprove))
            }

            setSelectedForApprove(selected)
            setDisplayModalApprove(true)
        },
        [setSelectedForApprove, setDisplayModalApprove]
    )

    const handleSendPurchaseOrder = useCallback(
        (selected: string[]) => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToSend))
            }

            setSelectedForSend(selected)
            setDisplayModalSend(true)
        },
        [setSelectedForSend, setDisplayModalSend]
    )

    const handleReceivedPurchaseOrder = useCallback(
        (selected: string[]) => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToMarkAsReceived))
            }

            setSelectedForReceived(selected)
            setDisplayModalReceived(true)
        },
        [setSelectedForReceived, setDisplayModalReceived]
    )

    const handleAddTagsPurchaseOrder = useCallback(
        (selected: string[]) => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToAddTags))
            }

            setSelectedForAddTags(selected)
            setDisplayModalAddTags(true)
        },
        [setSelectedForAddTags, setDisplayModalAddTags]
    )

    const handleSelectedPR = useCallback(
        (rowSelectionIds: string[]) => {
            setSelectedPOs(rowSelectionIds)
        },
        [setSelectedPOs]
    )

    const handleFile = useCallback(
        async (file: File) => {
            try {
                const result: ImportBatchResponseI = await importBatchPO(file)
                setResultBatchImport(result)
                closeModalBatchImport()
                showResultImportModal()
                await refetchPurchaseOrders()
            } catch (error) {
                console.error(error)
            }
        },
        [importBatchPO]
    )

    const checkFileType = useCallback(
        (file: File): CheckFileTypeResult => {
            const fileExtension = file.name.split(".").pop()
            if (fileExtension && ACCEPTED_FILE_EXTENSIONS.includes(fileExtension)) {
                return { result: true, error: null }
            }

            return { result: false, error: formatMessage(messages.errorWrongFileType) }
        },
        [formatMessage]
    )

    const handleClickInstructions = () => {
        window.open(NOTION_URL_BATCH_PR, "_blank")
    }

    const handleChangeTab = useCallback(
        (newValue: number | string) => {
            setTabValue(newValue as PurchaseOrdersTab)
            clearSelection()
            dispatch(purchaseOrdersActions.setCurrentPurchaseOrdersTab(newValue as PurchaseOrdersTab))
        },
        [setTabValue]
    )

    const handleConfirmModalApprove = useCallback(async () => {
        if (organizationId) {
            setLoading(true)
            setDisplayModalApprove(false)
            await approvePurchaseOrders(selectedForApprove, organizationId)
            clearSelection()
            setSelectedForApprove([])
            setLoading(false)
            return Promise.resolve(true)
        }
        return Promise.resolve(false)
    }, [selectedForApprove, organizationId])

    const handleConfirmModalSend = useCallback(async () => {
        if (organizationId) {
            setLoading(true)
            setDisplayModalSend(false)
            await sendPurchaseOrders(selectedForSend, organizationId)
            clearSelection()
            setSelectedForSend([])
            setLoading(false)
            return Promise.resolve(true)
        }
        return Promise.resolve(false)
    }, [selectedForSend, organizationId])

    const handleConfirmModalReceived = useCallback(async () => {
        if (organizationId) {
            setLoading(true)
            setDisplayModalReceived(false)
            await receivedPurchaseOrders(selectedForReceived, organizationId)
            clearSelection()
            setSelectedForReceived([])
            setLoading(false)
            return Promise.resolve(true)
        }
        return Promise.resolve(false)
    }, [selectedForReceived, organizationId])

    const handleConfirmModalAddTags = useCallback(
        async (tagsToAdd: TagI[], tagsToRemove: TagI[]) => {
            if (organizationId) {
                setLoading(true)
                setDisplayModalAddTags(false)
                await addTagsToPurchaseOrders(
                    organizationId,
                    selectedForAddTags,
                    TagObjectType.PURCHASE_ORDER,
                    purchaseOrders,
                    tagsToAdd,
                    tagsToRemove
                )
                clearSelection()
                setSelectedForAddTags([])
                setLoading(false)
                return Promise.resolve(true)
            }
            return Promise.resolve(false)
        },
        [selectedForAddTags, organizationId]
    )

    const handleHideModalApprove = useCallback(() => setDisplayModalApprove(false), [])
    const handleHideModalSend = useCallback(() => setDisplayModalSend(false), [])
    const handleHideModalReceived = useCallback(() => setDisplayModalReceived(false), [])
    const closeModalBatchImport = () => setModalBatchImportVisible(false)
    const showResultImportModal = () => setModalResultImportVisible(true)
    const closeModalResultImport = () => setModalResultImportVisible(false)
    const onClickBatchImport = () => setModalBatchImportVisible(true)
    const clearSelection = useCallback(() => setSelectedPOs([]), [setSelectedPOs])

    const countByStatus = useMemo(() => statusCounts(purchaseOrders, view), [purchaseOrders, view])
    const tabs = useMemo(() => getPurchaseOrderTabs(view), [view])

    const onClickNewDraftPo = useCallback(() => navigate(PURCHASE_ORDER_NEW_ROUTE), [])

    const { hasPermission: hasPOCreatePermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.CREATE,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_ORDERS
    )
    const actions: Actions[] = []
    if (hasPOCreatePermission) {
        actions.push({ label: formatMessage(messages.createSinglePO), action: onClickNewDraftPo })
        actions.push({ label: formatMessage(messages.importBatch), action: onClickBatchImport })
    }

    return (
        <>
            <HeaderH1 title={pageName} icon={<DollarSign />} />
            <Box className="main-box" component={"div"}>
                <ViewSwitcherTab
                    view={view}
                    labelSupplier={formatMessage(messages.labelSupplier)}
                    labelBuyer={formatMessage(messages.labelBuyer)}
                    actionBuyer={() => switchView(ViewTypeI.buyer)}
                    actionSupplier={() => switchView(ViewTypeI.supplier)}
                />

                {organizationId ? (
                    <>
                        <Filters actions={actions} />
                        <Tabs
                            className="purchase-requests-tabs"
                            defaultValue={tabValue}
                            setChange={handleChangeTab}
                            tabs={tabs.map((tab) => ({
                                label: getTabLabel(
                                    formatMessage(purchaseOrdersTabMessages[tab]),
                                    tab,
                                    view,
                                    countByStatus[tab]
                                ),
                                value: tab,
                                component: (
                                    <>
                                        {loading || loadingAddTags ? (
                                            <Loader />
                                        ) : (
                                            <List
                                                purchaseOrders={getPOsByViewAndTab(view, tab, purchaseOrders)}
                                                organizationId={organizationId}
                                                view={view}
                                                tags={tags}
                                                rowSelectionModel={selectedPOs}
                                                handleSelectedRows={handleSelectedPR}
                                            />
                                        )}
                                    </>
                                ),
                            }))}
                        />
                    </>
                ) : null}
                <ModalBatchImport
                    open={modalBatchImportVisible}
                    close={closeModalBatchImport}
                    handleFile={handleFile}
                    checkFileType={checkFileType}
                    handleClickInstructions={handleClickInstructions}
                    loading={loadingBatchImport}
                    uploadTip={messages.modalUploadTip}
                    title={messages.modalUploadTitle}
                />
                <ModalResultImport
                    open={modalResultImportVisible}
                    close={closeModalResultImport}
                    showNewImportModal={onClickBatchImport}
                    resultBatchImport={resultBatchImport}
                    title={messages.modalUploadTitle}
                />
                <ConfirmModal
                    title={formatMessage(messages.confirmApproveTitle)}
                    open={displayModalApprove}
                    close={handleHideModalApprove}
                    onConfirm={handleConfirmModalApprove}
                    confirmButtonType="primary"
                >
                    {formatMessage(messages.confirmApproveMessage, {
                        count: selectedForApprove.length,
                    })}
                </ConfirmModal>
                <ConfirmModal
                    title={formatMessage(messages.confirmSendTitle)}
                    open={displayModalSend}
                    close={handleHideModalSend}
                    onConfirm={handleConfirmModalSend}
                    confirmButtonType="primary"
                >
                    {formatMessage(messages.confirmSendMessage, {
                        count: selectedForSend.length,
                    })}
                </ConfirmModal>
                <ConfirmModal
                    title={formatMessage(messages.confirmReceivedTitle)}
                    open={displayModalReceived}
                    close={handleHideModalReceived}
                    onConfirm={handleConfirmModalReceived}
                    confirmButtonType="primary"
                >
                    {formatMessage(messages.confirmReceivedMessage, {
                        count: selectedForReceived.length,
                    })}
                </ConfirmModal>
                {organization ? (
                    <ModalAddTags
                        organization={organization}
                        selected={selectedForAddTags}
                        objects={purchaseOrders}
                        displayModal={displayModalAddTags}
                        setDisplayModal={setDisplayModalAddTags}
                        onConfirm={handleConfirmModalAddTags}
                    />
                ) : null}
                {selectedPOs.length ? (
                    <BulkActions
                        selected={selectedPOs}
                        onClose={clearSelection}
                        buttons={bulkActionButtons}
                        selectedMessage={formatMessage(messages.selected, {
                            s: selectedPOs.length > 1 ? "s" : "",
                        })}
                    />
                ) : null}
            </Box>
        </>
    )
})
