import { useCallback, useEffect } from "react"
import { useCustomFieldsApi } from "../customFieldsApi"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { customFieldsActions } from "../store/customFieldsSlice"
import { selectESGFields, selectFetchingESG } from "../store"
import { store } from "~/store"

export const useESGMetrics = () => {
    const customFieldsApi = useCustomFieldsApi()
    const dispatch = useAppDispatch()
    const esgFields = useAppSelector(selectESGFields)

    const fetch = useCallback(async () => {
        const fetching = selectFetchingESG(store.getState())
        if (!fetching) {
            dispatch(customFieldsActions.fetchingESG())
            const result = await customFieldsApi.getESG()
            dispatch(customFieldsActions.fetchingESGSuccess(result))
        }
    }, [dispatch, customFieldsApi])

    useEffect(() => {
        fetch()
    }, [fetch])

    return esgFields
}
