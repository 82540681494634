import { ParsingErrorType, genericParser } from "~/utils"
import { MessageContentParagraph, MessageI, MessageIO } from "./Message"
import { Result, ResultSuccess, isResultSuccess } from "~/core/Result"

export const parseMessage = (data: unknown): Result<MessageI, ParsingErrorType> => {
    const result = genericParser(data, MessageIO)
    if (isResultSuccess(result)) {
        return ResultSuccess({
            ...result.result,
            views: result.result.views ?? [],
            content: result.result.content as MessageContentParagraph[],
        })
    }
    return result
}
