import React from "react"

import loaderBg from "./loader-background.svg"
import loaderArrow from "./loader-arrow.svg"
import loaderSmall from "./loader-small.svg"
import "./Loader.scss"
import classnames from "classnames"

export const Loader = ({
    fullscreen,
    small,
    position,
}: {
    fullscreen?: boolean
    small?: boolean
    position?: string
}) => {
    const classes = classnames({
        ["loader"]: !small,
        ["loader-small"]: small,
        [`fullscreen`]: fullscreen,
        [`relative`]: position === "relative",
    })
    return (
        <div className={classes}>
            {small ? (
                <img src={loaderSmall} alt="loading..." />
            ) : (
                <>
                    <img src={loaderBg} className="loader-bg" alt="loading..." />
                    <img src={loaderArrow} className="loader-arrow" alt="loading..." />
                </>
            )}
        </div>
    )
}

export const LoaderAdornment: React.FC = () => (
    <div className="loader-adornment">
        <Loader small />
    </div>
)
