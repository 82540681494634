import * as t from "io-ts"
import { CreateTagIO } from "./CreateTag"
import { Opaque } from "~/utils"
import { TagGroupI } from "./TagGroup"

export type TagId = Opaque<string, { readonly T: unique symbol }>

export const TagIO = t.intersection([
    t.type({
        tagId: t.string,
    }),
    CreateTagIO,
])

export type TagI = t.TypeOf<typeof TagIO> & {
    tagId: TagId
}

export enum FromRecommandationValues {
    MANUAL = "MANUAL",
    AUTO = "AUTO",
}

export type SelectedTagI = TagI & {
    ratio?: number
    tagGroup?: TagGroupI
    fromRecommandation?: FromRecommandationValues
}

export type SelectedTagWithObjectIdI = SelectedTagI & {
    objectId?: string
}
