import * as t from "io-ts"
import { OrganizationTeamI, UserI } from "~/types"
import { optional } from "~/types/utils"
import { Opaque } from "~/utils"

export type ApiWorkflowId = Opaque<string, { readonly T: unique symbol }>

export enum WorkflowType {
    INVOICE = "INVOICE",
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
    UNKNOWN = "UNKNOWN",
}

export enum WorkflowNodeType {
    START_NODE = "START_NODE",
    BRANCH_CONDITION_NODE = "BRANCH_CONDITION_NODE",
    BRANCH_NODE = "BRANCH_NODE",
    VALIDATION_NODE = "VALIDATION_NODE",
    EXECUTION_NODE = "EXECUTION_NODE",
    CHECK_CONDITION_NODE = "CHECK_CONDITION_NODE",
    LINE_CHECK_CONDITION_NODE = "LINE_CHECK_CONDITION_NODE",
    BUDGET_NODE = "BUDGET_NODE",
    UNKNOWN = "UNKNOWN",
}

export enum ApiWorkflowValidationType {
    REFUSAL = "REFUSAL",
    APPROVAL = "APPROVAL",
}

export const ApiWorkflowConditionIO = t.type({
    targetProperty: t.string,
    operator: t.string,
    value: t.any,
    conditionType: optional(t.string),
})

export type ApiWorkflowConditionI = t.TypeOf<typeof ApiWorkflowConditionIO>

export const ApiWorkflowConditionBlockIO = t.type({
    conditions: t.array(ApiWorkflowConditionIO),
    conditionalNextNode: t.string,
    matchType: t.string,
})

const ApiWorkflowCommonNodeIO = t.type({
    type: t.string,
    nodeId: t.string,
})

export const ApiWorkflowBranchGroupIO = t.type({
    conditions: t.array(ApiWorkflowConditionIO),
})

export const ApiWorkflowBranchIO = t.intersection([
    t.type({
        groups: t.array(ApiWorkflowBranchGroupIO),
        nextNode: t.string,
    }),
    t.partial({ name: t.string }),
])

export const ApiWorkflowBranchNodeIO = t.intersection([
    ApiWorkflowCommonNodeIO,
    t.type({
        branches: t.array(ApiWorkflowBranchIO),
    }),
])
const ApiWorkflowNodeWithNextNodeIO = t.intersection([
    ApiWorkflowCommonNodeIO,
    t.partial({
        nextNode: optional(t.string),
    }),
])

export const ApiWorkflowBudgetNodeIO = t.intersection([
    ApiWorkflowNodeWithNextNodeIO,
    t.type({
        budgetId: t.string,
        action: t.string,
    }),
])

export const ApiWorkflowStartNodeIO = ApiWorkflowNodeWithNextNodeIO

export const ApiWorkflowConditionNodeIO = t.intersection([
    ApiWorkflowCommonNodeIO,
    t.type({
        conditionGroups: t.array(ApiWorkflowConditionBlockIO),
    }),
])

export const ApiWorkflowValidationNodeIO = t.intersection([
    ApiWorkflowNodeWithNextNodeIO,
    t.type({
        validationType: t.string,
    }),
])

export const ApiWorkflowReviewerIO = t.type({
    type: t.string,
    id: t.string,
})

export const ApiWorkflowReviewersIO = t.type({
    reviewers: t.array(ApiWorkflowReviewerIO),
    thresholdNumber: t.number,
})

export const ApiWorkflowCheckConditionNodeIO = t.intersection([
    ApiWorkflowCommonNodeIO,
    t.type({
        condition: ApiWorkflowReviewersIO,
        accepted: optional(t.string),
        refused: optional(t.string),
    }),
])

export const ApiWorkflowCheckLineConditionGroupIO = t.type({
    conditions: t.array(t.any),
    matchType: t.string,
    lineApproveCondition: ApiWorkflowReviewersIO,
})

export const ApiWorkflowCheckLineConditionNodeIO = t.intersection([
    ApiWorkflowNodeWithNextNodeIO,
    t.type({
        conditionGroups: t.array(ApiWorkflowCheckLineConditionGroupIO),
    }),
])

export const ApiWorkflowNodeIO = t.union([
    ApiWorkflowStartNodeIO,
    ApiWorkflowBranchNodeIO,
    ApiWorkflowConditionNodeIO,
    ApiWorkflowValidationNodeIO,
    ApiWorkflowCheckConditionNodeIO,
    ApiWorkflowCheckLineConditionNodeIO,
])

export type ApiWorkflowBranchGroupI = t.TypeOf<typeof ApiWorkflowBranchGroupIO>
export type ApiWorkflowBranchI = t.TypeOf<typeof ApiWorkflowBranchIO>

export type ApiWorkflowConditionBlockI = t.TypeOf<typeof ApiWorkflowConditionBlockIO>

export type ApiWorkflowStartNodeI = t.TypeOf<typeof ApiWorkflowStartNodeIO> & {
    type: WorkflowNodeType.START_NODE
}
export type ApiWorkflowBranchNodeI = t.TypeOf<typeof ApiWorkflowBranchNodeIO> & {
    type: WorkflowNodeType.BRANCH_NODE
}
export type ApiWorkflowConditionNodeI = t.TypeOf<typeof ApiWorkflowConditionNodeIO> & {
    type: WorkflowNodeType.BRANCH_CONDITION_NODE
}
export type ApiWorkflowValidationNodeI = t.TypeOf<typeof ApiWorkflowValidationNodeIO> & {
    type: WorkflowNodeType.VALIDATION_NODE
    validationType: ApiWorkflowValidationType
}
export type ApiWorkflowCheckConditionNodeI = t.TypeOf<typeof ApiWorkflowCheckConditionNodeIO> & {
    type: WorkflowNodeType.CHECK_CONDITION_NODE
}
export type ApiWorkflowCheckLineConditionGroupI = t.TypeOf<typeof ApiWorkflowCheckLineConditionGroupIO>
export type ApiWorkflowCheckLineConditionNodeI = t.TypeOf<typeof ApiWorkflowCheckLineConditionNodeIO> & {
    type: WorkflowNodeType.LINE_CHECK_CONDITION_NODE
}
export type ApiWorkflowBudgetNodeI = t.TypeOf<typeof ApiWorkflowBudgetNodeIO> & {
    type: WorkflowNodeType.BUDGET_NODE
}
export type ApiWorkflowUnknownNodeI = {
    type: WorkflowNodeType.UNKNOWN
    nodeId?: string
}
export type ApiWorkflowNodeI =
    | ApiWorkflowStartNodeI
    | ApiWorkflowBranchNodeI
    | ApiWorkflowConditionNodeI
    | ApiWorkflowValidationNodeI
    | ApiWorkflowCheckConditionNodeI
    | ApiWorkflowCheckLineConditionNodeI
    | ApiWorkflowBudgetNodeI
    | ApiWorkflowUnknownNodeI

export const ApiWorkflowIO = t.intersection([
    t.type({
        workflowId: t.string,
        type: t.string,
        workflowName: t.string,
        nodes: t.array(ApiWorkflowNodeIO),
    }),
    t.partial({
        enabled: t.boolean,
    }),
])

export type ApiWorkflowI = {
    id?: never
    workflowId: ApiWorkflowId
    workflowName: string
    type: WorkflowType
    nodes: ApiWorkflowNodeI[]
    enabled: boolean
}

export interface WorkflowCreatePayload {
    workflowName: string
    type: WorkflowType
    nodes: ApiWorkflowNodeI[]
}

export const ApiWorkflowCreateResponseIO = t.type({
    workflowId: t.string,
})
export type ApiWorkflowCreateResponseI = t.TypeOf<typeof ApiWorkflowCreateResponseIO>

export const PurchaseRequestCheckReviewerIO = t.type({
    type: t.string,
    id: t.string,
})
export type PurchaseRequestCheckReviewerI = t.TypeOf<typeof PurchaseRequestCheckReviewerIO>

export const PurchaseRequestCheckWorkflowIO = t.type({
    workflowId: t.string,
    reviewers: t.array(PurchaseRequestCheckReviewerIO),
    checkThreshold: t.number,
})

export const PurchaseRequestCheckStatusIO = t.type({
    checks: t.array(PurchaseRequestCheckWorkflowIO),
    approvers: t.array(t.string),
    refusers: t.array(t.string),
})

export const PurchaseRequestApiWorkflowLineCheckStatusIO = t.type({
    lineId: t.string,
    checks: t.array(PurchaseRequestCheckWorkflowIO),
    approvers: t.array(t.string),
    refusers: t.array(t.string),
})

export const PurchaseRequestWorkflowCheckIO = t.type({
    purchaseRequestCheckStatus: PurchaseRequestCheckStatusIO,
    lineCheckStatuses: t.array(PurchaseRequestApiWorkflowLineCheckStatusIO),
})

export type PurchaseRequestWorkflowCheckI = t.TypeOf<typeof PurchaseRequestWorkflowCheckIO>

export const InvoiceReviewIO = t.type({
    type: t.string,
    id: t.string,
})

export const InvoiceCheckWorkflowIO = t.type({
    workflowId: t.string,
    reviewers: t.array(InvoiceReviewIO),
    checkThreshold: t.number,
})
export const InvoiceWorkflowCheckIO = t.type({
    checkStatus: t.array(InvoiceCheckWorkflowIO),
    approvers: t.array(t.string),
    refusers: t.array(t.string),
})

export type InvoiceCheckWorkflowI = t.TypeOf<typeof InvoiceCheckWorkflowIO>
export type InvoiceWorkflowCheckI = t.TypeOf<typeof InvoiceWorkflowCheckIO>

export enum ReviewerType {
    USER = "USER",
    TEAM = "TEAM",
}

export type InvoiceReviewerTeamI = {
    type: ReviewerType.TEAM
    value: OrganizationTeamI
}

export type InvoiceReviewerUserI = {
    type: ReviewerType.USER
    value: UserI
}

export type InvoiceReviewerWithUsersI = InvoiceReviewerUserI | InvoiceReviewerTeamI

export type InvoiceCheckWorkflowWithUsersI = {
    workflowId: string
    reviewers: InvoiceReviewerWithUsersI[]
    checkThreshold: number
}

export type InvoiceWorkflowCheckWithUsers = {
    checkStatus: InvoiceCheckWorkflowWithUsersI[]
    approvers: UserI[]
    refusers: UserI[]
}

export type PurchaseRequestApiWorkflowLineCheckStatusI = t.TypeOf<typeof PurchaseRequestApiWorkflowLineCheckStatusIO>

export type PurchaseRequestCheckWorkflowWithUsers = {
    workflowId: ApiWorkflowId
    reviewers: UserI[]
    checkThreshold: number
    progress: number
    approvers: UserI[]
}

export type PurchaseRequestCheckStatusWithUsers = {
    checks: PurchaseRequestCheckWorkflowWithUsers[]
    approvers: string[]
    refusers: string[]
}

export type PurchaseRequestApiWorkflowLineCheckStatusWithUsersI = {
    lineId: string
    checks: PurchaseRequestCheckWorkflowWithUsers[]
    approvers: string[]
    refusers: string[]
}

export type PurchaseRequestCheckWorkflowI = t.TypeOf<typeof PurchaseRequestCheckWorkflowIO>

export type PurchaseRequestWorkflowCheckWithUsersI = {
    purchaseRequestCheckStatus: PurchaseRequestCheckStatusWithUsers
    lineCheckStatuses: PurchaseRequestApiWorkflowLineCheckStatusWithUsersI[]
}
