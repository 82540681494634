import React from "react"
import { TagsSelector } from "~/domains/tags/components/TagsSelector"
import { OrganizationId } from "~/types"
import { useTagsForCreatingPurchaseRequest } from "../store/hooks"

interface Props {
    organizationId: OrganizationId
}

export const TagsForCreatingPurchaseRequest: React.FC<Props> = ({ organizationId }) => {
    const [selectedTags, setSelectedTags] = useTagsForCreatingPurchaseRequest()
    return (
        <TagsSelector
            organizationId={organizationId}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            tagsRecommandations={null}
        />
    )
}
