import { Editor } from "slate"
import { CustomElement } from "../../../types/Slate"
import { MessageContentType } from "../../../types"

export const withMentions = (editor: Editor) => {
    const { isInline, isVoid, markableVoid } = editor

    editor.isInline = (element: CustomElement) => {
        return element.type === MessageContentType.Mention ? true : isInline(element)
    }

    editor.isVoid = (element: CustomElement) => {
        return element.type === MessageContentType.Mention ? true : isVoid(element)
    }

    editor.markableVoid = (element: CustomElement) => {
        return element.type === MessageContentType.Mention || markableVoid(element)
    }

    return editor
}
