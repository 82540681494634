import React from "react"
import { InvoiceI, ViewTypeI } from "~/types"
import { PurchaseOrderRelationI } from "../../types/PurchaseOrderRelation"
import { generatePath } from "react-router-dom"
import { Link, styled } from "@mui/material"
import { INVOICE_BUYER_ROUTE } from "~/features/buyer/routes"
import { EDIT_INVOICE_SUPPLIER_ROUTE } from "~/features/supplier/routes"

interface Props {
    purchaseOrderRelation: PurchaseOrderRelationI
    viewType: ViewTypeI
    invoice?: InvoiceI | null
}

const StyledLink = styled(Link)(({ theme }) => ({
    color: `${theme.palette.primary.main} !important`,
    textDecoration: "none",
    "&:hover": {
        textDecoration: "underline",
    },
}))

export const InvoiceLink: React.FC<Props> = ({ purchaseOrderRelation, viewType, invoice }) => {
    const label = invoice && invoice.reference ? `INV #${invoice.reference}` : "INV"
    const href = generatePath(viewType === ViewTypeI.buyer ? INVOICE_BUYER_ROUTE : EDIT_INVOICE_SUPPLIER_ROUTE, {
        invoiceId: purchaseOrderRelation.invoiceId,
    })
    return <StyledLink href={href}>{label}</StyledLink>
}
