import React from "react"
import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

interface Props {
    requesterName?: string
}

export function RequesterName({requesterName}: Props) {
    return <Box component={"div"} className={"requester-name"}>
        <label><FormattedMessage id={"purchase.requesterName"} defaultMessage={"Creator"} /></label>
        <span>{requesterName ?? ""}</span>
    </Box>
}
