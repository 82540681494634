import * as Sentry from "@sentry/browser"
import React, { ReactNode } from "react"
import { IFallbackProps } from "./AppCrashFallback"
import { AppReload } from "./AppCrashFallback/AppReload"

interface Props {
    children: ReactNode
    FallbackComponent: React.ComponentType<IFallbackProps>
}

interface State {
    hasError: boolean
}

interface EventState extends State {
    eventId: string
    error?: Error
}

const getExtras = (errorInfo: React.ErrorInfo) => ({
    componentStack: errorInfo.componentStack,
})

class ErrorBoundary extends React.Component<Props, EventState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            hasError: false,
            eventId: "",
            error: undefined,
        }
    }

    static getDerivedStateFromError(): State {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        Sentry.withScope((scope) => {
            scope.setExtras(getExtras(errorInfo))
            const eventId = Sentry.captureException(error)
            this.setState({
                eventId,
                error,
            })
        })
    }

    render(): React.ReactNode {
        const { hasError, eventId, error } = this.state
        if (hasError && error && error.message.startsWith("Failed to fetch dynamically imported module")) {
            return <AppReload eventId={eventId} />
        }
        const { FallbackComponent, children } = this.props
        return hasError ? <FallbackComponent eventId={eventId} error={error} /> : children
    }
}

export default ErrorBoundary
