import { useCallback, useEffect, useMemo /* , useRef */ } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../hooks"
import { accountActions, selectReceivedDocuments, UserAndOrganization } from "../accountSlice"
import { NO_ORGANIZATION_ID, OrganizationId, ReceivedDocumentI } from "~/types"
import { documentApi } from "~/api"
import { useGetBulkObjectsTags } from "~/domains/tags/hooks/useGetBulkObjectsTags"

export const useGetReceivedDocuments = (payload: UserAndOrganization, withoutTags = false) => {
    const dispatch = useAppDispatch()
    const state = useSelector(selectReceivedDocuments)
    const { getBulkObjectsTags } = useGetBulkObjectsTags(payload.organizationId)

    const getReceivedDocuments = useCallback(async () => {
        if (payload.userId && payload.organizationId) {
            try {
                dispatch(
                    accountActions.fetchReceivedDocuments({ ...payload, showLoader: !state.receivedDocuments.length })
                )
                const receivedDocuments: ReceivedDocumentI[] =
                    payload.userId && (payload.organizationId === NO_ORGANIZATION_ID || !payload.organizationId)
                        ? await documentApi.getReceivedDocuments(payload.userId)
                        : await documentApi.getBuyersDocuments(payload.organizationId as OrganizationId)

                dispatch(accountActions.fetchReceivedDocumentsSuccess(receivedDocuments))
                if (withoutTags || !receivedDocuments || receivedDocuments.length === 0) {
                    return
                }
                dispatch(accountActions.setReceivedDocumentsTagsLoading(true))
                const invoicesObjectsTags = await getBulkObjectsTags(
                    receivedDocuments.map((d) => d.invoiceId),
                    true
                )

                dispatch(accountActions.setReceivedDocumentsTags(invoicesObjectsTags))
                if (invoicesObjectsTags) {
                    dispatch(
                        accountActions.fetchReceivedDocumentsSuccess(
                            receivedDocuments.map((doc) => ({
                                ...doc,
                                tags: invoicesObjectsTags[doc.invoiceId] ?? [],
                            }))
                        )
                    )
                }
            } catch (error) {
                dispatch(accountActions.fetchReceivedDocumentsFailed(`${error}`))
            }
        }
    }, [dispatch, payload.userId, payload.organizationId, getBulkObjectsTags, withoutTags])

    useEffect(() => {
        getReceivedDocuments()
    }, [getReceivedDocuments])

    return useMemo(() => state, [state.error, state.loading, state.receivedDocuments])
}
