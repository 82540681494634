import * as t from "io-ts"
import { DocumentRelationId } from "./InvoiceRelation"

export const CreateRelationResultIO = t.type({
    relationId: t.string,
})

export type CreateRelationResultI = t.TypeOf<typeof CreateRelationResultIO> & {
    relationId: DocumentRelationId
}
