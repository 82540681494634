import { useAppSelector } from "~/store/hooks"
import { useCallback } from "react"
import { selectUser } from "~/store/account/accountSlice"
import { OrganizationId } from "~/types"
import { isDefined } from "~/utils/isDefined"
import { selectPendingCustomFieldValues } from "../store"
import { CreatePurchaseRequestDTO, PurchaseRequestDetails } from "../../purchase-requests/types/PurchaseRequests"
import { CreateCustomFieldPayload } from "../types/CreateCustomFieldPayload"
import { useCustomFieldsApi } from "../customFieldsApi"

export const useSavePurchaseRequestPendingCustomFieldValues = (organizationId: OrganizationId) => {
    const user = useAppSelector(selectUser)
    const pendingCustomFieldValues = useAppSelector(selectPendingCustomFieldValues)
    const customFieldsApi = useCustomFieldsApi()

    return useCallback(
        async (
            purchaseRequest: PurchaseRequestDetails | CreatePurchaseRequestDTO,
            updatededPurchaseRequest: PurchaseRequestDetails
        ) => {
            try {
                const customFieldsToCreate = updatededPurchaseRequest.lines
                    .map((line, lineIndex) => {
                        const temporaryId = purchaseRequest.lines[lineIndex]?.temporaryId
                        const customFields = temporaryId
                            ? Object.values(pendingCustomFieldValues[temporaryId] ?? {})
                            : null
                        const lineId = line.id
                        if (customFields && lineId) {
                            return customFields.map(
                                (customFieldPayload): CreateCustomFieldPayload => ({
                                    ...customFieldPayload,
                                    objectId: lineId,
                                    contextId: updatededPurchaseRequest.id,
                                })
                            )
                        }
                        return null
                    })
                    .filter(isDefined)
                    .flat()
                if (customFieldsToCreate.length > 0) {
                    await customFieldsApi.batchCreateCustomFields(customFieldsToCreate)
                }
            } catch (error) {
                console.error("error")
            }
        },
        [pendingCustomFieldValues, customFieldsApi, user, organizationId]
    )
}
