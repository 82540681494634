import * as t from "io-ts"
import { Opaque } from "~/utils"
import { CreateTagObjectIO } from "./CreateTagObject"
import { OrganizationId } from "~/types"
import { TagId } from "./Tag"
import { optional } from "~/types/utils"

export type TagObjectId = Opaque<string, { readonly T: unique symbol }>

export const TagObjectIO = t.intersection([
    t.type({
        organizationId: t.string,
    }),
    t.partial({
        tagName: t.string,
        tagGroupName: t.string,
        tagGroupShortName: optional(t.string),
    }),
    CreateTagObjectIO,
])

export type TagObjectI = t.TypeOf<typeof TagObjectIO> & {
    organizationId: OrganizationId
    tagId: TagId
}
