/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { call, put, select } from "redux-saga/effects"
import { InvoiceI, InvoiceRequestI, InvolvedPeopleI, OrganizationId, UserI } from "~/types"
import { invoiceApi, userApi } from "~/api"
import { invoiceActions, selectInvoiceId, selectInvolvedPeople } from "~/store/invoice/invoiceSlice"
import { PayloadAction } from "@reduxjs/toolkit"
import { WorkflowApprovePayload } from "~/features/workflow/types"

export function* fetchInvolvedUsers() {
    try {
        const involvedPeople: InvolvedPeopleI[] = yield select(selectInvolvedPeople)
        const userIds = involvedPeople.map((person) => person.userId)
        const users: UserI[] = yield call(userApi.findUsersByIds, [...new Set(userIds)])
        yield put(invoiceActions.fetchInvolvedUsersSuccess(users))
    } catch (error) {
        console.error(`Failed to get involved users`, error)
        yield put(invoiceActions.fetchInvolvedUsersFailed(`${error}`))
    }
}

export function* sendRequest(action: PayloadAction<InvoiceRequestI>) {
    try {
        yield call(invoiceApi.sendRequest, action.payload)
        yield put(invoiceActions.sendRequestSuccess(action.payload))
    } catch (error) {
        console.error(`Failed to send request`, error)
        yield put(invoiceActions.sendRequestFailed(`${error}`))
    }
}

export function* validateInvoice(action: PayloadAction<{ comment: string; tags: string[] }>) {
    try {
        const id = yield select(selectInvoiceId)
        const comment = action.payload.comment
        const tags = action.payload.tags
        yield call(invoiceApi.validate, {
            id,
            comment,
            tags,
        })
        yield put(invoiceActions.validateInvoiceSuccess())
    } catch (error) {
        console.error(`Failed to validate invoice`, error)
        yield put(invoiceActions.validateInvoiceFailed(`${error}`))
    }
}

export function* approveInvoice(action: PayloadAction<{ organizationId: OrganizationId } & WorkflowApprovePayload>) {
    try {
        const { ...payload } = action.payload
        yield call(invoiceApi.approve, payload)
        yield put(invoiceActions.approveInvoiceSuccess())
    } catch (error) {
        console.error(`Failed to approve invoice`, error)
        yield put(invoiceActions.approveInvoiceFailed())
    }
}

export function* rejectInvoice(action: PayloadAction<{ organizationId: OrganizationId } & WorkflowApprovePayload>) {
    try {
        const { ...payload } = action.payload
        yield call(invoiceApi.refuse, payload)
        yield put(invoiceActions.approveInvoiceSuccess())
    } catch (error) {
        console.error(`Failed to approve invoice`, error)
        yield put(invoiceActions.approveInvoiceFailed())
    }
}

export function* schedulePayment(action: PayloadAction<InvoiceI>) {
    try {
        yield call(invoiceApi.schedulePayment, action.payload)
        yield put(invoiceActions.schedulePaymentSuccess(action.payload.scheduledPaymentDate))
    } catch (error) {
        console.error(`Failed to schedule payment`, error)
        yield put(invoiceActions.schedulePaymentFailed(`${error}`))
    }
}

export function* setAsPaid(action: PayloadAction<string>) {
    try {
        yield call(invoiceApi.markAsPaid, action.payload)
        yield put(invoiceActions.setAsPaidSuccess())
    } catch (error) {
        console.error(`Failed to mark as paid`, error)
        yield put(invoiceActions.setAsPaidFailed(`${error}`))
    }
}
