import React from "react"
import "./DocumentChip.scss"
import { CrossIcon } from "~/components/Icons"
import { useShortenName } from "~/hooks/useShortenName"
import { Tooltip } from "@mui/material"

interface DocumentChipProps {
    type: string
    name: string
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onDelete: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    readonly?: boolean
}

const DocumentChip: React.FC<DocumentChipProps> = ({ type, name, onClick, onDelete, readonly = false }) => {
    const typeStr = type + " - "
    const maxChar = 27

    return (
        <div className="document-chip">
            <div className="document-chip-text-container" onClick={onClick}>
                <span className="document-chip-type">{typeStr}</span>
                <div>
                    <Tooltip title={name}>
                        <span className="document-chip-name noto-semi-bold">
                            {useShortenName(typeStr, name, maxChar)}
                        </span>
                    </Tooltip>
                </div>
            </div>
            {!readonly ? <div onClick={onDelete} className="document-chip-button">
                <CrossIcon size={18} />
            </div> : null}
        </div>
    )
}

export default DocumentChip
