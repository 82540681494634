import React, { PropsWithChildren } from "react"
import { AlertIcon } from "~/components/Icons"

import "./ErrorMessage.scss"

export const ErrorMessage: React.FC<PropsWithChildren> = ({ children }) => {
    if (!children) return null
    return (
        <div className="error-container">
            <AlertIcon color="#F56C6C" className="error-container-icon" />
            <div className="error-container-message">{children}</div>
        </div>
    )
}
