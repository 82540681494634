import React, { useEffect, useState } from "react"
import { PurchaseOrderRelationI } from "../../types/PurchaseOrderRelation"
import { InvoiceI, OrganizationId, ViewTypeI } from "~/types"
import { IconButton, styled } from "@mui/material"
import { invoiceApi } from "~/api"
import { Trash2 } from "react-feather"
import { InvoiceLink } from "./InvoiceLink"
import { useDeletePurchaseOrderRelation, useSubscribeToRelation } from "../../hooks"
import { SubscriptionType } from "~/domains/transactions/common/subscriptions"

interface Props {
    purchaseOrderRelation: PurchaseOrderRelationI
    organizationId: OrganizationId
    purchaseOrderId: string
    viewType: ViewTypeI
}

const PurchaseOrderRelationItem = styled("li")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
})

export const PurchaseOrderRelation: React.FC<Props> = ({ organizationId, purchaseOrderRelation, viewType }) => {
    const deleteRelation = useDeletePurchaseOrderRelation(organizationId, purchaseOrderRelation.relationId)
    useSubscribeToRelation(purchaseOrderRelation.relationId, SubscriptionType.PURCHASE_ORDER)
    const [invoice, setInvoice] = useState<InvoiceI | null>(null)
    useEffect(() => {
        invoiceApi.getById(purchaseOrderRelation.invoiceId).then(setInvoice)
    }, [purchaseOrderRelation.invoiceId])

    return (
        <PurchaseOrderRelationItem>
            <InvoiceLink purchaseOrderRelation={purchaseOrderRelation} invoice={invoice} viewType={viewType} />
            <div>
                <IconButton onClick={deleteRelation}>
                    <Trash2 color="#909399" size={16} />
                </IconButton>
            </div>
        </PurchaseOrderRelationItem>
    )
}
