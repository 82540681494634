export function getPriceTaxIncluded(originalPrice, taxPercentage) {

    const taxAmount = taxRateToBack(taxPercentage) * originalPrice
    return parseFloat(originalPrice) + taxAmount
}

export function taxRateFromBack(taxRate: number): number {
    return Math.round((taxRate * 100) * 100) / 100
}

export function taxRateToBack(taxRate: number): number {
    return taxRate / 100
}
