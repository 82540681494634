import { AxiosResponse } from "axios"
import axiosClient from "./axiosClient"
import {
    CreateRoleI,
    PermissionId,
    RoleI,
    RoleWithEntityIds,
    UpdateRoleI,
} from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { OrganizationId, TeamId, UserId, AuthorizationName, AuthorizationNameExtra } from "~/types"
import { SharedObject, SharedObjectType } from "~/types/SharedObjects"

const BASE_URL = import.meta.env.VITE_API_ROLES_PERMISSIONS_URL + "organizations/"

export const rolesPermissionsApi = {
    create(organizationId: OrganizationId, role: CreateRoleI): Promise<RoleI> {
        const url = `${BASE_URL}${organizationId}/roles`
        return axiosClient.post(url, role)
    },
    findAll(organizationId: OrganizationId): Promise<RoleI[]> {
        const url = `${BASE_URL}${organizationId}/roles`
        return axiosClient.get(url)
    },
    findAllWithUserIds(organizationId: OrganizationId): Promise<RoleWithEntityIds[]> {
        const url = `${BASE_URL}${organizationId}/roles?withUserIds=true&withTeamIds=true`
        return axiosClient.get(url)
    },
    findAllUserIdsByRole(organizationId: OrganizationId, roleId: string): Promise<UserId[]> {
        const url = `${BASE_URL}${organizationId}/roles/${roleId}/userIds`
        return axiosClient.get(url)
    },
    findAllPermissions(organizationId: OrganizationId): Promise<PermissionId[]> {
        const url = `${BASE_URL}${organizationId}/permissions`
        return axiosClient.get(url)
    },
    findById(organizationId: OrganizationId, roleId: string): Promise<RoleI> {
        const url = `${BASE_URL}${organizationId}/roles/${roleId}`
        return axiosClient.get(url)
    },
    delete(organizationId: OrganizationId, roleId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/roles/${roleId}`
        return axiosClient.delete(url)
    },
    update(organizationId: OrganizationId, roleId: string, role: UpdateRoleI): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/roles/${roleId}`
        return axiosClient.patch(url, role)
    },
    addRolesToUser(organizationId: OrganizationId, userId: UserId, roleIds: string[]): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/users/${userId}/roles/`
        return axiosClient.post(url, { roleIds })
    },
    removeRoleFromUser(organizationId: OrganizationId, userId: UserId, roleId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/users/${userId}/roles/${roleId}`
        return axiosClient.delete(url)
    },
    addRolesToTeam(organizationId: OrganizationId, teamId: TeamId, roleIds: string[]): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/teams/${teamId}/roles/`
        return axiosClient.post(url, { roleIds })
    },
    removeRoleFromTeam(organizationId: OrganizationId, teamId: TeamId, roleId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/teams/${teamId}/roles/${roleId}`
        return axiosClient.delete(url)
    },
    addPermissionsToRole(
        organizationId: OrganizationId,
        roleId: string,
        permissionIds: PermissionId[]
    ): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/roles/${roleId}/permissions/`
        return axiosClient.post(url, { permissionIds })
    },
    removePermissionToRole(organizationId: string, roleId: string, permissionId: PermissionId): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/roles/${roleId}/permissions/${permissionId}`
        return axiosClient.delete(url)
    },
    shareObject(
        organizationId: OrganizationId,
        objectId: string,
        objectType: SharedObjectType,
        userId: string,
        userOrganizationId: OrganizationId,
        creator: string,
        sendShareEmail: boolean
    ): Promise<SharedObject> {
        const url = `${BASE_URL}${organizationId}/shares`
        return axiosClient.post(url, { objectId, objectType, userId, creator, userOrganizationId, sendShareEmail })
    },
    fetchAllSharedObjects(organizationId: string): Promise<SharedObject[]> {
        const url = `${BASE_URL}${organizationId}/shares`
        return axiosClient.get(url)
    },
    removeSharedObject(organizationId: string, sharedObjectId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/shares/${sharedObjectId}`
        return axiosClient.delete(url)
    },
    checkPurchaseRequestPermissions(
        organizationId: string,
        authorizations: (AuthorizationName | AuthorizationNameExtra)[]
    ): Promise<Record<AuthorizationName | AuthorizationNameExtra, boolean>> {
        const url = `${BASE_URL}${organizationId}/permissions/purchase-requests`
        return axiosClient.post(url, { authorizations })
    },
}
