import { ParsingErrorType, genericParser } from "~/utils"
import { Result, ResultSuccess, isResultSuccess } from "~/core/Result"
import {
    PaginatedProductVersionI,
    PaginatedProductVersionIO,
    ProductVersionI,
    ProductVersionIO,
    ProductId,
    ProductVersionId,
} from "./ProductVersion"

export const parseProductVersion = (data: unknown): Result<ProductVersionI, ParsingErrorType> => {
    const result = genericParser(data, ProductVersionIO)
    if (isResultSuccess(result)) {
        return ResultSuccess({
            ...result.result,
            id: result.result.id as ProductVersionId,
            productId: result.result.productId as ProductId,
        })
    }
    return result
}

export const parsePaginatedProductVersion = (data: unknown): Result<PaginatedProductVersionI, ParsingErrorType> => {
    const result = genericParser(data, PaginatedProductVersionIO)
    if (isResultSuccess(result)) {
        return ResultSuccess({
            ...result.result,
            results: result.result.results.map((productVersion) => ({
                ...productVersion,
                id: productVersion.id as ProductVersionId,
                productId: productVersion.productId as ProductId,
            })),
        })
    }
    return result
}
