import { MessageDescriptor, defineMessages } from "react-intl"
import { FilterFieldProperty } from "../types"

const propertyLabels: Record<FilterFieldProperty, MessageDescriptor> = defineMessages({
    [FilterFieldProperty.AMOUNT]: {
        id: "workflow.filters.property.AMOUNT",
        defaultMessage: "amount",
    },
    [FilterFieldProperty.LINE_AMOUNT]: {
        id: "workflow.filters.property.LINE_AMOUNT",
        defaultMessage: "line amount",
    },
    [FilterFieldProperty.SUPPLIER]: {
        id: "workflow.filters.property.SUPPLIER",
        defaultMessage: "supplier",
    },
    [FilterFieldProperty.TAGS]: {
        id: "workflow.filters.property.TAGS",
        defaultMessage: "tags",
    },
    [FilterFieldProperty.TAGS_V2]: {
        id: "workflow.filters.property.TAGS",
        defaultMessage: "tags",
    },
    [FilterFieldProperty.AUTHOR]: {
        id: "workflow.filters.property.AUTHOR",
        defaultMessage: "requester",
    },
    [FilterFieldProperty.UNKNOWN]: {
        id: "workflow.filters.property.UNKNOWN",
        defaultMessage: "unknown",
    },
})

export const getPropertyLabel = (property: FilterFieldProperty): MessageDescriptor => propertyLabels[property]
