import React, { ReactNode, useState } from "react"
import { Button, Modal, Size } from "~/components"
import { TextField, Typography } from "@mui/material"
import { defineMessages, FormattedMessage } from "react-intl"

const messages = defineMessages({
    title: {
        id: "purchase.orders.order.actions.modalNote.title",
        defaultMessage: "Mark as sent",
    },
    label: {
        id: "purchase.orders.order.actions.modalNote.input",
        defaultMessage: "Tracking link, comment, note, ...",
    },
    cancel: {
        id: "purchase.orders.order.actions.modalNote.cancel",
        defaultMessage: "Cancel",
    },
    confirm: {
        id: "purchase.orders.order.actions.modalNote.confirm",
        defaultMessage: "Confirm",
    },
})

interface Props {
    open: boolean
    cancel: () => void
    confirm: (note: string) => void
    label?: ReactNode
}
export function ModalNote({ open, cancel, confirm, label }: Props) {
    const [note, setNote] = useState<string>("")
    const handleConfirm = () => {
        confirm(note)
    }

    return (
        <Modal open={open} size={Size.SM} className={"modal-note"}>
            <Modal.Header>
                <Typography variant="h2" gutterBottom>
                    {label ?? <FormattedMessage {...messages.title} />}
                </Typography>
            </Modal.Header>
            <Modal.Content>
                <TextField
                    id="note"
                    name="note"
                    type="text"
                    className="bg-grey mb-12"
                    label={<FormattedMessage {...messages.label} />}
                    value={note}
                    onChange={(e) => setNote(e.currentTarget.value)}
                    fullWidth
                />
            </Modal.Content>
            <Modal.Footer>
                <Button type="neutral" buttonType="button" onClick={cancel}>
                    <FormattedMessage {...messages.cancel} />
                </Button>
                <Button type="secondary" buttonType="submit" onClick={handleConfirm}>
                    <FormattedMessage {...messages.confirm} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
