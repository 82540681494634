import React, { useMemo } from "react"
import classnames from "classnames"
import "../../../../components/Steps/Steps.scss"
import { Check, ChevronRight, ChevronsRight, FileText, Package, Send, Edit2, Edit } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { Grid } from "@mui/material"
import {
    PurchaseOrdersResponse,
    PurchaseOrderStatus,
    PurchaseOrderStatusOrder,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { ViewTypeI } from "~/types"
import { useAppSelector } from "~/store/hooks"
import { selectPurchaseOrderRelationsState } from "~/domains/transactions/document-relations/store/documentRelationsSlice"
import { allLinesApproved } from "../../purchase-orders/core/purchaseOrder"

export interface StepsI {
    key: string
    name: string
    status: string
    icon: React.ReactNode
}

const messages = defineMessages({
    approved: {
        id: "purchase.orders.order.steps.approved",
        defaultMessage: "Internally approved",
    },
    PODraft: {
        id: "purchase.orders.order.steps.PODraft",
        defaultMessage: "Draft",
    },
    POSubmitted: {
        id: "purchase.orders.order.steps.POSubmitted",
        defaultMessage: "Submitted",
    },
    POShared: {
        id: "purchase.orders.order.steps.POShared",
        defaultMessage: "Shared",
    },
    mutuallyAccepted: {
        id: "purchase.orders.order.steps.mutuallyAccepted",
        defaultMessage: "Mutually accepted",
    },
    inDelivery: {
        id: "purchase.orders.order.steps.inDelivery",
        defaultMessage: "In delivery",
    },
    delivered: {
        id: "purchase.orders.order.steps.delivered",
        defaultMessage: "Delivered",
    },
    received: {
        id: "purchase.orders.order.steps.received",
        defaultMessage: "Received",
    },
    invoiced: {
        id: "purchase.orders.order.steps.invoiced",
        defaultMessage: "Invoiced",
    },
})

interface StepsProps {
    PO: PurchaseOrdersResponse
    disabled?: boolean
    viewType: ViewTypeI
}

export function StepsPurchaseOrder({ PO, disabled, viewType }: StepsProps) {
    const { formatMessage } = useIntl()

    const { purchaseOrderRelations } = useAppSelector(selectPurchaseOrderRelationsState)
    const areAllLinesApproved = useMemo(() => allLinesApproved(PO.lines, viewType), [PO.lines, viewType])

    const classes = classnames("steps po-steps", {
        ["disabled"]: disabled,
    })

    let steps: StepsI[]
    if (viewType === ViewTypeI.buyer) {
        steps = [
            {
                key: "submitted",
                name: formatMessage(messages.POSubmitted),
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.status) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderStatus.SUBMITTED_BUYER)
                        ? "done"
                        : "next",
                icon: <Edit2 size={18} />,
            },
            {
                key: "approved",
                name: formatMessage(messages.approved),
                status:
                    (PurchaseOrderStatusOrder.indexOf(PO.status) >=
                        PurchaseOrderStatusOrder.indexOf(PurchaseOrderStatus.SUBMITTED_BUYER) &&
                        areAllLinesApproved) ||
                    PurchaseOrderStatusOrder.indexOf(PO.status) >=
                        PurchaseOrderStatusOrder.indexOf(PurchaseOrderStatus.SUBMITTED_SUPPLIER)
                        ? "done"
                        : "next",
                icon: <Check size={18} />,
            },
            {
                key: "POSent",
                name: formatMessage(messages.POShared),
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.status) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderStatus.AWAITING_SUPPLIER_APPROVAL)
                        ? "done"
                        : "next",
                icon: <Send size={18} />,
            },
            {
                key: "inPreparation",
                name: formatMessage(messages.mutuallyAccepted),
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.status) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderStatus.IN_PREPARATION)
                        ? "done"
                        : "next",
                icon: <Check size={18} />,
            },
            {
                key: "inDelivery",
                name: formatMessage(messages.inDelivery),
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.status) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderStatus.OUT_FOR_DELIVERY)
                        ? "done"
                        : "next",
                icon: <ChevronsRight size={18} />,
            },
            {
                key: "received",
                name: formatMessage(messages.received),
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.status) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderStatus.RECEIVED)
                        ? "done"
                        : "next",
                icon: <Package size={18} />,
            },
            {
                key: "invoiced",
                name: formatMessage(messages.invoiced),
                status: purchaseOrderRelations?.length ? "done" : "next",
                icon: <FileText size={18} />,
            },
        ]
        if (PO.status === PurchaseOrderStatus.DRAFT_BUYER) {
            steps.unshift({
                key: "draft",
                name: formatMessage(messages.PODraft),
                status: "done",
                icon: <Edit size={18} />,
            })
        }
    } else {
        steps = [
            {
                key: "received",
                name: formatMessage(messages.received),
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.status) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderStatus.AWAITING_SUPPLIER_APPROVAL)
                        ? "done"
                        : "next",
                icon: <Send size={18} />,
            },
            {
                key: "approved",
                name: formatMessage(messages.mutuallyAccepted),
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.status) >
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderStatus.AWAITING_BUYER_APPROVAL)
                        ? "done"
                        : "next",
                icon: <Check size={18} />,
            },
            {
                key: "inDelivery",
                name: formatMessage(messages.inDelivery),
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.status) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderStatus.OUT_FOR_DELIVERY)
                        ? "done"
                        : "next",
                icon: <ChevronsRight size={18} />,
            },
            {
                key: "delivered",
                name: formatMessage(messages.delivered),
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.status) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderStatus.DELIVERED)
                        ? "done"
                        : "next",
                icon: <Package size={18} />,
            },
            {
                key: "invoiced",
                name: formatMessage(messages.invoiced),
                status: purchaseOrderRelations?.length ? "done" : "next",
                icon: <FileText size={18} />,
            },
        ]
    }

    return (
        <Grid item className={"stepper-container"}>
            <ul className={classes}>
                {steps.map((step, key) => (
                    <li key={key} className={step.status}>
                        <span className="steps_name">
                            {step.icon} {step.name}
                        </span>
                        <span className="steps_separator">
                            <ChevronRight color="var(--color-grey)" />
                        </span>
                    </li>
                ))}
            </ul>
        </Grid>
    )
}
