import { defineMessages, useIntl } from "react-intl"
import { ParsingErrorType } from "~/utils"

export enum FetchErrorType {
    HTTP_REQUEST_ERROR = "HTTP_REQUEST_ERROR",
    PARSING_ERROR = "PARSING_ERROR",
}

export type FetchError<T> =
    | {
          type: FetchErrorType.HTTP_REQUEST_ERROR
          partialResults?: never
          code: number
          error: string
      }
    | {
          type: FetchErrorType.PARSING_ERROR
          partialResults: T[]
          errors: ParsingErrorType[]
      }

const messages = defineMessages({
    [FetchErrorType.HTTP_REQUEST_ERROR]: {
        id: "common.fetchError.HTTP_REQUEST_ERROR",
        defaultMessage: "An unexpected error occured: {error}",
    },
    [FetchErrorType.PARSING_ERROR]: {
        id: "common.fetchError.HTTP_REQUEST_ERROR",
        defaultMessage: "An unexpected error occured.",
    },
})

export function useFetchErrorMessage<T>(error: FetchError<T> | null) {
    const { formatMessage } = useIntl()

    if (!error) return null

    if (error.type === FetchErrorType.HTTP_REQUEST_ERROR) {
        return formatMessage(messages[error.type], { error: error.error })
    }
    return formatMessage(messages[error.type])
}
