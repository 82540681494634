import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useIntl, defineMessages } from "react-intl"
import { documentApi } from "../Api/DocumentApi"
import { documentActions } from "../store/documentSlice"
import { DocumentId } from "../Documents"
import { commonMessages } from "~/common-messages"

const messages = defineMessages({
    removed: {
        id: "documents.hooks.deleteDocument",
        defaultMessage: "Document successfully removed.",
    },
})

export const useDeleteDocument = () => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const deleteDocument = useCallback(
        (documentId: DocumentId) => {
            if (documentId) {
                setLoading(true)
                documentApi
                    .deleteDocument(documentId)
                    .then(() => {
                        dispatch(documentActions.deleteDocumentSuccess(documentId))
                        toast.success(formatMessage(messages.removed))
                        setLoading(false)
                    })
                    .catch((error) => {
                        setLoading(false)
                        console.error(error)
                        setError(`${error}`)
                        toast.error(formatMessage(commonMessages.errorContact))
                    })
            }
        },
        [dispatch]
    )

    return { deleteDocument, loading, error }
}
