import { useCallback, useState } from "react"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId, UserId } from "~/types"
import { toast } from "react-toastify"
import { defineMessages, useIntl } from "react-intl"
import { rolesPermissionsApi } from "~/api/rolesPermissionsApi"
import { rolesPermissionsActions } from "../rolesPermissionsSlice"
import { SharedObjectType } from "~/types/SharedObjects"
import { selectUser } from "~/store/account/accountSlice"
import { sharedObjectMessages } from "~/components/ShareObjectModal"
import { capitalize } from "@mui/material"

const messages = defineMessages({
    success: { id: "share.object.create.success", defaultMessage: "{objectType} shared" },
    error: {
        id: "share.object.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const useCreateSharedObject = (organizationId: OrganizationId | undefined) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const currentUser = useAppSelector(selectUser)

    const createSharedObject = useCallback(
        async (
            userOrganizationId: OrganizationId,
            objectId: string,
            objectType: SharedObjectType,
            userId: UserId,
            userEmail: string,
            sendShareEmail: boolean = true
        ) => {
            if (organizationId) {
                try {
                    setLoading(true)

                    const sharedObject = await rolesPermissionsApi.shareObject(
                        organizationId,
                        objectId,
                        objectType,
                        userId,
                        userOrganizationId,
                        currentUser.id,
                        sendShareEmail
                    )

                    dispatch(
                        rolesPermissionsActions.sharedObject({
                            organizationId,
                            sharedObject: { ...sharedObject, userEmail: userEmail, userOrganizationId },
                        })
                    )
                    setLoading(false)
                    toast.success(
                        formatMessage(messages.success, {
                            objectType: capitalize(formatMessage(sharedObjectMessages[objectType])),
                        })
                    )
                } catch (e) {
                    setError(`${e}`)
                    setLoading(false)
                    toast.error(formatMessage(messages.error))
                    throw e
                }
            }
        },
        [organizationId, currentUser]
    )

    return {
        createSharedObject,
        error,
        loading,
    }
}
