import React from "react"
import { Grid, Skeleton } from "@mui/material"

export function SkeletonInput() {
    return (
        <Grid container className="menu skeleton">
            <Skeleton
                variant="rounded"
                animation="wave"
                width={"100%"}
                height={53}
                sx={{
                    minWidth: "200px",
                    marginBottom: "8px",
                }}
            />
        </Grid>
    )
}
