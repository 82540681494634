import React, { ChangeEvent, lazy, useCallback, useEffect, useMemo, useState } from "react"
import Grid from "@mui/material/Unstable_Grid2"
import { CheckCircle } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { Button, Loader, useTitle } from "~/core"
import "../../assets/css/Purchases.scss"
import { HeaderH1 } from "~/components/Header/HeaderH1"
import { PURCHASE_REQUEST_ROUTE, PURCHASE_REQUESTS_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { generatePath, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ActionsColumn } from "~/domains/transactions/purchase-requests/components/Actions/ActionsColumn"
import { StepsPurchaseRequest } from "~/domains/transactions/components/Steps/StepsPurchaseRequest"
import {
    PurchaseRequestDetails,
    PurchaseRequestLine,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

import { Description } from "~/domains/transactions/components/Description/Description"
import { ActionsHeader } from "~/domains/transactions/purchase-requests/components/Actions/ActionsHeader"
import { TotalAmount } from "~/domains/transactions/components/TotalAmount/TotalAmount"
import { PurchaseOrderLink } from "~/domains/transactions/purchase-requests/components/PurchaseOrderLink/PurchaseOrderLink"
import { Delete } from "~/domains/transactions/purchase-requests/components/Actions/Delete"
import {
    purchaseRequestsActions,
    selectPurchaseRequestsError,
    selectPurchaseRequestsLoading,
    selectShouldUpdatePR,
} from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { Dates } from "~/domains/transactions/components/Dates/Dates"
import { Dayjs } from "dayjs"
import { PurchaseViewType } from "~/domains/transactions/types/Purchases"
import { RequesterName } from "~/domains/transactions/components/RequesterName/RequesterName"
import { selectUser } from "~/store/account/accountSlice"
import { TagsSelector } from "~/domains/tags/components/TagsSelector"
import { SavePayloadType } from "~/domains/identity/features/organizations/components/ModalOrganizationSelectorDetails/types"
import { useFetchPurchaseRequest, useUpdatePurchaseRequest, useUpdatePurchaseRequestStatus } from "../store/hooks"
import { OrganizationI, OrganizationId, ViewTypeI, CurrencyI } from "~/types"
import { organizationAddressToAddress } from "../../purchase-orders/pages/PurchaseOrder"
import { ActionsHeaderCreate } from "~/domains/transactions/purchase-requests/components/Actions/ActionsHeaderCreate"
import { Documents } from "~/components/UploadDocument/Documents/Documents"
import { TagGroupI, TagObjectType } from "~/domains/tags/types"
import { ModalConfirm } from "~/components/ModalConfirm/ModalConfirm"
import { toast } from "react-toastify"
import { AddTransactionPayloadI, TransactionType } from "~/features/budget/types"
import { ModalAddToBudget } from "../../components/ModalAddToBudget/ModalAddToBudget"
import { useFetchBudgetsData } from "~/store/budget/hooks"
import { TransactionInBudgets } from "../../components/TransactionInBudgets"
import { purchaseRequestApi } from "~/domains/transactions/purchase-requests/api"
import { useCreateDraftPurchaseRequest } from "../store/hooks/useCreateDraftPurchaseRequest"
import { LinesTabs } from "../../custom-fields/components/LinesTabs"
import { CustomFieldObjectType } from "../../custom-fields/types/CustomFieldObjectType"
import { usePurchaseRequest } from "../store/hooks/usePurchaseRequest"
import { withSocketIOProvider } from "../../common/subscriptions/components/SocketIOContext"
import { CommunicationRoom } from "~/domains/communication/components/CommunicationRoom"
import { SharedObjectType } from "~/types/SharedObjects"
import { TagsForCreatingPurchaseRequest } from "../components/TagsForCreatingPurchaseRequest"
import { DraftDocumentI, DocumentObjectType } from "~/components/UploadDocument/Documents"
import usePartnerBrandname from "../../book-of-relations/store/hooks/usePartnerBrandname"
import { useOrganizationTagGroups } from "~/domains/tags/hooks"
import { useTagsForLines } from "../../components/Items/hooks/useTagsForLines"
import {
    TagsSelectorForLineCells,
    TagsSelectorWithStateForLineCells,
} from "~/domains/tags/components/TagsSelector/TagsSelectorForLineCells"
import { tagsActions } from "~/domains/tags/store/tagsSlice"
import { CurrencySelector } from "~/components/CurrencySelector"

const Items = lazy(() => import("../../components/Items/ItemsPR").then(({ ItemsPR }) => ({ default: ItemsPR })))
const Organizations = lazy(() =>
    import("../../components/Organizations/Organizations").then(({ Organizations }) => ({ default: Organizations }))
)

const messages = defineMessages({
    htmlTitle: { id: "purchase.requests.request.htmlTitle", defaultMessage: "Purchase request" },
    titleEdit: { id: "purchase.requests.request.titleEdit", defaultMessage: "Edit PR" },
    titleNew: { id: "purchase.requests.request.titleNew", defaultMessage: "New PR" },
    titleView: { id: "purchase.requests.request.titleView", defaultMessage: "PR" },
    taxLabel: { id: "purchase.requests.request.taxLabel", defaultMessage: "Tax excl." },
    purchaseOrder: { id: "purchase.requests.request.purchaseOrder", defaultMessage: "Purchase order" },
    confirm: {
        id: "purchase.requests.request.modalConfirm.title",
        defaultMessage: "Would you send the purchase request for approval?",
    },
    currency: {
        id: "purchase.requests.modalNewPR.currency",
        defaultMessage: "Currency",
    },
    submitForApproval: {
        id: "purchase.requests.request.askForSubmit",
        defaultMessage: "Would you like to submit this purchase request for approval?",
    },
    saveAsDraft: { id: "purchase.requests.request.modalConfirm.saveDraft", defaultMessage: "Save it as draft" },
    saveAndSend: { id: "purchase.requests.request.modalConfirm.send", defaultMessage: "Send for approval" },
    errorChangedOrganization: {
        id: "purchase.requests.request.errorChangedOrganization",
        defaultMessage: "You have changed the organization. This organization does not have this purchase request.",
    },
    tagsTitle: { id: "purchase.requests.request.tagsTitle", defaultMessage: "Tags" },
})

interface Props extends JSX.IntrinsicAttributes {
    edit?: boolean
    newPR?: boolean
}

interface ViewProps extends Props {
    organization: OrganizationI
    purchaseRequest: PurchaseRequestDetails
}

const getPRSideFromSupplierId = (purchaseRequestSupplier: OrganizationId, currentOrgId: OrganizationId): ViewTypeI =>
    purchaseRequestSupplier === currentOrgId ? ViewTypeI.supplier : ViewTypeI.buyer

const editablePurchaseRequestStatuses: Partial<Record<PurchaseRequestStatus, boolean>> = {
    [PurchaseRequestStatus.DRAFT]: true,
    [PurchaseRequestStatus.SUBMITTED]: true,
}

export const PurchaseRequestView: React.FC<ViewProps> = ({ edit, newPR, organization, purchaseRequest: PR }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const loading = useAppSelector(selectPurchaseRequestsLoading)
    const { updatePR, loading: updateLoading } = useUpdatePurchaseRequest(organization.id, PR.id)
    const shouldUpdatePR = useAppSelector(selectShouldUpdatePR)
    const navigate = useNavigate()
    const view = getPRSideFromSupplierId(PR.supplierId, organization.id)
    const brandName = usePartnerBrandname(PR.supplierId, PR?.supplierName)

    const [modalAddToBudgetVisible, setModalAddToBudgetVisible] = useState<boolean>(false)
    const [draftDocuments, setDraftDocuments] = useState<DraftDocumentI[]>([])

    const pageMode =
        edit || newPR || PR.status === PurchaseRequestStatus.DRAFT ? PurchaseViewType.EDIT : PurchaseViewType.VIEW

    const title = formatMessage(
        messages[`title${newPR ? "New" : pageMode === PurchaseViewType.EDIT ? "Edit" : "View"}`]
    )

    const [searchParams] = useSearchParams()
    const askSubmit = searchParams.get("askSubmit") // TODO: maybe to delete
    const [showModalSubmit, setShowModalSubmit] = useState<boolean>(false)
    const { updatePRStatus } = useUpdatePurchaseRequestStatus(PR.organizationId, PR.id)
    const { fetchPurchaseRequest } = useFetchPurchaseRequest(organization.id)

    const { tagGroups } = useOrganizationTagGroups(organization?.id)
    const {
        selectedTags: tags,
        setSelectedTags: setTags,
        fetchTags,
    } = useTagsForLines({
        lines: PR.lines,
        organizationId: organization?.id,
        tagGroups,
        objectType: TagObjectType.PURCHASE_REQUEST_LINE,
    })

    const { budgetsData } = useFetchBudgetsData(organization.id, false)
    const transactionPayload: AddTransactionPayloadI = {
        transactionRefId: PR.id,
        transactionStatus: PR.status,
        transactionType: TransactionType.PURCHASE_REQUEST,
        supplierOrgId: PR.supplierId,
        buyerOrgId: organization.id,
        description: PR.description,
        amount: PR.totalAmount ?? 0,
        amountWithoutTaxes: PR.totalAmountExcludingTax,
        amountRemainingToPay: 0,
        expectedDeliveryDate: PR.expectedDeliveryDate,
        partialAmount: 0,
        partialRate: 0,
    }

    useEffect(() => {
        return () => {
            dispatch(purchaseRequestsActions.resetData())
            dispatch(tagsActions.resetPendingTags())
        }
    }, [])

    useEffect(() => {
        if (askSubmit) {
            setShowModalSubmit(true)
        }
    }, [askSubmit])

    useEffect(() => {
        if (shouldUpdatePR && !updateLoading) {
            updatePR().then(() => {
                dispatch(purchaseRequestsActions.setShouldUpdatePR(false))
            })
        }
    }, [updatePR, shouldUpdatePR, updateLoading])

    useEffect(() => {
        if (tagGroups && tagGroups.length) {
            fetchTags()
        }
    }, [tagGroups])

    const canDelete = useMemo(() => {
        return !PR.purchaseOrder
    }, [PR.purchaseOrder])
    const handleDelete = useCallback(async () => {
        try {
            await purchaseRequestApi.delete(PR.organizationId, PR.id)
            navigate(PURCHASE_REQUESTS_ROUTE)
        } catch (e) {
            console.error(e)
        }
    }, [purchaseRequestApi.delete, PR.organizationId, PR.id, navigate])

    const handleSubmit = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault()
            await updatePRStatus(PurchaseRequestStatus.SUBMITTED)
            window.setTimeout(() => {
                navigate(generatePath(PURCHASE_REQUEST_ROUTE, { purchaseRequestId: PR.id }))
                setShowModalSubmit(false)
            }, 500)
        },
        [updatePRStatus]
    )

    const setDeliveryDate = useCallback(
        (date: Dayjs | null) => {
            if (date) {
                dispatch(
                    purchaseRequestsActions.updateData({
                        field: "expectedDeliveryDate",
                        value: date.set("hours", 18).toISOString(),
                    })
                )
                if (pageMode !== PurchaseViewType.EDIT) {
                    dispatch(purchaseRequestsActions.setShouldUpdatePR(true))
                }
            }
        },
        [dispatch, pageMode, updatePR]
    )

    const handleChangeDescription = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            dispatch(
                purchaseRequestsActions.updateData({
                    field: "description",
                    value: e.target.value,
                })
            )
        },
        [dispatch]
    )

    const handleCurrencyChange = useCallback(
        (currency: CurrencyI | null) => {
            if (currency) {
                dispatch(
                    purchaseRequestsActions.updateData({
                        field: "currency",
                        value: currency.code,
                    })
                )
            }
        },
        [dispatch]
    )

    const updateAddresses = useCallback((payload: SavePayloadType) => {
        if (payload.sendingAddress) {
            dispatch(
                purchaseRequestsActions.updateData({
                    field: "shippingAddress",
                    value: organizationAddressToAddress(payload.sendingAddress),
                })
            )
            dispatch(purchaseRequestsActions.setShouldUpdatePR(true))
        }
    }, [])

    const buyerOrganization = useMemo(
        () => ({
            id: organization.id,
            name: organization.name,
            type: ViewTypeI.buyer,
        }),
        [organization]
    )

    const supplierOrganization = useMemo(
        () => ({
            id: PR.supplierId ?? null,
            name: brandName,
            type: ViewTypeI.supplier,
        }),
        [PR]
    )

    const otherOrganizations = useMemo(() => {
        // we do not display a shared room on purchase request view
        const otherOrganizations: OrganizationId[] = []
        return otherOrganizations
    }, [organization, PR.organizationId, PR.supplierId, PR.status])

    const tagSelectorContext = useMemo(
        () => ({
            page: "purchase-request",
            purchaseRequest: PR,
            organization,
            user,
        }),
        [PR, organization, user]
    )

    const showModalAddToBudget = useCallback(() => setModalAddToBudgetVisible(true), [])
    const hideModalAddToBudget = useCallback(() => setModalAddToBudgetVisible(false), [])

    const handleSuccess = useCallback(async () => {
        await fetchPurchaseRequest(PR.id)
    }, [dispatch, PR])

    const renderLineTags = useCallback(
        (line: PurchaseRequestLine, tagGroupId?: string, usedTagGroups?: TagGroupI[]) => {
            if (!user || !organization) return null

            if (line.id) {
                return (
                    <TagsSelectorForLineCells
                        objectId={line.id}
                        tags={tags}
                        setTags={setTags}
                        tagGroups={tagGroups ?? []}
                        tagGroupId={tagGroupId ?? ""}
                        usedTagGroups={usedTagGroups ?? []}
                    />
                )
            }

            if (line.temporaryId) {
                return (
                    <TagsSelectorWithStateForLineCells
                        temporaryId={line.temporaryId}
                        tagGroups={tagGroups ?? []}
                        tagGroupId={tagGroupId ?? ""}
                        usedTagGroups={usedTagGroups ?? []}
                    />
                )
            }

            return null
        },
        [user, organization, tags, setTags, PR]
    )

    if (!organization) {
        return null
    }

    return (
        <div className="purchase-page">
            <HeaderH1 title={title + (!newPR ? "#" + PR.number : "")} backLink={PURCHASE_REQUESTS_ROUTE}>
                {newPR ? (
                    <ActionsHeaderCreate
                        organizationId={organization.id}
                        draftDocuments={draftDocuments}
                        setDraftDocuments={setDraftDocuments}
                    />
                ) : (
                    <ActionsHeader
                        id={PR.id}
                        status={PR.status}
                        organizationId={PR.organizationId}
                        mode={pageMode}
                        isConvertedToPO={!!PR.purchaseOrder}
                        buyerOrganization={{ id: PR.organizationId, name: PR.requesterName as string }}
                        supplierOrganization={{ id: PR.supplierId, name: PR.supplierName as string }}
                        permissions={PR.permissions}
                    />
                )}
            </HeaderH1>
            <StepsPurchaseRequest PR={PR} />
            <Grid container className={"main-box purchase-request"} spacing={0} gap={2}>
                <Grid xs className={"pr-column pr-column-left"}>
                    <TotalAmount
                        amount={PR.totalAmountExcludingTax}
                        currency={PR.currency}
                        taxLabel={formatMessage(messages.taxLabel)}
                    />
                    <Dates
                        creationDate={PR.creationDate}
                        expectedDeliveryDate={PR.expectedDeliveryDate}
                        pageMode={pageMode}
                        setDeliveryDate={setDeliveryDate}
                    />

                    {!newPR ? <RequesterName requesterName={PR.requesterName ?? ""} /> : null}
                    {PR.purchaseOrder ? (
                        <PurchaseOrderLink
                            purchaseOrder={PR.purchaseOrder}
                            label={formatMessage(messages.purchaseOrder)}
                        />
                    ) : null}
                    {PR.id && <TransactionInBudgets transaction={PR} showModalAddToBudget={showModalAddToBudget} />}
                    <CurrencySelector
                        value={PR.currency}
                        label={formatMessage(messages.currency)}
                        editMode={pageMode === PurchaseViewType.EDIT}
                        onChange={handleCurrencyChange}
                    />
                    <h4 className="tags-title">{formatMessage(messages.tagsTitle)}</h4>
                    {!newPR ? (
                        <>
                            <TagsSelector
                                organizationId={organization.id}
                                objectId={PR.id}
                                userId={user.id}
                                objectType={TagObjectType.PURCHASE_REQUEST}
                                context={tagSelectorContext}
                            />
                            <Documents
                                objectId={PR.id}
                                objectType={DocumentObjectType.PURCHASE_REQUEST}
                                organizationId={organization.id}
                            />
                        </>
                    ) : (
                        <>
                            <TagsForCreatingPurchaseRequest organizationId={organization.id} />
                            <Documents
                                organizationId={organization.id}
                                objectType={DocumentObjectType.PURCHASE_REQUEST}
                                draftDocuments={draftDocuments}
                                setDraftDocuments={setDraftDocuments}
                            />
                        </>
                    )}

                    {pageMode === PurchaseViewType.VIEW && !PR.purchaseOrder ? <ActionsColumn PR={PR} /> : null}
                    {canDelete ? <Delete hasPermission={PR.permissions?.delete} handleDelete={handleDelete} /> : null}
                </Grid>

                <Grid lg={7} md={6} xs={12} className={"main-content"}>
                    <Organizations
                        to={supplierOrganization}
                        from={buyerOrganization}
                        updateAddresses={updateAddresses}
                        shippingAddress={PR.shippingAddress}
                        readOnly={!editablePurchaseRequestStatuses[PR.status]}
                        showOrganizationSelector={
                            !PR.supplierId ||
                            (pageMode === PurchaseViewType.EDIT && !(PR.purchaseOrder && PR.purchaseOrder.id))
                        }
                        currentOrgSide={view}
                        objectType={DocumentObjectType.PURCHASE_REQUEST}
                    />
                    <Description
                        description={PR.description}
                        pageMode={pageMode}
                        handleChange={handleChangeDescription}
                    />

                    <LinesTabs
                        items={PR.lines}
                        idKey="id"
                        temporaryIdKey="temporaryId"
                        contextType={CustomFieldObjectType.PURCHASE_REQUEST}
                        contextId={PR.id}
                        currency={PR.currency}
                        organizationId={organization.id}
                        renderLineTags={renderLineTags}
                    >
                        <Items
                            mode={pageMode}
                            id={PR.id}
                            organizationId={PR.organizationId}
                            lines={PR.lines}
                            totalAmount={PR.totalAmount ?? 0}
                            totalAmountExcludingTax={PR.totalAmountExcludingTax}
                            currency={PR.currency}
                            status={PR.status}
                            permissions={PR.permissions}
                        />
                    </LinesTabs>
                </Grid>
                {organization && PR.id && (
                    <Grid xs className="pr-column pr-column-left">
                        <CommunicationRoom
                            organizationId={organization.id}
                            objectId={PR.id}
                            objectType={SharedObjectType.PurchaseRequest}
                            otherOrganizations={otherOrganizations}
                        />
                    </Grid>
                )}
                {loading ? (
                    <div className={"loader-wrapper"}>
                        <Loader fullscreen={true} />
                    </div>
                ) : null}
                <ModalConfirm
                    message={formatMessage(messages.submitForApproval)}
                    icon={
                        <div className={"icon-container green"}>
                            <CheckCircle size={30} />
                        </div>
                    }
                    button1={
                        <Button type="neutral" buttonType="button" onClick={() => setShowModalSubmit(false)}>
                            {formatMessage(messages.saveAsDraft)}
                        </Button>
                    }
                    button2={
                        <Button type="primary" buttonType="button" onClick={handleSubmit}>
                            {formatMessage(messages.saveAndSend)}
                        </Button>
                    }
                    open={showModalSubmit}
                />
            </Grid>
            <ModalAddToBudget
                open={modalAddToBudgetVisible}
                close={hideModalAddToBudget}
                organizationId={organization.id}
                budgets={budgetsData}
                transaction={PR}
                transactionPayload={transactionPayload}
                handleSuccess={handleSuccess}
            />
        </div>
    )
}

export const PurchaseRequest = withSocketIOProvider<Props>(({ edit }: Props) => {
    const navigate = useNavigate()
    const { formatMessage } = useIntl()
    const { purchaseRequestId } = useParams()
    const pageName = formatMessage(messages.htmlTitle)
    useTitle(pageName)
    const organization = useAppSelector(selectCurrentOrganization)

    const { purchaseRequest: PR, changedOrganization } = usePurchaseRequest(organization?.id, purchaseRequestId)
    const error = useAppSelector(selectPurchaseRequestsError)

    useEffect(() => {
        if (error) {
            toast.error(changedOrganization ? formatMessage(messages.errorChangedOrganization) : error)
            navigate(PURCHASE_REQUESTS_ROUTE)
        }
    }, [error, changedOrganization])

    if (!organization) {
        return null
    }
    return <PurchaseRequestView edit={edit ?? false} organization={organization} purchaseRequest={PR} />
})

export function PurchaseRequestNew() {
    const { formatMessage } = useIntl()
    const pageName = formatMessage(messages.htmlTitle)
    useTitle(pageName)
    const dispatch = useAppDispatch()
    const organization = useAppSelector(selectCurrentOrganization)

    useEffect(() => {
        dispatch(purchaseRequestsActions.resetData())
        dispatch(tagsActions.resetPendingTags())

        return () => {
            dispatch(purchaseRequestsActions.resetData())
            dispatch(tagsActions.resetPendingTags())
        }
    }, [])

    const purchaseRequest = useCreateDraftPurchaseRequest(organization?.id)

    if (!organization || !purchaseRequest) {
        return null
    }
    return (
        <PurchaseRequestView edit={true} newPR={true} organization={organization} purchaseRequest={purchaseRequest} />
    )
}
