import { OrganizationItemI } from "~/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { organizationActions, selectOrganizationState } from "~/store/organization/organizationSlice"
import { useEffect, useMemo } from "react"
import { useFetchOrganization } from "./useFetchOrganization"

export const useCurrentOrganization = (organizations: OrganizationItemI[], forceFetch?: boolean) => {
    const { currentOrganizationId } = useAppSelector(selectOrganizationState)
    const curentOrganizationExists = useMemo(
        () => !!organizations.find((organization) => organization.id === currentOrganizationId),
        [organizations, currentOrganizationId]
    )
    const { organization } = useFetchOrganization(
        curentOrganizationExists ? currentOrganizationId : undefined,
        forceFetch
    )
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (
            organizations.length > 0 &&
            (!currentOrganizationId ||
                organizations.find((organization) => organization.id === currentOrganizationId) === undefined)
        ) {
            if (currentOrganizationId) {
                dispatch(organizationActions.setCurrentOrganization(organizations[0].id))
            } else {
                dispatch(organizationActions.setCurrentOrganizationIfNotSet(organizations[0].id))
            }
        }
    }, [dispatch, currentOrganizationId, organizations])

    return organization
}
