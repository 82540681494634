import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FetchError } from "~/domains/common/apiClient/errors"
import { RootState } from "~/store"
import { EmailState } from "~/domains/transactions/emails/store/emailsStore"
import { OrganizationId } from "~/types"
import { AttachementI, PaginatedReceivedEmailsI } from "~/features/ocr/types/ReceivedEmail"

const initialState: EmailState = {
    currentOrganizationId: null,
    receivedEmails: [],
    loading: false,
    pagination: null,
    query: "",
}

const emailsSlice = createSlice({
    name: "catalog",
    initialState,
    reducers: {
        fetchEmails(state, { payload: { organizationId } }: PayloadAction<{ organizationId: OrganizationId }>) {
            if (organizationId !== state.currentOrganizationId) {
                state.receivedEmails = []
            }
            state.currentOrganizationId = organizationId
            state.loading = true
            state.fetchError = null
        },
        fetchEmailsSuccess(
            state,
            {
                payload: { organizationId, result },
            }: PayloadAction<{ organizationId: OrganizationId; result: PaginatedReceivedEmailsI }>
        ) {
            if (organizationId === state.currentOrganizationId) {
                state.loading = false
                state.receivedEmails = result.results
                state.pagination = result.pagination
            }
        },
        fetchEmailsFailure(
            state,
            {
                payload: { organizationId, error },
            }: PayloadAction<{ organizationId: OrganizationId; error: FetchError<PaginatedReceivedEmailsI> }>
        ) {
            if (organizationId === state.currentOrganizationId) {
                state.loading = false
                state.fetchError = error
            }
        },
        setQueryFilter(state, { payload: { query } }: PayloadAction<{ query: string }>) {
            state.query = query
        },
        updateAttachment(state, { payload: { attachment } }: PayloadAction<{ attachment: AttachementI }>) {
            state.receivedEmails = state.receivedEmails.map((email) => {
                const attachmentIndex = email.attachments.findIndex((a) => a.id === attachment.id)
                if (attachmentIndex === -1) return email
                return {
                    ...email,
                    attachments: [
                        ...email.attachments.slice(0, attachmentIndex),
                        attachment,
                        ...email.attachments.slice(attachmentIndex + 1),
                    ],
                }
            })
        },
        markAsRead(state, { payload: { id } }: PayloadAction<{ id: string }>) {
            state.receivedEmails = state.receivedEmails.map((email) => {
                if (email.id === id) {
                    return {
                        ...email,
                        read: true,
                    }
                }
                return email
            })
        },
    },
})

export const emailsActions = emailsSlice.actions
export const emailsReducer = emailsSlice.reducer

export const selectEmailsState = (state: RootState): EmailState => state.emails
