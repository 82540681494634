export enum ExecutionDate {
    "first_open_day" = "first_open_day",
    "periodic" = "periodic",
    "instant" = "instant",
    "deferred" = "deferred",
}

export enum PaymentStatus {
    "INITIATED" = "INITIATED",
    "VALIDATED" = "VALIDATED",
    "SCHEDULED" = "SCHEDULED",
    "EXECUTED" = "EXECUTED",
    "FAILED" = "FAILED",
    "CANCELLED" = "CANCELLED",
}

export enum TransferTimingCategory {
    NEXT_OPEN_DAY = "NEXT_OPEN_DAY",
    INSTANT = "INSTANT",
    SCHEDULED = "SCHEDULED",
}

export interface InitiatePaymentPayload {
    bankId: string
    transferTimingCategory: TransferTimingCategory
    scheduledDate?: string
}

export interface BankConnectorsI {
    id: string
    name: string
    normalizedName?: string
    logoImageUrl: string
    supportedTransferTimingCategories: string[]
}

export interface PaymentI {
    id: string
    status: PaymentStatus
    updateTimestamp: string
    scheduledDate: string | null
    errorMessage: string | null
}
