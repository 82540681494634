import * as t from "io-ts"
import { optional } from "./utils"

export const WSOcrImagesIO = t.type({
    imageLinks: t.array(t.string),
})

export const WSOcrDetailsIO = t.string

export const WSPaymentWebViewIO = t.type({
    paymentId: t.string,
    webViewUrl: t.string,
})

export const WSPaymentIO = t.intersection([
    t.type({
        status: t.string,
        updateTimestamp: t.string,
    }),
    t.partial({
        scheduledDate: optional(t.string),
        errorMessage: optional(t.string),
    }),
])

export const WSCompanyOcrResultIO = t.type({
    name: optional(t.string),
    registrations: t.record(t.string, t.string),
})

export const WSPaymentDetailsOcrResultIO = t.partial({
    accountNumber: optional(t.string),
    iban: optional(t.string),
    routingNumber: optional(t.string),
    swift: optional(t.string),
})

export const WSOCRResultIO = t.intersection([
    t.type({
        buyer: WSCompanyOcrResultIO,
        supplier: WSCompanyOcrResultIO,
        paymentDetails: WSPaymentDetailsOcrResultIO,
    }),
    t.partial({
        reference: optional(t.string),
        total: optional(t.number),
        totalExcludedTaxes: optional(t.number),
        totalDiscount: optional(t.number),
        dueDate: optional(t.string),
        issueDate: optional(t.string),
        purchaseOrderNumber: optional(t.string),
    }),
])

export enum WebSocketMessageType {
    OCR_PREVIEW_IMAGES = "ocr/preview-images",
    OCR_RESULT = "ocr/result",
    OCR_DETAILS = "ocr/details",
    PAYMENT_WEBVIEW = "payment/webview",
    PAYMENT_VALIDATED = "payment/validated",
    PAYMENT_SCHEDULED = "payment/scheduled",
    PAYMENT_SUCCESS = "payment/success",
    PAYMENT_CANCEL = "payment/cancel",
    PAYMENT_FAILED = "payment/failed",
    INVOICE_MARKED_AS_PAID = "invoice/marked-as-paid",
    INVOICE_BRANDED_IMAGES_GENERATED = "invoice/branded-images-generated",
    INVOICE_PERSON_INVOLVED = "invoice/person-involved",
    ERROR_OCR_IMAGES_FAIL = "error/ocr-images-fail",
    ERROR_OCR_FAIL = "error/ocr-fail",
    ERROR_PAYMENT_FAIL = "error/payment-fail",
}

export const WSMessageDataIO = t.union([
    t.type({
        payloadType: t.literal(WebSocketMessageType.OCR_RESULT),
        payload: WSOCRResultIO,
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.OCR_PREVIEW_IMAGES),
        payload: WSOcrImagesIO,
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.OCR_DETAILS),
        payload: WSOcrDetailsIO,
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.PAYMENT_WEBVIEW),
        payload: WSPaymentWebViewIO,
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.PAYMENT_VALIDATED),
        payload: WSPaymentIO,
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.PAYMENT_SCHEDULED),
        payload: WSPaymentIO,
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.PAYMENT_SUCCESS),
        payload: WSPaymentIO,
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.PAYMENT_CANCEL),
        payload: WSPaymentIO,
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.PAYMENT_FAILED),
        payload: WSPaymentIO,
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.INVOICE_MARKED_AS_PAID),
        payload: t.string, // datetime as iso string
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.INVOICE_BRANDED_IMAGES_GENERATED),
        payload: t.array(t.string), // list of urls
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.INVOICE_PERSON_INVOLVED),
        payload: t.string, // userId
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.ERROR_OCR_IMAGES_FAIL),
        payload: t.string,
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.ERROR_OCR_FAIL),
        payload: t.string,
    }),
    t.type({
        payloadType: t.literal(WebSocketMessageType.ERROR_PAYMENT_FAIL),
        payload: t.string,
    }),
])

export type WSOcrImagesI = t.TypeOf<typeof WSOcrImagesIO>
export type WSPaymentWebViewI = t.TypeOf<typeof WSPaymentWebViewIO>
export type WSPaymentI = t.TypeOf<typeof WSPaymentIO>
export type WSCompanyOcrResultI = t.TypeOf<typeof WSCompanyOcrResultIO>
export type WSPaymentDetailsOcrResultI = t.TypeOf<typeof WSPaymentDetailsOcrResultIO>
export type WSOCRResultI = t.TypeOf<typeof WSOCRResultIO>

export type WSMessageDataI = t.TypeOf<typeof WSMessageDataIO>
