import { useCustomFieldsApi } from "~/domains/transactions/custom-fields/customFieldsApi"
import { selectPendingCustomFieldValues } from "~/domains/transactions/custom-fields/store"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { CreatePurchaseOrderDTO, PurchaseOrders } from "../../types/PurchaseOrders"
import { useCallback } from "react"
import { CreateCustomFieldPayload } from "~/domains/transactions/custom-fields/types/CreateCustomFieldPayload"
import { isDefined } from "~/utils/isDefined"

export const useSavePurchaseOrderPendingCustomFieldValues = (organizationId: OrganizationId) => {
    const user = useAppSelector(selectUser)
    const pendingCustomFieldValues = useAppSelector(selectPendingCustomFieldValues)
    const customFieldsApi = useCustomFieldsApi()

    return useCallback(
        async (
            purchaseOrder: PurchaseOrders | CreatePurchaseOrderDTO,
            updatedPurchaseOrder: PurchaseOrders
        ) => {
            try {
                const customFieldsToCreate = updatedPurchaseOrder.lines
                    .map((line, lineIndex) => {
                        const temporaryId = purchaseOrder.lines[lineIndex]?.temporaryId
                        const customFields = temporaryId
                            ? Object.values(pendingCustomFieldValues[temporaryId] ?? {})
                            : null
                        const lineId = line.id
                        if (customFields && lineId) {
                            return customFields.map(
                                (customFieldPayload): CreateCustomFieldPayload => ({
                                    ...customFieldPayload,
                                    objectId: lineId,
                                    contextId: updatedPurchaseOrder.id,
                                })
                            )
                        }
                        return null
                    })
                    .filter(isDefined)
                    .flat()
                if (customFieldsToCreate.length > 0) {
                    await customFieldsApi.batchCreateCustomFields(customFieldsToCreate)
                }
            } catch (error) {
                console.error("error")
            }
        },
        [pendingCustomFieldValues, customFieldsApi, user, organizationId]
    )
}
