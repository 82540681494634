import { useCallback, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../../hooks"
import { accountActions, selectSentDocuments, UserAndOrganization } from "../accountSlice"
import { NO_ORGANIZATION_ID, OrganizationId, SentDocumentI } from "~/types"
import { documentApi } from "~/api"
import { useGetBulkObjectsTags } from "~/domains/tags/hooks/useGetBulkObjectsTags"

export const useGetSentDocuments = (payload: UserAndOrganization, withoutTags = false) => {
    const dispatch = useAppDispatch()
    const state = useSelector(selectSentDocuments)
    const { getBulkObjectsTags } = useGetBulkObjectsTags(payload.organizationId)

    const getSentDocuments = useCallback(async () => {
        if (payload.userId && payload.organizationId) {
            try {
                dispatch(accountActions.fetchSentDocuments({ ...payload, showLoader: !state.sentDocuments.length }))
                const sentDocuments: SentDocumentI[] =
                    payload.userId && (payload.organizationId === NO_ORGANIZATION_ID || !payload.organizationId)
                        ? await documentApi.getSentDocuments(payload.userId)
                        : await documentApi.getSuppliersDocuments(payload.organizationId as OrganizationId)

                dispatch(accountActions.fetchSentDocumentsSuccess(sentDocuments))

                if (withoutTags || !sentDocuments || sentDocuments.length === 0) {
                    return
                }
                dispatch(accountActions.setSentDocumentsTagsLoading(true))

                const invoicesObjectsTags = await getBulkObjectsTags(
                    sentDocuments.map((d) => d.invoiceId),
                    true
                )
                dispatch(accountActions.setSentDocumentsTags(invoicesObjectsTags))
                if (invoicesObjectsTags) {
                    dispatch(
                        accountActions.fetchSentDocumentsSuccess(
                            sentDocuments.map((doc) => ({
                                ...doc,
                                tags: invoicesObjectsTags[doc.invoiceId] ?? [],
                            }))
                        )
                    )
                }
            } catch (error) {
                dispatch(accountActions.fetchSentDocumentsFailed(`${error}`))
            }
        }
    }, [dispatch, payload.userId, payload.organizationId])

    useEffect(() => {
        getSentDocuments()
    }, [getSentDocuments])

    return useMemo(() => state, [state.error, state.loading, state.sentDocuments])
}
