import { OrganizationId } from "~/types"
import { DocumentDataI } from "../Documents"
import { KYBType } from "~/domains/identity/features/kyb/components/KYB"

export interface DocumentByOrgaState {
    documents: DocumentDataI[]
    loading: boolean
    loadingUpload: boolean
    error: string | null
}

interface DocumentState {
    documents: DocumentDataI[]
    loading: boolean
    loadingUpload: boolean
    error: string | null

    documentsByOrganization: Record<OrganizationId, Record<KYBType, DocumentByOrgaState>>
}

const documentState: DocumentState = {
    documents: [],
    loading: false,
    loadingUpload: false,
    error: null,

    documentsByOrganization: {},
}

export default documentState
