import * as t from "io-ts"
import { OrganizationId, TeamId, UserI, UserId } from "~/types"
import { optional } from "~/types/utils"
import { Opaque } from "~/utils"

export type MessageId = Opaque<string, { readonly T: unique symbol }>

export const MessageContentTextIO = t.intersection([
    t.type({
        text: t.string,
    }),
    t.partial({
        children: optional(t.any),
    }),
])
export type MessageContentTextI = t.TypeOf<typeof MessageContentTextIO>

interface MessageContentIOType {
    type: string
    children: Array<MessageContentIOType | MessageContentTextI>
}

const MessageContentIO: t.Type<MessageContentIOType> = t.recursion("MessageContentIO", () =>
    t.type({
        type: t.string,
        children: t.array(t.union([MessageContentIO, MessageContentTextIO])),
    })
)

export const MessageViewIO = t.type({
    id: t.string,
    messageId: t.string,
    userId: t.string,
    viewed: t.boolean,
    viewedAt: t.string,
})

export const MessageIO = t.intersection([
    t.type({
        id: t.string,
        senderId: t.string,
        createdAt: t.string,
        updatedAt: t.string,
        content: t.array(MessageContentIO),
    }),
    t.partial({
        isPinned: optional(t.boolean),
        views: optional(t.array(MessageViewIO)),
    }),
])

export type MessageContentText = t.TypeOf<typeof MessageContentTextIO> & {
    type?: never
}

export enum MessageContentType {
    Paragraph = "p",
    Mention = "mention",
}

export enum MentionTypes {
    USER = "user",
    TEAM = "team",
}

export type UserMentionI = {
    type: MentionTypes.USER
    value: UserId
    label: string
    organizationId: OrganizationId
}

export type TeamMentionI = {
    type: MentionTypes.TEAM
    value: TeamId
    label: string
    organizationId: OrganizationId
}

export type MentionI = UserMentionI | TeamMentionI

export type MentionElementI = {
    type: MessageContentType.Mention
    data: MentionI
    children: MessageContentText[]
}

export type MessageContentParagraph = {
    type: MessageContentType.Paragraph
    children: (MessageContentText | MentionElementI)[]
}

export type MessageViewI = t.TypeOf<typeof MessageViewIO> & {
    messageId: MessageId
    userId: UserId
}
export type MessageI = t.TypeOf<typeof MessageIO> & {
    id: MessageId
    senderId: UserId
    views: MessageViewI[]
    content: MessageContentParagraph[]
}
export type MessageWithUserI = MessageI & { user?: UserI }
