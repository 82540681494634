import { Draft, PayloadAction } from "@reduxjs/toolkit"
import { InvoiceState } from "./invoiceState"
import { InvoiceStatus } from "~/types"

export const supplierActions = {
    markAsResolved(state: Draft<InvoiceState>) {
        state.loading = true
    },
    markAsResolvedSuccess(state: Draft<InvoiceState>) {
        state.loading = false
        if (state.invoice) {
            state.invoice.status = InvoiceStatus.CONFIRMED
        }
    },
    markAsResolvedFailed(state: Draft<InvoiceState>, action: PayloadAction<string>) {
        state.loading = false
        state.error = action.payload
    },
    patchInvoice(state: Draft<InvoiceState>) {
        state.loading = true
    },
    patchInvoiceSuccess(state: Draft<InvoiceState>) {
        state.loading = false
    },
    patchInvoiceFailed(state: Draft<InvoiceState>, action: PayloadAction<string>) {
        state.loading = false
        state.error = action.payload
    },
    addInvoice(state: Draft<InvoiceState>) {
        state.loading = true
    },
    addInvoiceSuccess(state: Draft<InvoiceState>) {
        state.loading = false
    },
    addInvoiceFailed(state: Draft<InvoiceState>, action: PayloadAction<string>) {
        state.loading = false
        state.error = action.payload
    },
}
