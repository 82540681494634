import React, { useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { Tabs } from "~/components"
import { ESGCustomFieldsTable, ESGCustomFieldsTableProps } from "./ESGCustomFieldsTable"
import { BasicTabsTabProps } from "~/components/Tabs"
import { AnalyticalItem, AnalyticsTable } from "./AnalyticsTable"
import { TagGroupI } from "~/domains/tags/types"
import { CurrencyCodes } from "~/types"
import { CustomFieldsTable } from "./CustomFieldsTable/CustomFieldsTable"

const messages = defineMessages({
    items: { id: "purchase.orders.order.items", defaultMessage: "Items" },
    esgCustomFields: { id: "purchase.orders.order.esgCustomFields", defaultMessage: "ESG" },
    analytics: { id: "purchase.orders.order.analytics", defaultMessage: "Analytics" },
    customFields: { id: "purchase.orders.order.customFields", defaultMessage: "Custom Fields" },
})

type Props<T extends AnalyticalItem> = React.PropsWithChildren &
    ESGCustomFieldsTableProps<T> & {
        renderLineTags: (line: T, tagGroupId?: string, usedTagGroups?: TagGroupI[]) => JSX.Element | null
        currency: CurrencyCodes
        hasCustomFields?: boolean
    }

enum TabsEnum {
    ITEMS,
    ANALYTICAL,
    ESG_CUSTOM_FIELDS,
    CUSTOM_FIELDS,
}

export function LinesTabs<T extends AnalyticalItem>({
    organizationId,
    children,
    currency,
    renderLineTags,
    hasCustomFields,
    ...props
}: Props<T>) {
    const { formatMessage } = useIntl()
    const [tabValue, setTabValue] = useState<TabsEnum>(TabsEnum.ITEMS)

    const tabs: BasicTabsTabProps[] = useMemo(() => {
        const tabs: BasicTabsTabProps[] = organizationId
            ? [
                  {
                      label: formatMessage(messages.items),
                      value: TabsEnum.ITEMS,
                      component: children,
                  },
                  {
                      label: formatMessage(messages.analytics),
                      value: TabsEnum.ANALYTICAL,
                      component: (
                          <AnalyticsTable
                              items={props.items}
                              currency={currency}
                              renderLineTags={renderLineTags}
                              organizationId={organizationId}
                          />
                      ),
                  },
                  {
                      label: formatMessage(messages.esgCustomFields),
                      value: TabsEnum.ESG_CUSTOM_FIELDS,
                      component: <ESGCustomFieldsTable {...props} organizationId={organizationId} />,
                  },
              ]
            : []

        if (hasCustomFields) {
            tabs.push({
                label: formatMessage(messages.customFields),
                value: TabsEnum.CUSTOM_FIELDS,
                component: <CustomFieldsTable {...props} />,
            })
        }

        return tabs
    }, [children, props, organizationId, formatMessage])

    if (!organizationId) {
        return <>{children}</>
    }

    return (
        <Tabs
            className="line-items-table"
            defaultValue={tabValue}
            setChange={(value) => setTabValue(value as TabsEnum)}
            tabs={tabs}
        />
    )
}
