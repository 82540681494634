import React from "react"
import { Button } from "~/components"
import { ViewTypeI } from "~/types"
import "./ViewSwitcherTab.scss"

interface Props {
    view: ViewTypeI | string
    labelSupplier: string
    labelBuyer: string
    actionSupplier: () => void
    actionBuyer: () => void
}

export function ViewSwitcherTab({ view, labelSupplier, labelBuyer, actionSupplier, actionBuyer }: Props) {
    return (
        <div className="view-switcher">
            <Button type="tab" onClick={actionBuyer} className={view === ViewTypeI.buyer ? "selected" : ""}>
                {labelBuyer}
            </Button>
            <Button type="tab" onClick={actionSupplier} className={view === ViewTypeI.supplier ? "selected" : ""}>
                {labelSupplier}
            </Button>
        </div>
    )
}
