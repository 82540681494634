import React, { lazy } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/core"
import {
    MY_DOCUMENTS_ROUTE,
    ORGANIZATION_TEAMS_ROUTE,
    ORGANIZATION_CONTACTS_ROUTE,
    ORGANIZATION_DETAILS_ROUTE,
    ORGANIZATION_INFO_ROUTE,
    ORGANIZATION_INTEGRATION_ROUTE,
    ORGANIZATIONS_ROUTE,
    PROFILE_ROUTE,
    ORGANIZATION_TAGS_ROUTE,
    ORGANIZATION_INVOICE_EXPORTS_ROUTE,
    ORGANIZATION_CATALOGS_ROUTE,
    ORGANIZATION_CREATE_PRODUCT_ROUTE,
    ORGANIZATION_EDIT_PRODUCT_ROUTE, ORGANIZATION_KYB_ROUTE,
} from "./routes"

const Profile = lazy(() => import("./pages/Profile").then(({ Profile }) => ({ default: Profile })))
const MyDocuments = lazy(() => import("./pages/MyDocuments").then(({ MyDocuments }) => ({ default: MyDocuments })))
const Organizations = lazy(() =>
    import("./pages/Organizations").then(({ Organizations }) => ({ default: Organizations }))
)
const OrganizationDetails = lazy(() =>
    import("./pages/OrganizationDetails").then(({ OrganizationDetails }) => ({ default: OrganizationDetails }))
)
const OrganizationIntegrations = lazy(() =>
    import("./pages/OrganizationIntegrations").then(({ OrganizationIntegrations }) => ({
        default: OrganizationIntegrations,
    }))
)
const OrganizationMembers = lazy(() =>
    import("./pages/OrganizationMembers").then(({ OrganizationMembers }) => ({ default: OrganizationMembers }))
)
const OrganizationTeams = lazy(() =>
    import("./pages/OrganizationTeams").then(({ OrganizationTeams }) => ({ default: OrganizationTeams }))
)
const OrganizationTags = lazy(() =>
    import("./pages/OrganizationTags").then(({ OrganizationTags }) => ({ default: OrganizationTags }))
)
const OrganizationInvoiceExports = lazy(() =>
    import("./pages/OrganizationInvoiceExports").then(({ OrganizationInvoiceExports }) => ({
        default: OrganizationInvoiceExports,
    }))
)
const OrganizationCatalog = lazy(() =>
    import("./pages/OrganizationCatalog").then(({ OrganizationCatalog }) => ({
        default: OrganizationCatalog,
    }))
)
const OrganizationCreateProduct = lazy(() =>
    import("./pages/OrganizationCreateProduct").then(({ OrganizationCreateProduct }) => ({
        default: OrganizationCreateProduct,
    }))
)
const OrganizationEditProduct = lazy(() =>
    import("./pages/OrganizationEditProduct").then(({ OrganizationEditProduct }) => ({
        default: OrganizationEditProduct,
    }))
)
const OrganizationKYB = lazy(() =>
    import("./pages/OrganizationKYB").then(({ OrganizationKYB }) => ({
        default: OrganizationKYB,
    }))
)

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="profile_route">
            <Route path={PROFILE_ROUTE} element={<Profile />} />
            <Route path={ORGANIZATION_INFO_ROUTE} element={<OrganizationDetails />} />
            <Route path={ORGANIZATION_INTEGRATION_ROUTE} element={<OrganizationIntegrations />} />
            <Route path={ORGANIZATION_CONTACTS_ROUTE} element={<OrganizationMembers />} />
            <Route path={ORGANIZATION_TEAMS_ROUTE} element={<OrganizationTeams />} />
            <Route path={ORGANIZATION_TAGS_ROUTE} element={<OrganizationTags />} />
            <Route path={ORGANIZATION_CREATE_PRODUCT_ROUTE} element={<OrganizationCreateProduct />} />
            <Route path={ORGANIZATION_EDIT_PRODUCT_ROUTE} element={<OrganizationEditProduct />} />
            <Route path={ORGANIZATION_CATALOGS_ROUTE} element={<OrganizationCatalog />} />
            <Route path={ORGANIZATION_DETAILS_ROUTE} element={<OrganizationDetails />} />
            <Route path={ORGANIZATIONS_ROUTE} element={<Organizations />} />
            <Route path={MY_DOCUMENTS_ROUTE} element={<MyDocuments />} />
            <Route path={ORGANIZATION_KYB_ROUTE} element={<OrganizationKYB />} />
            <Route path={ORGANIZATION_INVOICE_EXPORTS_ROUTE} element={<OrganizationInvoiceExports />} />
        </Route>,
    ],
}
