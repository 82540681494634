import { useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { partnerApi } from "~/api"
import { OrganizationId, ViewTypeI } from "~/types"
import {
    PartnershipTypeOption,
    PartnershipType,
    PartnersDataI,
    SourceType,
} from "~/domains/transactions/book-of-relations/types"
import { toast } from "react-toastify"
import { bookOfRelationsActions } from "../bookOfRelationsSlice"

const messages = defineMessages({
    removed: {
        id: "partners.bookofrelations.delete.removed",
        defaultMessage: "Partner successfully removed.",
    },
    errorRemove: {
        id: "partners.bookofrelations.delete.errorRemove",
        defaultMessage: "We encountered an error removing the partner.",
    },
})

const partnershipTypeMap = {
    [PartnershipTypeOption.BUYER]: PartnershipType.BUYER,
    [PartnershipTypeOption.SUPPLIER]: PartnershipType.SUPPLIER,
    [PartnershipTypeOption.BOTH]: undefined,
}

export const useDeletePartner = (organizationId: OrganizationId) => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()

    const deletePartner = useCallback(
        (partner: PartnersDataI, side?: PartnershipTypeOption) => {
            const partnershipSide = side ? partnershipTypeMap[side] : undefined

            if (partner.organizationId) {
                dispatch(bookOfRelationsActions.deletePartner())
                partnerApi
                    .deletePartner(organizationId, partner.organizationId, partnershipSide)
                    .then(() => {
                        dispatch(
                            bookOfRelationsActions.deletePartnerSuccess({
                                organizationId: partner.organizationId,
                                partnershipType: side ?? PartnershipTypeOption.BOTH,
                            })
                        )
                        toast.success(formatMessage(messages.removed))
                    })
                    .catch((error) => {
                        console.error(`${error}`)
                        dispatch(bookOfRelationsActions.deletePartnerFailed(error))
                        toast.error(formatMessage(messages.errorRemove))
                    })
            }
        },
        [dispatch, organizationId, formatMessage]
    )

    return deletePartner
}

export const checkCanBeDeleted = (partnership: PartnersDataI, side: ViewTypeI): boolean => {
    if (side === ViewTypeI.supplier) {
        return partnership.supplier?.source === SourceType.MANUAL
    } else if (side === ViewTypeI.buyer) {
        return partnership.buyer?.source === SourceType.MANUAL
    }
    return false
}
