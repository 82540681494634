import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { isDefined } from "~/utils/isDefined"
import { purchaseRequestApi } from "../../api"
import { purchaseRequestsActions } from "../purchaseRequestsSlice"

const messages = defineMessages({
    purchaseRequestDeleted: {
        id: "purchase.requests.purchaseRequestDeleted",
        defaultMessage: "Your purchase request has been successfully deleted.",
    },
    purchaseRequestsDeleted: {
        id: "purchase.requests.purchaseRequestsDeleted",
        defaultMessage: "Your purchase requests has been successfully deleted.",
    },
    unableToDeletePurchaseRequest: {
        id: "purchase.requests.unableToDeletePurchaseRequest",
        defaultMessage: "An error occured: your purchase request could not be deleted.",
    },
    unableToDeletePurchaseRequests: {
        id: "purchase.requests.unableToDeletePurchaseRequests",
        defaultMessage: "An error occured: your purchase requests could not be deleted.",
    },
    unableToDeleteSomePurchaseRequests: {
        id: "purchase.requests.unableToDeleteSomePurchaseRequests",
        defaultMessage: "An error occured: {count} purchase request{s} could not be deleted.",
    },
})

export const getPurchaseRequestId = (
    r: PromiseSettledResult<unknown>,
    index: number,
    purchaseRequestIds: string[]
): string | undefined => (r.status === "rejected" ? undefined : purchaseRequestIds[index])

export const useDeletePurchaseRequests = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    return async (purchaseRequestIds: string[], organizationId: OrganizationId) => {
        const results = await Promise.allSettled(
            purchaseRequestIds.map((purchaseRequestId) => purchaseRequestApi.delete(organizationId, purchaseRequestId))
        )
        const successDeleted = results
            .map((r, index) => getPurchaseRequestId(r, index, purchaseRequestIds))
            .filter(isDefined)
        const failDeletedLength = results.length - successDeleted.length
        dispatch(purchaseRequestsActions.deletePurchaseRequests(successDeleted))
        if (failDeletedLength > 0) {
            toast.error(
                formatMessage(
                    failDeletedLength === results.length
                        ? messages.unableToDeletePurchaseRequests
                        : messages.unableToDeleteSomePurchaseRequests,
                    { count: failDeletedLength, s: failDeletedLength > 1 ? "s" : "" }
                )
            )
        } else {
            toast.success(
                formatMessage(
                    purchaseRequestIds.length === 1 ? messages.purchaseRequestDeleted : messages.purchaseRequestsDeleted
                )
            )
        }
        return true
    }
}
