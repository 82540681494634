import React, { Dispatch, PropsWithChildren, SetStateAction, createContext, useState } from "react"

export enum CallbackOutputType {
    STRING = "string",
    NUMBER = "number",
    DATE = "date",
}

type CallbackType<T> = Dispatch<SetStateAction<T>>

type Callback =
    | {
          function: CallbackType<string>
          type: CallbackOutputType.STRING
      }
    | {
          function: CallbackType<number>
          type: CallbackOutputType.NUMBER
      }

interface OcrExtractContextI {
    callback: Callback | null
    setCallback: Dispatch<SetStateAction<Callback | null>>
}

export const OcrExtractContext = createContext<OcrExtractContextI>({
    callback: null,
    setCallback: () => {
        return null
    },
})

export const WithOcrExtractContext: React.FC<PropsWithChildren> = ({ children }) => {
    const [callback, setCallback] = useState<Callback | null>(null)
    return <OcrExtractContext.Provider value={{ callback, setCallback }}>{children}</OcrExtractContext.Provider>
}
