import { ThemeProvider } from "@mui/material"
import React from "react"
import { Provider } from "react-redux"
// import Segment from 'react-segment-analytics';
// import { ConnectedRouter } from 'connected-react-router';
import { Loader, Theme } from "~/core"
import { LocalesProvider } from "~/i18n/LocalesProvider"
import RouterLayout from "~/core/RouterLayout"
import { IntercomProvider } from "react-use-intercom"
import { BrowserRouter } from "react-router-dom"
import buyer from "~/features/buyer"
import supplier from "~/features/supplier"
import account from "~/features/account"
import workflow from "~/features/workflow"
import partners from "~/domains/transactions/book-of-relations"
import ocr from "~/features/ocr"
import purchaseRequests from "~/domains/transactions/purchase-requests"
import purchaseOrders from "~/domains/transactions/purchase-orders"
import rolesPermissions from "~/domains/identity/features/roles-permissions"
import budget from "~/features/budget"
import prompt from "~/domains/copilot/prompt"
import ErrorBoundary from "./utils/ErrorBoundary"
import { AppCrashFallback } from "./utils/AppCrashFallback"
import { PersistGate } from "redux-persist/integration/react"
import { WithApiContext } from "./domains/common/apiClient"
import emails from "./domains/transactions/emails"
import { persistor, store } from "~/store"
import { ToastContainer } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"
import "./assets/css/App.scss"

import messages_en from "./lang/en.json"
import messages_fr from "./lang/fr.json"
import messages_ar from "~/lang/ar.json"
import messages_ch from "~/lang/ch.json"
import messages_de from "~/lang/de.json"

const messages = {
    fr: messages_fr,
    en: messages_en,
    ar: messages_ar,
    ch: messages_ch,
    de: messages_de,
}

const modules = [
    buyer,
    supplier,
    account,
    workflow,
    partners,
    ocr,
    purchaseRequests,
    purchaseOrders,
    rolesPermissions,
    budget,
    prompt,
    emails,
]
const modulesRoutes = modules.reduce(
    (routes: React.ReactElement[], module) => routes.concat(module.routes as React.ReactElement[]),
    []
)

const ENV = import.meta.env.VITE_ENV
const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID

export default function App() {
    return (
        <ErrorBoundary FallbackComponent={AppCrashFallback}>
            <ThemeProvider theme={Theme}>
                <Provider store={store}>
                    <PersistGate loading={<Loader />} persistor={persistor}>
                        <LocalesProvider messages={messages} locale={"en"}>
                            {/* <Segment writeKey={SEGMENT_KEY}> */}
                            <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={ENV !== "development"}>
                                <WithApiContext>
                                    <div className="App">
                                        <BrowserRouter>
                                            <RouterLayout modulesRoutes={modulesRoutes} modulesNavItems={[]} />
                                        </BrowserRouter>
                                        <ToastContainer
                                            position="top-right"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                        />
                                        {ENV === "production" ? null : <div id="environment">{ENV}</div>}
                                    </div>
                                </WithApiContext>
                            </IntercomProvider>
                            {/* </Segment> */}
                        </LocalesProvider>
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        </ErrorBoundary>
    )
}
