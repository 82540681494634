import { styled } from "@mui/material"
import React from "react"

const ProgressBarContainer = styled("div")({
    display: "block",
    height: "4px",
    backgroundColor: "#EBEEF5",
    borderRadius: "2px",
    position: "relative",
    overflow: "hidden",
})

interface ProgressBarGaugeProps {
    percentage: number
}

const ProgressBarGauge = styled("div")<ProgressBarGaugeProps>(({ theme, percentage }) => ({
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "2px",
    width: `${percentage}%`,
}))

const ProgressBarLabel = styled("div")({
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#909399",
})

interface Props extends React.PropsWithChildren {
    progress: number
}

export const LinearProgressBar: React.FC<Props> = ({ progress, children }) => {
    const percentage = Math.max(Math.min(progress * 100, 100), 0)
    const progressBar = (
        <ProgressBarContainer>
            <ProgressBarGauge percentage={percentage} />
        </ProgressBarContainer>
    )
    if (children) {
        return (
            <>
                <ProgressBarLabel>{children}</ProgressBarLabel>
                {progressBar}
            </>
        )
    }
    return progressBar
}
