import { useCallback, useState } from "react"
import { OrganizationId, ViewTypeI } from "~/types"
import { toast } from "react-toastify"
import { defineMessages, useIntl } from "react-intl"
import { PurchaseOrderStatus, PurchaseOrderStatusUpdate } from "../../types/PurchaseOrders"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api/purchaseOrdersApi"
import { useAppDispatch } from "~/store/hooks"
import { purchaseOrdersActions } from "../purchaseOrdersSlice"

const messages = defineMessages({
    approved: {
        id: "purchase.orders.order.statusUpdate.approved",
        defaultMessage: "Purchase order approved",
    },
    submitted: {
        id: "purchase.orders.order.statusUpdate.submitted",
        defaultMessage: "Purchase order submitted",
    },
    rejected: {
        id: "purchase.orders.order.statusUpdate.rejected",
        defaultMessage: "Purchase order rejected",
    },
    sent: {
        id: "purchase.orders.order.statusUpdate.sent",
        defaultMessage: "Purchase order sent",
    },
    unSent: {
        id: "purchase.orders.order.statusUpdate.unSent",
        defaultMessage: "Purchase order un-sent",
    },
    dispatched: {
        id: "purchase.orders.order.statusUpdate.dispatched",
        defaultMessage: "Purchase order dispatched",
    },
    delivered: {
        id: "purchase.orders.order.statusUpdate.delivered",
        defaultMessage: "Purchase order delivered",
    },
    received: {
        id: "purchase.orders.order.statusUpdate.received",
        defaultMessage: "Purchase order received",
    },
    error: {
        id: "purchase.orders.order.statusUpdate.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const useUpdatePurchaseOrderStatus = (purchaseOrderId: string, viewType: ViewTypeI) => {
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const dispatch = useAppDispatch()

    const updatePOStatus = useCallback(
        async (
            organizationId: OrganizationId,
            newStatus: PurchaseOrderStatusUpdate,
            currentStatus?: PurchaseOrderStatus,
            note?: string,
            poId?: string
        ) => {
            try {
                setLoading(true)
                let message = ""
                switch (newStatus) {
                    case PurchaseOrderStatusUpdate.APPROVE:
                        await purchaseOrdersApi.approve(organizationId, purchaseOrderId)
                        dispatch(
                            purchaseOrdersActions.updateData({
                                field: "status",
                                value:
                                    viewType === ViewTypeI.buyer
                                        ? PurchaseOrderStatus.AWAITING_SUPPLIER_APPROVAL
                                        : PurchaseOrderStatus.IN_PREPARATION,
                            })
                        )
                        message = "approved"
                        break
                    case PurchaseOrderStatusUpdate.SUBMIT:
                        await purchaseOrdersApi.submit(organizationId, poId ?? purchaseOrderId)
                        dispatch(
                            purchaseOrdersActions.updateData({
                                field: "status",
                                value:
                                    viewType === ViewTypeI.buyer
                                        ? PurchaseOrderStatus.SUBMITTED_BUYER
                                        : PurchaseOrderStatus.SUBMITTED_SUPPLIER,
                            })
                        )
                        message = "submitted"
                        break
                    case PurchaseOrderStatusUpdate.REJECT:
                        await purchaseOrdersApi.reject(organizationId, purchaseOrderId)
                        // TODO: which status
                        message = "rejected"
                        break
                    case PurchaseOrderStatusUpdate.SEND: {
                        await purchaseOrdersApi.send(organizationId, purchaseOrderId)
                        const status =
                            currentStatus === PurchaseOrderStatus.SUBMITTED_BUYER
                                ? PurchaseOrderStatus.AWAITING_SUPPLIER_APPROVAL
                                : currentStatus === PurchaseOrderStatus.SUBMITTED_SUPPLIER
                                ? PurchaseOrderStatus.AWAITING_BUYER_APPROVAL
                                : PurchaseOrderStatus.OUT_FOR_DELIVERY
                        dispatch(
                            purchaseOrdersActions.updateData({
                                field: "status",
                                value: status,
                            })
                        )
                        message = "sent"
                        break
                    }
                    case PurchaseOrderStatusUpdate.UN_SEND:
                        await purchaseOrdersApi.unSend(organizationId, purchaseOrderId)
                        dispatch(
                            purchaseOrdersActions.updateData({
                                field: "status",
                                value: PurchaseOrderStatus.IN_PREPARATION,
                            })
                        )
                        message = "unSent"
                        break
                    case PurchaseOrderStatusUpdate.DISPATCHED:
                        await purchaseOrdersApi.dispatched(organizationId, purchaseOrderId, note)
                        dispatch(
                            purchaseOrdersActions.updateData({
                                field: "status",
                                value: PurchaseOrderStatus.OUT_FOR_DELIVERY,
                            })
                        )
                        message = "dispatched"
                        break
                    case PurchaseOrderStatusUpdate.DELIVERED:
                        await purchaseOrdersApi.delivered(organizationId, purchaseOrderId)
                        dispatch(
                            purchaseOrdersActions.updateData({
                                field: "status",
                                value: PurchaseOrderStatus.DELIVERED,
                            })
                        )
                        message = "delivered"
                        break
                    case PurchaseOrderStatusUpdate.RECEIVED:
                        await purchaseOrdersApi.received(organizationId, purchaseOrderId)
                        dispatch(
                            purchaseOrdersActions.updateData({
                                field: "status",
                                value: PurchaseOrderStatus.RECEIVED,
                            })
                        )
                        message = "received"
                        break
                }
                setLoading(false)

                return toast.success(formatMessage(messages[message]))
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                toast.error(formatMessage(messages.error))
                throw e
            }
        },
        [purchaseOrderId, viewType, dispatch]
    )

    return {
        updatePOStatus,
        error,
        loading,
    }
}
