export const getCookie = (name: string) => {
    return document.cookie.split(";").some((c) => {
        return c.trim().startsWith(name + "=")
    })
}

export const deleteCookie = (name: string) => {
    if (getCookie(name)) {
        document.cookie = name + "=" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT"
    }
}

export const clearCookies = () => {
    const Cookies = document.cookie.split(";")
    for (let i = 0; i < Cookies.length; i++) {
        document.cookie = Cookies[i] + "=; expires=" + new Date(0).toUTCString()
    }
}

export const getJWTCookie = () => {
    return document.cookie
        .split("; ")
        .find((row) => row.startsWith("jwt"))
        ?.split("=")[1]
}

export const getLanguageCookie = () => {
    return document.cookie
        .split("; ")
        .find((row) => row.startsWith("language"))
        ?.split("=")[1]
}
