import React from "react"
import "./UserAvatar.scss"

interface UserAvatarProps {
    email?: string | null
}

export const UserAvatar = ({ email }: UserAvatarProps) => {
    let hash = 0
    if (email) {
        for (let i = 0; i < email.length; i++) {
            hash = email.charCodeAt(i) + ((hash << 5) - hash)
        }
    }

    const rgb = [hash & 0xff, (hash >> 8) & 0xff, (hash >> 16) & 0xff]

    const style = email ? { backgroundColor: `rgb(${rgb.join(",")})` } : { backgroundColor: "#7B61FF" }

    return (
        <div className="user-avatar" style={style}>
            {<span>{email?.charAt(0)}</span>}
        </div>
    )
}

export default UserAvatar
