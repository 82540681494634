import {
    WorkflowFilterListOperation,
    // WorkflowFilterDateOperation,
    WorkflowFilterNumberOperation,
    WorkflowFilterOperation,
    WorkflowFilterReferenceOperation,
    WorkflowFilterStringOperation,
    WorkflowFilterTagsOperation,
    WorkflowFilterUnknownOperation,
} from "~/features/workflow/types"

const stringOperationsParameter: Record<WorkflowFilterStringOperation, boolean> = {
    [WorkflowFilterStringOperation.EQUAL]: true,
    [WorkflowFilterStringOperation.NOT_EQUAL]: true,
}

const listOperationsParameter: Record<WorkflowFilterListOperation, boolean> = {
    [WorkflowFilterListOperation.CONTAINS]: true,
    [WorkflowFilterListOperation.DOES_NOT_CONTAIN]: true,
}

const referenceOperationsParameter: Record<WorkflowFilterReferenceOperation, boolean> = {
    [WorkflowFilterReferenceOperation.IS]: true,
    [WorkflowFilterReferenceOperation.IS_NOT]: true,
}

const tagsOpenationsParameter: Record<WorkflowFilterTagsOperation, boolean> = {
    [WorkflowFilterTagsOperation.CONTAINS_ANY_OF]: true,
    [WorkflowFilterTagsOperation.CONTAINS_NONE_OF]: true,
    [WorkflowFilterTagsOperation.CONTAINS_ALL_OF]: true,
}

const numberOperationsParameter: Record<WorkflowFilterNumberOperation, boolean> = {
    [WorkflowFilterNumberOperation.EQUAL]: true,
    [WorkflowFilterNumberOperation.GREATER_THAN_OR_EQUAL_TO]: true,
    [WorkflowFilterNumberOperation.LESS_THAN_OR_EQUAL_TO]: true,
    [WorkflowFilterNumberOperation.LESS_THAN]: true,
    [WorkflowFilterNumberOperation.GREATER_THAN]: true,
    [WorkflowFilterNumberOperation.DOES_NOT_EQUAL]: true,
}

// const dateOperationsParameter: Record<WorkflowFilterDateOperation, boolean> = {
//     [WorkflowFilterDateOperation.OLDER_THAN]: true,
//     [WorkflowFilterDateOperation.WEEKDAY_IS]: true,
// }

const unknownOperationsParameter: Record<WorkflowFilterUnknownOperation, boolean> = {
    [WorkflowFilterUnknownOperation.UNKNOWN]: true,
}

const operationsHasParameter = {
    ...stringOperationsParameter,
    ...listOperationsParameter,
    ...referenceOperationsParameter,
    ...tagsOpenationsParameter,
    ...numberOperationsParameter,
    // ...dateOperationsParameter,
    ...unknownOperationsParameter,
}

export const getOperationParameters = (operation: WorkflowFilterOperation): boolean => operationsHasParameter[operation]
