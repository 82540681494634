import { UserProvisionAndJoinOrganizationI, UserProvisionI, userApi } from "~/api"
import { ProvisionnedUserI } from "~/types"

export async function getOrCreateUserByEmail(userProvision: UserProvisionI): Promise<ProvisionnedUserI> {
    return await userApi.provisionUserAccount(userProvision)
}

export async function getOrCreateUserByEmailAndJoinOrganization(
    userProvision: UserProvisionAndJoinOrganizationI
): Promise<ProvisionnedUserI> {
    return await userApi.provisionUserAccountAndJoinOrganization(userProvision)
}
