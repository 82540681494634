import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "~/store"
import { WorkflowState } from "./workflowState"
import { OrganizationId, UserId } from "~/types"
import {
    InvoiceWorkflowCheckI,
    ApiWorkflowI,
    ApiWorkflowId,
    PurchaseRequestWorkflowCheckI,
} from "~/features/workflow/types/ApiWorkflow"

const initialState: WorkflowState = {
    workflowsFilter: "",
    workflowsOrganizationId: null,
    fetchingWorkflows: false,
    loading: false,
    error: null,

    purchaseRequestWorkflowCheck: {},
    invoiceWorkflowCheck: {},
}

const workflowSlice = createSlice({
    name: "workflow",
    initialState: initialState,
    reducers: {
        setFilter(state, action: PayloadAction<string>) {
            state.workflowsFilter = action.payload
        },
        fetchingWorkflows(state, action: PayloadAction<OrganizationId>) {
            state.workflowsOrganizationId = action.payload
            state.fetchingWorkflows = true
            state.errorFetchingWorkflows = undefined
        },
        fetchingWorkflowsSuccess(state, action: PayloadAction<ApiWorkflowI[]>) {
            state.fetchingWorkflows = false
            state.workflows = action.payload
        },
        fetchingWorkflowsError(state, action: PayloadAction<string>) {
            state.fetchingWorkflows = false
            state.errorFetchingWorkflows = action.payload
        },
        addWorkflow(state, action: PayloadAction<ApiWorkflowI>) {
            state.workflows = [action.payload, ...(state.workflows ?? [])]
        },
        updateWorkflow(state, action: PayloadAction<ApiWorkflowI>) {
            state.loading = false
            if (state.workflows) {
                const index = state.workflows.findIndex((t) => t.id === action.payload.id)
                if (index !== -1) {
                    state.workflows[index] = action.payload
                }
            }
        },
        setWorkflowEnabled(state, action: PayloadAction<{ workflowId: ApiWorkflowId; enabled: boolean }>) {
            if (state.workflows) {
                state.workflows = state.workflows.map((purchaseRequestWorkflow) => {
                    if (purchaseRequestWorkflow.workflowId === action.payload.workflowId) {
                        return {
                            ...purchaseRequestWorkflow,
                            enabled: action.payload.enabled,
                        }
                    }
                    return purchaseRequestWorkflow
                })
            }
        },
        deletingWorkflow(state) {
            state.loading = true
        },
        deletingWorkflowSuccess(state, action: PayloadAction<ApiWorkflowId>) {
            state.loading = false
            state.error = null
            state.workflows = state.workflows?.filter((workflow) => workflow.workflowId !== action.payload)
        },
        deletingWorkflowFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        updatingWorkflow(state) {
            state.loading = true
        },
        updatingWorkflowFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        setPurchaseRequestWorkflowChecks(
            state,
            {
                payload: { purchaseRequestId, check: checks },
            }: PayloadAction<{ purchaseRequestId: string; check: PurchaseRequestWorkflowCheckI }>
        ) {
            state.purchaseRequestWorkflowCheck[purchaseRequestId] = checks
        },
        setInvoiceWorkflowChecks(
            state,
            { payload: { invoiceId, check } }: PayloadAction<{ invoiceId: string; check: InvoiceWorkflowCheckI }>
        ) {
            state.invoiceWorkflowCheck[invoiceId] = check
        },
        approveInvoice(
            state,
            { payload: { invoiceId, approver } }: PayloadAction<{ invoiceId: string; approver: UserId }>
        ) {
            const invoiceWorkflowCheck = state.invoiceWorkflowCheck[invoiceId]
            if (invoiceWorkflowCheck) {
                state.invoiceWorkflowCheck[invoiceId] = {
                    ...invoiceWorkflowCheck,
                    approvers: [...invoiceWorkflowCheck.approvers, approver],
                }
            }
        },
        refuseInvoice(
            state,
            { payload: { invoiceId, refuser } }: PayloadAction<{ invoiceId: string; refuser: UserId }>
        ) {
            const invoiceWorkflowCheck = state.invoiceWorkflowCheck[invoiceId]
            if (invoiceWorkflowCheck) {
                state.invoiceWorkflowCheck[invoiceId] = {
                    ...invoiceWorkflowCheck,
                    refusers: [...invoiceWorkflowCheck.refusers, refuser],
                }
            }
        },
        approvePurchaseRequest(
            state,
            { payload: { purchaseRequestId, approver } }: PayloadAction<{ purchaseRequestId: string; approver: UserId }>
        ) {
            const purchaseRequestWorkflowCheck = state.purchaseRequestWorkflowCheck[purchaseRequestId]
            if (purchaseRequestWorkflowCheck) {
                state.purchaseRequestWorkflowCheck[purchaseRequestId] = {
                    ...purchaseRequestWorkflowCheck,
                    purchaseRequestCheckStatus: {
                        ...purchaseRequestWorkflowCheck.purchaseRequestCheckStatus,
                        approvers: [...purchaseRequestWorkflowCheck.purchaseRequestCheckStatus.approvers, approver],
                    },
                }
            }
        },
        refusePurchaseRequest(
            state,
            { payload: { purchaseRequestId, refuser } }: PayloadAction<{ purchaseRequestId: string; refuser: UserId }>
        ) {
            const purchaseRequestWorkflowCheck = state.purchaseRequestWorkflowCheck[purchaseRequestId]
            if (purchaseRequestWorkflowCheck) {
                state.purchaseRequestWorkflowCheck[purchaseRequestId] = {
                    ...purchaseRequestWorkflowCheck,
                    purchaseRequestCheckStatus: {
                        ...purchaseRequestWorkflowCheck.purchaseRequestCheckStatus,
                        refusers: [...purchaseRequestWorkflowCheck.purchaseRequestCheckStatus.refusers, refuser],
                    },
                }
            }
        },
        approvePurchaseRequestLine(
            state,
            {
                payload: { purchaseRequestId, lineId, approver },
            }: PayloadAction<{ purchaseRequestId: string; lineId: string; approver: UserId }>
        ) {
            const purchaseRequestWorkflowCheck = state.purchaseRequestWorkflowCheck[purchaseRequestId]
            if (purchaseRequestWorkflowCheck) {
                state.purchaseRequestWorkflowCheck[purchaseRequestId] = {
                    ...purchaseRequestWorkflowCheck,
                    lineCheckStatuses: purchaseRequestWorkflowCheck.lineCheckStatuses.map((lineCheckStatus) => {
                        if (
                            lineCheckStatus.lineId === lineId &&
                            lineCheckStatus.checks.some((check) =>
                                check.reviewers.map((reviewer) => reviewer.id).includes(approver)
                            )
                        ) {
                            return {
                                ...lineCheckStatus,
                                approvers: [...lineCheckStatus.approvers, approver],
                            }
                        }
                        return lineCheckStatus
                    }),
                }
            }
        },
        refusePurchaseRequestLine(
            state,
            {
                payload: { purchaseRequestId, lineId, refuser },
            }: PayloadAction<{ purchaseRequestId: string; lineId: string; refuser: UserId }>
        ) {
            const purchaseRequestWorkflowCheck = state.purchaseRequestWorkflowCheck[purchaseRequestId]
            if (purchaseRequestWorkflowCheck) {
                state.purchaseRequestWorkflowCheck[purchaseRequestId] = {
                    ...purchaseRequestWorkflowCheck,
                    lineCheckStatuses: purchaseRequestWorkflowCheck.lineCheckStatuses.map((lineCheckStatus) => {
                        if (
                            lineCheckStatus.lineId === lineId &&
                            lineCheckStatus.checks.some((check) =>
                                check.reviewers.map((reviewer) => reviewer.id).includes(refuser)
                            )
                        ) {
                            return {
                                ...lineCheckStatus,
                                refusers: [...lineCheckStatus.approvers, refuser],
                            }
                        }
                        return lineCheckStatus
                    }),
                }
            }
        },
        setSelectedNodeForDeletion(state, { payload: nodeId }: PayloadAction<string | undefined>) {
            state.selectedNodeForDeletion = nodeId
        },
        setSelectedNodeForInsertionBefore(state, { payload: nodeId }: PayloadAction<string | undefined>) {
            state.selectedNodeForInsertionBefore = nodeId
        },
    },
})

export const workflowActions = workflowSlice.actions

// // Selectors
export const selectWorkflowFilter = (state: RootState) => state.workflow.workflowsFilter
export const selectWorkflowOrganizationId = (state: RootState) => state.workflow.workflowsOrganizationId
export const selectFetchingWorkflows = (state: RootState) => state.workflow.fetchingWorkflows
export const selectErrorFetchingWorkflows = (state: RootState) => state.workflow.errorFetchingWorkflows
export const selectWorkflows = (state: RootState) => state.workflow.workflows
export const selectLoadingWorkflow = (state: RootState) => state.workflow.loading
export const selectPurchaseRequestWorkflowCheck = (state: RootState) => state.workflow.purchaseRequestWorkflowCheck
export const selectInvoiceWorkflowCheck = (state: RootState) => state.workflow.invoiceWorkflowCheck
export const selectSelectedNodeForDeletion = (state: RootState) => state.workflow.selectedNodeForDeletion
export const selectSelectedNodeForInsertionBefore = (state: RootState) => state.workflow.selectedNodeForInsertionBefore

// // Reducer
const workflowReducer = workflowSlice.reducer

export default workflowReducer
