import React, { useCallback, useState } from "react"
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import { ChevronDown, MoreHorizontal } from "react-feather"
import { Button } from "~/components"
import "./ActionsMenu.scss"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"
import { useIntl } from "react-intl"

export type Actions<T> = {
    label: string
    action: ((dataType: T) => void) | (() => void)
}

type Props<T> = {
    actions: Actions<T>[]
    dataType?: T
    labelButton?: string
    disabled?: boolean
}

const renderButton = (
    handleClick: (event: React.MouseEvent<HTMLButtonElement & MouseEvent>) => void,
    disabled?: boolean,
    labelButton?: string
) => {
    if (labelButton) {
        return (
            <Button type="primary" onClick={handleClick} className="menu-actions-icon" disabled={disabled}>
                {labelButton}
                <ChevronDown size={18} className="svg-white" />
            </Button>
        )
    }

    return (
        <IconButton onClick={handleClick} className="menu-actions-icon" disabled={disabled}>
            <MoreHorizontal size={18} className="svg-grey" />
        </IconButton>
    )
}

export const ActionsMenu = <T,>({ dataType, actions, labelButton, disabled }: Props<T>) => {
    const { formatMessage } = useIntl()
    const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement & MouseEvent>()
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement & MouseEvent>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.stopPropagation()
        setAnchorEl(undefined)
    }

    const handleDataAction = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        actionExec: (dataType: T) => void,
        dataType: T
    ) => {
        event.stopPropagation()
        actionExec(dataType)
        handleClose(event)
    }

    const handleNoDataAction = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, actionExec: () => void) => {
        event.stopPropagation()
        actionExec()
        handleClose(event)
    }

    const handleMenuItemClick = useCallback(
        (event: React.MouseEvent<HTMLLIElement, MouseEvent>, item: Actions<T>) => {
            return dataType
                ? handleDataAction(event, item.action, dataType)
                : handleNoDataAction(event, item.action as () => void)
        },
        [dataType]
    )

    const button = renderButton(handleClick, disabled, labelButton)

    if (!actions.length) return null

    return (
        <>
            {disabled ? (
                <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                    <div>{button}</div>
                </Tooltip>
            ) : (
                button
            )}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="menu-actions"
                style={{
                    marginTop: "4px",
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {actions.map((item, key) => (
                    <MenuItem key={key} onClick={(event) => handleMenuItemClick(event, item)}>
                        <span className={labelButton ? "menu-items-primary" : "menu-items-grey"}>{item.label}</span>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
