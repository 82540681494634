import React, { Dispatch, SetStateAction, useRef, useState } from "react"
import * as Sentry from "@sentry/browser"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { PURCHASE_REQUEST_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import { Button } from "~/components"
import { Grid } from "@mui/material"
import { OrganizationId } from "~/types"
import {
    useCreatePurchaseRequest,
    useUpdatePurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/store/hooks"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import {
    purchaseRequestsActions,
    selectPurchaseRequest,
} from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { toast } from "react-toastify"
import { PurchaseRequestStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { DraftDocumentI, ObjectId, DocumentObjectType } from "~/components/UploadDocument/Documents"
import { useUploadDocument } from "~/components/UploadDocument/hooks"
import { ConfirmModal } from "~/components/ConfirmModal"

const messages = defineMessages({
    save: {
        id: "purchase.requests.request.header.actions.save",
        defaultMessage: "Save",
    },
    send: {
        id: "purchase.requests.request.header.actions.send",
        defaultMessage: "Submit",
    },
    errorDescription: {
        id: "purchase.requests.request.header.actions.errorDescription",
        defaultMessage: "The description is mandatory",
    },
    errorItems: {
        id: "purchase.requests.request.header.actions.errorLines",
        defaultMessage: "You must add at least one item",
    },
    errorCreatingPR: {
        id: "purchase.requests.request.header.actions.errorCreatingPR",
        defaultMessage: "An error occurred while creating this purchase request.",
    },
    modalConfirmTitle: {
        id: "purchase.requests.request.header.modalConfirmTitle",
        defaultMessage: "Warning",
    },
    amountEqual0: {
        id: "purchase.requests.request.header.amountEqualZero",
        defaultMessage: "The amount of the PR is 0. Do you want to continue?",
    },
})

interface PropsPRActions {
    organizationId: OrganizationId
    draftDocuments?: DraftDocumentI[]
    setDraftDocuments?: Dispatch<SetStateAction<DraftDocumentI[]>>
}

export function ActionsHeaderCreate({ organizationId, draftDocuments, setDraftDocuments }: PropsPRActions) {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const PR = useAppSelector(selectPurchaseRequest)
    const { createPR } = useCreatePurchaseRequest(organizationId)
    const { uploadDocument } = useUploadDocument(organizationId)
    const { updatePRStatus /* , error, loading */ } = useUpdatePurchaseRequestStatus(organizationId, PR.id)
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
    const shouldSubmitRef = useRef<boolean>(false)

    const checkAmount = (e: React.MouseEvent, submit = false) => {
        e.preventDefault()
        if (!PR.totalAmount) {
            setShowConfirmDialog(true)
            shouldSubmitRef.current = submit
            return false
        }
        handleCreate(submit)
    }

    const handleConfirmDialog = () => {
        handleCreate(shouldSubmitRef.current)
        return Promise.resolve(true)
    }

    async function handleDraftDocuments(draftDocuments: DraftDocumentI[], id: ObjectId) {
        if (!draftDocuments.length) return
        const uploadPromises = draftDocuments.map((doc) => {
            if (doc.file) {
                return uploadDocument({
                    file: doc.file,
                    name: doc.name,
                    documentType: doc.documentType,
                    objectId: id,
                    objectType: DocumentObjectType.PURCHASE_REQUEST,
                    organizationIds: [organizationId],
                })
            } else {
                return Promise.resolve(null)
            }
        })

        await Promise.allSettled(uploadPromises)
    }

    const handleCreate = async (submit = false) => {
        if (PR.description === "") {
            return toast(formatMessage(messages.errorDescription), { type: "warning" })
        }
        if (!PR.lines.length) {
            return toast(formatMessage(messages.errorItems), { type: "warning" })
        }

        setLoading(true)
        try {
            const result = await createPR(PR)
            if (draftDocuments && setDraftDocuments) {
                setLoading(true)
                await handleDraftDocuments(draftDocuments, result.id)
                setDraftDocuments([])
            }
            dispatch(purchaseRequestsActions.resetData())
            if (submit) {
                await updatePRStatus(PurchaseRequestStatus.SUBMITTED, result.id)
            }
            navigate(generatePath(PURCHASE_REQUEST_ROUTE, { purchaseRequestId: result.id }))
        } catch (error) {
            toast(formatMessage(messages.errorCreatingPR), { type: "error" })
            Sentry.captureException(error, {
                extra: {
                    organizationId,
                    purchaseRequestData: PR,
                },
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <Grid item className={"actions"}>
            <>
                <Button type={"tertiary"} onClick={(e) => checkAmount(e)} disabled={loading}>
                    <FormattedMessage {...messages.save} />
                </Button>
                <Button type={"tertiary"} onClick={(e) => checkAmount(e, true)} disabled={loading}>
                    <FormattedMessage {...messages.send} />
                </Button>
            </>
            <ConfirmModal
                open={showConfirmDialog}
                close={() => setShowConfirmDialog(false)}
                onConfirm={handleConfirmDialog}
                title={<FormattedMessage {...messages.amountEqual0} />}
            />
        </Grid>
    )
}
