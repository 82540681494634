import { useCallback, useState } from "react"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { defineMessages, useIntl } from "react-intl"
import { selectUser } from "~/store/account/accountSlice"
import { CreatePurchaseOrderDTO } from "../../types/PurchaseOrders"
import { purchaseOrdersApi } from "../../api"
import { useSavePurchaseOrderPendingTags } from "./useSavePurchaseOrderPendingTags"
import { useSavePurchaseOrderPendingLineTags } from "./useSavePurchaseOrderPendingLineTags"
import { useSavePurchaseOrderPendingCustomFieldValues } from "./useSavePurchaseOrderPendingCustomFieldValues"
import {
    addLineIdToTags,
    hasPendingCustomFieldValues,
    hasPendingLineTags,
} from "~/domains/transactions/common/purchases/purchasesUtils"
import { useTagsApi } from "~/domains/tags/tagsApi"
import { isDefined } from "~/utils/isDefined"
import { selectPendingLineTags, tagsActions } from "~/domains/tags/store/tagsSlice"
import { toast } from "react-toastify"
import { selectPendingCustomFieldValues } from "~/domains/transactions/custom-fields/store"

const messages = defineMessages({
    success: {
        id: "purchase.orders.create.success",
        defaultMessage: "Purchase order created",
    },
    error: {
        id: "purchase.orders.create.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const useCreatePurchaseOrder = (organizationId: string) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const user = useAppSelector(selectUser)
    const tagsApi = useTagsApi()
    const pendingLineTags = useAppSelector(selectPendingLineTags)
    const pendingCustomFieldValues = useAppSelector(selectPendingCustomFieldValues)
    const savePurchaseOrderPendingTags = useSavePurchaseOrderPendingTags(organizationId)
    const savePurchaseOrderPendingLineTags = useSavePurchaseOrderPendingLineTags(organizationId)
    const savePurchaseOrderPendingCustomFieldValues = useSavePurchaseOrderPendingCustomFieldValues(organizationId)

    const createPO = useCallback(
        async (newPO: CreatePurchaseOrderDTO, showToast: boolean = true) => {
            try {
                setLoading(true)
                const result = await purchaseOrdersApi.create(organizationId, newPO)
                savePurchaseOrderPendingTags(result.id)

                const hasPendingTags = hasPendingLineTags(pendingLineTags, newPO)
                const hasPendingCustomFields = hasPendingCustomFieldValues(pendingCustomFieldValues, newPO)

                if (hasPendingTags || hasPendingCustomFields) {
                    try {
                        const purchaseOrder = await purchaseOrdersApi.findById(organizationId, result.id)
                        await Promise.allSettled([
                            hasPendingTags && savePurchaseOrderPendingLineTags(newPO, purchaseOrder),
                            hasPendingCustomFields && savePurchaseOrderPendingCustomFieldValues(newPO, purchaseOrder),
                        ])
                        if (hasPendingTags) {
                            const tags = purchaseOrder.lines
                                .flatMap((line, lineIndex) => {
                                    const temporaryId = newPO.lines[lineIndex]?.temporaryId
                                    const tags = temporaryId ? pendingLineTags[temporaryId] : null
                                    return tags && line.id ? addLineIdToTags(tags, line.id) : []
                                })
                                .filter(isDefined)
                            dispatch(tagsActions.setLinesTags(tags))
                            dispatch(tagsActions.resetPendingTags())
                        }
                    } catch (error) {
                        console.error("error")
                    }
                }

                setLoading(false)
                showToast && toast.success(formatMessage(messages.success))
                return result
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                toast.error(formatMessage(messages.error))
                throw e
            }
        },
        [
            savePurchaseOrderPendingTags,
            savePurchaseOrderPendingLineTags,
            savePurchaseOrderPendingCustomFieldValues,
            organizationId,
            user,
            pendingLineTags,
            dispatch,
            tagsApi,
        ]
    )

    return { createPO, loading, error }
}
