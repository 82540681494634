import { useCallback, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { organizationApi } from "~/api"
import { OrganizationId, OrganizationTeamI } from "~/types"
import { organizationActions, selectOrganizationTeamsState, selectOrganizationsFilters } from "../organizationSlice"
import { toast } from "react-toastify"
import { defineMessages, useIntl } from "react-intl"
import { useAppSelector } from "~/store/hooks"
import { WHITE_SPACE_REGEXP } from "~/utils/string"
import { store } from "~/store"

const messages = defineMessages({
    error: {
        id: "account.organization.teams.errorFetchTeams",
        defaultMessage: "We can't retrieve the teams. Please contact your administrator",
    },
})

const noFilter = () => true

const getTeamsFilter = (filter: string) => {
    if (!filter) {
        return noFilter
    }
    const filterWords = filter.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
    return (team: OrganizationTeamI) => {
        const teamWords = `${team.name}`.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
        return filterWords.every((word) => teamWords.some((teamWord) => teamWord.indexOf(word) >= 0))
    }
}

const NO_TEAMS: OrganizationTeamI[] = []

export const useFetchOrganizationTeams = (organizationId: OrganizationId, hasPermission: boolean) => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const filters = useAppSelector(selectOrganizationsFilters)

    const state = useAppSelector(selectOrganizationTeamsState)
    const teams = state.teams[organizationId] ?? NO_TEAMS
    const loading = state.loading[organizationId] === true
    const error = state.error[organizationId]

    const fetchOrganizationTeams = useCallback(
        async (forceFetch?: boolean) => {
            if (!organizationId) return
            const { teams, loading } = selectOrganizationTeamsState(store.getState())
            if (!forceFetch && (teams[organizationId] || loading[organizationId])) {
                return
            }
            dispatch(
                organizationActions.fetchOrganizationTeams({
                    organizationId,
                })
            )
            try {
                const teams = await organizationApi.fetchOrganizationTeams(organizationId)
                dispatch(
                    organizationActions.fetchOrganizationTeamsSuccess({
                        organizationId,
                        teams,
                    })
                )
                return teams
            } catch (error) {
                dispatch(
                    organizationActions.fetchOrganizationTeamsError({
                        organizationId,
                        error: `${error}`,
                    })
                )

                toast.error(formatMessage(messages.error))
            }
        },
        [organizationId]
    )

    useEffect(() => {
        hasPermission && fetchOrganizationTeams()
    }, [fetchOrganizationTeams, hasPermission])

    return useMemo(
        () => ({
            teams: teams.filter(getTeamsFilter(filters.teams)),
            loading,
            error,
            fetchOrganizationTeams,
        }),
        [teams, loading, error, fetchOrganizationTeams]
    )
}
