import { useCallback, useState } from "react"
import { useWorkflowsApi } from "~/features/workflow/core/workflowsApi"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { workflowActions } from "../workflowSlice"
import { OrganizationId } from "~/types"

export const usePurchaseRequestLineActions = (
    organizationId: OrganizationId,
    purchaseRequestId: string,
    lineId: string | null
) => {
    const workflowsApi = useWorkflowsApi()
    const user = useAppSelector(selectUser)
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState<boolean>(false)

    const approvePurchaseRequestLine = useCallback(async () => {
        if (!lineId) return false
        setLoading(true)
        const result = await workflowsApi.approvePurchaseRequestLine(organizationId, purchaseRequestId, lineId)
        if (result) {
            dispatch(workflowActions.approvePurchaseRequestLine({ purchaseRequestId, lineId, approver: user.id }))
        }
        setLoading(false)
        return result
    }, [workflowsApi, organizationId, purchaseRequestId, lineId, dispatch, user])

    const refusePurchaseRequestLine = useCallback(async () => {
        if (!lineId) return false
        setLoading(true)
        const result = await workflowsApi.refusePurchaseRequestLine(organizationId, purchaseRequestId, lineId)
        if (result) {
            dispatch(workflowActions.refusePurchaseRequestLine({ purchaseRequestId, lineId, refuser: user.id }))
        }
        setLoading(false)
        return result
    }, [workflowsApi, organizationId, purchaseRequestId, lineId, dispatch, user])

    return {
        loading,
        approvePurchaseRequestLine,
        refusePurchaseRequestLine,
    }
}
