import * as t from "io-ts"
import { Opaque } from "~/utils"
import { CountryCode } from "./CountryCode"
import { isLeft } from "fp-ts/lib/Either"
import { optional } from "./utils"

export enum WhitePagesIdTypes {
    FRENCH_SIRET = "SIRET",
    DUNS = "DUNS",
}

const ValidIdTypes = Object.values(WhitePagesIdTypes).reduce((acc, value) => {
    acc[value] = true
    return acc
}, {}) as Record<WhitePagesIdTypes, boolean>

export type DunsId = Opaque<string, { readonly T: unique symbol }>
export type FrenchSirenId = Opaque<string, { readonly T: unique symbol }>

export const WhitePagesAddressIO = t.partial({
    streetLine1: optional(t.string),
    streetLine2: optional(t.string),
    postalCode: optional(t.string),
    city: optional(t.string),
    country: optional(t.string),
})

export const WhitePagesResultIO = t.type({
    id: t.string,
    idType: t.string,
    name: t.string,
    city: t.string,
    countryCode: t.string,
    primaryAddress: optional(WhitePagesAddressIO),
    isOutOfBusiness: t.boolean,
})

export type WhitePagesResultI = t.TypeOf<typeof WhitePagesResultIO> & {
    countryCode: CountryCode
} & (
        | {
              idType: WhitePagesIdTypes.DUNS
              id: DunsId
          }
        | {
              idType: WhitePagesIdTypes.FRENCH_SIRET
              id: FrenchSirenId
          }
    )

export const parseWhitePagesResult = (whitePagesResult: unknown): WhitePagesResultI | undefined => {
    const result = WhitePagesResultIO.decode(whitePagesResult)
    if (isLeft(result)) {
        console.error({ error: result.left, whitePagesResult })
        return undefined
    } else if (!ValidIdTypes[result.right.idType]) {
        console.error(
            `idType is invalid: ${result.right.idType} (valid values are ${Object.keys(ValidIdTypes).join(",")})`
        )
        return undefined
    }
    return {
        ...result.right,
        idType: result.right.idType as WhitePagesIdTypes,
        countryCode: result.right.countryCode as CountryCode,
    }
}
