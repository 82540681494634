import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { OrganizationId } from "~/types"
import { defineMessages, useIntl } from "react-intl"
import { purchaseRequestsActions } from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { purchaseRequestApi } from "~/domains/transactions/purchase-requests/api/purchaseRequestsApi"
import { organizationApi, userApi } from "~/api"
import { budgetApi } from "~/api/budgetApi"
import { TransactionType } from "~/features/budget/types"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { useAppSelector } from "~/store/hooks"
import { organizationAddressToAddress } from "~/domains/transactions/purchase-orders/pages/PurchaseOrder"

const messages = defineMessages({
    error: {
        id: "purchase.requests.request.errorFetch",
        defaultMessage: "We can't retrieve the purchase request. Please contact your administrator",
    },
})

export const useFetchPurchaseRequest = (organizationId?: OrganizationId) => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const currentOrganization = useAppSelector(selectCurrentOrganization)

    const fetchPurchaseRequest = useCallback(
        async (requestId?: string) => {
            if (!requestId || !organizationId || !currentOrganization || currentOrganization.id !== organizationId) {
                return null
            }

            try {
                dispatch(purchaseRequestsActions.fetchPR(requestId))
                const purchaseRequest = await purchaseRequestApi.findById(organizationId, requestId)

                const { supplierId, requesterUserId, shippingAddress } = purchaseRequest

                if (!purchaseRequest.purchaseOrder && !shippingAddress && currentOrganization.address) {
                    const address = organizationAddressToAddress(currentOrganization.address)
                    const result = await purchaseRequestApi.update(organizationId, requestId, {
                        ...purchaseRequest,
                        shippingAddress: address,
                    })
                    purchaseRequest.shippingAddress = result ? address : null
                }
                const supplier = supplierId ? await organizationApi.getOrganizationById(supplierId) : null
                const user = await userApi.findUsersByIds([requesterUserId])

                purchaseRequest.supplierName = supplier?.name ?? ""
                purchaseRequest.requesterName = user[0].fullName

                const result = await budgetApi.getBudgetByTransaction(
                    organizationId,
                    requestId,
                    TransactionType.PURCHASE_REQUEST
                )
                if (result.length) {
                    purchaseRequest.budgets = result
                }

                dispatch(purchaseRequestsActions.fetchPRSuccess(purchaseRequest))

                return purchaseRequest
            } catch (error) {
                dispatch(purchaseRequestsActions.reset())
                dispatch(purchaseRequestsActions.fetchPRFailed(formatMessage(messages.error)))
            }
        },
        [organizationId, currentOrganization]
    )

    return { fetchPurchaseRequest }
}
