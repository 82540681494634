import { useCallback } from "react"
import { useFetchPartnerProfile } from "./useFetchPartnerProfile"
import { useCreatePartnerProfile } from "./useCreatePartnerProfile"
import { useCreatePartnerProfileContact } from "./useCreatePartnerProfileContact"
import { CreatePartnerProfileContactI, PartnerProfileContactI } from "../../types/BookOfRelations"
import { computePartnerProfile, createPartnerProfileContactPayload, isPartnerProfileContact } from "../../core"
import { OrganizationId } from "~/types"

export const usePartnerContactSave = (
    currentOrganizationId: OrganizationId | undefined | null,
    partnerId: OrganizationId | undefined | null
) => {
    const { partnerProfile } = useFetchPartnerProfile(currentOrganizationId, partnerId)
    const { createPartnerProfile } = useCreatePartnerProfile()
    const { createPartnerProfileContact } = useCreatePartnerProfileContact()

    const handleContactSave = useCallback(
        (contact: CreatePartnerProfileContactI | PartnerProfileContactI) => {
            const contactPayload = createPartnerProfileContactPayload(contact)
            if (!currentOrganizationId || !contactPayload || isPartnerProfileContact(contactPayload)) return

            if (!partnerProfile) {
                const computedProfile = computePartnerProfile(
                    partnerProfile,
                    { contacts: [contactPayload] },
                    currentOrganizationId,
                    partnerId
                )
                createPartnerProfile(computedProfile)
            } else {
                createPartnerProfileContact(partnerProfile.initiatorId, partnerProfile.partnerId, contactPayload)
            }
        },
        [partnerProfile, currentOrganizationId, partnerId, createPartnerProfile, createPartnerProfileContact]
    )

    return handleContactSave
}
