import { useEffect, useMemo, useRef, useState } from "react"

const DEFAULT_SAVING_TIME = 1500
const DEFAULT_DISPLAY_SAVED_TIME = 2000

interface Options {
    savingTime?: number
    displaySavedTime?: number
}

export enum SaveStatus {
    UNCHANGED,
    SAVING,
    SAVED,
}

export const useSaveBuffer = (save: () => void | Promise<unknown>, options?: Options) => {
    const [hasChanges, setHasChanges] = useState<boolean>(false)
    const [hasBeenSaved, setHasBeenSaved] = useState<boolean>(false)
    const savingTimeout = useRef<number>()

    const status = hasChanges ? SaveStatus.SAVING : hasBeenSaved ? SaveStatus.SAVED : SaveStatus.UNCHANGED

    useEffect(() => {
        if (hasChanges) {
            if (savingTimeout.current) {
                window.clearTimeout(savingTimeout.current)
            }
            savingTimeout.current = window.setTimeout(async () => {
                await save()
                setHasChanges(false)
                setHasBeenSaved(true)
            }, options?.savingTime ?? DEFAULT_SAVING_TIME)
        }
    }, [save, setHasChanges, hasChanges])

    useEffect(() => {
        if (!hasChanges && hasBeenSaved) {
            window.setTimeout(() => setHasBeenSaved(false), options?.displaySavedTime ?? DEFAULT_DISPLAY_SAVED_TIME)
        }
    }, [hasChanges])

    return useMemo(
        () => ({
            status,
            setHasChanges,
        }),
        [status, setHasChanges]
    )
}
