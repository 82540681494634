import * as t from "io-ts"
import { CreateTagGroupIO } from "./CreateTagGroup"
import { Opaque } from "~/utils"
import { OrganizationId } from "~/types"
import { TagAssignmentRule } from "./TagAssignmentRule"
import { TagI, TagIO } from "./Tag"

export type TagGroupId = Opaque<string, { readonly T: unique symbol }>

export const TagGroupIO = t.intersection([
    t.type({
        tagGroupId: t.string,
        organizationId: t.string,
    }),
    t.partial({
        tags: t.array(TagIO),
    }),
    CreateTagGroupIO,
])

export type TagGroupI = t.TypeOf<typeof TagGroupIO> & {
    tagGroupId: TagGroupId
    organizationId: OrganizationId
    assignmentRule: TagAssignmentRule
    tags: TagI[]
}
