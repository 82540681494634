import React, { useCallback, useState } from "react"
import { Button } from "../Button"
import { OrganizationId } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"
import { Share2 } from "react-feather"
import { ShareObjectModal } from "../ShareObjectModal"
import { defineMessages, useIntl } from "react-intl"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"

const messages = defineMessages({
    share: { id: "share.object.modal.share", defaultMessage: "Share" },
})

interface Props {
    organizationId: OrganizationId
    objectType: SharedObjectType
    objectId: string
    buyerOrganization: OrganizationSummary | undefined
    supplierOrganization: OrganizationSummary | undefined
    showIcon?: boolean
}

export const ShareButton: React.FC<Props> = ({
    organizationId,
    objectType,
    objectId,
    buyerOrganization,
    supplierOrganization,
    showIcon = false,
}) => {
    const { formatMessage } = useIntl()
    const [shareObjectModalOpen, setShareObjectModalOpen] = useState<boolean>(false)

    const showShareObjectModal = useCallback(() => setShareObjectModalOpen(true), [setShareObjectModalOpen])
    const hideShareObjectModal = useCallback(() => setShareObjectModalOpen(false), [setShareObjectModalOpen])

    return (
        <>
            <Button type="tertiary" buttonType="button" onClick={showShareObjectModal}>
                {showIcon ? <Share2 size={16} /> : null}
                <span>{formatMessage(messages.share)}</span>
            </Button>
            {shareObjectModalOpen ? (
                <ShareObjectModal
                    open={shareObjectModalOpen}
                    close={hideShareObjectModal}
                    organizationId={organizationId}
                    objectType={objectType}
                    objectId={objectId}
                    buyerOrganization={buyerOrganization}
                    supplierOrganization={supplierOrganization}
                    sendShareEmail={true}
                />
            ) : null}
        </>
    )
}
