import * as t from "io-ts"

export enum SubscriptionType {
    PURCHASE_REQUEST = "purchase-request",
    PURCHASE_ORDER = "purchase-order",
    INVOICE = "invoice",
    DOCUMENT_RELATION = "document-relation",
}

export enum SubscriptionEventType {
    SELF = "SELF",
    WORKFLOW = "WORKFLOW",
    DOCUMENT_RELATION = "DOCUMENT_RELATION",
}

export const SubscriptionEventIO = t.type({
    type: t.string,
    objectId: t.string,
})
export type SubscriptionEventI = {
    type: SubscriptionEventType
    objectId: string
}
