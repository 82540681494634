import React, { lazy, useState } from "react"
import classnames from "classnames"
import { Box } from "@mui/material"
import "./InvoiceViewer.scss"
import { ChevronDown, ChevronUp } from "react-feather"
import SkeletonImgInvoice from "~/components/Skeleton/SkeletonImgInvoice"
import { defineMessages, FormattedMessage } from "react-intl"
import { CompanyRegistrationI, ImportInvoiceCompanyInfoI, InvoiceI, PaymentDetailsI } from "~/types"

const OcrExtract = lazy(() =>
    import("~/features/invoice/components/OcrExtract").then(({ OcrExtract }) => ({ default: OcrExtract }))
)

interface InvoiceViewerProps {
    updateAction?: (data: Partial<InvoiceI>) => void
    updateBuyer?: (data: Partial<ImportInvoiceCompanyInfoI>) => void
    updateSupplier?:
        | ((data: Partial<CompanyRegistrationI>) => void)
        | ((data: Partial<ImportInvoiceCompanyInfoI>) => void)
    updatePaymentData?: (data: Partial<PaymentDetailsI>) => void
    imageUrls: string[]
    className?: string
    overflow?: boolean
    handleDownload?: () => void
    imagesOnly?: boolean
}

const messages = defineMessages({
    hide: {
        id: "buyer.invoiceViewer.hide",
        defaultMessage: "Hide the invoice",
    },
    display: {
        id: "buyer.invoiceViewer.display",
        defaultMessage: "Display the full invoice",
    },
})

export const InvoiceViewer = ({
    className,
    imageUrls,
    overflow,
    handleDownload,
    updateAction,
    updateBuyer,
    updateSupplier,
    updatePaymentData,
    imagesOnly,
}: InvoiceViewerProps) => {
    const classes = classnames("invoice-viewer", className, {
        ["overflow"]: overflow,
    })
    const [showInvoice, setShowInvoice] = useState<boolean>(false)
    const handleClick = () => {
        if (handleDownload) {
            handleDownload()
        }
    }

    return (
        <Box className={classes + (showInvoice ? " full" : "")}>
            {!imageUrls || !imageUrls.length ? (
                <SkeletonImgInvoice />
            ) : imagesOnly ? (
                imageUrls.map((url, key) => (
                    <img src={url} alt="" key={key} onClick={handleClick} className={handleDownload ? "pointer" : ""} />
                ))
            ) : updateAction && updateBuyer && updateSupplier && updatePaymentData ? (
                <OcrExtract
                    updateAction={updateAction}
                    updateBuyer={updateBuyer}
                    updateSupplier={updateSupplier}
                    updatePaymentData={updatePaymentData}
                    imageUrls={imageUrls}
                />
            ) : null}

            <div className="watch-full" onClick={() => setShowInvoice((prev) => !prev)}>
                {showInvoice ? (
                    <span>
                        <FormattedMessage {...messages.hide} /> <ChevronUp />
                    </span>
                ) : (
                    <span>
                        <FormattedMessage {...messages.display} /> <ChevronDown />
                    </span>
                )}
            </div>
        </Box>
    )
}
