import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from "react"
import * as Sentry from "@sentry/browser"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { Button } from "~/components"
import { Grid } from "@mui/material"
import { CurrencyCodes, OrganizationId, ViewTypeI } from "~/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { toast } from "react-toastify"
import { DocumentObjectType, DraftDocumentI, ObjectId } from "~/components/UploadDocument/Documents"
import { useUploadDocument } from "~/components/UploadDocument/hooks"
import { ConfirmModal } from "~/components/ConfirmModal"
import { purchaseOrdersActions, selectPurchaseOrder } from "../../store/purchaseOrdersSlice"
import { convertPOtoCreatePODTO } from "../../core/purchaseOrder"
import { useCreatePurchaseOrder, useUpdatePurchaseOrderStatus } from "../../store/hooks"
import { generatePath, useNavigate } from "react-router-dom"
import { PurchaseOrderLine, PurchaseOrderStatusUpdate } from "../../types/PurchaseOrders"
import { PURCHASE_ORDER_ROUTE } from "../../routes"

const messages = defineMessages({
    save: {
        id: "purchase.orders.order.header.actions.save",
        defaultMessage: "Save",
    },
    send: {
        id: "purchase.orders.order.header.actions.send",
        defaultMessage: "Submit",
    },
    errorDescription: {
        id: "purchase.orders.order.header.actions.errorDescription",
        defaultMessage: "The description is mandatory",
    },
    errorItems: {
        id: "purchase.orders.order.header.actions.errorLines",
        defaultMessage: "You must add at least one item",
    },
    errorVendor: {
        id: "purchase.orders.order.header.actions.errorVendor",
        defaultMessage: "You must specified a vendor",
    },
    errorDeliveryDate: {
        id: "purchase.orders.order.header.actions.errorDeliveryDate",
        defaultMessage: "You must specified a delivery date",
    },
    errorCreatingPR: {
        id: "purchase.orders.order.header.actions.errorCreatingPO",
        defaultMessage: "An error occurred while creating this purchase order.",
    },
    modalConfirmTitle: {
        id: "purchase.orders.order.header.modalConfirmTitle",
        defaultMessage: "Warning",
    },
    amountEqual0: {
        id: "purchase.orders.order.header.amountEqualZero",
        defaultMessage: "The amount of the PO is 0. Do you want to continue?",
    },
})

interface PropsPOCreateActions {
    organizationId: OrganizationId
    draftDocuments?: DraftDocumentI[]
    setDraftDocuments?: Dispatch<SetStateAction<DraftDocumentI[]>>
}

type AmountWithCurrency = {
    amount: number
    currency: CurrencyCodes
}
type LinesTotals = {
    amount: AmountWithCurrency
    amountExcludingTax: AmountWithCurrency
    tax: AmountWithCurrency
}

const newLinesTotals = (): LinesTotals => ({
    amount: {
        amount: 0,
        currency: CurrencyCodes.EUR,
    },
    amountExcludingTax: {
        amount: 0,
        currency: CurrencyCodes.EUR,
    },
    tax: {
        amount: 0,
        currency: CurrencyCodes.EUR,
    },
})
const computeLinesTotal = (lines: PurchaseOrderLine[]): LinesTotals => {
    return lines.reduce((acc, line) => {
        return {
            amount: {
                amount: acc.amount.amount + line.totalAmount,
                currency: CurrencyCodes.EUR,
            },
            amountExcludingTax: {
                amount: acc.amountExcludingTax.amount + line.totalAmountExcludingTax,
                currency: CurrencyCodes.EUR,
            },
            tax: {
                amount: acc.tax.amount + line.totalTax,
                currency: CurrencyCodes.EUR,
            },
        }
    }, newLinesTotals())
}

export function ActionsHeaderCreate({ organizationId, draftDocuments, setDraftDocuments }: PropsPOCreateActions) {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const PO = useAppSelector(selectPurchaseOrder)
    const { createPO } = useCreatePurchaseOrder(organizationId)
    const { uploadDocument } = useUploadDocument(organizationId)
    const { updatePOStatus /* , error, loading */ } = useUpdatePurchaseOrderStatus(PO.id, ViewTypeI.buyer)
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
    const shouldSubmitRef = useRef<boolean>(false)

    const linesTotals = useMemo(() => computeLinesTotal(PO.lines), [PO.lines])

    const checkAmount = useCallback(
        (e: React.MouseEvent, submit = false) => {
            e.preventDefault()
            if (PO.description === "") {
                return toast.warning(formatMessage(messages.errorDescription))
            }
            if (!PO.lines.length) {
                return toast.warning(formatMessage(messages.errorItems))
            }
            if (PO.expectedDeliveryDate === "") {
                return toast.warning(formatMessage(messages.errorDeliveryDate))
            }
            if (PO.supplierId === "") {
                return toast.warning(formatMessage(messages.errorVendor))
            }
            if (!linesTotals.amountExcludingTax.amount && !PO.totalAmount) {
                setShowConfirmDialog(true)
                shouldSubmitRef.current = submit
                return false
            }
            handleCreate(submit)
        },
        [PO, linesTotals]
    )

    const handleConfirmDialog = () => {
        handleCreate(shouldSubmitRef.current)
    }

    async function handleDraftDocuments(draftDocuments: DraftDocumentI[], id: ObjectId) {
        if (!draftDocuments.length) return
        const uploadPromises = draftDocuments.map((doc) => {
            if (doc.file) {
                return uploadDocument({
                    file: doc.file,
                    name: doc.name,
                    documentType: doc.documentType,
                    objectType: DocumentObjectType.PURCHASE_ORDER,
                    objectId: id,
                    organizationIds: [organizationId],
                })
            } else {
                return Promise.resolve(null)
            }
        })

        await Promise.allSettled(uploadPromises)
    }

    const handleCreate = async (submit = false) => {
        const data = convertPOtoCreatePODTO(PO)

        setLoading(true)
        try {
            const result = await createPO(data, false)
            if (draftDocuments && setDraftDocuments) {
                await handleDraftDocuments(draftDocuments, result.id)
                setDraftDocuments([])
            }
            dispatch(purchaseOrdersActions.resetData())
            if (submit) {
                await updatePOStatus(organizationId, PurchaseOrderStatusUpdate.SUBMIT, undefined, undefined, result.id)
            }
            navigate(generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: result.id }))
        } catch (error) {
            toast(formatMessage(messages.errorCreatingPR), { type: "error" })
            Sentry.captureException(error, {
                extra: {
                    organizationId,
                    purchaseorderData: data,
                },
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <Grid item className={"actions"}>
            <>
                <Button type={"tertiary"} onClick={(e) => checkAmount(e)} disabled={loading}>
                    <FormattedMessage {...messages.save} />
                </Button>
                <Button type={"tertiary"} onClick={(e) => checkAmount(e, true)} disabled={loading}>
                    <FormattedMessage {...messages.send} />
                </Button>
            </>
            <ConfirmModal
                open={showConfirmDialog}
                close={() => setShowConfirmDialog(false)}
                onConfirm={handleConfirmDialog}
                title={<FormattedMessage {...messages.amountEqual0} />}
            />
        </Grid>
    )
}
