import { useCallback, useEffect, useMemo } from "react"
import {
    PartnerProfileContactI,
    PartialPartnerProfile,
    PartnerProfileContactArrayIO,
    ProfileDetailsType,
    PartnerProfilePaymentDetailArrayIO,
    PartnerProfilePaymentDetailI,
} from "~/domains/transactions/book-of-relations/types"
import { partnerApi } from "~/api"
import { useDispatch } from "react-redux"
import { useAppSelector } from "~/store/hooks"

import { genericParser } from "~/utils"
import { isResultSuccess } from "~/core/Result"
import {
    bookOfRelationsActions,
    selectPartnerProfileContacts,
    selectPartnerProfilePaymentDetails,
} from "../bookOfRelationsSlice"
import { Decoder } from "io-ts"
import { RootState } from "~/store"
import * as Sentry from "@sentry/browser"

type FetchProfileDetailsResult<T> = {
    [key in ProfileDetailsType]?: T[] | null
} & {
    loading: boolean
    error: string | null
}

type FetchProfileDetails<T> = (
    organizationId: string | undefined,
    partnerId: string | undefined
) => FetchProfileDetailsResult<T>

function fetchPartnerProfileDetails<T>(
    selector: (state: RootState) => {
        details: T[]
        initiatorId: string | undefined
        partnerId: string | undefined
        loading: boolean
        error: string | null
    },
    apiFetchFunction: (organizationId: string, partnerId: string) => Promise<T[]>,
    parsedType: Decoder<unknown, unknown>,
    detailsType: ProfileDetailsType
): FetchProfileDetails<T> {
    return (organizationId: string | undefined, partnerId: string | undefined) => {
        const dispatch = useDispatch()
        const { details, initiatorId, partnerId: partnerIdFromStore, loading, error } = useAppSelector(selector)

        const fetchPartnerProfileDetails = useCallback(() => {
            if (!organizationId || !partnerId || (initiatorId === organizationId && partnerIdFromStore === partnerId)) {
                return
            }

            dispatch(bookOfRelationsActions.fetchPartnerProfileDetails())

            apiFetchFunction(organizationId, partnerId)
                .then((data) => {
                    const result = genericParser(data, parsedType)
                    if (isResultSuccess(result)) {
                        dispatch(bookOfRelationsActions.fetchPartnerProfileDetailsSuccess())
                        const partialProfile: PartialPartnerProfile = {
                            initiatorId: organizationId,
                            partnerId,
                            [detailsType]: data,
                        }
                        dispatch(bookOfRelationsActions.setPartialPartnerProfile(partialProfile))
                    } else {
                        const errorMsg = new Error(`Issue parsing partner's ${detailsType} - ${error}`)
                        Sentry.captureException(errorMsg, {
                            extra: { detailsType, partnerId },
                            tags: { organizationId },
                        })
                        dispatch(bookOfRelationsActions.fetchPartnerProfileDetailsFailed(errorMsg.message))
                    }
                })
                .catch((e) => {
                    const errorMsg = new Error(`Unable to fetch partner's ${detailsType} - ${error} - ${e}`)
                    Sentry.captureException(errorMsg, {
                        extra: { error: e.message, errorMsg, partnerId, detailsType },
                        tags: { organizationId },
                    })
                    dispatch(bookOfRelationsActions.fetchPartnerProfileDetailsFailed(e))
                    dispatch(bookOfRelationsActions.setPartialPartnerProfile(null))
                })
        }, [organizationId, partnerId, apiFetchFunction, parsedType, detailsType])

        useEffect(() => {
            fetchPartnerProfileDetails()
        }, [fetchPartnerProfileDetails])

        return useMemo(
            () => ({
                [detailsType]: details,
                loading,
                error,
            }),
            [details, loading, error]
        )
    }
}

export const useFetchPartnerContacts = fetchPartnerProfileDetails<PartnerProfileContactI>(
    selectPartnerProfileContacts,
    partnerApi.fetchPartnerProfileContacts,
    PartnerProfileContactArrayIO,
    "contacts"
)

export const useFetchPartnerPaymentDetails = fetchPartnerProfileDetails<PartnerProfilePaymentDetailI>(
    selectPartnerProfilePaymentDetails,
    partnerApi.fetchPartnerProfilePaymentDetails,
    PartnerProfilePaymentDetailArrayIO,
    "paymentDetails"
)
