import { Palette, SxProps, Theme, styled } from "@mui/material"
import React, { PropsWithChildren } from "react"
import { CrossIcon } from "~/components/Icons"

export type Variant = "primary" | "success" | "neutral" | "warning" | "error"
export type Size = "large" | "medium" | "small" | "mini"

interface CommonProps {
    variant: Variant
    size: Size
    borderLess: boolean
}

interface BasicProps extends PropsWithChildren {
    variant?: Variant
    size?: Size
    sx?: SxProps<Theme>
    borderLess?: boolean
}

type Props = BasicProps & ({ removable?: false; onRemove?: never } | { removable: true; onRemove: () => void })

const getBorderColor = (variant: Variant, palette: Palette) => {
    if (variant === "success") {
        return palette.success.dark
    } else if (variant === "warning") {
        return palette.warning.dark
    } else if (variant === "primary") {
        return palette.primary.dark
    } else if (variant === "error") {
        return palette.error.dark
    } else {
        return "#E9E9EB"
    }
}

const getBackground = (variant: Variant, palette: Palette) => {
    if (variant === "success") {
        return palette.success.light
    } else if (variant === "warning") {
        return palette.warning.light
    } else if (variant === "primary") {
        return palette.primary.light
    } else if (variant === "error") {
        return palette.error.light
    } else {
        return "linear-gradient(0deg, #F4F4F5, #F4F4F5),linear-gradient(0deg, #E9E9EB, #E9E9EB)"
    }
}

const getColor = (variant: Variant, palette: Palette) => {
    if (variant === "success") {
        return palette.success.main
    } else if (variant === "warning") {
        return palette.warning.main
    } else if (variant === "primary") {
        return palette.primary.main
    } else if (variant === "error") {
        return palette.error.main
    } else {
        return palette.grey[900]
    }
}

const heightBySize: Record<Size, string> = {
    large: "32px",
    medium: "28px",
    small: "24px",
    mini: "20px",
}

const paddingBySize: Record<Size, string> = {
    large: "3px 10px",
    medium: "3px 10px",
    small: "1px 8px",
    mini: "0 5px",
}

const ChipContainer = styled("span", {
    shouldForwardProp: (propsName) =>
        propsName !== "theme" && propsName !== "variant" && propsName !== "size" && propsName !== "borderLess",
})<CommonProps>(({ theme, variant, size, borderLess }) => ({
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    height: heightBySize[size],
    padding: paddingBySize[size],
    borderRadius: "4px",
    boxSizing: "border-box",
    border: `1px solid ${getBorderColor(variant, theme.palette)}`,
    color: getColor(variant, theme.palette),
    stroke: getColor(variant, theme.palette),
    background: getBackground(variant, theme.palette),
    gap: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    svg: {
        cursor: "pointer",
    },
    ...(borderLess && { border: "none" }),
}))

export const Chip: React.FC<Props> = ({
    children,
    borderLess = false,
    variant = "neutral",
    size = "medium",
    sx,
    removable,
    onRemove,
}) => {
    return (
        <ChipContainer variant={variant} size={size} sx={sx} borderLess={borderLess}>
            {children}
            {removable && <CrossIcon onClick={onRemove} size={12} color="current" />}
        </ChipContainer>
    )
}
