import { Tooltip } from "@mui/material"
import React from "react"
import { FormattedMessage, defineMessages } from "react-intl"
import { SuccessIcon, WarningIcon } from "~/components/Icons"
import "~/features/invoice/components/Payment/IbanValidationCheck.scss"

const messages = defineMessages({
    partnerIBANMatch: {
        id: "iban.validationCheck.partnerIBAN.match",
        defaultMessage: "Partner's IBAN match",
    },
    partnerIBANMismatch: {
        id: "iban.validationCheck.partnerIBAN.mismatch",
        defaultMessage: "Partner's IBAN mismatch",
    },
    partnerIBANverified: {
        id: "iban.validationCheck.partnerIBAN.verified",
        defaultMessage: "IBAN verified",
    },
    warningtooltip: {
        id: "iban.validationCheck.partnerIBAN.tooltipMessage.warning",
        defaultMessage: "The selected IBAN does not match any of your current declared partner's IBANs",
    },
})

interface Props {
    selectedIban: string | null | undefined
    partnerIbans: (string | null | undefined)[]
    isVerifiedIban: boolean
}

export const PartnerIbanCheck: React.FC<Props> = ({ selectedIban, partnerIbans, isVerifiedIban }) => {
    if (!selectedIban || partnerIbans?.length === 0) {
        return null
    }

    const isIbanMatch = partnerIbans.includes(selectedIban)

    return (
        <>
            <div className="iban-validation iban-validation-aligned">
                {isIbanMatch ? (
                    <div className="iban-validation-success">
                        <FormattedMessage {...messages.partnerIBANMatch} />
                        <SuccessIcon size={16} />
                    </div>
                ) : (
                    <div className="iban-validation-warning">
                        <Tooltip title={<FormattedMessage {...messages.warningtooltip} />} placement="bottom" arrow>
                            <div className="iban-validation-warning-sub-group">
                                <span>
                                    <FormattedMessage {...messages.partnerIBANMismatch} />
                                </span>
                                <WarningIcon size={16} />
                            </div>
                        </Tooltip>
                    </div>
                )}
            </div>
            <div className="iban-validation iban-validation-aligned">
                {isVerifiedIban ? (
                    <div className="iban-validation-success">
                        <FormattedMessage {...messages.partnerIBANverified} />
                        <SuccessIcon size={16} />
                    </div>
                ) : null}
            </div>
        </>
    )
}
