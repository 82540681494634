import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
    color?: string
    size?: number
}

export const LeftChevronIcon: React.FC<Props> = ({ color = "black", size = 24, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
    )
}
