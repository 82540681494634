import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
    color?: string
    size?: number
}

export const BoltIcon: React.FC<Props> = ({ color = "#7B61FF", size = 18, ...props }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5.79183 14.167L10.1043 9.00033H6.771L7.37516 4.27116L3.521 9.83366H6.41683L5.79183 14.167ZM3.66683 17.3337L4.50016 11.5003H0.333496L7.8335 0.666992H9.50016L8.66683 7.33366H13.6668L5.3335 17.3337H3.66683Z"
                fill={color}
            />
        </svg>
    )
}
