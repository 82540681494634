import { useCallback } from "react"
import { SubscriptionEventI, SubscriptionEventType, SubscriptionType } from "../../common/subscriptions"
import { DocumentRelationId } from "../types"
import { useAppDispatch } from "~/store/hooks"
import { documentRelationsActions } from "../store/documentRelationsSlice"
import { useSubscribeToUpdate } from "../../common/subscriptions/hooks"

export const useSubscribeToRelation = (relationId: DocumentRelationId, type: SubscriptionType) => {
    const dispatch = useAppDispatch()
    const onDocumentRelationEvent = useCallback(
        (event: SubscriptionEventI) => {
            if (event.type === SubscriptionEventType.DOCUMENT_RELATION) {
                if (type === SubscriptionType.INVOICE) {
                    dispatch(documentRelationsActions.removeInvoiceRelation(relationId))
                } else if (type === SubscriptionType.PURCHASE_ORDER) {
                    dispatch(documentRelationsActions.removePurchaseOrderRelation(relationId))
                }
            }
        },
        [dispatch, relationId]
    )
    useSubscribeToUpdate(SubscriptionType.DOCUMENT_RELATION, relationId, onDocumentRelationEvent)
}
