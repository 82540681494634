import { styled } from "@mui/material"
import React from "react"

interface Props {
    progress: number
    secondaryProgress?: number
    primaryColor?: string
    secondaryColor?: string
}

interface CircleProps {
    customColor?: string
}

const BackgroundCircle = styled("circle")(() => ({
    stroke: "#EBEEF5",
}))
const ForegroundCircle = styled("circle", { shouldForwardProp: (prop) => prop !== "customColor" })<CircleProps>(
    ({ theme, customColor }) => ({
        transform: "rotate(-90deg)",
        transformOrigin: "center center",
        transition: "stroke-dasharray 0.3s linear 0s",
        stroke: customColor ?? theme.palette.primary.main,
    })
)
const SecondaryForegroundCircle = styled("circle", {
    shouldForwardProp: (prop) => prop !== "customColor" && prop !== "progress",
})<Props & CircleProps>(({ theme, progress, customColor }) => ({
    transform: `rotate(${-1 * Math.round(90 - (progress * 360) / 100)}deg)`,
    transformOrigin: "center center",
    transition: "stroke-dasharray 0.3s linear 0s",
    stroke: customColor ?? theme.palette.error.main,
}))

const size = 26
const halfSize = size / 2
const strokeWidth = 4
const radius = (size - strokeWidth) / 2
const circumference = radius * Math.PI * 2

const cx = halfSize
const cy = halfSize
const r = radius

export const CircularProgressBar: React.FC<Props> = ({ progress, secondaryProgress, primaryColor, secondaryColor }) => {
    const percentage = Math.max(Math.min(progress * 100, 100), 0)
    const dash = (percentage * circumference) / 100
    const secondaryDash =
        typeof secondaryProgress === "number"
            ? strokeWidth / 2 + (Math.max(Math.min(secondaryProgress * 100, 100), 0) * circumference) / 100
            : null
    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
            <BackgroundCircle
                cx={cx}
                cy={cy}
                r={r}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                fill="none"
                stroke="red"
            />
            {secondaryDash !== null && (
                <SecondaryForegroundCircle
                    progress={percentage}
                    cx={cx}
                    cy={cy}
                    r={r}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    fill="none"
                    strokeDasharray={`${secondaryDash} ${circumference - secondaryDash}`}
                    customColor={secondaryColor}
                />
            )}
            <ForegroundCircle
                cx={cx}
                cy={cy}
                r={r}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                fill="none"
                strokeDasharray={`${dash} ${circumference - dash}`}
                customColor={primaryColor}
            />
        </svg>
    )
}
