import React, { useMemo } from "react"
import { MessageViewI, MessageWithUserI, RoomId } from "../../types"
import { Modal, Size } from "~/components"
import { MessageContent } from "../Message/MessageContent"
import { Typography } from "@mui/material"
import { MessageView } from "./MessageView"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { UserI } from "~/types"

interface Props {
    currentUser: UserI
    message: MessageWithUserI | null
    roomId: RoomId
    close: () => void
}

const getFirstMessageViewPerUser = (acc: MessageViewI[], messageView: MessageViewI) => {
    if (!acc.some((view) => view.userId === messageView.userId)) {
        acc.push(messageView)
    }
    return acc
}

const renderMessageView = (messageView: MessageViewI) => <MessageView key={messageView.id} messageView={messageView} />

export const MessageViewersModal: React.FC<Props> = ({ currentUser, message, roomId, close }) => {
    const messageViews = useMemo(() => message?.views.reduce(getFirstMessageViewPerUser, []), [message?.views])
    const allUsers = useMemo(() => {
        return messageViews?.map((view) => view.userId) ?? []
    }, [messageViews])
    useGetAllUsersQuery(allUsers)
    return (
        <Modal open={message !== null} onClose={() => close()} size={Size.SM}>
            <Modal.Header>
                {message && <MessageContent message={message} currentUser={currentUser} roomId={roomId} />}
            </Modal.Header>
            <Modal.Content>
                <Typography>Viewed by:</Typography>
                {messageViews?.map(renderMessageView)}
            </Modal.Content>
        </Modal>
    )
}
