import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { paymentActions } from "./paymentSlice"
import { BankConnectorsI, InitiatePaymentPayload } from "~/types"
import { nameNormalized } from "~/utils/string"
import { PayloadAction } from "@reduxjs/toolkit"
import { paymentApi } from "~/api"
import { selectInvoiceId } from "../invoice/invoiceSlice"


const addNormalizedName = (connectors: BankConnectorsI[]) => {
    return connectors.map((connector) => {
        connector.normalizedName = nameNormalized(connector.name)
        return connector
    })
}

function* getBankConnectors() {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data: BankConnectorsI[] = yield call(paymentApi.getBankConnectors)
        yield put(paymentActions.getBankConnectorsSuccess(addNormalizedName(data)))
    } catch (error) {
        console.error("failed to get bank connectors", error)
        yield put(paymentActions.getBankConnectorsFailed(`${error}`))
    }
}

function* initPayment(action: PayloadAction<InitiatePaymentPayload>) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const invoiceId: string | null = yield select(selectInvoiceId)
        if (!invoiceId) {
            throw Error("an invoice in mandatory to initiate a payment")
        }
        yield call(paymentApi.initPayment, invoiceId, action.payload)
        yield put(paymentActions.setInitWs())
    } catch (error) {
        console.error("failed to initiate payment", error)
        yield put(paymentActions.setPaymentFailed())
    }
}

export default function* paymentSaga() {
    yield all([
        takeLatest(paymentActions.getBankConnectors.type, getBankConnectors),
        takeLatest(paymentActions.initPayment.type, initPayment),
    ])
}
