import React, { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectAuthLoading, selectIsConnected } from "~/store/auth/authSlice"
import { errorsActions } from "~/store/errors/errorsSlice"
import { Loader } from "~/components"
import { accountActions, selectUserLoading } from "~/store/account/accountSlice"

export const ProtectedRoutes = () => {
    const isConnected = useAppSelector(selectIsConnected)
    const isAuthLoading = useAppSelector(selectAuthLoading)
    const isUserLoading = useAppSelector(selectUserLoading)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!isConnected && !isAuthLoading) {
            dispatch(accountActions.setLoading(false))
            dispatch(errorsActions.setErrorAuth(true))
        }
    }, [isConnected, isAuthLoading])

    if (isAuthLoading || isUserLoading) {
        return <Loader fullscreen={true} />
    }

    if (!isConnected) {
        return null
    }

    return <Outlet />
}
