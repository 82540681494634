import { useContext } from "react"
import { AxiosInstance } from "axios"
import { OrganizationId } from "~/types"
import { ApiContext } from "~/domains/common/apiClient"
import {
    CreateProductVersionPayload,
    PaginatedProductVersionI,
    ProductId,
    parsePaginatedProductVersion,
    parseProductVersion,
} from "./types"
import { parseResponse } from "~/domains/common/apiClient/parseResponse"
import { FetchErrorType } from "~/features/workflow/core"
import { Result, ResultError } from "~/core/Result"
import { FetchError } from "~/domains/common/apiClient/errors"
import { parseBatchImportProductVersionResult } from "./types/parseBatchImportProductVersionResult"
import { PaginationQuery } from "~/utils"

const BASE_URL = import.meta.env.VITE_API_CATALOG_URL
const IMPORT_BASE_URL = import.meta.env.VITE_API_IMPORT_FROM_FILES_URL

class CatalogApi {
    private static instance: CatalogApi
    private constructor(private axiosClient: AxiosInstance) {}

    static getInstance(axiosClient: AxiosInstance) {
        if (!CatalogApi.instance) {
            CatalogApi.instance = new CatalogApi(axiosClient)
        }
        return CatalogApi.instance
    }

    async importBatchProductsVersions(organizationId: OrganizationId, file: File) {
        const formData = new FormData()
        formData.append("file", file)
        formData.append("organizationId", organizationId)

        const result = await this.axiosClient.post(`${IMPORT_BASE_URL}catalog/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        return parseResponse(result, parseBatchImportProductVersionResult)
    }

    buildUrl(organizationId: OrganizationId, path: string) {
        return `${BASE_URL}${organizationId}${path}`
    }

    async fetchProductVersions(
        organizationId: OrganizationId,
        query?: PaginationQuery
    ): Promise<Result<PaginatedProductVersionI, FetchError<PaginatedProductVersionI>>> {
        const url = this.buildUrl(
            organizationId,
            `/product-version${query ? `?${new URLSearchParams(query as Record<string, string>).toString()}` : ""}`
        )
        try {
            const response = await this.axiosClient.get(url)
            return parseResponse(response, parsePaginatedProductVersion)
        } catch {
            return ResultError({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: 500,
                error: `An unexpected error occurred`,
            })
        }
    }

    async createProductVersion(organizationId: OrganizationId, payload: CreateProductVersionPayload) {
        const url = this.buildUrl(organizationId, `/product-version`)
        const response = await this.axiosClient.post(url, payload)
        return parseResponse(response, parseProductVersion)
    }

    async fetchProductVersion(organizationId: OrganizationId, productVersionId: ProductId) {
        const url = this.buildUrl(organizationId, `/product-version/${productVersionId}`)
        const response = await this.axiosClient.get(url)
        return parseResponse(response, parseProductVersion)
    }

    async updateProductVersion(
        organizationId: OrganizationId,
        productVersionId: ProductId,
        payload: Partial<CreateProductVersionPayload>
    ) {
        const url = this.buildUrl(organizationId, `/product-version/${productVersionId}`)
        const response = await this.axiosClient.patch(url, payload)
        return parseResponse(response, parseProductVersion)
    }

    async deleteProductVersion(organizationId: OrganizationId, productVersionId: ProductId) {
        const url = this.buildUrl(organizationId, `/product-version/${productVersionId}`)
        const response = await this.axiosClient.delete(url)
        return response.status >= 200 && response.status < 300
    }
}

export const useCatalogApi = () => {
    const { axiosClient } = useContext(ApiContext)
    return CatalogApi.getInstance(axiosClient)
}
