import { BankConnectorsI, InitiatePaymentPayload } from "~/types"
import axiosClient from "~/api/axiosClient"
import { AxiosResponse } from "axios"

const BASE_URL = import.meta.env.VITE_API_INVOICES_URL
const BASE_URL_PAYMENTS = import.meta.env.VITE_API_PAYMENT_URL

export const paymentApi = {
    getBankConnectors(): Promise<BankConnectorsI[]> {
        const url = BASE_URL_PAYMENTS + "banks"
        return axiosClient.get(url)
    },
    initPayment(invoiceId: string, payload: InitiatePaymentPayload): Promise<AxiosResponse> {
        const url = BASE_URL + "v1/invoices/" + invoiceId + "/initiate-payment"
        return axiosClient.post(url, payload)
    },
}
