import * as Sentry from "@sentry/browser"
import { AxiosResponse } from "axios"

import {
    Result,
    ResultError,
    ResultSuccess,
    getResultErrorValue,
    getResultSuccessValue,
    isResultError,
    isResultSuccess,
} from "~/core/Result"
import { FetchError, FetchErrorType } from "./errors"
import { ParsingErrorType } from "~/utils"

export function parseResponseAsArray<T>(
    response: AxiosResponse<unknown>,
    parser: (data: unknown) => Result<T, ParsingErrorType>
): Result<T[], FetchError<T>> {
    if (response.status < 200 || response.status >= 300) {
        return ResultError({
            type: FetchErrorType.HTTP_REQUEST_ERROR,
            code: response.status,
            error: `${response.data}`,
        })
    }
    if (!Array.isArray(response.data)) {
        const error: FetchError<T> = {
            type: FetchErrorType.PARSING_ERROR,
            partialResults: [],
            errors: [
                {
                    data: response.data,
                    errorsKey: [],
                },
            ],
        }
        Sentry.captureException(error, { extra: { url: response.request?.url } })
        return ResultError(error)
    }
    const results = response.data.map(parser)
    const successResult = results.filter(isResultSuccess)
    if (successResult.length !== results.length) {
        const errors = results.filter(isResultError)
        return ResultError({
            type: FetchErrorType.PARSING_ERROR,
            partialResults: successResult.map(getResultSuccessValue),
            errors: errors.map(getResultErrorValue),
        })
    }
    return ResultSuccess(successResult.map(getResultSuccessValue))
}
