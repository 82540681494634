import React from "react"
import { Grid } from "@mui/material"
import { SharedObjectType } from "~/types/SharedObjects"
import { ShareButton } from "~/components/ShareButton"
import { OrganizationI, ViewTypeI, AuthorizationName } from "~/types"
import { PurchaseOrders, PurchaseOrderStatus } from "../../types/PurchaseOrders"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"
import { Edit2, Send } from "react-feather"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { Button } from "~/components"
import { PurchaseViewType } from "~/domains/transactions/types/Purchases"
import DownloadPOPdf from "../DownloadPOPdf/DownloadPOPdf"
import { useHasPermissions } from "~/domains/identity/store/hooks/useHasPermissions"
import { DomainName, ScopeName } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"

const messages = defineMessages({
    send: { id: "purchase.orders.actionsHeader.send", defaultMessage: "Send" },
    edit: { id: "purchase.orders.actionsHeader.edit", defaultMessage: "Edit" },
    save: { id: "purchase.orders.actionsHeader.save", defaultMessage: "Save" },
    submit: { id: "purchase.orders.actionsHeader.submit", defaultMessage: "Submit" },
})

interface PropsPOActions {
    PO: PurchaseOrders
    organization: OrganizationI
    buyerOrganization: OrganizationSummary
    supplierOrganization: OrganizationSummary
    handleSend: () => void
    handleEdit: () => void
    handleSave: () => void
    handleSubmit: () => void
    mode: PurchaseViewType
    viewType: ViewTypeI
    allLinesApproved: boolean
    isOnApprovalSide: boolean
}

export function ActionsHeader({
    PO,
    organization,
    buyerOrganization,
    supplierOrganization,
    handleSend,
    handleEdit,
    handleSave,
    handleSubmit,
    mode,
    viewType,
    allLinesApproved,
    isOnApprovalSide,
}: PropsPOActions) {
    const { formatMessage } = useIntl()

    const { hasPermission: hasPOEditPermission } = useHasPermissions(
        organization.id ?? "",
        AuthorizationName.UPDATE,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_ORDERS
    )

    const displayEditButton =
        (viewType === ViewTypeI.buyer &&
            (PO.status === PurchaseOrderStatus.AWAITING_BUYER_APPROVAL ||
                PO.status === PurchaseOrderStatus.DRAFT_BUYER ||
                PO.status === PurchaseOrderStatus.SUBMITTED_BUYER)) ||
        (viewType === ViewTypeI.supplier &&
            (PO.status === PurchaseOrderStatus.AWAITING_SUPPLIER_APPROVAL ||
                PO.status === PurchaseOrderStatus.DRAFT_SUPPLIER ||
                PO.status === PurchaseOrderStatus.SUBMITTED_SUPPLIER))

    return (
        <Grid item className={"actions"}>
            {mode === PurchaseViewType.VIEW ? (
                <>
                    <DownloadPOPdf purchaseOrder={PO} />
                    <ShareButton
                        objectId={PO.id}
                        objectType={SharedObjectType.PurchaseOrder}
                        organizationId={organization.id}
                        buyerOrganization={buyerOrganization}
                        supplierOrganization={supplierOrganization}
                    />

                    {displayEditButton && hasPOEditPermission ? (
                        <Button type="primary" onClick={handleEdit}>
                            <Edit2 size={16} />
                            <span>{formatMessage(messages.edit)}</span>
                        </Button>
                    ) : null}

                    {PO.status === PurchaseOrderStatus.DRAFT_BUYER ||
                    PO.status === PurchaseOrderStatus.DRAFT_SUPPLIER ? (
                        <Button type="primary" onClick={handleSubmit}>
                            <span>{formatMessage(messages.submit)}</span>
                        </Button>
                    ) : null}

                    {allLinesApproved && isOnApprovalSide ? (
                        <Button type="primary" onClick={handleSend}>
                            <Send size={16} />
                            <span>{formatMessage(messages.send)}</span>
                        </Button>
                    ) : null}
                </>
            ) : (
                <>
                    <Button type={"tertiary"} onClick={handleSave}>
                        <>
                            <FormattedMessage {...messages.save} />
                        </>
                    </Button>
                </>
            )}
        </Grid>
    )
}
