import React from "react"
import { Modal, Size } from "~/components"
import "./ModalConfirm.scss"
interface Props {
    message: string
    icon: React.ReactNode
    button1: React.ReactNode
    button2: React.ReactNode
    open: boolean
}
export function ModalConfirm({ message, icon, button1, button2, open }: Props) {
    return (
        <Modal open={open} size={Size.SM} className={"modal-confirm"}>
            <Modal.Header>{icon}</Modal.Header>
            <Modal.Content>{message}</Modal.Content>
            <Modal.Footer>
                {button1 ? button1 : null}
                {button2 ? button2 : null}
            </Modal.Footer>
        </Modal>
    )
}
