import React, { useEffect } from "react"
import { Actions, DatatableFilters } from "~/components/DataTable/components/DatatableFilters"
import { defineMessages, useIntl } from "react-intl"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import {
    purchaseRequestsActions,
    selectPurchaseRequestFilter,
} from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { SpiceDBPermissionsResult } from "~/types"

const messages = defineMessages({
    newPR: {
        id: "purchase.requests.newPR",
        defaultMessage: "Request a purchase",
    },
})

interface Props {
    actions: Actions[]
    permissions: SpiceDBPermissionsResult | null
}

export const Filters: React.FC<Props> = ({ actions, permissions }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const filter = useAppSelector(selectPurchaseRequestFilter)

    const handleChangeFilter = (e) => {
        dispatch(purchaseRequestsActions.setFilter(e.target.value))
    }

    useEffect(() => {
        return () => {
            dispatch(purchaseRequestsActions.setFilter(""))
        }
    }, [])

    return (
        <>
            <DatatableFilters
                onChangeFilter={handleChangeFilter}
                valueFilter={filter}
                labelButton={formatMessage(messages.newPR)}
                actionsMenu={true}
                actions={actions}
                hasPermission={permissions?.create ?? false}
            />
        </>
    )
}
