import { useCallback, useState } from "react"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { toast } from "react-toastify"
import { defineMessages, useIntl } from "react-intl"
import { rolesPermissionsApi } from "~/api/rolesPermissionsApi"
import { rolesPermissionsActions } from "../rolesPermissionsSlice"

const messages = defineMessages({
    success: { id: "share.object.delete.success", defaultMessage: "Sharing deleted" },
    error: {
        id: "share.object.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const useDeleteSharedObject = (organizationId: OrganizationId | undefined) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const deleteSharedObject = useCallback(
        async (objectId: string) => {
            if (organizationId) {
                try {
                    setLoading(true)

                    await rolesPermissionsApi.removeSharedObject(organizationId, objectId)

                    dispatch(rolesPermissionsActions.deletedSharedObject(objectId))
                    toast.success(formatMessage(messages.success))
                    setLoading(false)
                } catch (e) {
                    setError(`${e}`)
                    setLoading(false)
                    toast.error(formatMessage(messages.error))
                    throw e
                }
            }
        },
        [organizationId]
    )

    return {
        deleteSharedObject,
        error,
        loading,
    }
}
