import { PayloadAction } from "@reduxjs/toolkit"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { selectUsers, usersActions } from "./usersSlice"
import { userApi } from "~/api"
import { UserI } from "~/types"

function* fetchUsers(action: PayloadAction<string[]>) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const currentUsers = yield select(selectUsers) as unknown as Record<string, UserI>
        const missingUserIds = action.payload.filter((userId) => !currentUsers[userId])
        if (missingUserIds.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const response: UserI[] = yield call(userApi.findUsersByIds, missingUserIds)
            yield put(usersActions.fetchUsersSuccess(response))
        } else {
            yield put(usersActions.fetchUsersSuccess([]))
        }
    } catch (e) {
        yield put(usersActions.fetchUsersFailed(`${e}`))
    }
}

export default function* usersSaga() {
    yield all([takeLatest(usersActions.fetchUsers.type, fetchUsers)])
}
