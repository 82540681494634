import React, { useCallback } from "react"
import { FromRecommandationValues, SelectedTagI } from "../../types"
import { Tooltip, styled } from "@mui/material"
import { CrossIcon } from "~/components/Icons"
import { getTagTooltip } from "~/domains/common/getTagTooltip"
import { useIntl } from "react-intl"

interface Props {
    tag: SelectedTagI
    showTagGroup?: boolean
    removeTag?: (tag: SelectedTagI) => void
    setTagToEdit?: React.Dispatch<React.SetStateAction<SelectedTagI | null>>
}

export const TagContainer = styled("div")<{ hasRatio: boolean }>(({ theme, hasRatio }) => ({
    display: "flex",
    gap: "4px",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    padding: "2px 6px 2px 9px",
    borderRadius: "24px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    cursor: hasRatio ? "pointer" : "default",
}))

const AutoContainer = styled("span")(({ theme }) => ({
    textTransform: "lowercase",
    fontVariant: "small-caps",
    fontWeight: 800,
    backgroundColor: theme.palette.primary.main,
    lineHeight: "1em",
    padding: "0 2px 1px",
    color: theme.palette.primary.contrastText,
    borderRadius: "2px",
}))

export const Tag: React.FC<Props> = ({ tag, showTagGroup = true, removeTag, setTagToEdit }) => {
    const { formatNumber } = useIntl()
    const onCrossClick = useCallback(
        (event: React.MouseEvent<SVGSVGElement>) => {
            event.stopPropagation()
            if (removeTag) {
                removeTag(tag)
            }
        },
        [removeTag, tag]
    )
    const onTagClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            if (tag.ratio && setTagToEdit) {
                event.stopPropagation()
                setTagToEdit(tag)
            }
        },
        [tag, setTagToEdit]
    )
    return (
        <Tooltip title={getTagTooltip(tag)}>
            <TagContainer hasRatio={typeof tag.ratio === "number"} onClick={onTagClick}>
                {tag.fromRecommandation === FromRecommandationValues.AUTO && <AutoContainer>AUTO</AutoContainer>}
                {showTagGroup &&
                    tag.tagGroup &&
                    (tag.tagGroup.shortName ? `${tag.tagGroup.shortName}: ` : `${tag.tagGroup.name.substring(0, 3)}: `)}
                {tag.name}
                {typeof tag.ratio === "number"
                    ? ` (${formatNumber(tag.ratio, { style: "percent", maximumFractionDigits: 0 })})`
                    : null}
                {tag.value ? ` (${tag.value})`:null}
                {removeTag && <CrossIcon size={18} color="var(--primary-color)" onClick={onCrossClick} />}
            </TagContainer>
        </Tooltip>
    )
}
