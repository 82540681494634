import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useCallback, useEffect, useState } from "react"
import { SelectedTagWithObjectIdI, TagGroupI, TagObjectI, TagObjectType } from "~/domains/tags/types"
import { AnalyticalItem } from "~/domains/transactions/custom-fields/components/AnalyticsTable"
import { useTagsApi } from "~/domains/tags/tagsApi"
import { getResultSuccessValue, isResultSuccess } from "~/core/Result"
import { isDefined } from "~/utils/isDefined"
import { manageSelectedTags, useCreateTagObject, useDeleteTagObject } from "~/domains/tags/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { useUpdateTagObject } from "~/domains/tags/hooks/useUpdateTagObject"
import { selectLinesTags, tagsActions } from "~/domains/tags/store/tagsSlice"

type TagsforLines = {
    loading: boolean
    selectedTags: SelectedTagWithObjectIdI[]
    setSelectedTags: (
        tagsOrFunction: SelectedTagWithObjectIdI[] | ((state: SelectedTagWithObjectIdI[]) => SelectedTagWithObjectIdI[])
    ) => void
    fetchTags: () => Promise<void>
}

interface Props<T extends AnalyticalItem> {
    lines: T[]
    organizationId?: string
    tagGroups: TagGroupI[] | null
    objectType: TagObjectType
}

const findTagInTagGroups = (tag: TagObjectI, tagGroups: TagGroupI[] | null): SelectedTagWithObjectIdI | null => {
    if (!tagGroups || !tagGroups.length) return null

    const t = tagGroups.flatMap((tagGroup) => tagGroup.tags).find((t) => t.tagId === tag.tagId)
    return t
        ? { ...t, objectId: tag.objectId, tagGroup: tagGroups.find((tagGroup) => tagGroup.tagGroupId === t.tagGroupId) }
        : null
}

const lineHasId = (line: AnalyticalItem): line is AnalyticalItem & { id: string } => "id" in line

export const useTagsForLines = ({
    lines,
    organizationId,
    tagGroups,
    objectType,
}: Props<AnalyticalItem>): TagsforLines => {
    const dispatch = useAppDispatch()
    const tagsApi = useTagsApi()
    const tags = useAppSelector(selectLinesTags)
    const user = useAppSelector(selectUser)
    const [selectedTags, setSelectedTags] = useState<SelectedTagWithObjectIdI[]>([])
    const [loading, setLoading] = useState(false)

    const createTagObject = useCreateTagObject(organizationId, undefined, objectType)
    const updateTagObject = useUpdateTagObject(organizationId, undefined, objectType)
    const deleteTagObject = useDeleteTagObject(organizationId, undefined)

    useEffect(() => {
        setSelectedTags(tags)
    }, [tags])

    const setSelectedTagsWraper = useCallback(
        (fn: ((state: SelectedTagWithObjectIdI[]) => SelectedTagWithObjectIdI[]) | SelectedTagWithObjectIdI[]) => {
            setSelectedTags((previousState) => {
                const newSate = manageSelectedTags(
                    previousState,
                    fn,
                    user.id,
                    createTagObject,
                    deleteTagObject,
                    updateTagObject
                )
                dispatch(tagsActions.setLinesTags(newSate))
                return newSate
            })
        },
        [setSelectedTags, dispatch, user, createTagObject, updateTagObject, deleteTagObject]
    )

    const fetchTags = useCallback(async () => {
        if (!lines.length || !organizationId) return

        setLoading(true)
        const results = await Promise.all(
            lines.filter(lineHasId).map((line) => tagsApi.getTagObjects(organizationId, line.id))
        )

        const tags = results.flatMap((res) => res.filter(isResultSuccess).map(getResultSuccessValue))
        const tagsWithObjectIds = tags
            .map((tag) => {
                const tagFromTagGroup = findTagInTagGroups(tag, tagGroups)
                if (tagFromTagGroup) {
                    return tagFromTagGroup
                }
                return null
            })
            .filter(isDefined)

        setLoading(false)
        setSelectedTags(tagsWithObjectIds)
        dispatch(tagsActions.setLinesTags(tagsWithObjectIds))
    }, [setSelectedTagsWraper, lines, organizationId, tagGroups])

    return { loading, selectedTags, setSelectedTags: setSelectedTagsWraper, fetchTags }
}
