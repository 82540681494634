import * as t from "io-ts"
import { Opaque } from "~/utils"

export enum DocumentType {
    CONTRACT = "CONTRACT",
    INVOICE = "INVOICE",
    QUOTE = "QUOTE",
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
    PURCHASE_ORDER = "PURCHASE_ORDER",
    DELIVERY_RECEIPT = "DELIVERY_RECEIPT",
    GOODS_RECEIPT = "GOODS_RECEIPT",
    BUSINESS_IDENTITY_PROOF = "BUSINESS_IDENTITY_PROOF",
    BANK_ACCOUNT_DETAILS = "BANK_ACCOUNT_DETAILS",
    ECOVADIS = "ECOVADIS",
    ISO = "ISO",
    DNB = "DNB",
    OTHER = "OTHER",
}

export enum DocumentObjectType {
    INVOICE = "INVOICE",
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
    PURCHASE_ORDER = "PURCHASE_ORDER",
    PARTNERSHIP = "PARTNERSHIP",
    OTHER = "OTHER",
    KYB = "KYB",
}

export type ObjectId = Opaque<string, { readonly T: unique symbol }>

export type DocumentId = Opaque<string, { readonly T: unique symbol }>

export const DocumentDataIO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        documentType: t.string,
        createdAt: t.string,
        updatedAt: t.string,
        latestFileVersion: t.string,
    }),
    t.partial({
        maxVersions: t.number,
        objectType: t.string,
    }),
])

export type DocumentDataI = t.TypeOf<typeof DocumentDataIO> & {
    id: DocumentId
    documentType: DocumentType
    objectType?: DocumentObjectType
    createdAt: Date
    updatedAt: Date
}

export type DraftDocumentI = {
    file: File
    name: string
    documentType: DocumentType
    objectType?: DocumentObjectType
}

export const DocumentAPIResponseIO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        documentType: t.string,
        createdAt: t.string,
        updatedAt: t.string,
        latestFileVersion: t.string,
    }),
    t.partial({
        maxVersions: t.number,
        versions: t.array(DocumentDataIO),
    }),
])

export type DocumentAPIResponseI = t.TypeOf<typeof DocumentAPIResponseIO> & {
    id: DocumentId
    documentType: DocumentType
    createdAt: Date
    updatedAt: Date
}

export const DocumentSuccessResponseIO = t.type({ message: t.string })
export type DocumentSuccessResponseI = t.TypeOf<typeof DocumentSuccessResponseIO>

export const DocumentErrorResponseIO = t.type({ error: t.string })
export type DocumentErrorResponseI = t.TypeOf<typeof DocumentErrorResponseIO>

export type UploadDocumentDTO = {
    file: File
    name: string
    documentType: DocumentType
    objectType: DocumentObjectType
    organizationIds: string[]
    objectId?: string
}

export const UploadDocumentResponseIO = t.type({
    organizationId: t.string,
    id: t.string,
    objectId: t.string,
    objectType: t.string,
    userId: t.string,
    createdAt: t.string,
    updatedAt: t.string,
    latestFileVersion: t.string,
})

export type UploadDocumentResponseI = t.TypeOf<typeof UploadDocumentResponseIO> & {
    id: DocumentId
    documentType: DocumentType
    objectType: DocumentObjectType
    createdAt: Date
    updatedAt: Date
}

export interface DuplicateDocumentPayload {
    sourceObjectId: string
    targetObjectId: string
}

export const AttachedToIO = t.partial({
    createdAt: t.string,
    documentId: t.string,
    objectId: t.string,
    objectType: t.string,
    organizationId: t.string,
    updatedAt: t.string,
    userId: t.string,
})

export const UploadDocumentResponseV2IO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        documentType: t.string,
        createdAt: t.string,
        updatedAt: t.string,
        latestFileVersion: t.string,
        attachedTo: AttachedToIO,
    }),
    t.partial({
        maxVersions: t.number,
        description: t.string,
    }),
])

export type UploadDocumentResponseV2I = t.TypeOf<typeof UploadDocumentResponseV2IO> & {
    id: DocumentId
    documentType: DocumentType
    createdAt: Date
    updatedAt: Date
}
